import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "log-item",
  templateUrl: "./log-item.component.html",
  styleUrls: ["./log-item.component.scss"]
})
export class LogItemComponent {
  @Input() headers = true;
  @Input() item;
  @Input() timeline: boolean;
  constructor() { }
}
