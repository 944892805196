import {
  OnInit,
  Input,
  Output,
  EventEmitter,
  Component
} from "@angular/core";

@Component({
  selector: 'bdr-create-delete-banner',
  templateUrl: './bdr-create-delete-banner.component.html',
  styleUrls: ['./bdr-create-delete-banner.component.scss']
})

export class BdrCreateDeleteBannerComponent implements OnInit {

  @Input() showCreateDeleteBanner: { showNewRows: boolean; showDeletesOrCopy: boolean }

  @Output() onCreateRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteRows: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCopyRows: EventEmitter<any> = new EventEmitter<any>();

  newRowObject: any;
  deleteRowsObject: any;
  copyRowsObject: any;

  constructor() {
    this.newRowObject = {
      showNewRow: false,
      confirmNewRow: false
    };
    this.deleteRowsObject = {
      showDeleteRows: false,
      confirmDeleteRows: false
    };

    this.copyRowsObject = {
      showCopyRows: false,
      confirmCopyRows: false
    };
  }


  ngOnInit() { }

  toggleNewRow() {
    this.newRowObject = {
      showNewRow: !this.newRowObject.showNewRow,
      confirmNewRow: false
    }
    this.onCreateRow.emit(this.newRowObject);
  }

  confirmNewRow() {
    this.newRowObject = {
      showNewRow: false,
      confirmNewRow: true
    };
    this.onCreateRow.emit(this.newRowObject);
  }

  toggleDeleteOrCopyRows() {
    this.deleteRowsObject = {
      showDeleteRows: true,
      confirmDeleteRows: false
    };
    this.onDeleteRows.emit(this.deleteRowsObject);

    this.copyRowsObject = {
      showCopyRows: true,
      confirmCopyRows: false
    };

    this.onCopyRows.emit(this.copyRowsObject);


    this.showCreateDeleteBanner = {
      showNewRows: true,
      showDeletesOrCopy: false
    }
  }

  confirmDeleteRows() {
    this.deleteRowsObject = {
      showDeleteRows: false,
      confirmDeleteRows: true
    };
    this.onDeleteRows.emit(this.deleteRowsObject);


    this.showCreateDeleteBanner = {
      showNewRows: true,
      showDeletesOrCopy: false
    }
  }

  confirmCopyRows() {
    this.copyRowsObject = {
      showCopyRows: false,
      confirmCopyRows: true
    };

    this.onCopyRows.emit(this.copyRowsObject);

    this.showCreateDeleteBanner = {
      showNewRows: true,
      showDeletesOrCopy: false
    }
  }
};

