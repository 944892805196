import { Pipe, PipeTransform } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

interface Filter {
  name: string;
  label: string;
}
interface ArgsFilter {
  search: string;
  filters: Filter[];
}

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any, args?: ArgsFilter): any {
    if (this.hasArgs(args)) {
      const filterNames = args.filters.map(filter => filter.name);
      const search = args.search.toLowerCase();

      return this.setItems(items).filter(item => {
        return filterNames.some(key => {
          if (!item[key]) {
            return;
          }
          const value = item[key].toString().toLowerCase();
          return value.includes(search);
        });
      });
    }
    return items;
  }

  private hasArgs(args: ArgsFilter): boolean {
    return args && args.search && args.filters.length > 0;
  }

  private setItems(items) {
    return items instanceof MatTableDataSource ? items.data : items;
  }
}
