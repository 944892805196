import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
  OnChanges,
  ChangeDetectionStrategy,
  NgZone,
  ChangeDetectorRef
} from '@angular/core';
import { SelectOption } from '../../models/common';
import { safeDetectChanges } from '../../util';

@Component({
  selector: 'bdr-select',
  templateUrl: './bdr-select.component.html',
  styleUrls: ['./bdr-select.component.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class BdrSelectComponent implements OnInit, OnChanges {
  @Input() options: SelectOption[];
  @Input() size: string;
  @Input() bold: boolean;
  @Input() caption: string;
  @Input() model: string;
  @Input() error: boolean;
  @Input() errorMsg: string;
  @Input() bottomError: boolean;
  @Input() direction: string;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() horizontal: boolean;
  @Input() initOpen: boolean;
  @Input() initialized: boolean;

  @Output() onInitialized: EventEmitter<any> = new EventEmitter();
  @Output() modelChange: EventEmitter<string> = new EventEmitter<string>();

  selectedOption: SelectOption;
  showOptions: boolean;
  init = false;

  constructor(private cdr: ChangeDetectorRef) {
    this.selectedOption = null;
    this.size = 'wide';
    this.error = false;
    this.errorMsg = '';
    this.direction = 'bottom';
    this.caption = '';
    this.placeholder = 'Elige una opción';
    this.disabled = false;
    this.showOptions = false;
    this.options = [];
  }

  ngOnInit() {
    if (this.model && this.options) {
      const modelOption = this.options.find(o => o.value === this.model);
      if (modelOption) {
        this.selectedOption = modelOption;
      }
    }
  }
  ngAfterViewInit() {
    if (!this.initialized && !this.showOptions && this.initOpen) {
      setTimeout(() => {
        if (!this.cdr['destroyed']) {
          this.showOptions = true;
          this.initialized = true;
          this.cdr.detectChanges()
          this.onInitialized.emit('true');
        } else {
          this.cdr.detach();
        }
      }, 0);
    }
  }
  ngOnDestroy() {
    this.cdr.detach();
  }
  ngOnChanges(changes: any) {
    if (changes.model) {
      this.model = changes.model.currentValue;

      const modelOption =
        this.model === ''
          ? { name: '', value: '' }
          : this.options.find(o => o.value === this.model);
      this.selectedOption = modelOption;
    }

    if (changes.options && !!changes.options.currentValue) {
      this.options = changes.options.currentValue;
      const modelOption =
        this.model === ''
          ? { name: '', value: '' }
          : this.options.find(o => o.value === this.model);
      this.selectedOption = modelOption;
    }
  }

  @HostListener('body:mouseup', ['$event'])
  hideOptions(event?): void {
    let target = event.target;
    if (!target || (this.showOptions && target && !/options/gi.test(target.className))) {
      event.stopPropagation();
      setTimeout(() => {
        this.showOptions = false;
      }, 0);
    }
  }

  selectOption(option: SelectOption): void {
    if (!this.disabled) {
      this.selectedOption = option;
      this.modelChange.emit(option.value);
      this.showOptions = false;
    }
  }

  toggleOptions(): void {
    if (!this.disabled) {
      this.showOptions = !this.showOptions;
    }
  }
}
