import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'dialog-filter-to-folder',
  templateUrl: './dialog-filter-to-folder.component.html',
  styleUrls: ['./dialog-filter-to-folder.component.scss']
})
export class DialogFilterToFolderComponent {
  @Input() id: any;
  @Input() filter: any = {};
  @Input() folders: any[] = [];
  @Output() save: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCloseAction: EventEmitter<any> = new EventEmitter<any>();
  folderId: number;
  constructor() { }

  cancel() {
    this.onCloseAction.emit(this.id);
  }

  saveChanges() {
    let emitObj = {
      filter: this.filter,
      folder: this.folderId
    };
    this.save.emit(emitObj);
    this.onCloseAction.emit(this.id);
  }
}
