import {
  Component,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core';

@Component({
  selector: 'bdr-input-predictive',
  templateUrl: './bdr-input-predictive.component.html',
  styleUrls: ['./bdr-input-predictive.component.scss']
})
export class BdrInputPredictiveComponent implements AfterViewInit {
  @ViewChild('input', { static: false }) inputElement: ElementRef;

  @Input() model: string;
  @Input() placeholder: string;

  @Input() disabled: boolean;
  @Input() border: boolean;
  @Input() size: string;

  @Output() modelChange: EventEmitter<string>;
  @Output() modelReset: EventEmitter<void>;

  constructor() {
    this.model = '';
    this.placeholder = 'Buscar';
    this.disabled = false;
    this.border = true;
    this.size = '';

    this.modelChange = new EventEmitter<string>();
    this.modelReset = new EventEmitter<void>();
  }

  ngAfterViewInit() {
    if (this.model && this.model.length > 0) {
      const input: HTMLInputElement = this.inputElement.nativeElement;

      input.classList.remove('ng-pristine');
      input.classList.add('ng-dirty');
    }
  }

  reset(): void {
    this.emitChange('');
    this.modelReset.emit();
  }

  emitChange(model: string): void {
    this.modelChange.emit(model);
  }
}
