
import { throwError as observableThrowError, Observable, Subject } from 'rxjs';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";

import { LogService } from "./../../../services/log/log.service";
import { uniq } from "lodash";
import * as moment from "moment";
import { catchError, takeUntil } from 'rxjs/operators';
import { safeDetectChanges } from '../../../util';

@Component({
  selector: "dialog-log-list",
  templateUrl: "./dialog-log-list.component.html",
  styleUrls: ["./dialog-log-list.component.scss"]
})
export class DialogLogListComponent implements OnInit, OnDestroy {
  loading = false;
  error = false;
  item: any;
  origin: any;
  logs: any;
  timeline: boolean = true;
  subscriptions = [];
  private destroy$: Subject<any> = new Subject()
  constructor(
    private logService: LogService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.loading = true;
    this.logService
      .getLogs(this.origin, this.item)
      .pipe(catchError(err => {
        this.loading = false;
        this.error = true;
        this.cdRef.detectChanges()
        return observableThrowError(err);
      }))
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.loading = false;
        this.error = false;
        res.result.forEach(item => {
          item = this.logService.mergeChanges(item);
        });
        this.logs = this.generateTimelineData(res.result);
        this.cdRef.detectChanges()
      });

  }

  ngOnDestroy() {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  generateTimelineData(logs) {
    let result = [];
    const years = uniq(
      logs.map(item => {
        return new Date(item.timestamp).getFullYear();
      })
    );
    years.forEach(year => {
      let yearItem = { year, months: [], rows: 0 };
      const yearResults = logs.filter(
        item => new Date(item.timestamp).getFullYear() === year
      );
      const months = uniq(
        yearResults.map(item => {
          return moment(item.timestamp).format("MMM");
        })
      );
      months.forEach(month => {
        let monthItem = { month, items: [], rows: 0 };

        monthItem.items = yearResults.filter(
          item => moment(item.timestamp).format("MMM") === month
        );
        monthItem.rows = monthItem.items.reduce((prev, current) => {
          prev = typeof prev === "object" ? prev.changes.length : prev;
          if (prev) {
            return prev + current.changes.length;
          }
          return current.changes.length;
        });
        yearItem.months.push(monthItem);
      });
      result.push(yearItem);
    });
    return result;
  }

  keys(obj) {
    return Object.keys(obj);
  }
}
