import { Pipe, PipeTransform } from '@angular/core';

import { formatCurrency } from '../util';

@Pipe({
  name: 'localeCurrency'
})
export class LocaleCurrencyPipe implements PipeTransform {
  transform(value: number, hasDecimals: boolean = false): string {
    return formatCurrency(value, hasDecimals);
  }
}
