import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OriginService {

  constructor() { }

  getHost() {
    return environment.host;
  }

  getFilterOrigins() {
    return Object.assign({}, environment.filters.filterOrigins)
  }

  getAllOrigins() {
    return [...environment.origins.allOrigins]
  }

  getFirstByReloadKey(reloadValue) {
    let origins = [...environment.origins.allOrigins]
    let result = origins.find((origin) => origin.reload === reloadValue && origin.reloadNicename !== undefined)
    
    return result
  }

  getAll() {
    return Object.assign({}, environment.origins)
  }

  getByKey(originKey) {
    return environment.origins[originKey]
  }

  getByOrigin(origin) {
    return environment.origins.allOrigins.find(item => item.origin === origin)
  }

  getOriginsFilteredByColumns() {
    return this.getAllOrigins()
      .filter(e => e.filteredConsult)
      .map(e => e.origin)
  }

  getOriginsWithValidations() {
    return this.getAllOrigins()
      .filter(e => e.hasValidations)
      .map(e => e.origin);
  }
}
