import { Component, ChangeDetectionStrategy } from "@angular/core";

import { ContextualmenuContent } from "../contextual-menu-content";

import { BdrContextualMenuService } from "../../../services/bdr-contextual-menu/bdr-contextual-menu.service";

@Component({
  selector: "ctx-grid-sort-menu",
  templateUrl: "./ctx-grid-sort-menu.component.html",
  styleUrls: ["./ctx-grid-sort-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CtxGridSortMenuComponent extends ContextualmenuContent {
  constructor(private _ctxmenuService: BdrContextualMenuService) {
    super(_ctxmenuService);
  }

  sort(dir: "asc" | "desc"): void {
    this.content.order[dir]();
    this.close();
  }
}
