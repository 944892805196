import { throwError as observableThrowError, Subject, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';

import { NotificationsService } from './../notifications/notifications.service';
import { DataApiService } from './../data-api/data-api.service';
import { OriginService } from './../origin/origin.service';

const SHEETS_WITH_CHECK_DECLARED = [
  'investments',
  'installations',
  'work_closure',
  'masterdata',
];

@Injectable()
export class TasksService {
  private tasksSubject: Subject<any> = new Subject<any>();
  private currentTasks: any[] = [];

  constructor(
    private request: DataApiService,
    private notificationsService: NotificationsService,
    private originService: OriginService,
  ) {}

  destroy() {
    this.currentTasks.forEach((task) => {
      task.unsubscribe();
    });
  }

  getTasksObservable() {
    this.getNotifications();
    return this.tasksSubject.asObservable();
  }

  getNotifications() {
    return this.notificationsService
      .getNotificationObservable()
      .pipe(
        map((notifications) =>
          notifications.filter((notification) => notification.class == 'executing_process'),
        ),
      )
      .subscribe((notifications) => {
        this.currentTasks = notifications;
        this.tasksSubject.next(this.currentTasks);
      });
  }

  getCurrentTasks() {
    return this.currentTasks;
  }

  reloadMasterdata(skipTransactional): void {
    return this.reloadSheet('masterdata', {
      reloadNicename: 'Maestros',
      skipTransactional: skipTransactional,
    });
  }

  reloadWorkClosure(): void {
    return this.reloadSheet('work-closure', { reloadNicename: 'Cierre de Obra' });
  }

  panicButton(): void {
    return this.reloadSheet('panic-button');
  }

  sheetsReload(sheets) { 
    this.request.sheetsReload(sheets)
      .pipe(
        catchError((err) => {
          this.notificationsService.add({
            type: 'error',
            message: 'Ha ocurrido un error al lanzar la tarea',
            fixed: false,
            popup: true,
          });
          return throwError(err);
        }),
      )
      .subscribe((res) => {
        if (res.result === 'ok') {
          this.notificationsService.add({
            type: 'info',
            message: 'La tarea se ha planificado correctamente y se ejecutará en breve.',
            fixed: false,
            popup: true,
          });
        }
      });
  }
  
  reloadSheet(sheet: string, body = {}): void {
    if (body['reloadNicename'] === undefined) {
      let origin = this.originService.getFirstByReloadKey(sheet);

      if (origin) {
        var reloadNicename = origin['reloadNicename'];
        reloadNicename = reloadNicename.replace('Sábana de ', '');

        body['reloadNicename'] = reloadNicename;
      }
    }

    let sheets_need_check_declared = [
      'installations',
      'investments',
      'work-closure',
      'masterdata',
    ];

    if (sheets_need_check_declared.includes(sheet) && !body['skipCheckDeclared']) {
      let message = `Se está revisando si hay expedientes ya declarados antes de lanzar la sábana '${body['reloadNicename']}'`;
      this.notificationsService.add({
        type: 'info',
        message: message,
        fixed: false,
        popup: true,
      });
    }

    this.request
      .reloadSheet(sheet, body)
      .pipe(
        catchError((err) => {
          this.notificationsService.add({
            type: 'error',
            message: 'Ha ocurrido un error al lanzar la tarea',
            fixed: false,
            popup: true,
          });
          return throwError(err);
        }),
      )
      .subscribe((res) => {
        if (res.result === 'ok') {
          this.notificationsService.add({
            type: 'info',
            message: 'La tarea se ha planificado correctamente y se ejecutará en breve.',
            fixed: false,
            popup: true,
          });
        }
      });
  }

  reloadFormCnmcValidations(form) {
    this.request
      .reloadFormCnmcValidations(form)
      .pipe(
        catchError((err) => {
          this.notificationsService.add({
            type: 'error',
            message: 'Ha ocurrido un error al lanzar la tarea',
            fixed: false,
            popup: true,
          });
          return throwError(err);
        }),
      )
      .subscribe((res) => {
        this.notificationsService.add({
          type: 'info',
          message: 'La tarea se ha planificado correctamente y se ejecutará en breve.',
          fixed: false,
          popup: true,
        });
      });
  }

  reloadFormCnmcErrorLogs(form) {
    this.request
      .reloadFormCnmcErrorLogs(form)
      .pipe(
        catchError((err) => {
          this.notificationsService.add({
            type: 'error',
            message: 'Ha ocurrido un error al lanzar la tarea',
            fixed: false,
            popup: true,
          });
          return throwError(err);
        }),
      )
      .subscribe((res) => {
        this.notificationsService.add({
          type: 'info',
          message: 'La tarea se ha planificado correctamente y se ejecutará en breve.',
          fixed: false,
          popup: true,
        });
      });
  }

  closeAuditory() {
    this.request
      .closeAuditory()
      .pipe(
        catchError((err) => {
          this.notificationsService.add({
            type: 'error',
            message: 'Ha ocurrido un error cerrando la auditoría',
            fixed: false,
            popup: true,
          });
          return throwError(err);
        }),
      )
      .subscribe((res) => {
        this.notificationsService.add({
          type: 'info',
          message: 'La auditoría se ha cerrado correctamente.',
          fixed: false,
          popup: true,
        });
      });
  }

  openAuditory(id) {
    this.request
      .openAuditory(id)
      .pipe(
        catchError((err) => {
          this.notificationsService.add({
            type: 'error',
            message: 'Ha ocurrido un error abriendo la auditoría.',
            fixed: false,
            popup: true,
          });
          return throwError(err);
        }),
      )
      .subscribe((res) => {
        this.notificationsService.add({
          type: 'info',
          message: 'La auditoría se ha abierto.',
          fixed: false,
          popup: true,
        });
      });
  }

  lockWorkclosureEditions() {
    this.request
      .closeAuditory()
      .pipe(
        catchError((err) => {
          this.notificationsService.add({
            type: 'error',
            message: 'Ha ocurrido un error bloqueando las ediciones del Cierre de Obra',
            fixed: false,
            popup: true,
          });
          return throwError(err);
        }),
      )
      .subscribe((res) => {
        this.notificationsService.add({
          type: 'info',
          message: 'Se han bloqueado las ediciones del Cierre de Obra.',
          fixed: false,
          popup: true,
        });
      });
  }

  unlockWorkclosureEditions(id) {
    this.request
      .openAuditory(id)
      .pipe(
        catchError((err) => {
          this.notificationsService.add({
            type: 'error',
            message: 'Ha ocurrido desbloqueado las ediciones del Cierre de Obra.',
            fixed: false,
            popup: true,
          });
          return throwError(err);
        }),
      )
      .subscribe((res) => {
        this.notificationsService.add({
          type: 'info',
          message: 'Se han desbloqueado las ediciones del Cierre de Obra',
          fixed: false,
          popup: true,
        });
      });
  }
}
