import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  ElementRef
} from "@angular/core";

import { Coordinates, ComponentBindings } from "../../models/common";
import {
  BdrContextualMenuService,
  CtxContext,
  CtxOptions
} from "./../../services/bdr-contextual-menu/bdr-contextual-menu.service";
import { WindowService } from "./../../services/window/window.service";

@Component({
  selector: "bdr-contextual-menu",
  templateUrl: "./bdr-contextual-menu.component.html",
  styleUrls: ["./bdr-contextual-menu.component.scss"],
  providers: [WindowService]
})
export class BdrContextualMenuComponent implements OnChanges {
  @ViewChild("menu", { static: true })
  menuElement: ElementRef;

  @ViewChild("placeholder", { read: ViewContainerRef, static: true })
  placeholderElement: ViewContainerRef;

  @ViewChild("rulerCanvas", { static: true })
  rulerCanvasElement: ElementRef;

  @Input() target: HTMLElement;
  @Input() component: Component;
  @Input() options: CtxOptions;
  @Output() onClose: EventEmitter<void>;

  position: Coordinates;
  bindings: ComponentBindings;
  positioning: string;
  cssHidden = true;
  window: Window;
  rulerCtx: CanvasRenderingContext2D;

  __ngOnChangesBypass: Function;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private windowService: WindowService,
    private hostElement: ElementRef,
    private ctxmenuService: BdrContextualMenuService
  ) {
    this.target = null;
    this.component = null;
    this.options = null;

    this.onClose = new EventEmitter<void>();

    this.position = { x: 0, y: 0 };
    this.positioning = "top";

    this.window = windowService.window;

    this.__ngOnChangesBypass = this.ngOnChanges;
  }

  public ngOnChanges() {
    if (this.component) {
      this.bindings = {
        inputs: {
          content: this.options.body
        },
        outputs: {
          onClose: () => this.close(),
          onContentLoaded: () => this.contentLoaded()
        }
      };

      const targetSizing: ClientRect = this.target.getBoundingClientRect();

      const dialogLeftOffset = 0;
      let dialogScrollOffset = 0;
      if (this.ctxmenuService.context === CtxContext.dialog) {
        dialogScrollOffset = this.ctxmenuService.dialogOverlayScroll;
      }

      this.position = {
        x: targetSizing.left + targetSizing.width / 2 - dialogLeftOffset,
        y: targetSizing.top + targetSizing.height + 5 + dialogScrollOffset
      };
      const rulerElement: HTMLCanvasElement = this.rulerCanvasElement
        .nativeElement;
      this.rulerCtx = rulerElement.getContext("2d");
    }
  }

  @HostListener("click")
  layerLClick() {
    this.close();
  }

  @HostListener("contextmenu", ["$event"])
  layerRClick(evt: MouseEvent) {
    evt.preventDefault();
    this.close();
  }

  close(): void {
    this.onClose.emit();
  }

  private contentLoaded(): void {
    const menuSizing: ClientRect = this.menuElement.nativeElement.getBoundingClientRect();
    const targetSizing: ClientRect = this.target.getBoundingClientRect();
    let dialogScrollOffset = 0;
    let windowHeight = this.window.innerHeight;
    const windowWidth = this.window.innerWidth;
    if (this.ctxmenuService.context === CtxContext.dialog) {
      dialogScrollOffset = this.ctxmenuService.dialogOverlayScroll;
      windowHeight = this.ctxmenuService.dialogWrapperRect.height;
    }

    const vDelta = windowHeight - (this.position.y + menuSizing.height);
    if (vDelta < 0) {
      setTimeout(() => {
        this.position.y =
          windowHeight - targetSizing.top + 5 - dialogScrollOffset;
        this.positioning = "bottom";
      }, 0);
    }

    this.adjustPositionToAlignment();

    if (this.position.x - this.menuElement.nativeElement.offsetWidth / 2 < 0) {
      setTimeout(() => {
        this.position.x += this.menuElement.nativeElement.offsetWidth / 2;
      }, 0);
    }
    if (
      this.position.x + this.menuElement.nativeElement.offsetWidth / 2 >
      windowWidth
    ) {
      setTimeout(() => {
        this.position.x -= this.menuElement.nativeElement.offsetWidth / 2;
      }, 0);
    }

    setTimeout(() => {
      this.cssHidden = false;
    }, 10);
  }

  adjustPositionToAlignment() {
    if (this.options.alignment === "right") {
      setTimeout(() => {
        this.position.x -=
          this.menuElement.nativeElement.offsetWidth / 2 -
          this.target.offsetWidth / 2;
      }, 0);
    }
    if (this.options.alignment === "left") {
      setTimeout(() => {
        this.position.x +=
          this.menuElement.nativeElement.offsetWidth / 2 +
          this.target.offsetWidth / 2;
      }, 0);
    }
  }
}
