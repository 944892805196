import {
  trigger,
  state,
  animate,
  transition,
  style
} from '@angular/animations';

export const slideInOutAnimation =
  // trigger name for attaching this animation to an element using the [@triggerName] syntax
  trigger('slideInOutAnimation', [
    // end state styles for route container (host)
    state(
      '*',
      style({
        transform: 'translateX(0)',
        opacity: 1
      })
    ),

    // route 'enter' transition
    transition(':enter', [
      // styles at start of transition
      style({
        transform: 'translateX(-100%)',
        opacity: 0
      }),

      // animation and styles at end of transition
      animate(
        '.7s ease',
        style({
          transform: 'translateX(0%)',
          opacity: 1
        })
      )
    ]),

    // route 'leave' transition
    transition(':leave', [
      // animation and styles at end of transition
      animate(
        '.7s ease',
        style({
          transform: 'translateX(-100%)',
          opacity: 0
        })
      )
    ])
  ]);
