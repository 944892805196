import { Injectable } from '@angular/core';
import { Subject, forkJoin, throwError, of, Observable } from 'rxjs';
import { startWith, catchError, reduce } from 'rxjs/operators';
import { Schema } from '../schema/schema.service';
import { FilterDB } from './../filter/filter.service';
import { MastersService } from '../masters/masters.service';
import { merge, groupBy } from 'lodash';

const FIELD_SPECIAL_CASES = ['tipologia1', 'tipologia2', 'tipologia_inversion2'];
import { Notification, NotificationsService } from '../notifications/notifications.service';


@Injectable()
export class GridService {
  performanceTypologies: any[];
  saveGridSubject: Subject<any> = new Subject<any>();
  defaultSchemaProperties = {
    inventory: {},
    forms: {},
    investments_adjustment: {}
  };
  constructor(
    private notificationsService: NotificationsService
  ) {
  }

  getDefaultGridProperties(): GridProperties {
    return {
      bordered: true,
      defaultSchemaVisibility: {},
      title: '',
      origin: '',
      filterType: 'filter',
      globalEditMode: false,
      showSelectBox: true,
      selectionMode: 'Row',
      export: false,
      edit: false,
      currentFilter: null,
      gridHeight: null
    };
  }

  resetSchema(scope, origin, currentSchema = {}, defaultSchema): any {
    let defaultSchemaProperty = {},
      schemaObj: any = {};
    if (scope) {
      defaultSchemaProperty[scope] = {};
      defaultSchemaProperty = this.defaultSchemaProperties[scope][origin] || {};
      schemaObj = currentSchema
        ? merge(schemaObj, currentSchema, Object.assign({}, defaultSchemaProperty), defaultSchema)
        : {};
      return JSON.parse(JSON.stringify(schemaObj));
    }
    defaultSchemaProperty = this.defaultSchemaProperties[origin] || {};
    currentSchema = currentSchema || {}
    schemaObj = merge(schemaObj, currentSchema, defaultSchemaProperty, defaultSchema);
    return JSON.parse(JSON.stringify(schemaObj));
  }

  getVisibleColumns(columns: any = {}) {
    return Object.keys(columns).filter(key => columns[key].visible === true);
  }

  createComponentExportData(fileNameObj, title, filterable = false): GridExportData {
    if (fileNameObj && fileNameObj.key && fileNameObj.value) {
      return <GridExportData>{
        filterable,
        origin: fileNameObj.value,
        title: title,
        filename: `${fileNameObj.value
          .replace(/_circular_vista/, '')
          .replace(/_regulatoria/, '')}_${fileNameObj.key}`
      };
    }
  }

  getFieldOptionValues(additionalSchemaData, row, key) {
    let dependentFields = this.getDependentFields(additionalSchemaData, key)
    if (!dependentFields) {
      return additionalSchemaData[key].optionValues || null
    }
    let optionValues = additionalSchemaData[key].optionValues
    dependentFields.forEach(depField => {
      let item
      item = optionValues.find(opv => opv[depField.property] === row[depField.field])
      optionValues = item && item.data ? item.data : !item ? optionValues : null
    })
    return optionValues
  }

  fieldGotValidDeps(additionalSchemaData, row, key) {
    if (!additionalSchemaData[key].dependentField) {
      return false
    }
    let optionValues = this.getFieldOptionValues(additionalSchemaData, row, key)
    let result = row[additionalSchemaData[key].dependentField] !== null &&
      row[additionalSchemaData[key].dependentField] >= 0 &&
      !!optionValues && !!optionValues.length

    if (!!optionValues && !!optionValues.length && !!additionalSchemaData[additionalSchemaData[key].dependentField].dependentField) {
      return result === true && this.fieldGotValidDeps(additionalSchemaData, row, additionalSchemaData[additionalSchemaData[key].dependentField].dependentField) === true
    }
    return result === true
  }

  getDependentFields(additionalSchemaData, key) {
    let currentKey = key
    let dependentFields = []
    while (additionalSchemaData[currentKey].dependentField) {
      dependentFields.push({ field: additionalSchemaData[currentKey].dependentField, property: additionalSchemaData[additionalSchemaData[currentKey].dependentField].optionValueProperty })
      currentKey = additionalSchemaData[currentKey].dependentField
    }
    return dependentFields.reverse()
  }

  getResultOptionValues(additionalSchemaData, row, key) {
    let opValueProperty = additionalSchemaData[key].optionValueProperty
    let finalOptionValues = this.getFieldOptionValues(additionalSchemaData, row, key)
    let item = finalOptionValues.find(item => item[opValueProperty] === row[key])
    row[key] = !!item ? item[opValueProperty] : null;
    if (!!finalOptionValues) {
      row.dependentOptions[key] = finalOptionValues;
    }
    let value = row[key]
    return { value, dependentOptions: row.dependentOptions[key] }
  }


  prepareRowForEdit(row, additionalSchemaData) {
    row.dependentOptions = {};
    for (const key in additionalSchemaData) {
      if (row.hasOwnProperty(key)) {
        if (
          !!additionalSchemaData[key].dependent ||
          this.fieldGotValidDeps(additionalSchemaData, row, key)
        ) {
          let resultRow = this.getResultOptionValues(additionalSchemaData, row, key)
          row[key] = resultRow.value
          if (!!resultRow.dependentOptions) {
            row.dependentOptions[key] = resultRow.dependentOptions
          }
        } else {
          row[key] = null;
        }
      }
    }
    return row;
  }

  saveGridChanges(fnToExecute, saveParams) {
    let saveObservables = [];
    saveObservables.push(fnToExecute(...saveParams));
    return saveObservables;
  }

  processSave(saveObservables: Observable<any>[]) {
    forkJoin(saveObservables)
      .pipe(
        catchError(err => {
          const errorNotification: Notification = {
            fixed: false,
            type: 'error',
            message: 'Error Guardando cambios realizados',
            popup: true
          };
          this.notificationsService.add(errorNotification);
          this.saveGridSubject.next({ type: 'ERROR' });
          return throwError(err);
        })
      )
      .subscribe(response => {
        const saveNotification: Notification = {
          fixed: false,
          type: 'confirmation',
          message: 'Modificaciones guardadas correctamente',
          popup: true
        };
        setTimeout(() => {
          this.notificationsService.add(saveNotification);
        }, 0);
        this.saveGridSubject.next({ type: 'SUCCESS' });
      });
  }

  getSaveGridSubject() {
    return this.saveGridSubject.asObservable();
  }

  processSchemaItems(
    schemaItems: Schema[],
    currentCols,
    additionalSchemaData,
    visibleColumns: any[],
    resetAdjust = false
  ): Schema[] {
    const items = schemaItems.map(item => {
      if (additionalSchemaData && additionalSchemaData[item.field]) {
        item = Object.assign(item, additionalSchemaData[item.field]);
      }

      const visible: boolean =
        !!visibleColumns && visibleColumns.includes(item.field)
          ? true
          : item.visible !== undefined ? item.visible : true;
      const fixed: boolean = false;
      const order: number = ![undefined, null].includes(item.order) ? item.order : Infinity;

      let currentColumn = currentCols.find((col: any) => col.field === item.field) || {};
      return {
        ...item,
        visible,
        fixed,
        order,
        pk: item.pk,
        link: item.link,
        adjusted: !!resetAdjust ? false : currentColumn.adjusted === true,
        fastSearch: item.fastSearch !== undefined ? item.fastSearch : true
      };
    });
    const sortedItems = this.sortSchemaItems(items);
    return sortedItems;
  }

  private sortSchemaItems(items: Schema[]): Schema[] {
    const orderedItems = items
      .filter(item => {
        return isFinite(item.order);
      })
      .sort((a, b) => a.order - b.order);
    const unorderedItems = items.filter(item => !isFinite(item.order));
    return orderedItems.concat(unorderedItems);
  }
}

export interface GridProperties {
  origin: string;
  filterOrigin?: string;
  title?: string;
  schemaScope?: string;
  lastUpdated?: Date;
  key?: string;
  bordered?: boolean;
  exportData?: GridExportData[];
  headerActions?: HeaderActions[];
  resultsShowingCount?: number;
  currentRequestParams?: any;
  gridActions?: any;
  columnSelector?: boolean;
  voidRow?: boolean;
  selectable?: boolean;
  showSelectBox?: boolean;
  adjustColumns?: boolean;
  selectionMode?: any;
  reload?: boolean;
  metadata?: any;
  editingRows?: number[];
  globalEditMode?: boolean;
  additionalSchemaData?: any;
  notMainGrid?: boolean;
  highLight?: HighLight;
  export: boolean;
  edit: boolean;
  editDblClick?: boolean;
  columnSearch?: boolean;
  generate?: boolean;
  staticSchema?: Schema[];
  externalData?: any;
  filterType: string;
  preFiltered?: boolean;
  currentFilter: FilterDB;
  gridHeight: number;
  changeColumnSelection?: Function;
  visibleColumns?: string[];
  defaultSchemaVisibility?: any;
  distinct?: boolean;
}

export interface GridExportData {
  origin: string;
  title: string;
  filterable: boolean;
  filterOrigin: string;
  filename?: string;
  bypassFields?: boolean;
}

export interface HeaderActions {
  type: string;
  title?: string;
  fire?: any;
  requireSelected?: boolean;
  requireFilter?: boolean;
  downloading?: boolean;
  reset?: any;
  maxNumber?: number;
}

export interface HighLight {
  type: string;
  filter: Object;
}
