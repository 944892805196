const deepAssign = require('deep-assign');

import { baseEnvironment } from './environment.dev';
export const environment = deepAssign(baseEnvironment, {
  production: false,
  envName: 'prod',
  signatureHost: 'https://8jgxlwrp61.execute-api.eu-west-1.amazonaws.com',
  apiDomain: '8jgxlwrp61.execute-api.eu-west-1.amazonaws.com',
  host: 'https://8jgxlwrp61.execute-api.eu-west-1.amazonaws.com/api',
  grid: { defaultLimit: 50 },
  state: { interval: 10000 },
  tasks: { interval: 10000, taskInterval: 10000 },
  async: {
    defaultInterval: 10000,
    query: {
      interval: 4000,
      completeStatus: 'succeeded',
      errorStatus: 'failed',
    },
  },
  auth: {
    domain: 'bdret-pro.auth.eu-west-1.amazoncognito.com',
    cognitoDomain: 'cognito-idp.eu-west-1.amazonaws.com/eu-west-1_yHV84grue',
    auth0clientId: '605n0evrbo8lb5p3jpkn6qrt83',
    identityPoolId: 'eu-west-1:769e86e5-bd6a-4ff2-a1c9-5e23083ff47d',
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_yHV84grue',
    clientId: '605n0evrbo8lb5p3jpkn6qrt83',
    scope: ['openid', 'email', 'profile', 'aws.cognito.signin.user.admin'],
  },
});
