import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { ContextualmenuContent } from './../contextual-menu-content';
import { BdrContextualMenuService } from '../../../services/bdr-contextual-menu/bdr-contextual-menu.service';
import { FilterDB } from './../../../services/filter/filter.service';

@Component({
  selector: 'bdr-ctx-applied-filters',
  templateUrl: './ctx-applied-filters.component.html',
  styleUrls: ['./ctx-applied-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CtxAppliedFiltersComponent extends ContextualmenuContent
  implements OnInit {
  filters: FilterDB[];

  constructor(private _ctxmenuService: BdrContextualMenuService) {
    super(_ctxmenuService);
  }

  ngOnInit() {
    this.filters = this.content.filters;
  }

  removeAppliedFilter(filter: FilterDB) {
    const index = this.filters.findIndex(item => item === filter);
    this.filters.splice(index, 1);
    this.content.removeAppliedFilter(filter, this.filters.length);
  }

  getItemName(item: FilterDB): string {
    const filterDefaultName = 'Filtro aplicado';
    const ruleDefaultName = 'Regla aplicada';
    const itemDefaultName = 'Elemento aplicado';

    const currentDefaultName = this.isFilter(item)
      ? filterDefaultName
      : this.isRule(item) ? ruleDefaultName : itemDefaultName;

    return item.name !== '' ? item.name : currentDefaultName;
  }

  private isFilter(item: FilterDB): boolean {
    const filterTypes = ['filter', 'issueFilter'];
    return filterTypes.indexOf(item.meta.type) !== -1;
  }

  private isRule(item: FilterDB): boolean {
    const ruleTypes = ['rule', 'workflowRule', 'assignationRule'];
    return ruleTypes.indexOf(item.meta.type) !== -1;
  }
}
