import { origins } from './common/origins';
import { DEFAULT_VIEWS } from './common/default-views';
import { filters } from './common/filters.conf';
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.
export const baseEnvironment = {
  origins,
  filters,
  defaultViews: DEFAULT_VIEWS,
  production: false,
  envName: 'dev',
  signatureHost: 'https://g47dsg8gb5.execute-api.eu-west-1.amazonaws.com',
  apiDomain: 'g47dsg8gb5.execute-api.eu-west-1.amazonaws.com',
  host: 'https://g47dsg8gb5.execute-api.eu-west-1.amazonaws.com/api',
  // apiDomain: 'localhost:8000',
  // host: 'http://localhost:8000',
  grid: { defaultLimit: 50, requestTimeout: 1000 },
  state: { interval: 10000 },
  tasks: { interval: 10000, taskInterval: 10000 },
  async: {
    defaultInterval: 10000,
    query: { interval: 4000, completeStatus: 'succeeded', errorStatus: 'failed' },
  },
  auth: {
    //server config
    domain: 'bdret-dev.auth.eu-west-1.amazoncognito.com',
    cognitoDomain: 'cognito-idp.eu-west-1.amazonaws.com/eu-west-1_tDhyAZ2Jb',
    auth0clientId: '543ndive44do4vpffd1enhkd90',
    identityPoolId: 'eu-west-1:9631f05c-5926-426b-8aa6-b048f3b852c4',
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_tDhyAZ2Jb',
    clientId: '543ndive44do4vpffd1enhkd90',
    scope: ['openid', 'email', 'profile', 'aws.cognito.signin.user.admin'],
  },
};
