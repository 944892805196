import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'bdr-toggle-button',
  template: `
    <div style="display:flex; flex-direction:row" [ngClass]="{'disabled': !enable }" [title]="disableReason"> 
      <span style="font-size: 90%; padding-top: 6px; margin-right: 3px;display:flex">
        {{ label }}
      </span>
      <div class="toggle-button"> 
        <input
          *ngIf="enable"
          #checkbox
          type="checkbox"
          id="toggle-button-checkbox-{{id}}"
          [checked]="checked"
          (change)="changed.emit(checkbox.checked)"
        />
        <label class="toggle-button-switch" for="toggle-button-checkbox-{{id}}"></label>
        <div class="toggle-button-text"></div>
      </div>
    </div>

  `,
  styleUrls: ['./toggle-button.component.scss'],
})

export class ToggleButtonComponent {
  @Input() id: string;

  @Input() label: string;
  @Input() checked: boolean = false;
  @Input() enable: boolean = true;
  @Input() disableReason: string = "";

  @Output() changed = new EventEmitter<boolean>();
}
