import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChildren,
  QueryList,
  ViewContainerRef,
  ChangeDetectorRef,
} from "@angular/core";
import { Subscription } from "rxjs";

import {
  FilterService,
  FilterDB,
  FolderDB,
} from "./../../services/filter/filter.service";
import { BdrContextualMenuService } from "../../services/bdr-contextual-menu/bdr-contextual-menu.service";
import { BdrRowDragComponent } from "../../components/bdr-row-drag/bdr-row-drag.component";
import {
  sortArrayByKey,
} from "../../util";

@Component({
  selector: "bdr-filter-list",
  templateUrl: "./bdr-filter-list.component.html",
  styleUrls: ["./bdr-filter-list.component.scss"],
})
export class BdrFilterListComponent implements OnInit, OnDestroy {
  @ViewChildren(BdrRowDragComponent, { read: ViewContainerRef })
  itemElements: QueryList<ViewContainerRef>;

  @Input() appliedFilters: FilterDB[];
  @Input() origin: string;
  @Input() type: string;
  @Output() close: EventEmitter<Function>;
  @Output() loadItemRequest: EventEmitter<FilterDB>;
  @Output() addToFolderRequest: EventEmitter<any> = new EventEmitter();
  @Output() showConfirm: EventEmitter<any> = new EventEmitter();
  @Output() newItemRequest: EventEmitter<any>;
  @Output() modelChange: EventEmitter<string>;
  @Output() applyItemRequest: EventEmitter<FilterDB>;

  setFocus: EventEmitter<boolean>;
  iconSearch: string;
  textEmptySearch: boolean;
  inputSearch: boolean;
  items: any = {};
  currentItems: FilterDB[] = [];
  displayItems: FilterDB[] = [];
  displayFolders: FolderDB[] = [];
  itemsSubscription: Subscription = new Subscription();
  search: string;
  searchRegExp: RegExp;
  tags: string[];
  canEditFilters: boolean;

  constructor(
    private filterService: FilterService,
    private ctxmenuService: BdrContextualMenuService,
    private cdr: ChangeDetectorRef
  ) {
    this.type = "filter";
    this.close = new EventEmitter<Function>();
    this.loadItemRequest = new EventEmitter<FilterDB>();
    this.newItemRequest = new EventEmitter<any>();
    this.modelChange = new EventEmitter<string>();
    this.applyItemRequest = new EventEmitter<FilterDB>();
    this.setFocus = new EventEmitter<boolean>();
    this.search = "";
    this.searchRegExp = new RegExp("");
    this.iconSearch = "search";
    this.textEmptySearch = false;
    this.inputSearch = false;
    this.tags = [];

    // TODO: Permissions ONLY ADMINS
    this.canEditFilters = true;
  }

  getFolders(items: FilterDB[]) {
    let folders = items.filter((item) => !item.name && item.folder_id !== -1);
    folders.forEach((folder) => {
      folder.children = items.filter(
        (item) => !!item.name && item.folder_id === folder.folder_id
      );
    });
    return folders
      .map((item) => {
        return new FolderDB(item);
      })
      .sort((a, b) => {
        return a.folder_id - b.folder_id;
      });
  }

  getFreeFilters(items) {
    return sortArrayByKey(
      items
        .filter((item) => !item.folder_id || item.folder_id === -1)
        .map((item) => new FilterDB(item)),
      "name"
    );
  }

  ngOnInit() {
    this.items[this.origin] = this.items[this.origin] || [];
    this.itemsSubscription = this.filterService
      .getFilterSubject()
      .subscribe((response: any = {}) => {
        this.items = response;
        this.updateCollections(this.items[this.origin]);
        this.tags = Array.from(
          new Set(
            this.currentItems
              .map((item) => item.tag)
              .filter((tag) => !!tag)
              .sort((a: any, b: any) => (!!a && !!b ? a.localeCompare(b) : -1))
          )
        );
        if (
          this.items[this.origin] &&
          this.items[this.origin].map((item) => item.tag).filter((tag) => !tag)
            .length > 0
        ) {
          this.tags.push(null);
        }
      });
  }

  ngOnDestroy() {
    if (this.itemsSubscription) {
      this.itemsSubscription.unsubscribe();
    }
  }
  updateCollections(items) {
    this.currentItems = items || [];
    this.displayFolders = this.currentItems
      ? this.getFolders(this.currentItems)
      : [];
    this.displayItems = this.currentItems
      ? this.getFreeFilters(this.currentItems)
      : [];
    this.cdr.detectChanges();
  }

  ngOnChanges(changes) {
    this.updateCollections(this.items[this.origin]);
    if (changes.origin && this.origin) {
      this.items[this.origin] = this.items[this.origin] || [];
      this.filterService.get(this.origin);
    }
    if (changes.appliedFilters && this.items[this.origin]) {
      this.items[this.origin] = this.items[this.origin].map((item) => ({
        ...item,
        applied:
          this.appliedFilters.find((applied) => applied.id === item.id) !==
          undefined,
      }));
      this.updateCollections(this.items[this.origin]);
    }
  }

  clear() {
    this.search = "";
    this.iconSearch = "cancel-search";
    this.textEmptySearch = false;
  }

  openInputSearch() {
    this.inputSearch = true;
    this.iconSearch = "cancel-search";
    this.setFocus.emit(true);
  }

  closeInputSearch() {
    this.filterOptions("");
    this.inputSearch = false;
    this.iconSearch = "search";
  }

  closePanel() {
    this.close.emit();
  }

  filterOptions(value: string): void {
    this.search = value;
    this.searchRegExp = new RegExp(value, "i");
    this.iconSearch = "cancel-search";
    let filteredItems = this.items[this.origin].filter(
      (item) =>
        (item.name &&
          item.name.toUpperCase().indexOf(value.toUpperCase()) > -1) ||
        (item.folder &&
          item.folder.toUpperCase().indexOf(value.toUpperCase()) > -1)
    );
    this.updateCollections(filteredItems);
    this.textEmptySearch = this.displayItems.length === 0;
  }

  deleteElement(item: FolderDB): void {
    if (item.children.length > 0) {
      let event = {
        title: "Eliminar carpeta",
        content:
          "<div class='a-c'>" +
          "<p>¿Estás seguro de que deseas eliminar la carpeta seleccionada con todos los filtros que están dentro?</p>" +
          "<p>Esta operación eliminará todos los filtros individuales sin posibilidad de recuperarlos.</p>" +
          "<p>Si prefieres eliminar algún filtro específico, puede hacerse de forma manual dentro de cada filtro.</p>" +
          "</div>",
        id: "dialog-confirm-delete-folder",
        buttonText: "Eliminar",
        showCancel: true,
        notHeader: false,
        confirm: () => {
          this.deleteItem(item);
        },
      };
      this.showConfirm.emit(event);
    } else {
      this.deleteItem(item);
    }
  }

  deleteItem(item: FilterDB): void {
    this.filterService.hide(Object.assign({}, item));
  }

  loadItem(item: FilterDB): void {
    this.closePanel();
    setTimeout(() => {
      this.loadItemRequest.emit(item);
    }, 0);
  }

  duplicateItem(item: FilterDB): void {
    this.filterService.duplicate(item);
  }

  getFolderMenu(item: FolderDB) {
    const editOptions = {
      options: [
        {
          caption: "Editar",
          icon: "ico-edit",
          class: "menu-item-edit",
          action: () => {
            item.toggleEditMode();
          },
        },
      ],
    };

    const deleteOptions = {
      options: [
        {
          caption: "Eliminar",
          icon: "ico-delete",
          class: "menu-item-remove",
          action: () => this.deleteElement(item),
        },
      ],
    };

    const menuOptions = [editOptions, deleteOptions];
    return { body: menuOptions };
  }

  getMenu(item: FilterDB): any {
    const applyOption = {
      options: [
        {
          caption: "Aplicar",
          icon: "ico-clickable",
          class: "menu-item-apply",
          action: () => this.applyItem(item),
        },
      ],
    };

    const editOptions = [
      {
        caption: "Editar",
        icon: "ico-edit",
        class: "menu-item-edit",
        action: () => this.loadItem(item),
      },
    ];

    const addToFolderOptions = [
      {
        caption: "Añadir a carpeta",
        icon: "ico-masterdata",
        class: "menu-item-add-to-folder",
        action: () => this.addToFolder(item),
      },
    ];

    const removeFromFolderOptions = [
      {
        caption: "Sacar de la carpeta",
        icon: "ico-masterdata",
        class: "menu-item-remove-from-folder",
        action: () => this.removeFromFolder(item),
      },
    ];

    if (this.canEditFilters) {
      let folderOptions =
        !!item.folder_id && item.folder_id !== -1
          ? removeFromFolderOptions
          : addToFolderOptions;
      applyOption.options = [
        ...applyOption.options,
        ...editOptions,
        ...folderOptions,
      ];
    }

    const deleteOptions = {
      options: [
        {
          caption: "Eliminar",
          icon: "ico-delete",
          class: "menu-item-remove",
          action: () => this.deleteItem(item),
        },
      ],
    };

    const menuOptions = this.canEditFilters
      ? [applyOption, deleteOptions]
      : [applyOption];

    return { body: menuOptions };
  }

  addToFolder(item: FilterDB) {
    let emitObj = {
      filter: item,
      folders: this.displayFolders.map((folder) => {
        return {
          name: folder.folder_name,
          value: folder.folder_id,
        };
      }),
    };
    this.addToFolderRequest.emit(emitObj);
  }

  removeFromFolder(item: FilterDB) {
    item.folder_id = -1;
    delete item.folder_name;
    delete item.folder_desc;
    this.filterService.update(new FilterDB(item));
    this.updateCollections(this.items[this.origin]);
  }

  applyItem(item: FilterDB) {
    const filter = new FilterDB({
      id: item.id,
      filter: item.filter || JSON.parse(item.query),
      meta: { type: this.type },
      name: item.name,
      table: "",
    });

    this.closePanel();
    setTimeout(() => {
      this.applyItemRequest.emit(filter);
    }, 100);
  }

  createItem(): void {
    this.closePanel();
    setTimeout(() => {
      this.newItemRequest.emit();
    }, 100);
  }

  cancelFolder(item: FolderDB) {
    item.toggleEditMode();
    if (!item.saved) {
      let index = this.items[this.origin].findIndex(
        (filterItem) => filterItem.folder_id === item.folder_id
      );
      if (index !== -1) {
        this.items[this.origin].splice(index, 1);
      }
      this.updateCollections(this.items[this.origin]);
    }
  }

  saveFolder(item: FolderDB): void {
    this.filterService.saveFolder(item);
  }

  createFolder(): void {
    const folder = new FolderDB({
      id: this.filterService.getNewFilterId(this.origin),
      folder_id: this.filterService.getNewFolderId(this.origin),
      table: this.origin,
    });
    folder.toggleEditMode();
    this.items[this.origin].push(folder);
    this.updateCollections(this.items[this.origin]);
  }
}
