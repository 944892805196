import { EventEmitter, AfterViewInit, Directive } from "@angular/core";

import { BdrContextualMenuService } from "./../../services/bdr-contextual-menu/bdr-contextual-menu.service";

@Directive()
export class ContextualmenuContent implements AfterViewInit {
  location: any;
  content: any;
  onClose: EventEmitter<void>;
  onContentLoaded: EventEmitter<void>;

  constructor(private ctxmenuService: BdrContextualMenuService) {
    this.content = null;
    this.onClose = new EventEmitter<void>();
    this.onContentLoaded = new EventEmitter<void>();
  }

  ngAfterViewInit() {
    this.onContentLoaded.emit();
  }

  close(): void {
    this.onClose.emit();
  }
}
