import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'dialog-message',
  templateUrl: './dialog-message.component.html',
  styleUrls: ['./dialog-message.component.scss']
})
export class DialogMessageComponent {
  @Input() content: string;
  @Output() closeDialog: EventEmitter<string> = new EventEmitter<string>();
}
