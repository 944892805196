import {
  Component,
  Input,
  EventEmitter,
  Output,
  ChangeDetectionStrategy
} from "@angular/core";

import { Notification } from "./../../services/notifications/notifications.service";

@Component({
  selector: "bdr-inline-notification",
  templateUrl: "./bdr-inline-notification.component.html",
  styleUrls: ["./bdr-inline-notification.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BdrInlineNotificationComponent {
  @Input() notification: Notification;

  @Output() closeInlineNotification: EventEmitter<void>;

  constructor() {
    this.closeInlineNotification = new EventEmitter<void>();
  }

  public close() {
    this.closeInlineNotification.emit();
  }
}
