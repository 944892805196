import {
  Component,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';


@Component({
  selector: 'bdr-reload-options',
  templateUrl: './bdr-reload-options.component.html',
  styleUrls: ['./bdr-reload-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BdrReloadOptionsComponent {
  @Input() origin: any;

  @Output() close: EventEmitter<Function> = new EventEmitter<Function>();
  @Output() launchReload: EventEmitter<Function> = new EventEmitter<Function>();

  closePanel() {
    this.close.emit();
  }

  reload($event) {
    this.launchReload.emit($event);
  }

}
