import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "dialog-confirm",
  templateUrl: "./dialog-confirm.component.html",
  styleUrls: ["./dialog-confirm.component.scss"]
})
export class DialogConfirmComponent {
  @Input() content: string;
  @Input() buttonText: string = "Actualizar";
  @Input() alternativeButtonText: string;
  @Output() closeDialog: EventEmitter<string> = new EventEmitter<string>();
  @Output() confirm: EventEmitter<string> = new EventEmitter<string>();
  @Output() confirmAlternative: EventEmitter<string> = new EventEmitter<string>();

  onConfirm() {
    this.confirm.emit();
  }
  onConfirmAlternative() {
    this.confirmAlternative.emit();
  }
}
