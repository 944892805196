import { Injectable } from '@angular/core';
import { of, Subject, Observable, throwError } from 'rxjs';
import { BdrRequestService } from '../bdr-request/bdr-request.service';
import { catchError } from 'rxjs/operators';
import { OriginService } from '../origin/origin.service';
import { NotificationsService } from '../notifications/notifications.service';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  uploadSubject: Subject<UploadState> = new Subject<UploadState>();
  private host: string;
  private masterDataPathsOrigin: any;
  private masterDataGenerateUrlOrigin: any;

  constructor(
      private request: BdrRequestService, 
      private originService: OriginService,
      private notificationsService: NotificationsService,

    ) {
    this.host = this.originService.getHost();
    this.masterDataPathsOrigin = this.originService.getByKey('masterDataPaths');
    this.masterDataGenerateUrlOrigin = this.originService.getByKey('masterDataGenerateUrl');
  }

  getMasterDataPaths() {
    return this.request.get(`${this.host}/${this.masterDataPathsOrigin}`);
  }

  getUploadLink(uploadPath) {
    let body = {
      path: uploadPath
    };
    return this.request.post(`${this.host}/${this.masterDataGenerateUrlOrigin}`, body);
  }

  private uploadError(err) {
    this.uploadSubject.next({
      status: 'ERROR',
      response: err
    });

    this.notificationsService.add({
      type: "error",
      message: 'Ha ocurrido un error subiendo el fichero',
      fixed: false,
      popup: true
    });

    return throwError(err);
  }

  startUpload(path, year, file): void {
    let finalPath = this.getUploadFinalPath(path, year);
    let formData = new FormData();

    this.getUploadLink(finalPath)
      .pipe(catchError(this.uploadError.bind(this)))
      .subscribe(result => {
        for (let key in result.fields) {
          formData.append(key, result.fields[key]);
        }
        formData.append('file', file);
        return this.uploadFile(result.url, formData)
          .pipe(catchError(this.uploadError.bind(this)))
          .subscribe(response => {
            //Returns 2 responses? The first is not an instance of HttpResponse
            if (response.type !== 0) {
              this.uploadSubject.next({
                status: 'SUCCESS',
                response
              });
              this.notificationsService.add({
                type: "confirmation",
                message: 'Fichero guardado correctamente',
                fixed: false,
                popup: true
              });
              
            }
          });
      });
  }

  getUploadState() {
    return this.uploadSubject.asObservable();
  }

  getUploadFinalPath(path, year) {
    if (path === 'sap_incurridos') {
      let today = new Date().toISOString();
      let month = year === today.substr(0, 4) ? today.substr(5, 2) : '12';
      return `${path}/${year}/${month}/${path}.csv.gz`
    }

    if (path.includes('traspasos_at_agrupado_ot_manual_')) {
      return `traspasos_at_agrupado_ot_manual/${year}/${path.replace("traspasos_at_agrupado_ot_manual_", "")}.csv.gz`
    }

    return `${path}/${year}/${path}.csv.gz`;
  }

  uploadFile(url, file): Observable<any> {
    return this.request.s3UploadPresigned(url, file);
  }
}

export interface UploadState {
  status: string;
  response: any;
}
