import { Component } from '@angular/core';
type Order = 'asc' | 'desc';

@Component({
  selector: 'validations-custom-column',
  template: `
    <ng-container *ngFor="let validation of (params.data.validaciones_ufd || '').split('|')">
      <span *ngIf="validation" class="validations validations--ela-detail fake-icon" [title]="getUFDValidationNicename(validation)">
        {{ validation }}
      </span>
    </ng-container>
  `,
  styles: []
})
export class ValidationsCellRenderer {
  params: any;

  agInit(params) {
    this.params = params;
  }

  getUFDValidationNicename(id) {
    return `Validación ${id}`;
  }
}
