import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ContextualmenuContent } from '../contextual-menu-content';
import { BdrContextualMenuService } from '../../../services/bdr-contextual-menu/bdr-contextual-menu.service';

@Component({
  selector: 'ctx-generate-file-inventory',
  templateUrl: './ctx-generate-file-inventory.component.html',
  styleUrls: ['./ctx-generate-file-inventory.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CtxGenerateFileInventoryComponent extends ContextualmenuContent {
  /*
  * It should receive a "content object as input"
  * {
  *   isFilter: boolean,
  *   component: string
  * }
  * */
  constructor(private _ctxmenuService: BdrContextualMenuService) {
    super(_ctxmenuService);
  }

  export(type: string): void {
    this.content.export(type);
    this.close();
  }
}
