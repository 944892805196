import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as clone from 'clone';
import {
  Observable, Subject, Subscription
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogActionsComponent } from '../../containers/dialog/dialog-actions/dialog-actions.component';
import { DialogRevertReclassificationsComponent } from '../../containers/dialog/dialog-revert-reclassifications.component.ts/dialog-revert-reclassifications.component';
import { DialogRevertSplittedAppointmentsComponent } from '../../containers/dialog/dialog-revert-splitted-appointments.component.ts/dialog-revert-splitted-appointments.component';
import { DialogSplitAppointmentComponent } from '../../containers/dialog/dialog-split-appointment/dialog-split-appointment.component';
import { DialogService } from '../../containers/dialog/dialog.service';
import { GridHandlerViewComponent } from '../../containers/grid-handler-view/grid-handler-view.component';
import { BdrValidationsService } from '../../services/bdr-validations/bdr-validations.service';
import { InvestmentFilterService } from '../../services/investment-filter/investment-filter.service';
import { MastersService } from '../../services/masters/masters.service';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { OriginService } from '../../services/origin/origin.service';
import { SchemaService } from '../../services/schema/schema.service';
import { TasksService } from '../../services/tasks/tasks.service';
import { safeDetectChanges } from "../../util";
import { DataApiService } from './../../services/data-api/data-api.service';
import { GridExportData, GridProperties, GridService } from './../../services/grid/grid.service';
import { DownloadsService } from '../../services/downloads/downloads.service';




const SPLIT_APPOINTMENTS_ACTION_VIEWS = [
  'sabana_inversiones_mtbt_detalle_vista',
  'sabana_inversiones_at_detalle_traspaso_vista',
  'sabana_inversiones_despachos_detalle_vista',
  'sabana_inversiones_equipos_medida_vista',
  'sabana_inversiones_edificios_varios_vista',
];

const WITH_ACTIONS_VIEWS = [
  'sabana_inversiones_mtbt_detalle_vista',
  'sabana_inversiones_at_detalle_traspaso_vista',
  'sabana_inversiones_despachos_detalle_vista',
  'sabana_inversiones_equipos_medida_vista',
  'sabana_inversiones_edificios_varios_vista',
  'sap_incurridos_manual_registro_vista'
];

@Component({
  selector: 'investments-sheet',
  templateUrl: './investments-sheet.component.html',
  styleUrls: ['./investments-sheet.component.scss'],
})
export class InvestmentsSheetComponent implements OnDestroy, OnInit {
  @ViewChild(GridHandlerViewComponent, { static: false }) gridHandler: GridHandlerViewComponent;
  saveGridSubscription: Subscription;
  gridProperties: GridProperties;
  manualValidations: any[] = [];
  error: boolean;
  dialogs: any[] = [];
  subscriptions: any[] = [];
  editData: any[] = [];
  editingRows: Set<number>;
  showModalSaving: any;
  panels: any;
  savingFlag: any;
  editMode = false;
  appliedFilters: any[] = [];
  origin: any;
  filterOrigin: any;
  subheaderSelector = [];
  currentOrigin: any = {};
  investmentOrigin: any;
  hasDeferredAppointments: boolean;
  deferredAppointments: any[];
  hasDeferredWorks: boolean;
  deferredWorks: any[];
  hasDeferredAppointmentsBuildings: boolean;
  deferredAppointmentsBuildings: any[];
  hasDeferredAppointmentsOffices: boolean;
  deferredAppointmentsOffices: any[];
  hasDeferredAppointmentsMeasuring: boolean;
  deferredAppointmentsMeasuring: any[];
  elaId: string;
  currentView: string;

  selectedRows = [];

  saveDisabled: boolean = false;
  saveDisabledReason: string;

  private destroy$: Subject<any> = new Subject();

  private closedAuditory: boolean = false;

  constructor(
    private validationsService: BdrValidationsService,
    private gridService: GridService,
    private apiService: DataApiService,
    private tasksService: TasksService,
    private dialogService: DialogService,
    private originService: OriginService,
    private schemaService: SchemaService,
    private mastersService: MastersService,
    private investmentsFiltersService: InvestmentFilterService,
    private notificationsService: NotificationsService,
    public matDialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private downloadsService: DownloadsService
  ) {
    this.origin = this.originService.getByKey('investments').mtbt[0].origin;
    this.investmentOrigin = this.originService.getByKey('investments');
    this.filterOrigin = this.originService.getByKey('investmentsFilter');
    this.editingRows = new Set();
    this.currentOrigin = this.subheaderSelector = this.getSubheaderSelector();

    this.saveGridSubscription = this.gridService
      .getSaveGridSubject()
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.processSaveGridResponse.bind(this));

    this.panels = {
      downloads: { disabled: false },
      fastFilter: { disabled: true },
      filters: { disabled: true },
      visualization: { disabled: false },
      validations: { disabled: false },
      manualValidations: { disabled: false },
      splitAppointment: { disabled: false },
      refreshSheet: { disabled: false },
    };

    this.validationsService
      .getManualValidationsObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.manualValidations = res;
      });
  }

  ngOnInit() {
    this.gridProperties = this.newGrid({ key: undefined });
    this.gridProperties.schemaScope = 'investment';
    //this.changeOrigin({ key: 'mtbt' });
    this.mastersService
      .getCanUpdateSheetNotificator()
      .pipe(takeUntil(this.destroy$))
      .subscribe((canUpdate) => {
        this.panels.refreshSheet.disabled = canUpdate === false;
      });

    this.notificationsService.getNotificationObservable().subscribe((notifications) => {
      this.closedAuditory = notifications.some(n => n.class === 'closed_auditory');
      if (this.gridProperties && this.gridProperties.headerActions) {
        if (this.closedAuditory) {
          this.gridProperties.headerActions = this.gridProperties.headerActions.filter(action => action.type !== 'actions');
          this.saveDisabled = true;
          this.saveDisabledReason = 'La auditoría está cerrada';
        } else {
          if (this.gridProperties.headerActions.every(action => action.type !== 'actions')) {
            this.addEditActions(this.gridProperties);
          }
          this.saveDisabled = false;
          this.saveDisabledReason = null;
        }
      }
    });
  }

  getSubheaderSelector() {
    return Object.values(this.investmentOrigin).map((value: any[]) => {
      return value.reduce((acc, element) => {
        let key = element.namespace.slice(element.namespace.indexOf('.') + 1);
        return {
          title: value.length > 1 ? key.toUpperCase().replace('MTBT', 'MT/BT')
            .replace('DISPATCHERS', 'Despachos')
            .replace('RECLASSIFICATIONS', 'Reclasificaciones') : element.title,
          key,
          changeEvent: this.changeOrigin.bind(this),
          selectData: this.investmentOrigin[key].map((item) => {
            return { name: item.title, value: item.origin };
          }),
        };
      }, {});
    });
  }

  ngOnDestroy() {
    this.dialogService.clearDialogs();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  selectedRowsChange(evt): void {
    this.selectedRows = evt;
  }

  dblClick(item): void {
    this.editingRows.add(item);
    this.setEditMode();
  }

  cancelEdit(): void {
    this.editingRows.clear();
    this.gridProperties.editingRows = Array.from(this.editingRows);
    this.editData = [];
    this.setEditMode();
  }

  setEditMode() {
    if (
      this.gridProperties.editingRows &&
      this.editingRows.size !== this.gridProperties.editingRows.length
    ) {
      this.gridProperties.editingRows = Array.from(this.editingRows);
    }
    this.editMode = this.gridProperties.editingRows && this.gridProperties.editingRows.length > 0;
    this.gridProperties.globalEditMode = this.editMode;
    this.gridProperties = Object.assign({}, this.gridProperties);
  }

  generateSheet(type) {
    this.tasksService.reloadSheet(type);
  }

  gridEditChange($event) {
    if (this.gridProperties && this.gridProperties.reload) {
      this.gridProperties.reload = false;
    }
    this.editData = $event.object;
    this.savingFlag = false;
    safeDetectChanges(this.cdr);
  }

  saveChanges($event) {
    this.savingFlag = true;
    if ($event) {
      this.editData = $event;
    }
    let amendments = null;
    if (this.origin === 'sabana_inversiones_mtbt_agregada_vista') {
      amendments = this.aggregatedMTBTAmendments();
      this.gridService.processSave([this.apiService.putAmendments(amendments)]);
    }
    this.showModalSaving = false;
  }

  private processSaveGridResponse(response) {
    if (response.type === 'ERROR') {
      this.savingFlag = false;
      return;
    }
    this.savingFlag = false;
    this.showModalSaving = false;
    this.gridProperties = { ...this.gridProperties, reload: true };
    this.cancelEdit();
  }

  filterGroupedByELAAtFinal() {
    this.investmentsFiltersService.filterGroupedByELAAtFinal(this.selectedRows);
  }

  filterGroupedAtDetail() {
    this.investmentsFiltersService.filterGroupedAtDetail(this.selectedRows);
  }

  downloadGroupedByEla() {
    const exportDispatchInstallations: GridExportData = {
      origin: 'sabana_inversiones_despachos_detalle_vista',
      filterOrigin: 'sabana_inversiones_despachos_detalle_vista',
      filterable: true,
      filename: `Instalaciones Despachos`,
      title: `Instalaciones Despachos`
    };

    const exportAtInstallations: GridExportData = {
      origin: 'sabana_inversiones_at_detalle_traspaso_vista',
      filterOrigin: 'sabana_inversiones_at_detalle_traspaso_vista',
      filterable: true,
      filename: `Instalaciones AT`,
      title: `Instalaciones AT`
    };

    this.downloadsService.asyncDownload(
      exportDispatchInstallations,
      true,
      [],
      [],
      []
    );

    this.downloadsService.asyncDownload(
      exportAtInstallations,
      true,
      [],
      [],
      []
    );
  }

  filterGroupedByELAAtDetail() {
    this.investmentsFiltersService.filterGroupedByELAAtDetail(this.selectedRows);
  }

  filterAtDetailWithELAGroupedFilters() {
    this.investmentsFiltersService.filterAtDetailWithELAGroupedFilters();
  }


  filterAtDetailWithGroupedFilters() {
    this.investmentsFiltersService.filterAtDetailWithGroupedFilters();
  }

  filterMtbtDetailWithWorksFilters() {
    this.investmentsFiltersService.filterMtbtDetailWithWorksFilters();
  }

  filterDispatchersDetailWithWorksFilters() {
    this.investmentsFiltersService.filterDispatchersDetailWithWorksFilters();
  }

  showSelectedActions(id?) {
    let actions = [];

    if (SPLIT_APPOINTMENTS_ACTION_VIEWS.includes(this.currentView)) {
      if (this.selectedRows.filter(item => item.clasificacion_inversion_final == 'PARTIDO-EXCLUIR').length == 0) {
        actions.push({
          title: 'Editar apuntes',
          icon: 'ico-copy',
          action: this.showSplitAppointmentDialog.bind(this),
        });
      }

      if (this.selectedRows.some(row => row.tipo_reclasificacion && row.clasificacion_inversion_final !== 'PARTIDO-EXCLUIR')) {
        actions.push({
          title: 'Revertir reclasificaciones',
          icon: 'ico-copy',
          action: this.showRevertReclassificationsDialog.bind(this),
        });
      }

      actions.push({
        title: 'Revertir particiones',
        icon: 'ico-copy',
        action: this.showRevertSplittedAppointmentsDialog.bind(this),
      });
    } else if (this.currentView === 'sap_incurridos_manual_registro_vista') {
      actions.push({
        title: 'Revertir reclasificaciones',
        icon: 'ico-copy',
        action: this.showRevertReclassificationsDialog.bind(this),
      });
    }

    if (actions) {
      const dialogOptions: any = {
        component: DialogActionsComponent,
        title: 'Acciones',
        fixed: true,
        size: {
          width: 40,
          height: 40,
        },
        bindings: {
          inputs: {
            id: 'actions',
            type: 'actions',
            actions: actions,
          },
        },
      };

      if (!id || typeof id === 'object') {
        return this.dialogService.createDialog(dialogOptions);
      }

      this.dialogService.updateDialog(id, dialogOptions);
    }
  }

  backAction(id) {
    this.showSelectedActions(id);
  }

  showSplitAppointmentDialog() {
    
    this.matDialog.open(DialogSplitAppointmentComponent, {
      data: {
        table: this.currentView,
        selectedRows: this.selectedRows
      },
    });
    this.dialogService.closeDialog('actions');
  }

  showRevertReclassificationsDialog() {
    this.matDialog.open(DialogRevertReclassificationsComponent, {
      data: {
        table: this.currentView,
        selectedRows: this.selectedRows.filter(row => row.tipo_reclasificacion && row.clasificacion_inversion_final !== 'PARTIDO-EXCLUIR')
      },
    });
    this.dialogService.closeDialog('actions');
  }

  showRevertSplittedAppointmentsDialog() {
    this.matDialog.open(DialogRevertSplittedAppointmentsComponent, {
      data: {
        table: this.currentView,
        selectedRows: this.selectedRows
        // .filter(row=>row.id !== row.id_original || row.clasificacion_inversion_final === 'PARTIDO-EXCLUIR')
      },
    });
    this.dialogService.closeDialog('actions');
  }

  changeOrigin(evt) {
    let newGridProperties = this.newGrid(evt);
    if (evt.key === 'at') {
      newGridProperties.exportData.push(<GridExportData>{
        title: 'Descargar archivos instalaciones zonas',
        origin: 'landing',
        filterOrigin: 'sabana_inversiones_traspasos_at_agrupado_ot_vista',
        filterable: false,
        bypassFields: true,
      });
    }

    if (evt.value === 'sabana_inversiones_mtbt_detalle_vista') {
      newGridProperties.exportData.push(
        <GridExportData>{
          title: 'Asignación incurrido instalaciones',
          origin: 'incurred',
          filterable: false,
        },
        <GridExportData>{
          title: 'Detalle instalaciones',
          origin: 'installations',
          filterable: false,
        }
      );
    }
    this.gridProperties = clone(newGridProperties);
  }

  private newGrid(evt) {
    let newGridProperties = this.gridService.getDefaultGridProperties();
    newGridProperties.globalEditMode = false;
    newGridProperties.bordered = true;
    newGridProperties.edit = true;
    newGridProperties.editDblClick = true;
    newGridProperties.generate = true;
    newGridProperties.columnSearch = true;
    newGridProperties.columnSelector = true;
    newGridProperties.editingRows = [];
    newGridProperties.currentRequestParams = {};

    newGridProperties.showSelectBox = true;
    newGridProperties.reload = false;
    newGridProperties.defaultSchemaVisibility = {};

    if (['at', 'mtbt', 'dispatchers', 'reclassifications'].includes(evt.key) && evt.value === null) {
      evt.key = '';
    }

    if (!evt.value && evt.key) {
      evt.value = this.investmentOrigin[evt.key][0].origin;
    }

    if (evt.value) {
      newGridProperties.filterOrigin = evt.value;
      newGridProperties.origin = evt.value;
      this.currentView = evt.value;

      newGridProperties.additionalSchemaData = this.schemaService.getDefaultSchema(
        newGridProperties.origin
      );
      this.origin = this.originService.getByOrigin(evt.value).origin;
      let title = this.originService.getByOrigin(evt.value).title;
      newGridProperties.exportData = [this.gridService.createComponentExportData(evt, title, true)];
    }

    //header actions
    newGridProperties.headerActions = [];

    this.addEditActions(newGridProperties);

    if (this.currentView === 'sabana_inversiones_at_traspaso_agregado_ela_vista') {
      newGridProperties.headerActions.push({
        type: 'at_download_installations',
        requireSelected: false,
        requireFilter: false,
        fire: this.downloadGroupedByEla.bind(this),
      });
      newGridProperties.headerActions.push({
        type: 'at_detail_filter',
        title: 'Ha seleccionado demasiados registros (máximo 20)',
        maxNumber: 20,
        requireSelected: true,
        fire: this.filterGroupedByELAAtDetail.bind(this),
      });
      newGridProperties.headerActions.push({
        type: 'at_aggregate_transfer_filter',
        title: 'Ha seleccionado demasiados registros (máximo 20)',
        maxNumber: 20,
        requireSelected: true,
        fire: this.filterGroupedByELAAtFinal.bind(this),
      });
      newGridProperties.headerActions.push({
        type: 'at_agregate_filter',
        requireSelected: false,
        requireFilter: true,
        fire: this.filterAtDetailWithELAGroupedFilters.bind(this),
      });
    } else if (this.currentView === 'sabana_inversiones_at_traspaso_agregado_vista') {
      newGridProperties.headerActions.push({
        type: 'at_detail_filter',
        title: 'Ha seleccionado demasiados registros (máximo 5)',
        maxNumber: 5,
        requireSelected: true,
        fire: this.filterGroupedAtDetail.bind(this),
      });
      newGridProperties.headerActions.push({
        type: 'at_detail_filter',
        requireSelected: false,
        requireFilter: true,
        fire: this.filterAtDetailWithGroupedFilters.bind(this),
      });
    } else if (this.currentView === 'sabana_inversiones_mtbt_agregada_vista') {
      newGridProperties.headerActions.push({
        type: 'mtbt_detail_filter',
        requireSelected: false,
        requireFilter: true,
        fire: this.filterMtbtDetailWithWorksFilters.bind(this),
      });
    } else if (this.currentView === 'sabana_inversiones_despachos_agregado_obra_vista') {
      newGridProperties.headerActions.push({
        type: 'dispatchers_detail_filter',
        requireSelected: false,
        requireFilter: true,
        fire: this.filterDispatchersDetailWithWorksFilters.bind(this),
      });
    }
    return newGridProperties;
  }

  private addEditActions(newGridProperties: GridProperties) {
    if (WITH_ACTIONS_VIEWS.includes(this.currentView) && !this.closedAuditory) {
      newGridProperties.headerActions.push({
        type: 'actions',
        requireSelected: true,
        fire: this.showSelectedActions.bind(this),
      });
    }
  }

  private aggregatedMTBTAmendments() {
    return this.editData.map((editedRow) => {
      let primaryKeys = {
        anio_contable: editedRow.anio_retributivo,
        codigo_obra: editedRow.codigo_obra,
      };
      let newManualValues = {};
      editedRow.currentEditedFields.forEach(
        (editedField) => (newManualValues[editedField] = editedRow[editedField])
      );
      return this.amendment(
        'sabana_inversiones_mtbt_agregada_manual',
        'upsert',
        primaryKeys,
        newManualValues
      );
    });
  }

  private amendment(table, amendentType, primaryKeys, newValues) {
    return {
      table: table,
      amendment_type: amendentType,
      primary_keys: primaryKeys,
      new_values: newValues,
    };
  }

}
