import { throwError as observableThrowError, Subject, throwError, Subscription } from 'rxjs';
import { catchError, takeUntil, map } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { DialogConfirmComponent } from './../../containers/dialog/dialog-confirm/dialog-confirm.component';
import { origins } from './../../../environments/common/origins';

import { AuthService } from './../../services/auth/auth.service';
import { TasksService } from './../../services/tasks/tasks.service';
import { DialogService } from './../../containers/dialog/dialog.service';
import { LoadsService } from '../../services/loads/loads.service';
import { FileUploadService } from './../../services/file-upload/file-upload.service';
import { GridProperties, GridService } from '../../services/grid/grid.service';
import { MastersService } from './../../services/masters/masters.service';
import { DialogFileUploadComponent } from './../../containers/dialog/dialog-file-upload/dialog-file-upload.component';
import {
  NotificationsService,
  Notification,
} from './../../services/notifications/notifications.service';
import * as moment from 'moment';

@Component({
  selector: 'loads-report',
  templateUrl: './loads-report.component.html',
  styleUrls: ['./loads-report.component.scss'],
})
export class LoadsReportComponent implements OnInit {
  data: any;

  lastLoadDate = '';
  lastAutomaticLoadDate = '';

  lastLoadTime = '';
  lastAutomaticLoadTime = '';
  
  gridProperties: GridProperties;

  landingDataPaths: any[] = [];
  panels: any = {};
  
  loadErrorsTitle = 'Errores a la hora de cargar los archivos en la BDR';
  retributiveYear: string;
  
  currentTasks: any[];

  constructor(
    public auth: AuthService,
    private taskService: TasksService,
    private dialogService: DialogService,
    private loadsService: LoadsService,
    private gridService: GridService,
    private mastersService: MastersService,
  ) {
    this.gridProperties = this.gridService.getDefaultGridProperties();
    this.gridProperties.globalEditMode = false;
    this.gridProperties.bordered = true;
    this.gridProperties.edit = false;
    this.gridProperties.reload = false;
    this.gridProperties.voidRow = true;
    this.gridProperties.columnSelector = false;
    this.gridProperties.editDblClick = false;
    this.gridProperties.generate = false;
    this.gridProperties.showSelectBox = false;
    this.gridProperties.columnSearch = false;
    this.gridProperties.editingRows = [];
    this.gridProperties.currentRequestParams = {};
    this.gridProperties.headerActions = [
      {
        type: 'clearLoads',
        fire: this.cleanLogs.bind(this),
        title: 'Limpiar campos',
        requireSelected: true,
      },
    ];
    this.gridProperties.additionalSchemaData = {};

    this.gridProperties.origin = origins.loadsLogError;
    this.panels = {
      filtersEditor: false,
      filters: false,
      validations: false,
      manualValidations: false,
    };
  }

  ngDoCheck() {
    this.getCurrentTasks();
  }

  ngOnInit() {
    this.auth.getAuthenticated().subscribe((authenticated) => {
      if (!!authenticated) {
        this.mastersService.getRetributiveYear().subscribe((year) => {
          this.retributiveYear = year;

          if (this.retributiveYear !== new Date().getFullYear().toString()) {
            const date = this.loadsService.currentYearAutomaticLoad(parseInt(this.retributiveYear));
            this.lastAutomaticLoadDate = date.date;
            this.lastAutomaticLoadTime = date.time;
          }

          this.loadsService
            .fetchEtlLastExecution(this.retributiveYear)
            .subscribe((etlLastExecutions) => {

                etlLastExecutions.result.forEach( etlLastExecution => { 
                  const etlLastExecutionDate = moment(etlLastExecution.end_date).format('L');
                  const etlLastExecutionTime = moment(etlLastExecution.end_date).format('LT');

                  if (etlLastExecution.etl_type === 'automatic' ) { 
                    this.lastAutomaticLoadDate = etlLastExecutionDate;
                    this.lastAutomaticLoadTime = etlLastExecutionTime;
                    
                  } 

                  if (etlLastExecution.etl_type === 'manual' ) { 
                    this.lastLoadDate = etlLastExecutionDate;
                    this.lastLoadTime = etlLastExecutionTime;
                  } 
                })
              })
        });
      }
    });
  }

  confirmReloadFiles() {
    const dialogOptions: any = {
      component: DialogConfirmComponent,
      bindings: {
        inputs: {
          buttonText: 'Actualizar maestros',
          content:
            '<p>¿Desea volver a cargar los datos de este archivo actualizados?</p>' +
            '<p>Los datos anteriores se borrarán y se actualizarán por los nuevos.</p>',
        },
        outputs: {
          confirm: this.confirmDialog.bind(this, 'false'),
          confirmAlternative: this.confirmDialog.bind(this, 'true'),
          onCloseAction: this.dialogService.closeDialog.bind(this.dialogService),
        },
      },
      notHeader: true,
      fixed: true,
    };
    this.dialogService.createDialog(dialogOptions);
  }

  reloadFiles(skipTransactional) {
    this.taskService.reloadMasterdata(skipTransactional);
  }

  confirmDialog(skipTransactional, id) {
    this.reloadFiles(skipTransactional);
    this.dialogService.closeDialog(id);
  }

  cleanLogs() {
    this.loadsService.clearLoads().subscribe(() => {
      this.gridProperties.lastUpdated = new Date();
      this.reloadGrid();
    });
  }

  reloadGrid() {
    this.gridProperties.origin = '';
    this.gridProperties = Object.assign({}, this.gridProperties);
    setTimeout(() => {
      this.gridProperties.origin = origins.loadsLogError;
      this.gridProperties = Object.assign({}, this.gridProperties);
    }, 200);
  }

  fileUploadDialog() {
    const title = 'Subir archivos maestros';
    const dialogOptions: any = {
      id: 'file-upload-dialog',
      title,
      retributiveYear: this.retributiveYear,
      size: { width: 20, height: 40 },
      component: DialogFileUploadComponent,
      fixed: true,
      notHeader: true,
      headerComponent: true,
      bindings: {
        inputs: {
          title
        },
        outputs: {
          
        },
      },
    };
    this.dialogService.createDialog(dialogOptions);
  }

  getCurrentTasks() {
    this.currentTasks = this.taskService.getCurrentTasks();
  }
}
