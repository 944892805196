import { AfterViewChecked, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[contextMenu]'
})
export class ContextMenuDirective implements AfterViewChecked {
  @Input('contextMenu') id?: any;

  private table: Element;
  private menu: HTMLElement;
  private backdrop: HTMLElement;

  constructor(private el: ElementRef) {
    console.log(el)
  }

  ngAfterViewChecked() {
    const isContentLoaded = !!this.el.nativeElement.innerText;
    const hasListenerCreated = this.table;

    if (isContentLoaded && !hasListenerCreated) {
      this.initListenerTable();
    }
  }

  private gridId() {
    return (this.id ? "#" + this.id : "ag-grid-angular") + " .ag-body-viewport";
  }

  private initListenerTable(): void {
    this.table = document.querySelector(this.gridId());
    this.table.addEventListener('contextmenu', this.createMenuContext.bind(this));
  }

  private createMenu(event: Event): void {
    const { top, left } = this.table.getBoundingClientRect();
    const { pageX, pageY } = event as MouseEvent;
    const target = event.target as HTMLInputElement;

    let positionTop = pageY - (top - this.table.scrollTop);
    let positionLeft = pageX - left;

    this.menu = document.createElement('div');
    this.menu.className = 'context-menu';
    this.menu.style.cssText = `position: absolute; top: ${positionTop}px; left: ${positionLeft}px;`;
    this.menu.innerHTML = '<span class="context-menu-text"> Copiar contenido </span>';

    const value = !!target.innerText ? target.innerText : target.value;

    this.menu.addEventListener(
      'click',
      e => {
        e.stopPropagation();
        this.copyStringToClipboard(value).then(() => {
          this.removeChildrens(e);
        });
      },
      { once: true }
    );

    this.table.appendChild(this.menu);
  }

  private createBackdrop(): void {
    this.backdrop = document.createElement('div');
    this.backdrop.className = 'context-menu-backdrop';

    this.backdrop.addEventListener('contextmenu', this.removeChildrens.bind(this));
    this.backdrop.addEventListener('click', this.removeChildrens.bind(this));

    this.table.appendChild(this.backdrop);
  }

  private createMenuContext(event: Event): void {
    event.preventDefault();
    this.createMenu(event);
    this.createBackdrop();
  }

  private copyStringToClipboard(str): Promise<boolean> {
    return new Promise(resolve => {
      let el = document.createElement('textarea');
      el.value = str;
      el.setAttribute('readonly', '');
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      resolve(true);
    });
  }

  private removeChildrens(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.table.removeChild(this.menu);
    this.table.removeChild(this.backdrop);
  }
}
