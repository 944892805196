export const MASSIVE_EDITIONS_CONFIG = {
  tables_single_query_edition: ['fa1_vista'],
};

export interface ValidationConfig {
  title: string;
  fieldName: string;
  hasExtraFilters: boolean;
}

export const VALIDATIONS_CONFIG = {
  sabana_inversiones_mtbt_agregada_vista: {
    title: 'Validaciones UFD',
    fieldName: 'validaciones_ufd',
    hasExtraFilters: true,
  },
  sabana_inversiones_despachos_agregado_obra_vista: {
    title: 'Validaciones UFD',
    fieldName: 'validaciones_ufd',
    hasExtraFilters: true,
  },
  fa1_vista: {
    title: 'Validaciones',
    fieldName: 'validaciones',
    hasExtraFilters: false,
  },
};
