import { throwError as observableThrowError, Subject, Observable, timer } from 'rxjs';
import { startWith, catchError, takeUntil, switchMap } from 'rxjs/operators';

export class QueryPoller {
  private requestService: any;
  private querySubject: Subject<AsyncQueryResponse>;
  private pollSubject: Subject<any>;
  private query: AsyncQuery;
  constructor(requestService, query: AsyncQuery) {
    this.requestService = requestService;
    this.query = query;
    this.querySubject = new Subject();
    this.pollSubject = new Subject();
    this.poll();
  }
  getObservable() {
    return this.querySubject.asObservable();
  }
  complete() {
    this.pollSubject.next({});
  }
  poll() {
    timer(0, this.query.interval)
      .pipe(takeUntil(this.pollSubject))
      .pipe(
        switchMap(() => {
          return this.requestService.get(this.query.url);
        })
      )
      .pipe(
        startWith(() => {
          return this.requestService.get(this.query.url);
        })
      )
      .pipe(
        catchError(err => {
          let errorObj = this.getErrorObject(err);
          this.querySubject.next(errorObj);
          setTimeout(() => {
            // this.poll();
            // console.log('Restartpoll?');
          }, 1000);
          return observableThrowError(err);
        })
      )
      .subscribe((res: any) => {
        if (res.status === this.query.errorStatus) {
          this.complete();
          let errorObj = this.getErrorObject(res);
          this.querySubject.next(errorObj);
          return;
        }
        if (res.status === this.query.completeStatus) {
          this.complete();
          let successObj: AsyncQueryResponse = {
            type: 'SUCCESS',
            data: res
          };
          this.querySubject.next(successObj);
        }
      });
  }
  getErrorObject(data: any): AsyncQueryResponse {
    return {
      type: 'ERROR',
      data
    };
  }
}

export interface AsyncQuery {
  url: string;
  interval: number;
  completeStatus: string;
  errorStatus: string;
}

export interface AsyncQueryResponse {
  type: string;
  data: any;
}
