import { Component, OnInit, Input, Output, EventEmitter, Injectable, ChangeDetectorRef } from '@angular/core';
import { ManualWeights } from '../../../models/common';
import { Subject } from 'rxjs';

@Component({
  selector: 'dialog-facility-weights',
  templateUrl: './dialog-facility-weights.component.html',
  styleUrls: ['./dialog-facility-weights.component.scss']
})
@Injectable()
export class DialogWeightsComponent implements OnInit {
  @Input() id: string;
  @Input() type: string;
  @Input() elaDesc: string;
  @Input() elaAmount: number;

  @Input() incurred: any;
  @Input() installations: any;
  @Input() weights: any;

  @Input() origin: string;

  @Output() onModifyWeights: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteWeights: EventEmitter<any> = new EventEmitter();

  @Output() backAction: EventEmitter<any> = new EventEmitter();
  @Output() closeDialog: EventEmitter<any> = new EventEmitter();

  error: boolean;

  destroy$: Subject<any> = new Subject();

  constructor(private cdr: ChangeDetectorRef) {

  }

  ngOnInit() {
    let vtrSum = this.installations.map(row => row.vtr).reduce((a, b) => a + b, 0);
    this.installations.forEach(i => {
      i.reparto_original = i.vtr * 100 / vtrSum;
      i.reparto = (this.weights[`${i.instalacion}_${i.secuencial}`] || 0);
    });
  }

  back(): void {
    this.backAction.emit(this.id);
  }

  registerWeight(installation, weight: string): void {
    installation.reparto = Number(weight);
    this.weights[`${installation.instalacion}_${installation.secuencial}`] = installation.reparto;
  }

  deleteManualWeights(): void {
    for (let installation of this.installations) {
      installation.reparto = 0;
    }
    this.cdr.detectChanges();
  }

  hasWeights() {
    return this.installations.some(i => i.reparto > 0);
  }

  save(): void {
    let instWeights = this.installations.map(i => i.reparto);
    let incurredKey = `${this.incurred.ela_final}_${this.incurred.ot}_${this.incurred.clase_coste}`;

    if (instWeights.every(weight => weight == 0)) {
      this.onDeleteWeights.emit({
        weightToDelete: <ManualWeights>{
          anio_contable: this.incurred.anio_retributivo,
          ela_final: this.incurred.ela_final,
          ot: this.incurred.ot,
          clase_coste: this.incurred.clase_coste,
          origen_instalacion: this.origin,
          repartos: {}
        },
        origin: this.origin,
        weights: this.weights,
        incurredKey
      });
      this.back();
    } else {
      this.error = (100 != instWeights.reduce((sum, weight) => sum + weight));
      if (!this.error) {
        this.onModifyWeights.emit({
          manualWeight: <ManualWeights>{
            anio_contable: this.incurred.anio_retributivo,
            ela_final: this.incurred.ela_final,
            ot: this.incurred.ot,
            clase_coste: this.incurred.clase_coste,
            origen_instalacion: this.origin,
            repartos: this.weights
          },
          origin: this.origin,
          weights: this.weights,
          incurredKey
        });
        this.back();
      }
    }
  }
}

