import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'dialog-edit-multiple',
  templateUrl: './dialog-edit-multiple.component.html',
  styleUrls: ['./dialog-edit-multiple.component.scss']
})
export class DialogEditMultipleComponent implements OnInit {
  @Input() id: string;
  @Input() fields: any[] = [];
  @Input() rows: any[] = [];
  @Output() backAction: EventEmitter<any> = new EventEmitter();
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() closeDialog: EventEmitter<any> = new EventEmitter();
  rowsToSave = [];
  constructor() { }

  ngOnInit() {
    this.rows.forEach(row => {
      if (row.codigo_obra) {
        if (row.id) {
          this.rowsToSave.push({
            id: row.id,
            codigo_obra: row.codigo_obra,
            anio_retributivo: row.anio_retributivo
          });
        } else {
          this.rowsToSave.push({
            codigo_obra: row.codigo_obra,
            anio_retributivo: row.anio_retributivo
          });
        }
      } else {
        this.rowsToSave.push(row);
      }
    });
  }

  back(): void {
    this.backAction.emit(this.id);
  }

  valueChange(field, value): void {
    this.rowsToSave.forEach(row => {
      row[field] = value;
      if (!row.editedFields) {
        row.editedFields = new Set();
      }
      row.editedFields.add(field);
    });

    if (this.rowsToSave.length > 0) {
      this.removeDisabledFields();
    }
    this.change.emit(this.rowsToSave);
  }

  saveChanges(): void {
    if (this.rowsToSave.length > 0) {
      this.removeDisabledFields();
    }
    this.save.emit(this.rowsToSave);
    this.closeDialog.emit(this.id);
  }

  private removeDisabledFields() {
    const activeFields = this.fields
      .filter(item => item.editActive)
      .map(item => item.field);

    this.rowsToSave.forEach(row => {
      for (const key in row) {
        if (
          key !== 'id' &&
          key !== 'codigo_obra' &&
          key !== 'anio_retributivo' &&
          key !== 'editedFields' &&
          !activeFields.includes(key)
        ) {
          delete row[key];
        }
      }
    });
  }
  get canSave() {
    return this.fields.filter(item => item.editActive).length > 0;
  }
}
