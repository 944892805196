import { Filter } from '../../services/filter/filter.service';
import { AgGridColumn } from 'ag-grid-angular';

export interface MasterAmountResponse {
  total: number;
  filtered: number;
}

export interface MasterResponse {
  amount: MasterAmountResponse;
  count: number;
  filtered: number;
  manual_table: string;
  headers: AgGridCustomColumn[];
  result: any[];
  unload_to_s3_after_amendment: boolean;
}
export interface MasterParams {
  limit: number;
  offset?: number;
  sort?: { dir: string; field: string }[];
  query?: MasterParamsQuery;
}

export interface MasterParamsQuery {
  filters?: { rules: Filter[]; condition: string }[];
  logic?: string;
}

export interface MasterTable {
  headers: AgGridCustomColumn[];
  rows: { [key: string]: any }[];
  filtered?: number;
  count?: number;
  manualTable?: string;
  canUnloadS3?: boolean;
}

export interface MasterPayload {
  amendments: Map<number | string, Partial<MasterPayloadItem>>;
}

export interface MasterPayloadItem {
  table?: string;
  amendment_type?: MASTER_AMENDMENT_TYPES;
  primary_keys: { [key: string]: string | number };
  new_values: { [key: string]: string | number };
}

export enum MASTER_AMENDMENT_TYPES {
  UPSERT = 'upsert',
  DELETE = 'delete',
}

export type AgGridCustomColumn = Partial<
  AgGridColumn & { primaryKey: boolean; validationField: boolean; options: string[]; isEditable: boolean }
>;
