import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { ContextualmenuContent } from './../contextual-menu-content';
import { BdrContextualMenuService } from '../../../services/bdr-contextual-menu/bdr-contextual-menu.service';

@Component({
  selector: 'bdr-ctx-input-multiple',
  templateUrl: './ctx-input-multiple.component.html',
  styleUrls: ['./ctx-input-multiple.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CtxInputMultipleComponent extends ContextualmenuContent
  implements OnInit {
  model: string[];
  numeric: boolean;
  error: boolean;
  errorMsg: string;
  constructor(private _ctxmenuService: BdrContextualMenuService) {
    super(_ctxmenuService);
    this.model = [];
    this.numeric = false;
    this.error = false;
    this.errorMsg = '';
  }

  ngOnInit() {
    this.model = this.content.list;
    this.numeric = this.content.numeric;
    this.error = this.content.error;
    this.errorMsg = this.content.errorMsg;
  }

  apply() {
    this.content.apply(this.model);
  }
}
