import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnInit,
  OnChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { GridExportData } from '../../services/grid/grid.service';
import { FilterDB } from '../../services/filter/filter.service';
import { Subject } from 'rxjs';
import { OriginService } from '../../services/origin/origin.service';
import { BdrValidationsService } from '../../services/bdr-validations/bdr-validations.service';
import { DialogService } from '../../containers/dialog/dialog.service';
import { DialogSplitReclassificationMassEditionComponent } from '../../containers/dialog/dialog-split-reclassification-mass-edition/dialog-split-reclassification-mass-edition.component';
import { DownloadsService } from '../../services/downloads/downloads.service';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { TasksService } from '../../services/tasks/tasks.service';
import { MatDialog } from '@angular/material/dialog';
import { VALIDATIONS_CONFIG, ValidationConfig } from '../../../environments/common/components.conf';

@Component({
  selector: 'bdr-side-panel',
  templateUrl: './bdr-side-panel.component.html',
  styleUrls: ['./bdr-side-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BdrSidePanelComponent implements OnChanges, OnInit {
  @Input() panels: any;
  @Input() appliedFilters: any;
  @Input() visibleColumns: string[];
  @Input() allColumns: string[];
  @Input() filters: any;
  @Input() resultsCount: any;
  @Input() headerActions: any[];
  @Input() title: string;
  @Input() originTableName: string;
  @Input() disabledVisualization: boolean;
  @Input() scope: string;
  @Input() columnsSearchVisible: boolean;
  @Input() visualizationPanelVisible: boolean;
  @Input() massEditPanelVisible: boolean;
  @Input() exportData: GridExportData[];
  @Input() selectedRows: boolean;
  @Input() rowsToEdit: any[] = [];
  @Input() hasDeferredAppointments: boolean;
  @Input() deferredAppointments: any[];
  @Input() hasDeferredWorks: boolean;
  @Input() deferredWorks: any[];
  @Input() hasDeferredAppointmentsBuildings: boolean;
  @Input() deferredAppointmentsBuildings: any[];
  @Input() hasDeferredAppointmentsOffices: boolean;
  @Input() deferredAppointmentsOffices: any[];
  @Input() hasDeferredAppointmentsMeasuring: boolean;
  @Input() deferredAppointmentsMeasuring: any[];

  @Output() onSwitchPanels = new EventEmitter();
  @Output() newItemRequest = new EventEmitter();
  @Output() addFilterToFolderRequest = new EventEmitter();
  @Output() onApplyFilter = new EventEmitter();
  @Output() onValidationsDetail = new EventEmitter();
  @Output() showConfirm = new EventEmitter();
  @Output() manualValidationsClick = new EventEmitter();
  @Output() outputColumnSearch: Subject<boolean> = new Subject<boolean>();
  @Output() generateAction: EventEmitter<void> = new EventEmitter<void>();

  panelTypes = {
    FILTER: 'filter',
  };

  downloadsPanelVisible = false;
  fastFilterPanelVisible = false;
  filtersPanelVisible = false;
  validationsPanelVisible = false;
  manualValidationsPanelVisible = false;
  refreshSheetPanelVisible = false;
  retributiveYear: string;
  validationsScope;
  enabledMassEditButton: boolean = false;
  disabledMassEditButtonReason: string = '';
  disabledReloadButtonReason: string = '';
  enabledReloadButton: boolean = true;
  exportCNMCPanelVisible: boolean = false;
  logsCNMCPanelVisible: boolean = false;
  reloadLogsCNMCPanelVisible = false;
  validationConfig: ValidationConfig;

  constructor(
    private originService: OriginService,
    private validationsService: BdrValidationsService,
    private dialogService: DialogService,
    private downloadService: DownloadsService,
    private taskService: TasksService,
    private notificationsService: NotificationsService,
    private cdr: ChangeDetectorRef,
    private matDialog: MatDialog,
  ) {}

  ngOnChanges(changes) {
    this.validationConfig = VALIDATIONS_CONFIG[this.originTableName];

    if (this.originTableName) {
      if (this.originTableName.includes('sabana_inversiones_mtbt')) {
        this.validationsScope = 'mtbt';
      } else if (this.originTableName.includes('sabana_inversiones_despachos_agregado_obra')) {
        this.validationsScope = 'dispatchers';
      } else if (this.originTableName.includes('sabana_inversiones_at')) {
        this.validationsScope = 'at';
      } else if (this.originTableName.includes('sabana_informe_instalaciones')) {
        this.validationsScope = 'installations';
      } else if (this.originTableName.includes('_inventario_vista')) {
        this.validationsScope = 'forms.inventory';
      } else if (this.originTableName === 'fa1_vista') {
        this.validationsScope = 'fa1';
      }

      this.validationsService.setScope(this.validationsScope);
    }

    this.panels.validations = this.originService
      .getOriginsWithValidations()
      .includes(this.originTableName);
    if (changes.appliedFilters) {
      let currentFastFilters = this.areThereFastFilters();
      if (this.columnsSearchVisible !== currentFastFilters) {
        this.toggleColumnSearch(currentFastFilters);
      }
    }

    this.enabledMassEditButton = this.appliedFilters.length > 0;
  }

  ngOnInit() {
    this.subscribe();
  }

  areThereFastFilters() {
    return (
      this.appliedFilters.filter((item: FilterDB) => {
        return item.isFastFilter();
      }).length > 0
    );
  }

  switchPanels(currentPanel: string, isVisible: boolean, mouseEvent?: MouseEvent): void {
    if (currentPanel !== 'fastFilterPanelVisible' && !!this.columnsSearchVisible) {
      this.toggleColumnSearch(!this.columnsSearchVisible);
    }
    this.massEditPanelVisible = false;
    this.downloadsPanelVisible = false;
    this.filtersPanelVisible = false;
    this.validationsPanelVisible = false;
    this.manualValidationsPanelVisible = false;
    this.refreshSheetPanelVisible = false;
    this.exportCNMCPanelVisible = false;
    this.logsCNMCPanelVisible = false;
    this.reloadLogsCNMCPanelVisible = false;

    if (this.hasOwnProperty(currentPanel)) {
      this[currentPanel] = !isVisible;
      this.onSwitchPanels.emit(isVisible);
    }
    this.setPositionRefreshMenu(mouseEvent);
  }

  private setPositionRefreshMenu(mouseEvent: MouseEvent) {
    if (this.refreshSheetPanelVisible) {
      const sideBarTop = document.querySelector('aside.side-panel').getBoundingClientRect().top;
      setTimeout(() => {
        const refreshSheetMenu: HTMLElement = document.querySelector(
          '.panel-wrapper.refreshSheetMenu',
        );
        const valueTop = Math.abs(sideBarTop - mouseEvent.pageY);
        refreshSheetMenu.style.top = `${valueTop - 29}px`;
      }, 0);
    }
  }

  togglePanel(currentPanel: string, isVisible: boolean): void {
    this[currentPanel] = !isVisible;
    this[`${currentPanel}Visible`] = !isVisible;
    this.onSwitchPanels.emit(isVisible);
  }

  validationsDetail(evt) {
    if (this.panels && this.panels.validationsDetail) {
      this.togglePanel('validationsPanel', true);
      this.panels.validationsDetail(evt);
      return;
    }
  }

  confirmDialog(id) {
    this.taskService.panicButton();
    this.dialogService.closeDialog(id);
  }

  manualValidationsClickAction($event) {
    this.manualValidationsClick.emit($event);
    this.validationsPanelVisible = false;
  }

  addFilterToFolder($event) {
    this.addFilterToFolderRequest.emit($event);
  }

  showConfirmAction($event) {
    this.showConfirm.emit($event);
  }

  showMassEdit($event) {
    this.matDialog.open(DialogSplitReclassificationMassEditionComponent, {
      data: {
        table: this.originTableName,
        filters: this.appliedFilters,
      },
    });
    this.dialogService.closeDialog('actions');
  }

  newItem(type, filter?) {
    this.newItemRequest.emit({ type, filter });
  }

  applyFilter(filter) {
    this.onApplyFilter.emit(filter);
  }

  showVisualization() {
    let fn = this.headerActions.find((e) => e.type === 'columnSelect');
    if (fn.fire) {
      fn.fire();
    }
  }

  exportF9() {
    const formName = this.originTableName;
    this.downloadService.downloadGeneratedForms(
      `Exportando formulario ${this.originTableName}`,
      formName,
    );
  }

  toggleColumnSearch(columnSearchVisible) {
    this.downloadsPanelVisible = false;
    this.outputColumnSearch.next(columnSearchVisible);
  }

  massEdition() {
    let fn = this.headerActions.find((e) => e.type === 'massEditor');
    if (fn.fire) {
      fn.fire();
    }
  }

  showFastfilter() {
    this.switchPanels('fastFilterPanelVisible', this.columnsSearchVisible);
    this.toggleColumnSearch(true);
  }

  refreshSheet(evt) {
    this.switchPanels('reloadSheetPanelVisible', this.refreshSheetPanelVisible);
    this.generateAction.emit(evt);
  }

  closedAuditoryNotification;

  subscribe() {
    this.notificationsService.getNotificationObservable().subscribe((notifications) => {
      this.enabledReloadButton = false;

      if (this.originTableName) {
        let origin = this.originService.getByOrigin(this.originTableName);

        let reload = origin.reload;
        let specificReload = origin.specificReload;

        let executingReloadProcessNotification = notifications.find(
          (notification) =>
            notification.class === 'executing_process' &&
            (notification.detail.caused_by.includes(reload) ||
              notification.detail.caused_by.includes(specificReload)),
        );

        if (executingReloadProcessNotification) {
          this.disabledReloadButtonReason =
            'Este proceso ya está en ejecución: ' + executingReloadProcessNotification.message;
          this.enabledReloadButton = false;
        } else {
          this.enabledReloadButton = true;
          this.disabledReloadButtonReason = '';
        }
        
        this.closedAuditoryNotification = notifications.find(
          (notification) => notification.class === 'closed_auditory',
        );
        this.cdr.detectChanges();
      }
    });
  }

  getOrigin() {
    return this.originService.getByOrigin(this.originTableName);
  }

  closeAuditory() {
    this.taskService.closeAuditory();
  }

  openAuditory() {
    this.taskService.openAuditory(this.closedAuditoryNotification.id);
  }

  get closedAuditory() {
    return this.closedAuditoryNotification;
  }
}
