import { Subject, Subscription, throwError, of } from 'rxjs';
import { Injectable } from '@angular/core';

import { environment } from './../../../environments/environment';
import { AuthService } from './../auth/auth.service';
import { NotificationsService, Notification } from './../notifications/notifications.service';
import { BdrRequestService } from './../bdr-request/bdr-request.service';
import { getExpandedItem, sortArrayByKey } from '../../util';
import { ItemDB } from '../../models/common';
import { startWith, catchError } from 'rxjs/operators';

export class FilterDB extends ItemDB {
  id?: any;
  filter?: Filter;
  quick_filter?: boolean;
  name?: string;
  folder_id?: number;
  folder_name?: string;
  folder_desc?: string;
  cross_origin?: string;
  table?: string;
  meta?: any;
  active?: boolean;
  tag?: string;
  applied?: boolean;
  admin?: boolean;
  saved?: boolean;
  query?: string;
  query_filter: string;
  children?: FilterDB[];

  constructor(item?: any) {
    super();
    this.initialize(item);
  }

  initialize(item: any = {}) {
    this.id = item.id || '';
    this.meta = item.meta || { type: 'filter' };
    this.table = item.table || '';
    this.name = item.name;
    this.folder_id = item.folder_id || -1;
    this.folder_name = item.folder_name || '';
    this.folder_desc = item.folder_desc || '';
    this.children = item.children || [];
    this.saved = !!item.saved;
    this.active = item.active || true;
    this.tag = item.tag;
    this.applied = item.applied;
    this.admin = item.admin;
    this.quick_filter = !!item.quick_filter;
    this.filter = item.filter || { rules: [] };
  }

  formatFilter() {
    return {
      filter: {
        condition: 'AND',
        rules: [getExpandedItem(this.filter, [])]
      }
    };
  }

  getSaveObj() {
    let saveObj = <FilterDB>{
      id: this.id,
      name: this.name,
      table: this.table,
      folder_id: this.folder_id || -1,
      query: JSON.stringify(this.filter),
      query_filter: JSON.stringify(this.formatFilter())
    };
    if (!!this.folder_name) saveObj.folder_name = this.folder_name;
    if (!!this.folder_desc) saveObj.folder_desc = this.folder_desc;
    return saveObj;
  }

  isFastFilter() {
    return this.id && this.id.toString().startsWith('fs_');
  }
}

export class FolderDB extends FilterDB {
  editMode: boolean = false;
  expanded: boolean = true;
  constructor(item?: any) {
    super();
    this.initialize(item);
  }

  getSaveObj() {
    return <FolderDB>{
      table: this.table,
      id: this.id,
      folder_id: this.folder_id,
      folder_name: this.folder_name,
      folder_desc: this.folder_desc
    };
  }

  initialize(item: any = {}) {
    this.id = item.id || ''
    this.table = item.table || ''
    this.meta = item.meta || { type: 'folder' }
    this.name = null
    this.editMode = item.editMode || false
    this.folder_id = item.folder_id || 1
    this.folder_name = item.folder_name || ''
    this.folder_desc = item.folder_desc || ''
    this.children = item.children || [];
    this.saved = item.saved || false;
    this.active = item.active || true;
    this.tag = item.tag;
    this.applied = item.applied;
    this.admin = item.admin;
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
  }
}

export class Filter {
  condition: string;
  rules: (FilterRule | Filter)[];
}

export class FilterRule {
  field?: string;
  id: string;
  input?: string;
  operator?: string;
  type: string;
  value: string | string[];
  reference?: string;
  origin?: string;

}

@Injectable()
export class FilterService {
  private filters: any = {};
  private filterSubject: Subject<FilterDB[]>;
  private callBackend: {
    [origin: string]: boolean;
  };
  private hiddenFilters: { [origin: string]: { item: FilterDB; index: number }[] } = {};
  private profileSubscription: Subscription;

  constructor(
    private request: BdrRequestService,
    private auth: AuthService,
    private notificationsService: NotificationsService
  ) {
    this.filterSubject = new Subject<FilterDB[]>();
    this.callBackend = {
      filters: false
    };
    this.profileSubscription = null;
  }

  private processResponseItem(element) {
    element.saved = true;
    if (element.query) {
      element.filter = JSON.parse(element.query);
    }
    if (element.folder_id === -1 || (!!element.name && !!element.folder_id)) {
      element = new FilterDB(element);
    } else if (!element.name && element.folder_id !== -1) {
      element = new FolderDB(element);
    }
    return element;
  }

  private processResponse(response, origin) {
    for (let i = 0, len = response.result.length; i < len; i++) {
      response.result[i] = this.processResponseItem(response.result[i]);
    }
    this.filters[origin] = sortArrayByKey(response.result, 'name') || [];
    this.hiddenFilters[origin] = [];
    this.filterSubject.next(this.filters);
  }

  public get(origin: string): void {
    if (!this.isCalledBackend(origin)) {
      this.callBackend[origin] = true;
      this.checkProfileAndPermission()
        .then(result => {
          return this.request.get(this.getEndpoint(origin)).subscribe(
            response => {
              this.processResponse(response, origin);
            },
            error => {
              this.callBackend[origin] = false;
            }
          );
        })
        .catch(error => {
          this.callBackend[origin] = false;
          return throwError(error);
        });
    }
  }

  public getFilterSubject() {
    this.sortFilters('name');
    return this.filterSubject.asObservable().pipe(startWith(this.filters));
  }

  private sortFilters(sortkey) {
    Object.keys(this.filters).forEach(filterKey => {
      this.filters[filterKey] = sortArrayByKey(this.filters[filterKey], sortkey);
    });
  }

  public create(item: FilterDB): void {
    const saveValue = item.getSaveObj();
    this.checkProfileAndPermission()
      .then(result => {
        this.request.post(`${environment.host}/filters`, saveValue).subscribe(
          (newItem: any) => {
            item.saved = true;
            this.filters[item.table].unshift(item);
            this.addNotification('confirmation', 'Filtro creado correctamente.');
            this.sortFilters('name');
            this.filterSubject.next(this.filters);
          },
          (error: Error) => {
            this.addNotification('error', 'No se ha podido crear el filtro.');
          }
        );
      })
      .catch(error => {
        console.error(error);
        // return throwError(error)
      });
  }

  public saveFolder(item: FolderDB) {
    if (!!item.saved) {
      return this.updateFolder(item);
    }
    this.createFolder(item);
  }

  public createFolder(item: FolderDB) {
    const saveValue = item.getSaveObj();
    this.checkProfileAndPermission()
      .then(result => {
        this.request.post(`${environment.host}/filters/folders`, saveValue).subscribe(
          (newItem: any) => {
            item.saved = true;
            item.toggleEditMode();
            let index = this.filters[item.table].findIndex(
              filterItem => filterItem.folder_id === item.folder_id
            );
            if (index === -1) {
              this.filters[item.table].unshift(item);
            } else {
              this.filters[item.table][index] = item;
            }
            this.addNotification('confirmation', 'Carpeta creada correctamente.');
            this.sortFilters('name');
            this.filterSubject.next(this.filters);
          },
          (error: Error) => {
            this.addNotification('error', 'No se ha podido crear la carpeta.');
          }
        );
      })
      .catch(error => {
        console.error(error);
        // return throwError(error)
      });
  }

  public duplicate(item: FilterDB): void {
    const itemToDuplicate = this.prepareToDuplicate(item);
    this.create(itemToDuplicate);
  }

  public update(item: FilterDB): void {
    const saveValue = item.getSaveObj();
    this.checkProfileAndPermission()
      .then(result => {
        this.request.put(`${this.getEndpoint(item.table)}/${item.id}`, saveValue).subscribe(
          (updatedItem: any) => {
            const index = this.filters[item.table].findIndex(
              itemUpdated => itemUpdated.id === item.id
            );
            item.saved = true;

            this.filters[item.table][index] = item;
            this.addNotification('confirmation', 'Filtro actualizado correctamente.');
            setTimeout(() => {
              this.sortFilters('name');
              this.filterSubject.next(this.filters);
            }, 500);
          },
          (error: Error) => {
            this.addNotification('error', 'No se ha podido actualizar el filtro.');
          }
        );
      })
      .catch(error => {
        console.error(error);
        return throwError(error);
      });
  }

  public updateFolder(item: FolderDB): void {
    const saveValue = item.getSaveObj();
    this.checkProfileAndPermission()
      .then(result => {
        this.request
          .put(`${this.getFolderEndpoint(item.table)}/${item.folder_id}`, saveValue)
          .subscribe(
            (updatedItem: any) => {
              const index = this.filters[item.table].findIndex(
                itemUpdated => itemUpdated.id === item.id
              );
              item.saved = true;
              item.toggleEditMode();
              this.filters[item.table][index] = item;
              this.addNotification('confirmation', 'Carpeta actualizada correctamente.');
              setTimeout(() => {
                this.sortFilters('name');
                this.filterSubject.next(this.filters);
              }, 500);
            },
            (error: Error) => {
              this.addNotification('error', 'No se ha podido actualizar la carpeta.');
            }
          );
      })
      .catch(error => {
        console.error(error);
        return throwError(error);
      });
  }
  private isFolder(item) {
    return !!item.folder_id && item.folder_id !== -1 && !item.name;
  }
  public hide(item: any): void {
    if (!this.hiddenFilters[item.table]) {
      this.hiddenFilters[item.table] = [];
    }
    const index = this.filters[item.table].findIndex(res => res.id === item.id);
    const hiddenFilter = {
      item,
      index
    };

    this.hiddenFilters[item.table].push(hiddenFilter);
    this.filters[item.table].splice(index, 1);

    this.addNotification(
      'confirmation',
      this.isFolder(item) ? 'Carpeta borrada correctamente.' : 'Filtro borrado correctamente.'
    );
    this.sortFilters('name');
    this.filterSubject.next(this.filters);
  }

  public undoHide(item: FilterDB): void {
    const index = this.hiddenFilters[item.table].findIndex(res => res.item.id === item.id);
    if (index > -1) {
      this.filters[item.table].splice(
        this.hiddenFilters[item.table][index].index,
        0,
        this.hiddenFilters[item.table][index].item
      );
      this.hiddenFilters[item.table].splice(index, 1);
      this.sortFilters('name');
      this.filterSubject.next(this.filters);
    }
  }

  private removeLocalFolder(item: FolderDB) {
    for (let i = this.filters[item.table].length - 1; i >= 0; i--) {
      if (this.filters[item.table][i].folder_id === item.folder_id) {
        this.filters[item.table].splice(i, 1);
      }
    }
  }

  public removeFolder(item: FolderDB) {
    this.checkProfileAndPermission()
      .then(result => {
        this.request
          .delete(`${this.getFolderEndpoint(item.table)}/${item.folder_id}`)
          .pipe(
            catchError(err => {
              console.error(err);
              this.addNotification('error', 'No se ha podido borrar la carpeta.');
              this.undoHide(item);
              return throwError(err);
            })
          )
          .subscribe(response => {
            this.removeLocalFolder(item);
            this.sortFilters('name');
            this.filterSubject.next(this.filters);
          });
      })
      .catch((err: Error) => {
        console.error(err);
      });
  }

  public remove(item: FilterDB): void {
    this.checkProfileAndPermission()
      .then(result => {
        this.request.delete(`${this.getEndpoint(item.table)}/${item.id}`).subscribe(
          () => {
            this.sortFilters('name');
            this.filterSubject.next(this.filters);
          },
          (error: Error) => {
            this.addNotification('error', 'No se ha podido borrar el filtro.');
            this.undoHide(item);
          }
        );
      })
      .catch(error => {
        console.error(error);
      });
  }

  addFilterToFolder(filter, folder_id) {
    filter.folder_id = folder_id;
    this.update(filter);
  }

  public isValidForm(name: string, qbFilter: any): boolean {
    const nameValid = name !== undefined;
    const qbFilterValid: boolean = qbFilter.rules.length > 0;
    return nameValid && qbFilterValid;
  }

  public prepareToDuplicate(item: FilterDB): FilterDB {
    const itemToDuplicate = new FilterDB({
      id: this.getNewFilterId(item.table),
      name: item.name + '_copia',
      filter: item.filter,
      quick_filter: item.quick_filter || false,
      table: item.table
    });
    return itemToDuplicate;
  }

  /**
   * Returns a new object that refers to a saved item.
   *
   * @private
   * @param {Filter} item Object referring to saved item in DB.
   * @returns {Filter} Returns an object that refers to a saved item with
   *  the structure needed by QueryBuilder element.
   *
   * @memberOf QueryBuilderComponent
   */
  public getExpandedFilter(item: Filter): Filter {
    return getExpandedItem(item, this.filters);
  }

  private getEndpoint(origin: string): string {
    return `${environment.host}/filters/${origin}`;
  }
  private getFolderEndpoint(origin: string): string {
    return `${environment.host}/filters/folders/${origin}`;
  }

  private isCalledBackend(origin): boolean {
    return !!origin && this.callBackend[origin] !== undefined ? this.callBackend[origin] : false;
  }

  private addNotification(type: string, message: string): void {
    const not: Notification = {
      type,
      message,
      fixed: false,
      popup: true,
    };
    this.notificationsService.add(not);
  }

  private checkProfileAndPermission(): any {
    return new Promise((resolve, reject) => {
      this.profileSubscription = this.auth
        .getProfile()
        .pipe(
          catchError(error => {
            reject('No tiene perfil');
            return throwError('No tiene perfil');
          })
        )
        .subscribe(profile => {
          if (profile) {
            return resolve(profile);
          }
          reject('No tiene permisos');
        });
    });
  }

  public reset() {
    Object.keys(this.filters).forEach(key => {
      this.filters[key] = [];
    });

    Object.keys(this.callBackend).forEach(key => {
      this.callBackend[key] = false;
    });

    Object.keys(this.hiddenFilters).forEach(key => {
      this.hiddenFilters[key] = [];
    });
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
  }

  getNewFilter(table): FilterDB {
    return new FilterDB({
      id: this.getNewFilterId(table),
      name: '',
      table
    });
  }

  getNewFilterId(table): number {
    if (!this.filters[table]) {
      this.filters[table] = [];
    }
    let maxId = Math.max(...this.filters[table].map(item => item.id));
    maxId = maxId < 0 ? 0 : maxId;
    return maxId + 1;
  }

  getNewFolderId(table): number {
    if (!this.filters[table]) {
      this.filters[table] = [];
    }
    let displayFolders = this.filters[table].filter(item => this.isFolder(item));
    let maxId = Math.max(...displayFolders.map(item => item.folder_id));
    maxId = maxId < 0 ? 0 : maxId;
    return maxId + 1;
  }

  formatFilter(appliedFilters: FilterDB[] = []): FilterDB {
    let filter: FilterDB;
    if (appliedFilters.length > 0) {
      filter = new FilterDB({
        filter: {
          condition: 'AND',
          rules: appliedFilters.map((appliedFilter: any) => {
            switch (appliedFilter.meta.type) {
              case 'filter':
              case 'validations-filter':
              case 'manual-validations-filter':
                return this.getExpandedFilter(appliedFilter.filter);
            }
          })
        }
      });
    }
    return filter || null;
  }

  getFastSearchConditions(appliedFilters: FilterDB[] = []) {
    let fastSearchFilters = appliedFilters.filter(item => !!item.meta.fastSearch);
    let conditions = {};
    fastSearchFilters.forEach(item => {
      conditions[item.filter.rules[0]['field']] = <FastSearchCondition>{
        colName: item.filter.rules[0]['field'],
        search: item.filter.rules[0]['value'],
        type: item.filter.rules[0]['type']
      };
    });
    return conditions;
  }
}

export interface FastSearchCondition {
  colName: string;
  search: string;
  type: string;
}
class FilterServiceMessages {
  static readonly create = {
    success: 'Filtro creado correctamente.',
    error: {
      default: 'No se ha podido crear el filtro.'
    }
  };

  static readonly update = {
    success: 'Filtro actualizado correctamente.',
    error: {
      default: 'No se ha podido actualizar el filtro.',
      circular: 'No se ha podido actualizar el filtro porque está causando una referencia circular.'
    }
  };

  static readonly hide = {
    success: 'Se ha iniciado el proceso de eliminación del filtro.'
  };

  static readonly delete = {
    error: {
      default: 'No se ha podido borrar el filtro.',
      ruleInReferences: 'No se ha podido borrar el filtro porque está siendo referenciado.'
    }
  };
}
