import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'legalization',
  templateUrl: './legalization.component.html',
  styleUrls: ['./legalization.component.scss']
})
export class LegalizationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
