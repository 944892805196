import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DownloadsService } from '../../services/downloads/downloads.service';

@Component({
  selector: 'bdr-header-notifications',
  templateUrl: './bdr-header-notifications.component.html',
  styleUrls: ['./bdr-header-notifications.component.scss']
})
export class BdrHeaderNotificationsComponent implements OnInit {
  @Input() editMode: boolean;
  @Input() saving: boolean;
  @Input() text: string;
  @Input() isDisabled: boolean;
  @Input() isDisabledReason: string;

  @Input() recurrentEditionCheckbox: boolean;

  @Output() onSaveChanges: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancelSave: EventEmitter<void> = new EventEmitter<void>();

  recurrentEdition: boolean;

  constructor(public downloadsService: DownloadsService) { }

  ngOnInit() { }

  saveChanges($event) {
    this.onSaveChanges.emit({recurrentEdition: this.recurrentEdition});
  }
  
  cancelActions() {
    this.onCancelSave.emit();
  }
}
