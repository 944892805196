import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy
} from "@angular/core";
import clone from "clone";

import { BdrContextualMenuService } from "./../../services/bdr-contextual-menu/bdr-contextual-menu.service";
import { CtxInputMultipleComponent } from "./../bdr-contextual-menu/ctx-input-multiple/ctx-input-multiple.component";

@Component({
  selector: "bdr-input-multiple",
  templateUrl: "./bdr-input-multiple.component.html",
  styleUrls: ["./bdr-input-multiple.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputMultipleComponent {
  @ViewChild("input", { static: false }) inputComponent: ElementRef;

  @Input() caption: string;
  @Input() model: string[];
  @Input() separator: string;
  @Input() error: boolean;
  @Input() disabled: boolean;
  @Input() errorMsg: string;
  @Input() bottomError: string;
  @Input() numeric: boolean;
  @Input() grow: boolean;

  @Output() modelChange: EventEmitter<string[]>;
  @Output() validationError: EventEmitter<{ error: boolean; message: string }>;

  value: string;
  focus: boolean;

  constructor(private ctxmenuService: BdrContextualMenuService) {
    this.model = [];
    this.separator = " ";
    this.modelChange = new EventEmitter<string[]>();
    this.validationError = new EventEmitter<{
      error: boolean;
      message: string;
    }>();
    this.disabled = false;
    this.error = false;
    this.errorMsg = "";
    this.caption = "";
    this.value = "";
    this.focus = false;
    this.grow = true;
  }

  keydown(evt: KeyboardEvent): void {
    if (evt.keyCode === 8 && this.value === "") {
      // backspace
      this.model.pop();
      this.modelChange.emit(this.model);
      this.validateWords(this.model);
    }

    if (evt.keyCode === 13 || evt.key === this.separator) {
      // enter or separator
      this.processWords();
      evt.preventDefault();
    }
  }

  processWords(): void {
    // model is not instantly updated on paste
    setTimeout(() => {
      if (this.value.length > 0) {
        const words = this.value
          .replace(/&nbsp/g, " ")
          .trim()
          .split(this.separator);
        // .filter(word => !this.numeric || (!!this.numeric && Number.isInteger(Number(word))));

        if (!!this.numeric && !this.error) {
          this.validateWords(words);
        }

        if (words.length > 0) {
          this.model.push(...words);
          this.modelChange.emit(this.model);
          this.value = "";
        }
        this.value = "";
      }
    }, 0);
  }

  removeWord(index: number): void {
    this.model.splice(index, 1);
    this.modelChange.emit(this.model);
    this.validateWords(this.model);
  }

  focusOnInput(): void {
    <HTMLInputElement>this.inputComponent.nativeElement.focus();
    this.focus = true;
  }

  openMenu(evt: MouseEvent) {
    const options = {
      complex: true,
      body: {
        list: clone(this.model),
        numeric: this.numeric,
        error: this.error,
        errorMsg: this.errorMsg,
        apply: (model: Array<string>) => {
          this.model = model;
          this.ctxmenuService.close();
          this.validateWords(this.model);
        }
      }
    };

    this.ctxmenuService.open(CtxInputMultipleComponent, evt, options);
  }

  private checkError(list): boolean {
    return list.length !== list.filter(a => Number.isInteger(Number(a))).length;
  }

  private validateWords(list): void {
    if (!!this.numeric) {
      this.error = this.checkError(list);
      this.errorMsg = "Formato incorrecto";
      this.validationError.emit({
        error: this.error,
        message: this.errorMsg
      });
    }
  }
  validateWord(word: string): boolean {
    return !!this.numeric && !Number.isInteger(Number(word));
  }

  onBlur(): void {
    this.focus = false;
    this.processWords();
  }
}
