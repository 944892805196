import { Injectable } from '@angular/core';

import { CognitoUserSession, CognitoUserPool } from 'amazon-cognito-identity-js';
import { CognitoAuth, CognitoAuthOptions } from 'amazon-cognito-auth-js';
import * as AWS from 'aws-sdk/global';
import * as awsservice from 'aws-sdk/lib/service';
import * as CognitoIdentity from 'aws-sdk/clients/cognitoidentity';
import { environment } from './../../../environments/environment';

export interface CognitoCallback {
  cognitoCallback(message: string, result: any): void;
}

export interface Callback {
  callback(): void;
  callbackWithParam(result: any): void;
}

@Injectable()
export class CognitoUtil {
  public static _REGION = environment.auth.region;

  public static _IDENTITY_POOL_ID = environment.auth.identityPoolId;
  public static _USER_POOL_ID = environment.auth.userPoolId;
  public static _CLIENT_ID = environment.auth.clientId;

  public static _POOL_DATA: any = {
    UserPoolId: CognitoUtil._USER_POOL_ID,
    ClientId: CognitoUtil._CLIENT_ID,
  };
  public cognitoCreds: AWS.CognitoIdentityCredentials;

  getUserPool(): CognitoUserPool {
    return new CognitoUserPool(CognitoUtil._POOL_DATA);
  }

  getCurrentUser() {
    return this.getUserPool().getCurrentUser();
  }

  buildCognitoAuth() {
    let url = `${window.location.protocol}//${window.location.host}/`;
    let authData = {
      ClientId: environment.auth.auth0clientId,
      AppWebDomain: environment.auth.domain,
      TokenScopesArray: environment.auth.scope,
      RedirectUriSignIn: url,
      UserPoolId: environment.auth.userPoolId,
      RedirectUriSignOut: url,
      AdvancedSecurityDataCollectionFlag: false,
    };

    return new CognitoAuth(authData);
  }

  buildCognitoCreds(idTokenJwt: string) {
    let url = environment.auth.cognitoDomain;
    let logins: CognitoIdentity.LoginsMap = {};
    logins[url] = idTokenJwt;
    let params = {
      IdentityPoolId: CognitoUtil._IDENTITY_POOL_ID /* required */,
      Logins: logins,
    };
    let serviceConfigs = <awsservice.ServiceConfigurationOptions>{};
    let creds = new AWS.CognitoIdentityCredentials(params, serviceConfigs);
    this.setCognitoCreds(creds);
    return creds;
  }

  setCognitoCreds(creds: AWS.CognitoIdentityCredentials) {
    this.cognitoCreds = creds;
    AWS.config.credentials = creds;
  }
}
