import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  OnChanges,
  ViewChild
} from "@angular/core";
import { WindowService } from "../../services/window/window.service";

@Component({
  selector: "bdr-input-search",
  templateUrl: "./bdr-input-search.component.html",
  styleUrls: ["./bdr-input-search.component.scss"],
  providers: [WindowService]
})
export class BdrInputSearchComponent implements OnInit, OnChanges {
  @ViewChild("searchInput", { static: false }) searchInput: ElementRef;
  @Input() model: string;
  @Input() size: string;
  @Input() bold: boolean;
  @Input() placeholder: string;
  @Input() options;
  @Input() selectMode: boolean;
  @Input() disabled: boolean;
  @Input() errorMsg: string;
  @Input() bottomError: boolean;

  @Output() modelChange: EventEmitter<string>;
  @Output() searchChange: EventEmitter<string>;

  active: boolean;
  displayOptions: any;
  iconSearch: boolean;
  iconCancel: boolean;
  positionDropdown: string;
  caption: string;
  focus: boolean;

  constructor(
    private windowService: WindowService,
    private hostElement: ElementRef
  ) {
    this.model = "";
    this.size = "regular";
    this.options = [];
    this.disabled = false;
    this.errorMsg = "";

    this.modelChange = new EventEmitter<string>();
    this.searchChange = new EventEmitter<string>();

    this.active = false;
    this.displayOptions = [];
    this.iconSearch = false;
    this.iconCancel = false;
    this.positionDropdown = null;
    this.caption = "";
    this.focus = false;
  }

  ngOnInit() {
    this.placeholder = this.placeholder || "";
    this.caption = this.getCaptionValue(this.model);
    this.searchChange.emit(this.caption);
  }

  ngOnChanges(changes: any) {
    // if (!!changes.model) {
    //   this.caption = this.getCaptionValue(changes.model.currentValue);
    //   // this.searchChange.emit(this.caption);
    // }
  }

  emitChange(value: string): void {
    this.modelChange.emit(value);
    this.displayOptions = [];
    this.setActive(false);
    this.model = value;
  }

  filterOptions(value: any): void {
    this.errorMsg = "";
    if (value !== "") {
      this.setIconSearch(false);
      this.setIconCancel(true);
      this.setActive(true);
      this.displayOptions = this.options.filter(choice =>
        choice.caption.toUpperCase().includes(value.toUpperCase())
      );
      if (this.displayOptions.length > 0) {
        this.calculatePositionDropDown(this.displayOptions.length);
      }
    } else {
      this.iconSearch = !!this.focus;
      this.displayOptions = this.options;
      this.setIconCancel(false);
      this.setActive(false);
    }
    this.caption = value;
    this.searchChange.emit(value);
  }

  setFocusInput(event: any) {
    if (event.target.value === "") {
      this.setIconSearch(true);
      this.setIconCancel(false);
    } else {
      this.setIconCancel(true);
      this.setIconSearch(false);
    }
    this.setFocus(true);
    this.setActive(true);
  }

  setBlurInput(event: any) {
    this.setIconCancel(false);
    this.setIconSearch(false);
    this.setFocus(false);
    this.setActive(false);
    this.displayOptions = [];
    this.caption = this.getCaptionValue(this.model);
  }

  dropOptionList(value: any) {
    this.displayOptions = this.options;
    this.searchInput.nativeElement.setSelectionRange(
      0,
      this.searchInput.nativeElement.value.length
    );
    this.calculatePositionDropDown(this.displayOptions.length);
    this.setActive(!this.active);
  }

  selectOption(option: any) {
    this.caption = this.getCaptionValue(option.name);
    // this.searchChange.emit(this.caption);
    this.model = option.name;
    this.setActive(false);
    this.emitChange(option.name);
  }

  calculatePositionDropDown(nOptions: number): void {
    const cellHeight = 30;
    const maxHeightWindow = 200;
    const componentHeight = 36;

    const distance = this.getComponentDistance();
    const optionsHeight =
      nOptions * cellHeight <= maxHeightWindow
        ? nOptions * cellHeight
        : maxHeightWindow;
    const disToBottom =
      this.hostElement.nativeElement.ownerDocument.body.getBoundingClientRect()
        .height -
      distance.top -
      componentHeight;

    if (disToBottom >= optionsHeight) {
      this.positionDropdown = "bottom";
    } else {
      this.positionDropdown = "top";
    }
  }

  getComponentDistance(): any {
    return {
      left: this.hostElement.nativeElement.getBoundingClientRect().left,
      top: this.hostElement.nativeElement.getBoundingClientRect().top
    };
  }

  private getCaptionValue(value: string): string {
    const option = this.options.find(item => item.name === value);
    return !!option ? option.caption : "";
  }

  private setIconCancel(bool): void {
    this.iconCancel = bool;
  }
  private setIconSearch(bool): void {
    this.iconSearch = bool;
  }

  private setFocus(bool): void {
    this.focus = bool;
  }

  private setActive(bool): void {
    this.active = bool;
    if (!bool) {
      this.displayOptions = [];
    }
  }
}
