import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";

import {
  QueryBuilderConditionService,
  ConditionRow
} from "./query-builder-condition.service";
import { Schema } from "./../../../services/schema/schema.service";
import { SelectOption } from "./../../../models/common";

@Component({
  selector: "bdr-query-builder-condition",
  templateUrl: "./query-builder-condition.component.html",
  styleUrls: ["./query-builder-condition.component.scss"],
  providers: [QueryBuilderConditionService]
})
export class QueryBuilderConditionComponent implements OnInit {
  @Input() queryRule: ConditionRow;
  @Input()
  schemaItems: { [origin: string]: { caption: string, items: Schema[] } };
  @Input() operators: any[];
  @Input() isVisibleButtonTrash: boolean;
  @Input() gridSelector: boolean; // temporal, 1ª columna para elegir el grid...

  @Output() validationError: EventEmitter<{ error: boolean; message: string }>;
  @Output() removeConditionRequest: EventEmitter<void>;
  @Output() expandItemRequest: EventEmitter<ConditionRow>;
  @Output() validateConditionRequest: EventEmitter<boolean>;


  currentSchemaItems: { name: string; caption: string; type: string }[];
  operatorsShown: any[];
  inputType: string;
  type: string;
  inputValueFormatted: string;
  multipleInputValue: string[];
  originItems: any;
  fieldSelectorReady: boolean;

  originErrorMessage = "";

  constructor(private conditionHandler: QueryBuilderConditionService) {
    this.validationError = new EventEmitter<{
      error: boolean;
      message: string;
    }>();
    this.removeConditionRequest = new EventEmitter<void>();
    this.expandItemRequest = new EventEmitter<ConditionRow>();
    this.validateConditionRequest = new EventEmitter<boolean>();

    this.fieldSelectorReady = true;
  }

  ngOnInit() {
    this.originItems = Object.keys(this.schemaItems).map(item => {
      return {
        caption: this.schemaItems[item].caption,
        name: item,
        value: item
      };
    });
    this.queryRule.origin = this.queryRule.origin || this.originItems[0].value;
    this.currentSchemaItems = this.schemaItems[this.queryRule.origin].items.map(this.getSchemaItem.bind(this));
    this.formatDefaultValues();
    this.isVisibleButtonTrash = true;
  }

  private formatOperator(item) {
    return {
      name: item.caption,
      value: item.name
    };
  }


  private getSchemaItem(item) {
    return {
      name: item.field,
      caption: item.title,
      type: item.type
    };
  }
  private formatDefaultValues(): void {
    this.operatorsShown = this.operators.map(this.formatOperator.bind(this));
    this.inputType = this.conditionHandler.getInputType(
      this.currentSchemaItems,
      this.queryRule.field
    );
    this.inputValueFormatted = <string>this.queryRule.value;

    if (this.queryRule.type === "date") {
      this.inputType = this.queryRule.operator.includes("time")
        ? "datetime-local"
        : "date";

      // Solución temporal para que las condiciones ya creadas que incluyan fechas sigan funcionando
      if (!this.queryRule.operator.includes("date") && !!this.queryRule.value) {
        this.queryRule.value = (<string>this.queryRule.value).replace("T", " ");
        this.inputType = "datetime-local";
      }

      this.inputValueFormatted = !!this.queryRule.value
        ? (<string>this.queryRule.value).replace(" ", "T")
        : undefined;
    }

    if (!!(<any>this.queryRule).reference) {
      this.type =
        this.queryRule.type.indexOf("rule") !== -1 ? "rule" : "filter";
    }

    if (["in", "not_in"].indexOf(this.queryRule.operator) !== -1) {
      this.multipleInputValue = <string[]>this.queryRule.value;
    } else {
      this.multipleInputValue = [];
    }
  }

  removeCondition($event: any): void {
    this.removeConditionRequest.emit();
  }

  expandItem(): void {
    this.expandItemRequest.emit(this.queryRule);
  }

  originChange(originSelected: string): void {
    // reset queryRule
    this.queryRule.field = "";
    this.queryRule.operator = "";
    this.queryRule.value = "";
    this.currentSchemaItems = this.schemaItems[originSelected]
      ? this.schemaItems[originSelected].items.map(this.getSchemaItem.bind(this))
      : [];

    if (
      this.originItems.find(originItem => originItem.name === originSelected)
    ) {
      this.queryRule.origin = originSelected;
    } else {
      this.queryRule.origin = "";
      this.originErrorMessage = "Campo obligatorio";
    }
  }

  filterChange(optionSelected: string): void {
    this.setFieldOption(optionSelected);
    this.setTypeOption(optionSelected);
    this.setOperatorOption(this.operatorsShown[0].value);
    this.setValueOption(this.queryRule.type === "boolean" ? null : "");
    this.validateCondition();
  }

  operatorChange(optionSelected: string): void {
    this.setOperatorOption(optionSelected);
    this.setValueOption("");
    this.validateCondition();

    if (this.queryRule.type === "date") {
      this.inputType = optionSelected.includes("time")
        ? "datetime-local"
        : "date";
    }
  }

  valueChange($event: MouseEvent): void {
    const optionSelected = (<any>$event.target).value;
    this.inputValueFormatted = optionSelected;
    if (this.queryRule.type === "date") {
      this.setValueOption(optionSelected.replace("T", " "));
    } else {
      this.setValueOption(optionSelected);
    }

    this.validateCondition();
  }

  valueMultipleChange(valueList: string[]) {
    this.queryRule.value = valueList;
  }

  setVisibleButtonTrash(visible: boolean) {
    this.isVisibleButtonTrash = visible;
  }

  private validateCondition(): void {
    this.validateConditionRequest.emit(
      this.conditionHandler.validateCondition(this.queryRule)
    );
  }

  private setFieldOption(option: string): void {
    this.queryRule.field = option;
    this.queryRule.id = option;
  }

  private setOperatorOption(option: string): void {
    this.queryRule.operator = option;
  }

  private setTypeOption(option: string): void {
    const inputType = this.conditionHandler.getInputType(
      this.currentSchemaItems,
      option
    );

    const optionType = this.conditionHandler.getOptionType(
      this.currentSchemaItems,
      option
    );

    this.inputType = inputType;
    this.queryRule.type = optionType;

    this.operatorsShown = this.conditionHandler
      .filterArray(this.operators, optionType)
      .map(this.formatOperator.bind(this));
  }

  private setValueOption(option: string): void {
    const nullableOperators = [
      "is_empty",
      "is_null",
      "is_not_empty",
      "is_not_null",
      "is_empty_or_null",
      "is_not_empty_or_null",
      "is_true",
      "is_false"
    ];
    const isOperatorNullable =
      nullableOperators.indexOf(this.queryRule.operator) !== -1;
    this.inputValueFormatted =
      this.queryRule.type === "date" ? option.replace(" ", "T") : option;
    this.queryRule.value = !isOperatorNullable ? option : null;
  }

  handleInputMultipleValidationError(error: {
    error: boolean;
    message: string;
  }): void {
    this.validationError.emit(error);
  }
}
