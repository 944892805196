import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class LoggedGuard implements CanActivate {
  constructor(private authService: AuthService, private route: Router) { }

  async canActivate() {
    let result = await this.authService.isAuthenticated();
    if (result === true) {
      return result;
    }

    this.route.navigate(['']);
    return false;
  }
}
