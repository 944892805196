import { Component, OnInit } from '@angular/core';
import { BdrContextualMenuService } from '../../../services/bdr-contextual-menu/bdr-contextual-menu.service';
import { ContextualmenuContent } from '../contextual-menu-content';

@Component({
  selector: 'ctx-retributive-year-selector',
  templateUrl: './ctx-retributive-year-selector.component.html',
  styleUrls: ['./ctx-retributive-year-selector.component.scss']
})
export class CtxRetributiveYearSelectorComponent extends ContextualmenuContent {
  constructor(private _ctxmenuService: BdrContextualMenuService) {
    super(_ctxmenuService);
  }

  optionClick(option: any): void {
    option.action();
    this.onClose.emit();
  }
}
