import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ContextualmenuContent } from './../contextual-menu-content';
import {
  CtxListItemOption,
  BdrContextualMenuService
} from './../../../services/bdr-contextual-menu/bdr-contextual-menu.service';

@Component({
  selector: 'ctx-list',
  templateUrl: './ctx-list.component.html',
  styleUrls: ['./ctx-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CtxListComponent extends ContextualmenuContent {
  constructor(private _ctxmenuService: BdrContextualMenuService) {
    super(_ctxmenuService);
  }

  optionClick(option: CtxListItemOption): void {
    if (option.hasOwnProperty('action') && typeof option.action === 'function') {
      option.action();
      this.onClose.emit();
    }
  }
}
