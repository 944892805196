import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from "@angular/core";

@Component({
  selector: "bdr-splash",
  templateUrl: "./bdr-splash.component.html",
  styleUrls: ["./bdr-splash.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BdrSplashComponent {
  @Input() icon: boolean;
  @Input() title: string;
  @Input() clickable: boolean;
  @Input() descriptions: string[];
  @Input() iconImage = 'ico-eyenot'
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();
  constructor() { }

  clickEvt() {
    this.onClick.emit();
  }
}
