import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';

@Component({
  selector: 'bdr-select-multiple-check',
  templateUrl: './bdr-select-multiple-check.component.html',
  styleUrls: ['./bdr-select-multiple-check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BdrSelectMultipleCheckComponent implements OnInit, OnChanges {
  @Input() modelValue;
  @Input() optionValues = [];
  @Input() separator: string;
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  values = [];
  valueMap = {};
  showOptions = false;
  constructor() { }

  ngOnInit() {
    this.processValue();
  }

  ngOnChanges(changes) {
    if (changes) {
      this.processValue();
    }
  }

  processValue() {
    this.values = this.separator
      ? this.modelValue.split(this.separator)
      : this.modelValue;
    this.optionValues.forEach(element => {
      this.valueMap[element.name] = this.values.indexOf(element.value) !== -1;
    });
  }

  toggleOptions(evt: MouseEvent) {
    if (evt) {
      evt.stopPropagation();
    }
    const target = evt.target;
    if (target['nodeName'] !== 'DIV' && this.showOptions === false) {
      this.showOptions = true;
    } else if (
      target['nodeName'] === 'DIV' ||
      (target['classList'].value.includes('icon-ico-select_down') ||
        target['classList'].value.includes('icon-ico-select_up'))
    ) {
      this.showOptions = !this.showOptions;
    }
  }

  changeValue() {
    const values = [];
    for (const key in this.valueMap) {
      if (this.valueMap[key] === true) {
        values.push(key);
      }
    }
    this.onChange.emit(values.join(this.separator));
  }
}
