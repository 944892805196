import { Component, OnInit } from "@angular/core";

@Component({
  selector: "investments-plan",
  templateUrl: "./investments-plan.component.html",
  styleUrls: ["./investments-plan.component.scss"]
})
export class InvestmentsPlanComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
