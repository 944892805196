import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';

import { AuthService } from '../auth/auth.service';

@Injectable()
export class BdrRequestService {
  constructor(private http: HttpClient, private auth: AuthService) { }

  get(url: string, parameters?: any): Observable<any> {
    const options = this.getOptions(parameters);
    return this.http.get(url, options);
  }

  getFile(url: string, parameters?: any): Observable<any> {
    return this.http.get(url, this.getOptions(parameters, false, true));
  }

  post(url: string, body: any, image?: boolean): Observable<any> {
    return this.http.post(url, body, this.getOptions(undefined, image));
  }

  put(url: string, body: any, parameters?: any): Observable<any> {
    return this.http.put(url, body, this.getOptions(parameters));
  }

  delete(url: string): Observable<any> {
    return this.http.delete(url, this.getOptions());
  }

  private getOptions(parameters?: any, image?: boolean, file?: boolean): any {
    const options = <any>{
      headers: this.getHeaders(image, file),
      params: this.getURLSeachParameters(parameters)
    };
    if (file) {
      options.responseType = 'blob';
      options.observe = 'response';
    }
    return options;
  }

  s3UploadPresigned(url, formData: FormData) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');
    let request = new HttpRequest('POST', url, formData, { headers: headers });
    return this.http.request(request);
  }

  private getHeaders(image = false, file = false): any {
    const headersObj = {};
    if (image) {
      headersObj['Access-Control-Allow-Origin'] = '*';
      headersObj['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';
      return new HttpHeaders(headersObj);
    }
    if (file) {
      headersObj['Accept'] = 'application/octet-stream';
    }
    headersObj['Content-Type'] = 'application/json';
    return new HttpHeaders(headersObj);
  }

  private getURLSeachParameters(parameters: any = { offset: 0, limit: 50 }): HttpParams {
    const search = {};
    Object.getOwnPropertyNames(parameters).forEach(element => {
      let val: any = parameters[element];
      if (typeof val === 'object') {
        val = JSON.stringify(val);
      }
      search[element] = val;
    });
    return new HttpParams({
      fromObject: search
    });
  }
}
