import {
  Component,  OnInit,
  EventEmitter,
  Output,
  Input,
  OnChanges,
  ChangeDetectionStrategy
} from '@angular/core';

import { GridExportData } from '../../services/grid/grid.service';
import { DownloadsService } from '../../services/downloads/downloads.service';
import { DataApiService } from '../../services/data-api/data-api.service';
import { NotificationsService, Notification } from '../../services/notifications/notifications.service';

@Component({
  selector: 'bdr-export-cnmc-options',
  templateUrl: './bdr-export-cnmc-options.component.html',
  styleUrls: ['./bdr-export-cnmc-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BdrExportCNMCOptionsComponent implements OnChanges {
  @Input() appliedFilters: any = [];
  @Input() visibleColumns = [];
  @Input() allColumns = [];
  @Input() exportData: GridExportData[];
  @Output() close: EventEmitter<Function> = new EventEmitter<Function>();

  isFilter: boolean = false;
  isSelectedFields: boolean = false;


  constructor(
    private downloadsService: DownloadsService,
    private dataApiService: DataApiService,
    private notificationsService: NotificationsService,
  ) { }

  ngOnChanges() {
    this.isFilter = this.appliedFilters.length > 0;
    this.isSelectedFields = this.visibleColumns.length !== this.allColumns.length
  }

  closePanel() {
    this.close.emit();
  }

  exportCNMC() {
    this.dataApiService.checkAllsCNMCForms().subscribe(response=>{
      if (response.result === 'ok'  ) {
        return this.downloadsService.downloadCNMCGeneratedForms();
      }
      this.showExportError('No puede exportar los formularios porque alguno de ellos tiene validaciones bloqueantes');
    });
  }

  exportGroupedForms() {
    let origin = this.exportData[0].origin;
    this.dataApiService.checkCNMCForm(origin).subscribe(response=>{
      if (response.result === 'ok') {
        return this.downloadsService.downloadCNMCGroupedGeneratedForms(origin);
      }
      this.showExportError('El formulario que está intentando exportar tiene validaciones bloqueantes');
    });
  }

  exportForm(customView = undefined) {
    let exportItem = this.exportData[0];
    let downloadTitle = exportItem.title;
    let fileName = exportItem.filename;

    let newExportItem: GridExportData = {
      ...exportItem,
      origin: exportItem.origin,
      filterOrigin: exportItem.filterOrigin,
      title: exportItem.origin,
    }

    let visibleColumns = this.visibleColumns;
    let allColumns = this.allColumns;

    if (customView) {
        let originalOrigin = newExportItem.origin;
        let originSplit = originalOrigin.split('_vista');
        newExportItem.origin = `${originSplit[0]}_${customView}_vista`;

        let columnToRemove = 'identificador_regulatorio';
        visibleColumns = visibleColumns.filter((element) => element !== columnToRemove);
        allColumns = allColumns.filter((element) => element !== columnToRemove);

    }

    this.downloadsService.asyncDownload(newExportItem, false, [], visibleColumns, allColumns, 'cnmc');
  }



  exportFilteredForm(customView = undefined) {
    let exportItem = this.exportData[0];

    let newExportItem: GridExportData = {
      ...exportItem,
      origin: exportItem.origin,
      filterOrigin: exportItem.filterOrigin,
      title: exportItem.origin,
    };

    let visibleColumns = this.visibleColumns;
    let allColumns = this.allColumns;

    if (customView) {
      let originalOrigin = newExportItem.origin;
      let originSplit = originalOrigin.split('_vista');
      newExportItem.origin = `${originSplit[0]}_${customView}_vista`;

      let columnToRemove = 'identificador_regulatorio';

      visibleColumns = visibleColumns.filter((element) => element !== columnToRemove);
      allColumns = allColumns.filter((element) => element !== columnToRemove);

      let replacementField = this.getReplacementField(exportItem.origin);

      if (replacementField) {

        let modifiedFilters = this.appliedFilters.map(filter => {
          if (filter.filter && filter.filter.rules) {
            let modifiedRules = filter.filter.rules.map(rule => {
              if (rule.field === 'identificador_regulatorio') {
                return {
                  ...rule,
                  field: replacementField
                };
              }
              return rule;
            });
            return {
              ...filter,
              filter: {
                ...filter.filter,
                rules: modifiedRules
              }
            };
          }
          return filter;
        });

        this.downloadsService.asyncDownload(newExportItem, this.isFilter || this.isSelectedFields, modifiedFilters, visibleColumns, allColumns, 'cnmc');
        return;
      }
    }
    this.downloadsService.asyncDownload(newExportItem, this.isFilter || this.isSelectedFields, this.appliedFilters, visibleColumns, allColumns, 'cnmc');
  }

  groupedForms() {
    return [
      'fb1at_vista',
      'fb1bt_vista',
      'fb1mt_vista',

      'fb1_1at_vista',
      'fb1_1bt_vista',
      'fb1_1mt_vista',

      'fb7atmt_vista',
      'fb7bt_vista'
    ].includes(this.exportData[0].origin);
  }

  getReplacementField(customView: string): string | undefined {
    switch (customView) {
      case 'fb1bt_vista':
      case 'fb1mt_vista':
      case 'fb1at_vista':
        return 'identificador_tramo';
      case 'fb1_1bt_vista':
      case 'fb1_1at_vista':
      case 'fb1_1mt_vista':
        return 'cod_segmento';
      case 'fb2_vista':
      case 'fb2_1_vista':
      case 'fb2_2_vista':
        return 'identificador_ct';
      case 'fb3_2_vista':
      case 'fb3_3_vista':
        return 'identificador_subestacion';
      case 'fb4_vista':
        return 'identificador_posicion';
      case 'fb5_vista':
        return 'identificador_maquina';
      case 'fb6_vista':
        return 'identificador_em';
      default:
        return undefined;
    }
  }

  getRegulatoryParameter(customView: string): string | null {
    let regulatoryParameter: string | null = null;

    switch (customView) {
        case 'fb1bt_vista':
        case 'fb1mt_vista':
        case 'fb1at_vista':
            regulatoryParameter = 'pk_id_regulatorio';
            break;
        case 'fb1_1bt_vista':
        case 'fb1_1at_vista':
        case 'fb1_1mt_vista':
            regulatoryParameter = 'pk_id_regulatorio_cod_segmento';
            break;
        case 'fb2_vista':
        case 'fb2_1_vista':
        case 'fb2_2_vista':
            regulatoryParameter = 'pk_id_regulatorio';
            break;
        case 'fb3_2_vista':
        case 'fb3_3_vista':
            regulatoryParameter = 'pk_id_regulatorio';
            break;
        case 'fb4_vista':
            regulatoryParameter = 'pk_id_regulatorio';
            break;
        case 'fb5_vista':
            regulatoryParameter = 'pk_id_regulatorio';
            break;
        case 'fb6_vista':
            regulatoryParameter = 'pk_id_regulatorio';
            break;
        default:
            regulatoryParameter = null;
    }

    return regulatoryParameter;
  }


  hasRegulatoryId(origin) {
    return [
      'fb1bt_vista',
      'fb1mt_vista',
      'fb1at_vista',

      'fb1_1bt_vista',
      'fb1_1at_vista',
      'fb1_1mt_vista',

      'fb2_vista',

      'fb2_1_vista',
      'fb2_2_vista',

      'fb3_2_vista',
      'fb3_3_vista',

      'fb4_vista',
      'fb5_vista',
      'fb6_vista',
    ].includes(origin);
  }

  private showExportError(msg) {
    const notificationError: Notification  = {
      type: 'error',
      message: msg,
      fixed: false,
      popup: true
    };
    this.notificationsService.add(notificationError);
  }
}
