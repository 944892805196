import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { BdrButtonComponent } from "../bdr-button/bdr-button.component";
import { slideInOutAnimation } from '../../utils/slide-in-out.animation';
import { FilterDB } from '../../services/filter/filter.service';
import { DownloadsService } from "../../services/downloads/downloads.service";
import { namespaceNicenames } from '../../../environments/common/origins';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: "bdr-button-selectbox-selector",
  templateUrl: "./bdr-button-selectbox-selector.component.html",
  styleUrls: ["./bdr-button-selectbox-selector.component.scss"],
  animations: [slideInOutAnimation]
})
export class BdrButtonSelectboxSelectorComponent implements OnInit {
  @Input() config;
  @Input() changedColumns: boolean;
  @Input() appliedFilters: FilterDB[];
  @Input() resultsCount: any;
  @Input() infoMasterConfiguration: any
  @Input() selectedRows: any[] =[];
  @Input() hasSelectedRows: boolean;
  @Input() selectedRowsNumber: number;
  @Input() headerActions: any;
  @Input() scope: string;
  @Input() editMode: boolean;
  @Input() saving: boolean;
  @Input() saveDisabled: boolean;
  @Input() saveDisabledReason: string;
  @Input() origin?: string;
  @Input() originKey: string;
  @Input() showCreateDeleteBanner: { showNewRows: boolean; showDeletesOrCopy: boolean }
  @Input() recurrentEditionCheckbox: boolean;

  @Output() onResetColumns: EventEmitter<any> = new EventEmitter<any>();
  @Output() onOpenColumnSelector: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSaveChanges: EventEmitter<void> = new EventEmitter<void>();
  @Output() onCancelSave: EventEmitter<void> = new EventEmitter<void>();
  @Output() resetFilterRequest: EventEmitter<FilterDB> = new EventEmitter<FilterDB>();
  @Output() onChangeTab: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCreateRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteRows: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCopyRows: EventEmitter<any> = new EventEmitter<any>();
  selectedGroup: any;
  selectedKey: string;
  selectedKeyObj = {};
  model: string;
  pillsOptions: any[];

  constructor(public downloadsService: DownloadsService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.selectedGroup = this.getDefaultGroup();
    this.pillsOptions = this.config.map(item => {
      return {
        name: item.title,
        value: item.key,
        disabled: !(item.selectData && item.selectData.length > 0)
      };
    });

    for (let configItem of this.config) {
      this.selectedKeyObj[configItem.key] = null;
    }

    if (this.selectedGroup.key) {
      this.model = this.selectedGroup.key;
      this.selectedKeyObj[this.model] = this.selectedKey;
      this.changeOption(null);
    }

    this.activatedRoute.params.subscribe(params => {
      if (params.sheet) {
        this.changeSelectedGroup(params.sheet);
        if (params.view) {
          this.changeOption(params.view);
        }
      }
    });

  }

  getDefaultGroup(): any {
    for (let i = 0, len = this.config.length; i < len; i++) {
      if (this.config[i].default) {
        return this.config[i];
      }
    }
    return { key: undefined, value: undefined, selectData: [] };
  }

  notificationTextByOrigin() {
    return "Está modificando datos que afectan a " + namespaceNicenames[this.origin];
  }

  changeSelectedGroup(evt) {
    this.selectedGroup = this.config.find(item => item.key === evt);
    this.selectedGroup.changeEvent({
      key: this.selectedGroup.key,
      value: this.selectedKeyObj[this.selectedGroup.key],
    });
    //this.selectedKey = this.selectedGroup.selectData[0].value;
    this.onChangeTab.emit(this.selectedGroup);
  }

  changeOption(changedValue) {
    this.selectedKey = changedValue;
    this.selectedKeyObj[this.selectedGroup.key] = changedValue;
    this.selectedGroup.changeEvent({
      key: this.selectedGroup.key,
      value: changedValue
    });

  }

  showColumnSelector() {
    if (this.selectedKeyObj[this.selectedGroup.key]) {
      this.onOpenColumnSelector.next();
    }
  }

  resetColumns() {
    this.onResetColumns.next();
  }

  bdr

  getItemName(item: FilterDB): string {
    const filterDefaultName = "Filtro aplicado";
    const ruleDefaultName = "Regla aplicada";
    const itemDefaultName = "Elemento aplicado";

    const currentDefaultName = this.isFilter(item)
      ? filterDefaultName
      : this.isRule(item) ? ruleDefaultName : itemDefaultName;

    return item.name !== "" ? item.name : currentDefaultName;
  }

  private isFilter(item: FilterDB): boolean {
    const filterTypes = [
      "filter",
      "manual-validations-filter",
      "validations-filter",
      "issueFilter"
    ];
    return filterTypes.indexOf(item.meta.type) !== -1;
  }

  private isRule(item: FilterDB): boolean {
    const ruleTypes = ["rule", "workflowRule", "assignationRule"];
    return ruleTypes.indexOf(item.meta.type) !== -1;
  }

  resetFilter(filter: FilterDB): void {
    this.resetFilterRequest.emit(filter);
  }

  saveChanges($event) {
    this.onSaveChanges.emit($event);
  }
  cancelActions() {
    this.onCancelSave.emit();
  }
  createRow(createRowObject) {
    this.onCreateRow.emit(createRowObject);
  }
  deleteRows(deleteRowObject) {
    this.onDeleteRows.emit(deleteRowObject);
  }
  copyRows(copyRowObject) {
    this.onCopyRows.emit(copyRowObject);
  }
}