import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams, IAfterGuiAttachedParams } from 'ag-grid-community';
import { MasterDataService } from '../../../../master-data/services/master-data.service';
type Order = 'asc' | 'desc';

@Component({
  selector: 'custom-header',
  template: `
    <div class="custom-header" (click)="onSortChanged($event)">
      {{ params.displayName }}
      <bdr-icon
        *ngIf="params.isEditable"
        class="custom-header--edit"
        icon="edit"
        esir="true"
      ></bdr-icon>
      <ng-container *ngIf="hasSort">
        <bdr-icon *ngIf="isOrderAsc" class="custom-header--asc" icon="ico-select_up"></bdr-icon>
        <bdr-icon *ngIf="!isOrderAsc" class="custom-header--desc" icon="ico-select_down"></bdr-icon>
      </ng-container>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
      }

      .custom-header {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
      }
      bdr-icon {
        margin-left: 5px;
      }

      bdr-icon.custom-header--edit {
        color: #ef8200;
      }

      bdr-icon.custom-header--asc,
      bdr-icon.custom-header--desc {
        margin-left: 15px;
      }
    `
  ]
})
export class CustomHeaderComponent implements IHeaderAngularComp {
  refresh(params: IHeaderParams): boolean {
    throw new Error('Method not implemented.');
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    throw new Error('Method not implemented.');
  }
  params: any;
  isOrderAsc: boolean;
  hasSort: boolean = true;
  private hasMultiSort: boolean = true;

  agInit(params) {
    this.params = params;
    if (this.params.column.isSortAscending()) {
      this.isOrderAsc = true;
    } else if (this.params.column.isSortDescending()) {
      this.isOrderAsc = false;
    } else {
      this.hasSort = false;
    }
  }

  onSortChanged(event) {
    this.hasSort = true;
    this.isOrderAsc = !this.isOrderAsc;
    const order: Order = this.isOrderAsc ? 'asc' : 'desc';
    this.params.setSort(order, this.hasMultiSort);
  }

}
