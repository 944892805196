import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class WorkClosureService {
  private workCodesAggregates: string[] = [];

  setWorkCodes(codes: string[]) {
    this.workCodesAggregates = codes;
  }

  getWorkCodes(): string[] {
    return this.workCodesAggregates;
  }
}
