import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { MatTable } from '@angular/material/table';
import { ManualStartupAct } from '../../../../shared/interfaces/manual-startup-act.interface';

@Component({
  selector: 'bdr-table-installations-startup-act',
  templateUrl: './bdr-table-installations-startup-act.component.html',
  styleUrls: ['./bdr-table-installations-startup-act.component.scss'],
})
export class TableInstallationsStartupActComponent implements OnChanges {
  @ViewChild(MatTable, { read: ElementRef, static: false }) private matTableRef: ElementRef;
  @Input() columns: any[] = [];
  @Input() startupActs: any[] = [];
  @Input() options: any[] = [];
  @Input() workCode;
  @Input() canEdit: boolean;

  headerColumns;
  displayedColumns;

  manualStartupAct: ManualStartupAct = {};

  @Output() onChangeStartupAct = new EventEmitter<any>();

  ngOnInit() {
    this.manualStartupAct = {};
  }

  ngOnChanges() {
    if (this.columns.length > 0) {
      this.setHeaders(this.columns);
    }
  }

  setHeaders(headers) {
    // Headers
    this.headerColumns = headers;

    this.displayedColumns = this.headerColumns
      .filter((header) => header.visible)
      .map((header) => header.name);
  }

  onChangeEditable(value, field: string) {
    this.manualStartupAct[field] = value;
    this.onChangeStartupAct.emit({
      anio_informacion: this.startupActs[0] ? this.startupActs[0].anio_informacion : null,
      expediente: this.workCode,
      newValues: this.manualStartupAct
    });
  }
}
