import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bdr-collapse-panel',
  templateUrl: './collapse-panel.component.html',
  styleUrls: ['./collapse-panel.component.scss'],
})
export class CollapsePanelComponent implements OnInit {
  isExpanded: boolean;

  constructor() { }

  ngOnInit() { }

  onClick(event: Event) {
    event.stopPropagation();
    this.isExpanded = !this.isExpanded;
  }
}
