import {
  Component,
  Injectable,
  Inject,
  OnInit
} from '@angular/core';
import { DataApiService } from '../../../services/data-api/data-api.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DownloadsService } from '../../../services/downloads/downloads.service';
import { Subject } from 'rxjs';
import { AsyncQueryResponse } from '../../../models/query-poller';

import * as _ from 'lodash';


interface DialogData {
  table: string;
  selectedRows: any[];
  editableFields: any[];
}

@Component({
  selector: 'dialog-revert-reclassifications',
  templateUrl: './dialog-revert-reclassifications.component.html',
  styleUrls: ['./dialog-revert-reclassifications.component.scss']
})
@Injectable()
export class DialogRevertReclassificationsComponent implements OnInit {
  selectedRows = [];
  selectedRowsVersions = [];

  loading: boolean = false;
  saving: boolean = false;

  rowsNumber = 0;
  currentPage = 0;
  pages = [];

  retributiveYear: string;

  constructor(
    private dataApi: DataApiService,
    private downloadsService: DownloadsService,
    public dialogRef: MatDialogRef<DialogRevertReclassificationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  }

  ngOnInit() {
    this.rowsNumber = this.data.selectedRows.length;
    this.pages = Array.from(Array(Math.ceil(this.rowsNumber / 5)).keys());
    this.selectedRows = this.data.selectedRows.map(row => Object.assign({}, row));

    this.loading = true;
    this.dataApi.getReclassificationManualRegisters(this.selectedRows.map(row => row.id))
      .subscribe((response) => {

        this.selectedRowsVersions = response.map(row => {
          let currentVersion = row.versions[0];
          currentVersion.currentValue = true;

          let olderVersion = row.versions[row.versions.length - 1];
          let olderVersionDisable = olderVersion.despachos_digitalizacion && !olderVersion.editado_manualmente;
          let olderVersionDisableReason = olderVersionDisable ? 'Reclasificación automática (no se puede revertir)' : '';
          row.versions.push({
            id: row.id,
            disable: olderVersionDisable,
            disableReason: olderVersionDisableReason,
            original: true
          });
          return row;
        });
        this.loading = false;
      }
      );
  }

  sort(id) {
    let rowVersions = this.selectedRowsVersions[id];
    rowVersions.descendingSort = !rowVersions.descendingSort;
    rowVersions.versions.reverse();
  }

  changeSelectedVersions(id, version) {
    this.selectedRowsVersions
      .find(row => row.id === id).versions
      .filter(v => v.fecha != version.fecha)
      .forEach(v => v.selected = false);
  }

  paginatedRows() {
    let paginatedRows = this.selectedRowsVersions.slice(5 * this.currentPage, Math.min(5 * (this.currentPage + 1), this.rowsNumber));
    return this.rowsToDraw(paginatedRows);
  }


  setPage(page) {
    this.currentPage = page;
  }

  rows() {
    return this.rowsToDraw(this.selectedRowsVersions);
  }

  rowsToDraw(rowsToDraw: any[]) {
    let rows = [];
    rowsToDraw.forEach(selectedRow => {
      rows.push(selectedRow);
    });
    return rows;
  }

  save() {
    let payload = {
      sheet: this.data.table.replace('_vista', ''),
      appointments: this.appointmentsToRevert()
    };
    this.dataApi.revertReclassifications(payload).subscribe(response => {
      let asyncId = response.async_job_id;
      this.downloadsService.addDownload({
        id: asyncId,
        fileName: "Revirtiendo reclasificaciones",
        url: '#',
        status: 'RUNNING'
      });
      let jobSubject = new Subject<AsyncQueryResponse>();
      let poller = this.dataApi.jobQueryPoller(asyncId);
      poller.getObservable().subscribe((pollerResponse: AsyncQueryResponse) => {
        poller.complete();
        this.downloadsService.assignData(pollerResponse, asyncId);
        jobSubject.next(pollerResponse);
        this.downloadsService.downloadReady = true;
        return;
      });
      this.dialogRef.close();
    });
  }

  private appointmentsToRevert() {
    return [].concat(...this.selectedRowsVersions.map(row => {
      return row.versions.filter(version => version.selected).map(version => (
        {
          id: row.id,
          fecha: version.fecha
        }
      ));
    }));
  }
}
