import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ViewChild, ElementRef, Renderer2, AfterViewChecked, OnChanges, ChangeDetectorRef, Directive } from "@angular/core";

import { orderBy } from "lodash";
import { safeDetectChanges } from '../../util';

@Directive()
export class GridHandler {
  @Input() gridProperties;
  @Input() manualInstallationsEdition;
  @Output() onGridEditChange: EventEmitter<any> = new EventEmitter();
  @Output() selectedRowsChange: EventEmitter<any> = new EventEmitter();
  @Output() onDblClick: EventEmitter<any> = new EventEmitter();
  @Output() onLogDialogRequest: EventEmitter<any> = new EventEmitter();

  gridEditChange($event) {
    this.onGridEditChange.emit($event);
  }

  dblClick($event) {
    this.onDblClick.emit($event);
  }

  changeSelectedRows(evt) {
    this.selectedRowsChange.emit(evt);
  }

  logDialogRequest(evt) {
    this.onLogDialogRequest.emit(evt);
  }
}

@Component({
  selector: "simple-grid-handler",
  templateUrl: "./simple-grid-handler.component.html",
  styleUrls: ["./simple-grid-handler.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleGridHandlerComponent extends GridHandler
  implements AfterViewChecked, OnChanges {
  @ViewChild("container", { static: false }) container: ElementRef;

  rendered = false;
  constructor(private renderer: Renderer2, private cdr: ChangeDetectorRef) {
    super();
  }
  ngOnChanges(changes) {
    if (!!changes && changes.gridProperties) {
      this.cdr.detectChanges()
    }
  }
  ngAfterViewChecked() {
    if (!this.rendered) {
      this.rendered = true;
      setTimeout(() => {
        this.renderer.setStyle(
          this.container.nativeElement,
          "height",
          `${this.container.nativeElement.offsetHeight}px`
        );
      }, 500);
    }
  }
}
