import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DownloadsService } from '../../../../services/downloads/downloads.service';
import { GridExportData } from '../../../../services/grid/grid.service';
import { FileUploadService } from '../../../../services/file-upload/file-upload.service';
import { TasksService } from '../../../../services/tasks/tasks.service';
import { DataApiService } from "../../../../services/data-api/data-api.service";
import { NotificationsService } from '../../../../services/notifications/notifications.service';

@Component({
  selector: 'installation-editions-dialog',
  templateUrl: './installation-editions-dialog.component.html',
  styleUrls: ['./installation-editions-dialog.component.scss']
})
export class InstallationEditionsDialogComponent implements OnInit {
  @Output() onAssociateInstallation: EventEmitter<any> = new EventEmitter<any>();

  installationsFile: File;
  editionsFile: File;

  error: boolean = false;
  errorMsg: string;

  blockButtonText: string = "Bloquear ediciones";
  nonUploaded: boolean = true;
  isBlockedWorkClosure: boolean = false

  constructor(
    public dialogRef: MatDialogRef<InstallationEditionsDialogComponent>,
    private downloadsService: DownloadsService,
    private fileUploadService: FileUploadService,
    private tasksService: TasksService,
    private dataApiService: DataApiService,
    private notificationsService: NotificationsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() { }

  close(): void {
    this.dialogRef.close();
  }

  save() {
    this.tasksService.reloadSheet('replace-manual-installations');
    this.dialogRef.close();
  }

  downloadEditions() {
    this.download('sabana_inversiones_cierre_obra_instalaciones_manual', 'Cierre de Obra - Ediciones de instalaciones');
  }
  
  downloadInstallations() {
    this.download('sabana_inversiones_cierre_obra_asociar_desasociar_instalaciones_manual_vista', 'Cierre de Obra - Asociar/desasociar instalaciones');
  }

  installationsFileUpdate(event) {  
    this.installationsFile = this.file(event);
  }

  editionsFileUpdate(event) {  
    this.editionsFile = this.file(event);
  }

  private file(event) { 
    let file = event.target.files[0];
    if(!file.name.endsWith('.csv.gz')) {
      this.error = true;
      this.errorMsg= 'El formato debe ser .csv.gz';
      return null;
    }
    this.error = false;
    this.errorMsg= '';
    return file;
  }

  blockWorkClosure() {
    this.dataApiService.blockWorkClosure().subscribe(res => {
      this.isBlockedWorkClosure = true;
      this.notificationsService.add({
        type: 'confirmation',
        message: 'Se ha bloqueado la edición de la Pantalla de Cierre de Obra',
        fixed: false,
        popup: true
      });
    });
  }

  uploadEditions() {
    this.upload('sabana_inversiones_cierre_obra_instalaciones_manual', this.editionsFile);
  }

  uploadInstallations() {
    this.upload('sabana_inversiones_cierre_obra_asociar_desasociar_instalaciones_manual', this.installationsFile);

  }
  
  private upload(origin, file) {
    this.fileUploadService.startUpload(
      origin,
      this.data.retributiveYear,
      file
    );
    this.nonUploaded = this.error || !(this.installationsFile || this.editionsFile);   
  }

  private download(origin, title) {
    const exportItem: GridExportData = {
      origin: origin,
      filterOrigin: origin,
      filterable: true,
      filename: title,
      title: title
    };

    this.downloadsService.asyncDownload(
      exportItem,
      true,
      [],
      [],
      []
    );
  }

}
