import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core';

import { environment } from './../../../../environments/environment';
import { SelectOption } from './../../../models/common';
import { Schema } from './../../../services/schema/schema.service'
import {
  ConditionGroup,
  ConditionRow
} from '../query-builder-condition/query-builder-condition.service';

@Component({
  selector: 'bdr-query-builder-group',
  templateUrl: './query-builder-group.component.html',
  styleUrls: ['./query-builder-group.component.scss']
})
export class QueryBuilderGroupComponent implements OnInit {
  @ViewChild('groupItem', { static: false }) groupElement: ElementRef;

  @Input() query: ConditionGroup;
  @Input() schemaItems: { [origin: string]: { [filterOrigin: string]: Schema[] } };
  @Input() operators: any[];
  @Input() depth: number;
  @Input() index: number;
  @Input() isVisibleButtonTrash: boolean;
  @Input() gridSelector: boolean; // temporal, 1ª columna para elegir el grid...
  @Input() origin: string;

  @Output() validationError: EventEmitter<{ error: boolean; message: string }>;
  @Output() openMainListRequest: EventEmitter<void>;
  @Output() expandItemMainRequest: EventEmitter<any>;
  @Output() removeGroupRequest: EventEmitter<number>;
  @Output() dragStartMainRequest: EventEmitter<any>;
  @Output() dropMainRequest: EventEmitter<any>;
  @Output() dropRequest: EventEmitter<any>;

  collapsed: boolean;
  logicOperators: SelectOption[];
  origins: string[];
  constructor() {
    this.validationError = new EventEmitter<{
      error: boolean;
      message: string;
    }>();
    this.openMainListRequest = new EventEmitter<void>();
    this.expandItemMainRequest = new EventEmitter<any>();
    this.removeGroupRequest = new EventEmitter<number>();
    this.dragStartMainRequest = new EventEmitter<any>();
    this.dropMainRequest = new EventEmitter<any>();
    this.dropRequest = new EventEmitter<any>();

    this.logicOperators = [
      { name: 'AND', value: 'AND' },
      { name: 'OR', value: 'OR' }
    ];
    this.gridSelector = false;
  }

  ngOnInit() {
    this.isVisibleButtonTrash = true;
  }

  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }

  dragStart($event: any): void {
    this.dragStartMainRequest.emit($event);
    this.isVisibleButtonTrash = false;
  }

  dragStartMain($event: any): void {
    this.dragStartMainRequest.emit($event);
    this.isVisibleButtonTrash = false;
  }

  drop($event: any, query: any, prependGroup: boolean): void {
    this.dropMainRequest.emit({
      parent: query,
      element: $event,
      group: prependGroup
    });
    this.isVisibleButtonTrash = true;
  }

  dropMain($event): void {
    this.dropMainRequest.emit($event);
  }

  expandItemMain($event: any): void {
    this.expandItemMainRequest.emit($event);
  }

  expandItem(linkedItem: ConditionRow, index: number): void {
    this.expandItemMainRequest.emit({
      index,
      element: linkedItem,
      parent: this.query
    });
  }

  openMainList(): void {
    this.openMainListRequest.emit();
  }

  removeGroup(queryRowIndex: number): void {
    this.removeGroupRequest.emit(queryRowIndex);
  }

  removeCondition(queryRowIndex: number): void {
    this.query.rules.splice(queryRowIndex, 1);
  }

  removeGroupItem(index: number) {
    this.query.rules.splice(index, 1);
  }

  addCondition(query: ConditionGroup) {
    query.rules.push(this.getClearCondition());
  }

  getClearCondition() {
    return {
      id: '',
      origin: this.origin || '',
      field: '',
      type: '',
      operator: '',
      value: ''
    }
  }

  addGroup(query: ConditionGroup) {
    query.rules.push({
      condition: 'AND',
      rules: [this.getClearCondition(), this.getClearCondition()]
    });
  }

  setVisibleButtonTrash(visible: boolean) {
    this.isVisibleButtonTrash = visible;
  }

  handleValidationError(error: { error: boolean; message: string }): void {
    this.validationError.emit(error);
  }
}
