import { Subject } from "rxjs";
import { startWith } from 'rxjs/operators';

export class DownloadsList {
  private downloads: Download[] = [];
  private subject = new Subject();
  constructor(currentDownloads: Download[] = []) {
    this.downloads = currentDownloads;
    // this.downloadChanges();
  }

  isPresent(fileName: string) { 
    return this.downloads.some(d=>d.fileName === fileName)
  }

  addDownload(download: Download) {

    //NOTE: Can be deleted once all data downloads report successMessage field
    if (download.successMessage === undefined) {
      download.successMessage = download.fileName
    }
    
    this.downloads.push(download);
    this.downloadChanges();
  }
  downloadChanges() {
    this.notifySubject();
    this.saveDownloads();
  }
  notifySubject() {
    this.subject.next(this.downloads);
  }
  cancelAll() {
    this.downloads = [];
    this.downloadChanges();
  }
  getSubject() {
    return this.subject.asObservable().pipe(startWith(this.downloads));
  }
  saveDownloads() {
    localStorage.setItem("BDR_DOWNLOADS_LIST", JSON.stringify(this.downloads));
  }
  cancelOne(index) {
    this.downloads.splice(index, 1);
    this.downloadChanges();
  }
  assignData(data, downloadId) {
    this.downloads.forEach(element => {
      if (element.id === downloadId) {
        element.status = data.type;
        //element.fileName = element.fileName.replace('Ejecutando', 'Se ha ejecutado')
        if (data.type === "SUCCESS" && data.data.result) {
          element.url = data.data.result.url;
        }
      }
    });
  }
  isEmpty(): boolean {
    return this.downloads.length === 0;
  }
}

export interface Download {
  id: string;
  fileName: string;
  successMessage?: String;
  url: string;
  status: string; // asignar valores
}
