import { Injectable } from '@angular/core';

@Injectable()
export class DialogI18nService {
  constructor() { }

  public getType(key: string): string {
    let text = 'filtro'
    return this.checkKey(key, text)
  }

  public getNewItemTitle(key: string): string {
    let text = 'Nuevo filtro'
    return this.checkKey(key, text);
  }

  public getEditItemTitle(key: string): string {
    let text = 'Editar filtro'
    return this.checkKey(key, text);
  }

  public getItemNameTitle(key: string): string {
    let text = 'Nombre del filtro'
    return this.checkKey(key, text)
  }

  private checkKey(key: string, text: string) {
    const type = {
      filter: text
    };
    return !!type[key] ? type[key] : '';
  }


  public getAvailableItemList(key: string): string {
    let text = 'Tus filtros disponibles'
    return this.checkKey(key, text)
  }
}
