import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { CustomHeaderComponent } from '../shared/components/aggrid-table/custom-header/custom-header.component';
import { MaterialModule } from '../shared/modules/material.module';
import { SharedModule } from '../shared/shared.module';
// Modules
import { ComponentsModule } from './../components/components.module';
import { ContainersModule } from './../containers/containers.module';
import { DialogModule } from './../containers/dialog/dialog.module';
// Services
import { FilterService } from './../services/filter/filter.service';
import { ContributionsSheetComponent } from './contributions-sheet/contributions-sheet.component';
import { ElaDetailComponent } from './ela-detail/ela-detail.component';
import { FormsComponent } from './forms/forms.component';
// Components - Pages
import { HomePageComponent } from './home-page/home-page.component';
import { InstallationsSheetComponent } from './installations-sheet/installations-sheet.component';
import { InventorySheetComponent } from './inventory-sheet/inventory-sheet.component';
import { InvestmentsPlanComponent } from './investments-plan/investments-plan.component';
import { InvestmentsAdjustmentSheetComponent } from './investments-adjustment-sheet/investments-adjustment-sheet.component';
import { InvestmentsSheetComponent } from './investments-sheet/investments-sheet.component';
import { LegalizationComponent } from './legalization/legalization.component';
import { LoadsReportComponent } from './loads-report/loads-report.component';
import { LoginComponent } from './login/login.component';




const components = [
  LoginComponent,
  HomePageComponent,
  InvestmentsAdjustmentSheetComponent,
  InvestmentsSheetComponent,
  InventorySheetComponent,
  InstallationsSheetComponent,
  ContributionsSheetComponent,
  LoadsReportComponent,
  LegalizationComponent,
  FormsComponent,
  InvestmentsPlanComponent,
  ElaDetailComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule,
    ContainersModule,
    HttpClientModule,
    DialogModule,
    SharedModule,
    RouterModule,

    MaterialModule,
    AgGridModule.withComponents([CustomHeaderComponent]),
  ],
  exports: components,
  declarations: components,
  providers: [FilterService],
})
export class PagesModule { }
