import { Component, ChangeDetectionStrategy } from '@angular/core';

import { ContextualmenuContent } from '../contextual-menu-content';
import {
  BdrContextualMenuService,
  CtxActionItem
} from '../../../services/bdr-contextual-menu/bdr-contextual-menu.service';
import { ComponentBindings } from './../../../models/common';

@Component({
  selector: 'bdr-ctx-actions-menu',
  templateUrl: './ctx-actions-menu.component.html',
  styleUrls: ['./ctx-actions-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CtxActionsMenuComponent extends ContextualmenuContent {
  componentSelected: Component;
  componentBindings: ComponentBindings;

  constructor(private _ctxmenuService: BdrContextualMenuService) {
    super(_ctxmenuService);

    this.componentSelected = null;
    this.componentBindings = {
      inputs: {},
      outputs: {}
    };
  }

  selectComponent(option: CtxActionItem): void {
    if (
      option.hasOwnProperty('action') &&
      typeof option.action === 'function'
    ) {
      option.action();
    } else {
      this.componentSelected = option.component;
      if (option.hasOwnProperty('bindings') && !!option.bindings) {
        this.componentBindings = option.bindings;
      }
    }
  }
}
