import { Injectable, QueryList } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as clone from 'clone';

import { FilterService } from './../../services/filter/filter.service';
import { DialogWrapperComponent } from './dialog-wrapper/dialog-wrapper.component';
import { ComponentBindings } from './../../models/common';

export class Dialog {
  id: string;
  minimized: boolean;
  title: string;
  component: any;
  bindings: ComponentBindings;
  fixed?: boolean;
  notHeader?: boolean;
  headerComponent?: boolean;
}

export class DialogSideList {
  id: string;
  icon: string;
  type: string;
  origin?: string;
  list: FilterService;
}

export class DialogOption {
  id: string;
  name: string;
}

@Injectable({ providedIn: 'root' })
export class DialogService {
  private dialogs: Dialog[] = [];
  private dialogsSubject: Subject<Dialog[]> = new Subject<Dialog[]>();

  constructor() { }

  public getDialogsObservable(): Observable<Dialog[]> {
    return this.dialogsSubject.asObservable();
  }

  public createDialog(options: any): void {
    const canCreate = !this.dialogs.find((dialog) => {
      const optionInputs = options.bindings.inputs;
      const dialogInputs = dialog.bindings.inputs;
      return !!optionInputs.item && !!dialogInputs.item
        ? optionInputs.item._id === dialogInputs.item._id
        : optionInputs.item === dialogInputs.item && optionInputs.type === dialogInputs.type;
    });
    if (canCreate) {
      const newDialog: Dialog = Object.assign({}, options);
      newDialog.bindings.inputs.id = new Date().getTime() + '';
      newDialog.minimized = false;
      this.dialogs.push(newDialog);
      this.dialogsSubject.next(this.dialogs);
    }
  }

  updateDialog(id, dialog) {
    const currentDialogIndex = this.dialogs.findIndex((item) => item.bindings.inputs.id === id);
    if (currentDialogIndex !== undefined) {
      this.dialogs[currentDialogIndex] = clone(dialog);
      this.dialogsSubject.next(this.dialogs);
    }
  }

  public closeDialog(id: string): void {
    const index = this.dialogs.findIndex((item) => item.bindings.inputs.id === id);
    this.dialogs.splice(index, 1);
    this.dialogsSubject.next(this.dialogs);
  }

  public minimizeDialog(id: string): void {
    const dialog = this.dialogs.find((item) => item.bindings.inputs.id === id);
    if (!!dialog) {
      dialog.minimized = true;
    }
  }
  public maximizeDialog(id: string): void {
    const dialog = this.dialogs.find((item) => item.bindings.inputs.id === id);
    if (!!dialog) {
      dialog.minimized = false;
    }
  }

  public clearDialogs(): void {
    this.dialogs = [];
    this.dialogsSubject.next(this.dialogs);
  }

  public getDialogComponent(
    list: QueryList<DialogWrapperComponent>,
    id: string
  ): DialogWrapperComponent {
    return list.filter((item) => item.id === id)[0];
  }
}
