import { Injectable } from "@angular/core";

export class ConditionRow {
  id: string;
  origin: string;
  field: string;
  fieldError?: string;
  type: string;
  operator: string;
  operatorError?: string;
  value: string | number | Date | string[];
  reference?: string;
  valueError?: string;
}

export class ConditionGroup {
  condition: string;
  rules: Array<ConditionRow | ConditionGroup>;
}

@Injectable()
export class QueryBuilderConditionService {
  constructor() { }

  public filterArray(list: any[], option: string): any[] {
    return list.filter(item => item.type.indexOf(option) !== -1);
  }

  public getOptionId(list: any[], key: string): string {
    const optionMapped = list.find(item => {
      return item.caption === key;
    });
    return !!optionMapped ? optionMapped.name : "";
  }

  public getOptionCaption(list: any[], key: string): string {
    const optionMapped = list.find(item => {
      return item.name === key;
    });
    return !!optionMapped ? optionMapped.caption : "";
  }

  public getInputType(list: any[], key: string): string {
    const defaultType = "text";
    const inputTypes = {
      string: "text",
      number: "number",
      date: "date"
    };

    const optionMapped = list.find(item => item.name === key);

    return !optionMapped
      ? defaultType
      : inputTypes[optionMapped.type] || defaultType;
  }

  public getOptionType(list: any[], key: string): string {
    const defaultType = "string";
    const optionMapped = list.find(item => item.name === key);

    return !optionMapped ? defaultType : optionMapped.type || defaultType;
  }

  public validateOption(option: string): boolean {
    return option !== "";
  }

  public validateCondition(queryRule: ConditionRow): boolean {
    const isOperatorNullable = queryRule.operator.indexOf("empty") !== -1;
    const valueConditionValid = isOperatorNullable
      ? queryRule.value === null
      : queryRule.value !== "";

    return (
      this.validateOption(queryRule.field) &&
      this.validateOption(queryRule.id) &&
      this.validateOption(queryRule.operator) &&
      this.validateOption(queryRule.type) &&
      valueConditionValid
    );
  }

  public formatDate(value: string): string {
    const splittedDateValue = value.split("T");
    return splittedDateValue.length === 1
      ? splittedDateValue[0] + "T00:00:00"
      : value;
  }
}
