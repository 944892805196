import {
  Component,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { Observable, Subscription, Subject } from 'rxjs';

import { DialogService, Dialog } from './../dialog.service';
import { DialogWrapperComponent } from './../dialog-wrapper/dialog-wrapper.component';
import { WindowService } from '../../../services/window/window.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'bdr-dialog-list',
  templateUrl: './dialog-list.component.html',
  styleUrls: ['./dialog-list.component.scss']
})
export class DialogListComponent implements AfterViewInit, OnDestroy {
  @ViewChildren(DialogWrapperComponent)
  @ViewChild('rulerCanvas', { static: true })
  rulerCanvasElement: ElementRef;

  dialogComponents: QueryList<DialogWrapperComponent>;
  focusedDialog: string;
  dialogs: Dialog[] = [];
  showOverlay: boolean;
  window: Window;
  rulerCtx: CanvasRenderingContext2D;
  private destroy$: Subject<any> = new Subject()
  constructor(
    private dialogService: DialogService,
    private windowService: WindowService,
    private cdr: ChangeDetectorRef
  ) {
    this.window = this.windowService.window;
  }

  ngAfterViewInit() {
    this.subscribeItems();

    const rulerElement: HTMLCanvasElement = this.rulerCanvasElement
      .nativeElement;
    this.rulerCtx = rulerElement ? rulerElement.getContext('2d') : null;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  handleFocusDialog(id: string): void {
    this.focusedDialog = id;
  }

  handleMinimizeDialog(): void {
    this.reorderMinimizedDialogs();
  }

  handleRestoreDialog(id: string): void {
    const dialog: Dialog = this.dialogs.find(
      item => item.bindings.inputs.id === id
    );
    if (!!dialog) {
      dialog.minimized = false;
    }
    this.reorderMinimizedDialogs();
  }

  handleCloseDialog(id: string): void {
    this.dialogService.closeDialog(id);
    this.reorderMinimizedDialogs();
  }

  private reorderMinimizedDialogs(): void {
    this.dialogs.filter(item => item.minimized).forEach((item, index) => {
      const dialog = this.dialogService.getDialogComponent(
        this.dialogComponents,
        item.bindings.inputs.id
      );
      dialog.moveDialog(
        `calc(100% - 3.5rem)`,
        `calc(100% - ${(index + 1) * 31}rem)`,
        'auto',
        'auto'
      );
    });
  }

  private subscribeItems(): void {
    this.dialogService
      .getDialogsObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(dialogs => {
        this.dialogs = this.processDialogs(dialogs);
        this.checkOverlay();
        this.cdr.detectChanges()
      });
  }

  private checkOverlay(): void {
    this.showOverlay = false;
    this.dialogs.forEach(dialog => {
      if (dialog.fixed) {
        this.showOverlay = true;
      }
    });
  }

  private processDialogs(dialogs) {
    dialogs.forEach(dialog => {
      dialog.bindings.inputs['rulerCtx'] = this.rulerCtx;
      dialog.bindings.inputs['dialogSize'] = dialog.size;
    });
    return dialogs;
  }
}
