import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import {
  NotificationsService,
  Notification,
} from '../../services/notifications/notifications.service';
import { takeUntil } from 'rxjs/operators';
import { DataApiService } from '../../services/data-api/data-api.service';
import { MastersService } from '../../services/masters/masters.service';

@Component({
  selector: 'bdr-notifications-wrapper',
  templateUrl: './notifications-wrapper.component.html',
  styleUrls: ['./notifications-wrapper.component.scss'],
})
export class NotificationsWrapperComponent implements OnInit, OnDestroy {
  notifications: Notification[];
  unreadNotifications: Notification[];
  showNotifications = false;
  private destroy$: Subject<any> = new Subject();

  constructor(
    private notificationsService: NotificationsService,
    private mastersService: MastersService
  ) {
    this.notifications = [];
    this.unreadNotifications = [];
  }

  ngOnInit() {
    this.getNotifications();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  toggleShowNotifications() {
    this.showNotifications = !this.showNotifications;
  }

  markAsRead(notification: Notification): void {
    this.notificationsService.markAsRead(notification);
  }

  deleteAll(): void {
    this.notificationsService.deleteAllNotifications();
  }

  markAllAsRead(): void {
    this.notificationsService.markAllAsRead();
  }

  private getNotifications(): void {
    this.notificationsService
      .getNotificationObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((items: Notification[]) => {
        this.notifications = items;
        this.unreadNotifications = items.filter((item: Notification) => !item.read);
      });
    this.notificationsService.refresh();
  }

  public action(notification) {
    this.notificationsService.action(notification);
    this.notificationsService.close(notification);
  }

  public showDetail(notification) {
    this.toggleShowNotifications();
    this.notificationsService.showDetail(notification);
  }
}
