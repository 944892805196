import { NgModule } from '@angular/core';

// Modules
import { CommonModule } from '@angular/common';
import { ComponentsModule } from './../../components/components.module';

// Components
import { QueryBuilderComponent } from './query-builder.component';
import { QueryBuilderConditionComponent } from './query-builder-condition/query-builder-condition.component';
import { QueryBuilderGroupComponent } from './query-builder-group/query-builder-group.component';

// Directives
import { QueryBuilderDragDirective } from './query-builder-drag.directive';
import { QueryBuilderDropDirective } from './query-builder-drop.directive';

@NgModule({
  imports: [CommonModule, ComponentsModule],
  exports: [QueryBuilderComponent],
  declarations: [
    QueryBuilderComponent,
    QueryBuilderConditionComponent,
    QueryBuilderDragDirective,
    QueryBuilderDropDirective,
    QueryBuilderGroupComponent
  ],
  providers: []
})
export class QueryBuilderModule { }
