import { Injectable } from '@angular/core';
import { Observable, Subject, interval, of, from } from 'rxjs';
import { startWith } from 'rxjs/operators';
import * as AWS from 'aws-sdk/global';
import * as jwtDecode from 'jwt-decode';
import * as auth0 from 'auth0-js';
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import sigV4Client from '../../utils/sigV4Client';
import { environment } from './../../../environments/environment';

import { CognitoUtil, CognitoCallback } from './cognito-util';
import { CognitoAuth, CognitoAuthOptions } from 'amazon-cognito-auth-js';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
  private profile: UserProfile;
  private profileChange: Subject<UserProfile> = new Subject<UserProfile>();
  private authChange: Subject<any> = new Subject<any>();
  authenticated = false;
  private credentials: AWS.CognitoIdentityCredentials;
  private cognitoUser: CognitoUser;
  private TOKEN_NAME = 'id_token';
  private PROFILE_NAME = 'profile';
  public recoveryCode = '';
  private webAuth: any;
  private sv4client: any;
  private gettingCredentials = false;

  private authData: any;
  private auth: any;

  constructor(
    private cognitoUtil: CognitoUtil,
    private router: Router,
  ) {
    AWS.config.region = CognitoUtil._REGION;
    this.auth = cognitoUtil.buildCognitoAuth();

    this.auth.userhandler = {
      onSuccess: (session: any): void => {
        const idToken = session.getIdToken().getJwtToken();
        this.authenticate_and_load_profile(idToken);
      },
      onFailure: (error: any): void => {
        console.log('Error: ' + error);
        this.auth.getSession();
      },
    };

    this.auth.useCodeGrantFlow();

    if (window.location.href.includes('?code=')) {
      this.auth.parseCognitoWebResponse(window.location.href);
      this.router.navigate(['']); //navegamos para quitar el ?code=
    }
  }

  async isAuthenticated(): Promise<boolean> {
    let pass = false,
      token;
    try {
      token = this.retrieveToken();
      pass = !!token;
      if (!!pass) {
        this.getCredentials(token);
      }
    } catch (e) {
      pass = false;
      if (this.authenticated) {
        this.logout();
      }
    }

    if (!pass) {
      return Promise.resolve(false);
    }

    this.setAuthenticated(pass);
    return Promise.resolve(this.authenticated);
  }

  /**
   * Removes token and profile info from localStorage, so authenticated()
   * returns false.
   */
  logout(): void {
    const currentUser = this.cognitoUtil.getCurrentUser();
    if (currentUser) {
      currentUser.signOut();
    }
    localStorage.removeItem(this.TOKEN_NAME);
    localStorage.removeItem(this.PROFILE_NAME);
    this.profile = null;
    this.profileChange.next(this.profile);
    this.auth.signOut();
    this.setAuthenticated(false);
  }

  private setAuthenticated(authValue) {
    this.authenticated = authValue;
    this.authChange.next(this.authenticated);
  }

  getProfile(): Observable<UserProfile> {
    return this.profileChange.asObservable().pipe(startWith(this.profile));
  }

  initProfile(): void {
    if (this.authenticated) {
      const lsProfile = localStorage.getItem(this.PROFILE_NAME);
      if (!this.profile && lsProfile) {
        this.profile = JSON.parse(lsProfile);
        this.profile.picture = '/assets/images/no_photo.png';
      }
    }
  }

  getAuthenticated() {
    return this.authChange.asObservable().pipe(startWith(this.authenticated));
  }

  async login(username: string, password: string, errorCallback: Function) {
    this.cognitoUser['username'] = username;
    const authenticationData = {
      Username: username,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const self = this;
    this.cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        self.handleLogin(result.getIdToken().getJwtToken());
        return result;
      },
      onFailure: (err) => {
        errorCallback(err);
      },
    });
  }

  /**
   * Handles login side effects.
   *
   * Stores the token into localStorage and gets the user profile.
   */
  private handleLogin(token: string, tokenInfo?: any): void {
    const tokenInfoData = tokenInfo ? tokenInfo : jwtDecode(token);
    this.storeToken(token);
    this.profile = {
      email: tokenInfoData.email,
      name: tokenInfoData.name,
      surname: tokenInfoData.family_name,
      picture: tokenInfoData.picture,
    };
    this.profile.picture = '/assets/images/no_photo.png';
    this.storeProfile(this.profile);
    this.profileChange.next(this.profile);
    this.isAuthenticated();
  }

  confirmNewPassword(email: string, verificationCode: string, password: string, callback) {
    this.cognitoUser['username'] = email;
    this.cognitoUser.confirmPassword(verificationCode, password, {
      onSuccess: function () {
        callback(null, null);
      },
      onFailure: function (err) {
        callback(err, null);
      },
    });
  }

  recoverPassword(userName: string, callback) {
    this.cognitoUser['username'] = userName;
    this.cognitoUser.forgotPassword({
      onSuccess: function () {
        callback(null, null);
      },
      onFailure: function (err) {
        callback(err, null);
      },
    });
  }

  firstLoginConfirm(userAttributes: any, password, errorCallback: Function) {
    // set default picture
    userAttributes.picture = '/assets/images/no_photo.png';
    // the api doesn't accept this field back
    delete userAttributes.email_verified;
    this.cognitoUser.completeNewPasswordChallenge(password, userAttributes, {
      onSuccess: (result) => {
        this.handleLogin(result.getIdToken().getJwtToken(), userAttributes);
      },
      onFailure: (err) => {
        errorCallback(err);
      },
    });
  }

  private storeToken(token: string): void {
    localStorage.setItem(this.TOKEN_NAME, token);
  }

  retrieveToken() {
    return localStorage.getItem(this.TOKEN_NAME);
  }

  /**
   * Stores the user's profile in localStorage.
   */
  private storeProfile(profile: any): void {
    const serializedProfile = JSON.stringify(profile);
    localStorage.setItem(this.PROFILE_NAME, serializedProfile);
  }

  /*  Cognito IDP Login */

  public loginIDP() {
    this.auth.getSession();
  }

  /* Cognito IDP auth */
  async authenticateIDP(token) {
    this.storeToken(token);
    await this.getCredentials(token);
  }

  async getCredentials(token) {
    if (!!this.gettingCredentials) {
      return;
    }
    this.gettingCredentials = true;
    this.credentials = this.cognitoUtil.buildCognitoCreds(token);

    let promise = new Promise((resolve, reject) => {
      this.credentials.get(async (err) => {
        this.setAuthenticated(true);
        this.gettingCredentials = false;
        if (!err) {
          await this.createSigV4Client();
          return resolve(this.credentials);
        }
        this.logout();
        return reject(err);
      });
    });
    return promise;
  }

  async createSigV4Client(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.sv4client) {
        if (!!this.gettingCredentials) {
          let timerCheck = setInterval(() => {
            if (!this.gettingCredentials && !!this.credentials.data) {
              this.setSigV4Client();
              clearInterval(timerCheck);
              return resolve(true);
            }
          }, 1000);
        } else {
          if (!this.gettingCredentials) {
            this.setSigV4Client();
            return resolve(true);
          }
        }
      }
    });
  }

  setSigV4Client() {
    let clientData = {
      // Your AWS temporary access key
      accessKey: this.credentials.data.Credentials.AccessKeyId,
      // Your AWS temporary secret key
      secretKey: (this.credentials.data.Credentials as any).SecretKey,
      // Your AWS temporary session token
      sessionToken: this.credentials.data.Credentials.SessionToken,
      // API Gateway region
      region: CognitoUtil._REGION,
      // API Gateway URL
      endpoint: environment.signatureHost,
    };
    this.sv4client = sigV4Client.newClient(clientData);
  }
  /**
   * Parses the url hash, if present, to extract the token and log in
   * the user.
   *
   * This will happen with SSO login (it redirects).
   */
  private parseUrlHash(): void {
    let idToken = new URL(window.location.href.replace(/#/g, '?')).searchParams.get('id_token');
    this.authenticate_and_load_profile(idToken);
  }

  private authenticate_and_load_profile(idToken): void {
    if (idToken) {
      let userInfo = jwtDecode(idToken);
      this.authenticateIDP(idToken);

      let isSAML = userInfo.identities !== undefined;
      if (isSAML) {
        this.profile = {
          email: userInfo.identities[0].userId.split('@')[0],
          name: userInfo.name.split(', ')[1],
          surname: userInfo.name.split(', ')[0],
          picture: 'example',
        };
      } else {
        this.profile = {
          email: userInfo.email.split('@')[0],
          name: userInfo['cognito:username'],
          surname: '',
          picture: 'example',
        };
      }

      this.profileChange.next(this.profile);
      this.storeProfile(this.profile);
    }
  }

  async getSignedRequest(request): Promise<any> {
    let headers = {};
    let queryParams = {};
    request.params.keys().forEach((key) => {
      queryParams[key] = request.params.get(key);
    });
    request.headers.keys().forEach((key) => {
      headers[key] = request.headers.get(key);
    });
    let path = request.url.substring(
      request.url.indexOf(environment.signatureHost) + environment.signatureHost.length,
      request.url.length,
    );
    if (!this.sv4client) {
      await this.createSigV4Client();
    }

    let body = request.method === 'DELETE' ? undefined : request.body ? request.body : {};
    let signedRequest = this.sv4client.signRequest({
      // The HTTP method
      method: request.method,
      // The request path
      path,
      // The request headers
      headers,
      // The request query parameters
      queryParams,
      // The request body
      body,
    });
    return Promise.resolve(signedRequest);
  }
}

export class UserProfile {
  email: string;
  picture: string;
  name: string;
  surname: string;
}
