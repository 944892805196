import * as moment from 'moment';

import { SelectOption } from './models/common';
import { UserProfile } from './services/auth/auth.service';
import { Filter } from './services/filter/filter.service';
import { environment } from '../environments/environment';
import { ChangeDetectorRef } from '@angular/core';

export function columnTitleFormatter(name: string): string {
  let origin = '';
  const chunks = name
    .split('_')
    .reverse()
    .map((word: string) => {
      if (['odi', 'sgc', 'sat', 'g17', 'g03'].indexOf(word) > -1 && origin === '') {
        origin = ' [' + word.toUpperCase() + ']';
        return false;
      }
      return word;
    })
    .filter((chunk: any) => !!chunk);
  const composed: string = [...chunks].reverse().join(' ') + origin;
  const firstWord: string = composed.substr(0, 1);

  return firstWord.toUpperCase() + composed.substr(1);
}

export function titleToKey(title = ''): string {
  return title.toLowerCase().replace(/ /g, '_');
}

export function ucfirst(text: string): string {
  if (text.length === 0) {
    return '';
  }
  return text[0].toUpperCase() + text.substring(1);
}

export function formatDate(value: string): string {
  const regex = /\.\d{3}Z$/;
  if (regex.test(value)) {
    const split = value.replace(regex, '').split('T');
    return split[0].split('-').reverse().join('/') + ' ' + split[1];
  }
  return value;
}

export function formatDateTime(value: string): string {
  const split = value.replace('Z', '').split('.')[0].split('T');
  return split[0].split('-').reverse().join('/') + ' ' + split[1];
}

export function formatDateWithoutTime(value: string): string {
  return value.split('T')[0].split('-').reverse().join('/');
}

export function formatNumber(value: number): string {
  return value.toLocaleString('es-ES');
}

export function formatCurrency(value: number, hasDecimals: boolean): string {
  let currency = {
    style: 'currency',
    currency: 'EUR',
    currencyDisplay: 'symbol',
    useGrouping: true,
  };

  if (hasDecimals) {
    currency['minimumFractionDigits'] = 0;
    currency['maximumFractionDigits'] = 0;
  }

  return value.toLocaleString('de-DE', currency);
}

export function mergeFilters(...filters): any {
  const rules = disarray(filters);
  if (rules && rules.length > 0) {
    return {
      filters: [{ rules, condition: 'AND' }],
      logic: 'and',
    };
  }
  return null;
}

function disarray(arr: any[], res = []): any[] {
  if (arr instanceof Array) {
    arr.forEach((e) => {
      if (e instanceof Array) {
        const d = disarray(e, []);
        if (d.length > 0) {
          res.push(...d);
        }
      } else {
        if (e !== null && e !== undefined) {
          res.push(e);
        }
      }
    });
    return res;
  }
  return null;
}

// https://developer.mozilla.org/es/docs/Web/Guide/HTML/Canvas_tutorial/Dibujando_formas#Líneas
export function roundedRect(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number
) {
  ctx.beginPath();
  ctx.moveTo(x, y + radius);
  ctx.lineTo(x, y + height - radius);
  ctx.quadraticCurveTo(x, y + height, x + radius, y + height);
  ctx.lineTo(x + width - radius, y + height);
  ctx.quadraticCurveTo(x + width, y + height, x + width, y + height - radius);
  ctx.lineTo(x + width, y + radius);
  ctx.quadraticCurveTo(x + width, y, x + width - radius, y);
  ctx.lineTo(x + radius, y);
  ctx.quadraticCurveTo(x, y, x, y + radius);
  ctx.stroke();
}


export function objectValues(obj: any): any[] {
  return Object.keys(obj).map((k) => obj[k]);
}

export function zeroPad(num, places) {
  const zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join('0') + num;
}

export function isFloat(val) {
  return Number(val) === val && val % 1 !== 0;
}

export function getExpandedItem(item: Filter, list: any[]): Filter {
  const newItemRules = item.rules.map((rule: any) => {
    if (!!rule.rules && rule.rules.length === 1) {
      return getExpandedItem(rule, list);
    }
    if (!!rule.condition) {
      return getExpandedItem(rule, list);
    } else {
      if (!!rule.reference) {
        const it = list.find((item2: any) => item2['id'] === rule.reference);
        if (it) {
          return getExpandedItem(it.filter, list);
        }
      }
      if (Array.isArray(rule.value)) {
        if (rule.value.length === 1) {
          return { ...rule, value: rule.value[0] };
        }
        const ruleItems = rule.value.map((value) => {
          return { ...rule, value };
        });
        return getExpandedItem({ condition: 'OR', rules: ruleItems }, list);
      }
      return rule;
    }
  });
  if (newItemRules.length > 0) {
    if (newItemRules.length === 1) {
      return newItemRules[0];
    }
    return {
      condition: item.condition,
      rules:
        newItemRules.length === 1 && newItemRules[0].rules ? newItemRules[0].rules : newItemRules,
    };
  }
  return item;
}

export function sortArrayByKey(items: Array<any>, key: string): Array<any> {
  return items.sort((a: any, b: any) => (!!a[key] && !!b[key] ? a[key].localeCompare(b[key]) : -1));
}

export function safeSet(obj, path, value) {
  const segs = path.split('.');
  segs.reduce(function set(deep, seg, i) {
    return (deep[seg] = segs.length - 1 === i ? (deep[seg] = value) : deep[seg] || {});
  }, obj);
  return obj;
}


export function extractRules(rules = []) {
  const rule = {};
  rules.forEach((item) => {
    if (item.rules) {
      Object.assign(rule, extractRules(item.rules));
    }
    if (item && item.field && item.value) {
      Object.assign(rule, { [item.field]: item.value });
    }
  });
  return rule;
}

export function getGridsSelectOption(): SelectOption[] {
  return [{ name: 'Sábana de inversiones', value: environment.origins.investments.mtbt[0].origin }];
}

export function capitalize(str): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const FIELDS = {
  id: 'ID',
  clasificacion_inversion: 'CLASIFICACION DE INVERSION',
  codigo_obra: 'CÓDIGO DE OBRA',
  codigo_obra_trabajo: 'CÓDIGO DE OBRA - TRABAJO',
  instalaciones: 'INSTALACIONES',
  importe: 'IMPORTE',
  coherencias: 'COHERENCIAS',
  coherencias_manuales: 'COHERENCIAS MANUALES',
  clasificacion: 'CLASIFICACIÓN',
  origen: 'ORIGEN',
  codigo_instalacion: 'CÓDIGO DE INSTALACIÓN',
  mei_desc: 'MEI DESCRIPCIÓN',
  tipologia0: 'TIPOLOGÍA DE ACTUACIÓN 1',
  tipologia1: 'TIPOLOGÍA DE ACTUACIÓN 2',
  tipologia2: 'TIPOLOGÍA DE ACTUACIÓN 3',
  tipologia_inversion1: 'TIPOLOGÍA DE INVERSIÓN 1',
  tipologia_inversion2: 'TIPOLOGÍA DE INVERSIÓN 2',
  importe_directo_propio: 'IMPORTE DIRECTO PROPIO',
  importe_directo_cedido: 'IMPORTE DIRECTO CEDIDO',
};
export const ORIGINS = {
  sabana_inversiones_mtbt_detalle_vista: 'Inversiones',
  gdocelda: 'Gdo celda',
  sgt_presupuestouucc: 'Sgt presupuesto uucc',
  sgm_presupuestouucc: 'Sgm presupuesto uucc',
  gdoct_vista: 'Gdoct vista',
  gdoctss_vista: 'Gdoctss vista',
};

export function safeDetectChanges(cdr: ChangeDetectorRef) {
  if (!cdr['destroyed']) {
    cdr.detectChanges();
  }
}

export const formatCurrencyDigits = function (value, minimumFractionDigits: number = 2) {
  const formatCurrency = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits,
  });
  return formatCurrency.format(value);
};
