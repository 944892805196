import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { AuthService } from '../services/auth/auth.service';
import { environment } from './../../environments/environment';



@Injectable()
export class SignRequestInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.method !== 'OPTIONS' && req.url.match(environment.apiDomain)) {
      req = req.clone({ headers: req.headers.set('usertoken', this.auth.retrieveToken()) })
      let result = from(signRequest(req, next, this.auth))
      return result
    }
    return next.handle(req);
  }
}

async function signRequest(req, handler, authService) {
  let signedRequest = await authService.getSignedRequest(req)
  for (let [key, value] of Object.entries(signedRequest.headers)) {
    req = req.clone({ headers: req.headers.set(key, value) })
  }
  return handler.handle(req).toPromise()
}