import { Injectable } from "@angular/core";
import { ComponentBindings } from "./../../models/common";

export class CtxOptions {
  draggable?: boolean;
  complex?: boolean;
  alignment?: string;
  onClose?: any;
  naked?: any;
  branded?: any;
  body: CtxListItem[] | any;
}

export class CtxListItem {
  options: CtxListItemOption[];
  title: string;
}

export class CtxListItemOption {
  caption: string;
  action: Function;
  icon?: string;
}

export class CtxActionItem {
  caption: string;
  component: any;
  bindings: ComponentBindings;
  action: Function;
}

export enum CtxContext {
  "app",
  "dialog",
  "userbox"
}

@Injectable()
export class BdrContextualMenuService {
  visible: boolean;
  component: any;
  options: CtxOptions;
  target: HTMLElement;
  context: CtxContext;

  dialogOverlayScroll: number;
  dialogWrapperRect: ClientRect;

  list: Array<{
    component: any;
    options: CtxOptions;
    target: HTMLElement;
    context: CtxContext;
  }>;

  constructor() {
    this.visible = false;
    this.component = null;
    this.options = null;
    this.list = [];
    this.context = CtxContext.app;
    this.dialogOverlayScroll = 0;
    this.dialogWrapperRect = <ClientRect>{ left: 0, height: 0 };
  }

  open(
    component: any,
    event: MouseEvent,
    options = <CtxOptions>{},
    context = CtxContext.app,
  ): void {
    const target = <HTMLElement>event.target;
    this.component = component;
    this.options = options;
    this.target =
      context === CtxContext.userbox && target.nodeName !== "bdr-user-box"
        ? <HTMLElement>target.closest("bdr-user-box")
        : target;
    // TODO: Fix with observables
    setTimeout(() => {
      this.visible = true;
    }, 0);

    this.context = context;
    if (context === CtxContext.dialog) {
      this.dialogOverlayScroll = (event.target as HTMLElement).closest(".overlay").scrollTop;
      this.dialogWrapperRect = (event.target as HTMLElement)
        .closest(".dialog.fixed")
        .getBoundingClientRect();
    }
  }

  close(): void {
    if (this.options && this.options.onClose) {
      this.options.onClose();
    }
    this.visible = false;
  }

  add(
    component: any,
    event: MouseEvent,
    options = <CtxOptions>{},
    context = CtxContext.app
  ): number {
    this.list.push({
      component,
      options,
      context,
      target: <HTMLElement>event.target
    });
    return this.list.length - 1;
  }

  remove(index: number): void {
    this.list.splice(index, 1);
  }

  clear(): void {
    this.list = [];
  }
}
