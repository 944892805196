import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'bdr-hero-image',
  templateUrl: './bdr-hero-image.component.html',
  styleUrls: ['./bdr-hero-image.component.scss']
})
export class BdrHeroImageComponent implements OnInit {
  segmentPath: string;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    const url = this.activatedRoute['_routerState'].snapshot.url.replace('/', '');
    this.segmentPath = url ? url : 'home';
  }
}
