import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from "@angular/core";

import { CtxListComponent } from "./../bdr-contextual-menu/ctx-list/ctx-list.component";

import {
  BdrContextualMenuService,
  CtxContext
} from "./../../services/bdr-contextual-menu/bdr-contextual-menu.service";
import { safeDetectChanges } from '../../util';

@Component({
  selector: "bdr-user-box",
  templateUrl: "./bdr-user-box.component.html",
  styleUrls: ["./bdr-user-box.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BdrUserBoxComponent implements OnChanges {
  @Input() profile;
  @Input() version: string;
  @Output() logoutAction: EventEmitter<void>;
  showMenu: boolean;
  captionLogout: string;

  constructor(
    private ctxmenuService: BdrContextualMenuService,
    private cdr: ChangeDetectorRef
  ) {
    this.showMenu = false;
    this.logoutAction = new EventEmitter<void>();
  }

  ngOnChanges() {
    this.captionLogout = "Cerrar sesión";
  }

  openMenu(event: MouseEvent): void {
    const options = {
      onClose: this.closeMenu.bind(this),
      body: [
        {
          version: this.version,
          options: [
            {
              caption: this.captionLogout,
              action: this.logout.bind(this)
            }
          ]
        }
      ]
    };
    this.ctxmenuService.open(
      CtxListComponent,
      event,
      options,
      CtxContext.userbox
    );
    this.showMenu = true;
  }

  private closeMenu() {
    this.showMenu = false;
    this.cdr.detectChanges()
  }
  logout() {
    this.logoutAction.emit();
  }
}
