
import { throwError as observableThrowError, Observable } from 'rxjs';
import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  ChangeDetectorRef
} from '@angular/core';


import { LogService } from './../../../services/log/log.service';
import { ContextualmenuContent } from '../contextual-menu-content';
import { BdrContextualMenuService } from '../../../services/bdr-contextual-menu/bdr-contextual-menu.service';
import { catchError } from 'rxjs/operators';
import { safeDetectChanges } from '../../../util';

@Component({
  selector: 'ctx-log-info',
  templateUrl: './ctx-log-info.component.html',
  styleUrls: ['./ctx-log-info.component.scss']
})
export class CtxLogInfoComponent extends ContextualmenuContent
  implements OnInit, OnDestroy {
  error = false;
  errorMessage = 'Ha ocurrido un error obteniendo la información';
  loading = false;
  logInfo: any = {};
  subscriptions = [];

  constructor(
    private _ctxmenuService: BdrContextualMenuService,
    private logService: LogService,
    private cdRef: ChangeDetectorRef
  ) {
    super(_ctxmenuService);
  }

  ngOnInit() {
    this.loading = true;
    const subscription = this.logService
      .getLatestLog(this.content.origin, this.content.item)
      .pipe(catchError(err => {
        this.error = true;
        this.loading = false;
        return observableThrowError(err);
      }))
      .subscribe(res => {
        this.error = false;
        this.loading = false;
        try {
          this.logInfo = this.logService.mergeChanges(res.result);
        } catch (err) {
          this.error = true;
        }
        this.cdRef.detectChanges()
      });
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subs => {
      if (subs.unsubscribe) {
        subs.unsubscribe();
      }
    });
  }

  showAll() {
    this.content.showLogDialog(this.content.origin, this.content.item);
    this.close();
  }
}
