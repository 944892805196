import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { map } from "rxjs/operators";

@Component({
  selector: 'bdr-volumetries',
  templateUrl: './bdr-volumetries.component.html',
  styleUrls: ['./bdr-volumetries.component.scss']
})
export class BdrVolumetriesComponent implements OnInit {
  @Input() resultsCount: any[] = [];
  segmentPath: string;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.url.pipe(
      map(url => {
        const path = url[0] ? url[0].path : 'home';
        return path;
      })
    )
      .subscribe(path => {
        this.segmentPath = path;
      });
  }

}
