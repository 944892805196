import { Subscription, Observable, Subject } from 'rxjs';
import { DialogFilterComponent } from '../dialog/dialog-filter/dialog-filter.component';
// import { WorkflowService } from './../../services/workflow.service';
import { FilterService, FilterDB } from './../../services/filter/filter.service';
import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import * as clone from 'clone';

import { DialogService, Dialog } from './../dialog/dialog.service';
import { GridProperties, GridService } from './../../services/grid/grid.service';

import { DialogConfirmComponent } from './../dialog/dialog-confirm/dialog-confirm.component';
import { DialogLogListComponent } from './../dialog/dialog-log-list/dialog-log-list.component';
import { ucfirst, safeDetectChanges } from '../../util';
import { InvestmentFilterService } from '../../services/investment-filter/investment-filter.service';
import { GridHandler } from '../../components/simple-grid-handler/simple-grid-handler.component';
import { BdrValidationsService } from '../../services/bdr-validations/bdr-validations.service';
import { DialogColumnSelectorComponent } from '../dialog/dialog-column-selector/dialog-column-selector.component';
import { DialogMassEditComponent } from '../dialog/dialog-mass-edit/dialog-mass-edit.component';
import { CtxLogInfoComponent } from '../../components/bdr-contextual-menu/ctx-log-info/ctx-log-info.component';
import { BdrContextualMenuService } from '../../services/bdr-contextual-menu/bdr-contextual-menu.service';
import { Schema, SchemaService } from './../../services/schema/schema.service';
import { DialogFilterToFolderComponent } from '../dialog/dialog-filter-to-folder/dialog-filter-to-folder.component';
import { SidePanelUI } from '../../models/common';
import { takeUntil } from 'rxjs/operators';

const FILTER_TYPES = {
  coherencias: 'validations-filter',
  coherencias_manuales: 'manual-validations-filter',
};

@Component({
  selector: 'grid-handler-view',
  templateUrl: './grid-handler-view.component.html',
  styleUrls: ['./grid-handler-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridHandlerViewComponent extends GridHandler implements OnInit, OnDestroy, OnChanges {
  @Input() saving: boolean;
  @Input() savingType: string;
  @Input() showModalSaving: string;
  @Input() simulatedOrigin: string;
  @Input() originKey: string;
  @Input() title: string;
  @Input() gridOverrides: any;
  @Input() panels: SidePanelUI;
  @Input() resultCustomMap: Function;
  @Input() customBindings: any[];
  @Input() requestParamsCustomFilter: any;
  @Input() volumetries: boolean;
  @Input() gridProperties: GridProperties = <any>{ origin: '' };
  @Input() editMode: boolean;
  @Input() editChangedValues: boolean = false;
  @Input() canSave: boolean;
  @Input() actionsMenuOptions: any[];
  @Input() subheaderSelector: any[];
  @Input() scope: string;
  @Input() validationsId;
  @Input() hasDeferredAppointments: any[];
  @Input() deferredAppointments: boolean;
  @Input() hasDeferredWorks: any[];
  @Input() deferredWorks: boolean;
  @Input() hasDeferredAppointmentsBuildings: any[];
  @Input() deferredAppointmentsBuildings: boolean;
  @Input() hasDeferredAppointmentsOffices: any[];
  @Input() deferredAppointmentsOffices: boolean;
  @Input() hasDeferredAppointmentsMeasuring: any[];
  @Input() deferredAppointmentsMeasuring: boolean;
  @Input() isTabSelected: boolean = false;
  @Input() saveDisabled: boolean = false;
  @Input() saveDisabledReason: string;
  @Input() recurrentEditionCheckbox: boolean;

  @Output() onGenerateAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDblClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRowClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedRowsChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() onValidationsClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEditAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSaveChanges: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCreateRows: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteRows: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCopyRows: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() resultCount: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCurrentRequestParamsChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSaveFilterRequest: EventEmitter<any> = new EventEmitter<any>();
  changedColumns = false;
  newFilter: FilterDB;
  currentOrigin: string;
  resultsCount: any;
  infoMasterConfiguration: any;
  defaultSchemaVisibility: any;
  visibleColumns = [];
  massEditionObject: any;
  currentFilter: FilterDB = undefined;
  currentFilterType: string;
  baseOrigin: string;
  currentDataQuery: any;
  dataSource: Function;
  simulationDetails: { id: string; plan: any; status: string };
  expanded = false;
  selectedRows = false;
  rows: any[] = [];
  columnsSearchVisible = false;
  grouping = false;
  groupingColumns: { field: string; title: string }[] = [];
  closedIssues: boolean;
  visualizationDialogVisible = false;
  massEditPanelVisible = false;
  allColumns: string[];
  appliedFilters: FilterDB[] = [];
  resultsShowingCount: number;
  filters: any;
  dialogSubscription: Observable<Dialog[]>;
  firstSchemaChange: boolean = false;
  showCreateDeleteBanner: {
    showNewRows: boolean;
    showDeletesOrCopy: boolean;
  } = {
    showNewRows: false,
    showDeletesOrCopy: false,
  };
  panelTypes = {
    FILTER: 'filter',
  };
  fastSearchConditions: {
    [field: string]: { type?: string; search: string };
  } = {};
  currentRequestParams = {};
  subscriptions: any[] = [];
  manualItemsSubscription: Subscription;
  manualItems = [];
  validations = [];
  clickCellActionMapping = {
    modificado: this.showLogCtx.bind(this),
  };
  filterSchemaItems: {
    [origin: string]: {
      [filterOrigin: string]: { caption: string; items: Schema[] };
    };
  } = {};
  schemaSubscription: Subscription;
  createNewRowObject: any;
  deleteRowsObject: any;
  copyRowsObject: any;

  private destroy$: Subject<any> = new Subject();

  constructor(
    private dialogService: DialogService,
    private gridService: GridService,
    private filterService: FilterService,
    private investmentFilters: InvestmentFilterService,
    private ctxmenuService: BdrContextualMenuService,
    private validationsService: BdrValidationsService,
    private cdr: ChangeDetectorRef,
    private schemasService: SchemaService,
  ) {
    super();
  }

  ngOnInit() {
    this.investmentFilters
      .getAppliedFilters()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.appliedFilters = result[this.getCurrentOrigin()] || [];
        if (this.appliedFilters && this.appliedFilters.length > 0) {
          this.applyNewFilters(this.appliedFilters);
        }
        safeDetectChanges(this.cdr);
      });

    this.schemasService
      .getFilterSchemas()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response: {
          [origin: string]: {
            [filterOrigin: string]: { caption: string; items: Schema[] };
          };
        }) => {
          this.filterSchemaItems = response;
          this.enableFilters();
        },
      );

    if (!!this.getCurrentOrigin()) {
      this.resetSchemaColumns();
    }

    this.dialogService
      .getDialogsObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        safeDetectChanges(this.cdr);
      });
    this.getManualValidations();
    this.getValidations();
  }

  ngOnDestroy() {
    this.dialogService.clearDialogs();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngOnChanges(changes) {
    if (
      changes.gridProperties &&
      !changes.gridProperties.firstChange &&
      changes.gridProperties.previousValue !== changes.gridProperties.currentValue
    ) {
      if (
        !changes.gridProperties.previousValue ||
        (changes.gridProperties.previousValue &&
          changes.gridProperties.previousValue.origin !==
            changes.gridProperties.currentValue.origin)
      ) {
        this.firstSchemaChange = false;
        this.resetSchemaColumns();
        this.appliedFilters = this.investmentFilters.getFilterByOrigin(this.getCurrentOrigin());
        this.currentFilter = this.filterService.formatFilter(this.appliedFilters);
        this.fastSearchConditions = this.filterService.getFastSearchConditions(this.appliedFilters);
        this.setColumnSearch(Object.keys(this.fastSearchConditions).length > 0);
      }
      if (
        this.gridProperties.columnSelector &&
        this.gridProperties.headerActions &&
        !this.gridProperties.headerActions.find((item) => item.type === 'columnSelect')
      ) {
        let columnSelector = {
          type: 'columnSelect',
          fire: this.openColumnSelector.bind(this),
        };
        let massEdit = {
          type: 'massEditor',
          fire: this.openMassEditorDialog.bind(this),
        };
        this.gridProperties.headerActions.push(massEdit);
        this.gridProperties.headerActions.push(columnSelector);
      }
    }
    this.cdr.detectChanges();
  }

  private getValidations() {
    this.validationsService
      .getSummary()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          this.validations = this.validationsService.formatSummary(response.result);
          this.gridProperties.metadata = this.gridProperties.metadata || {};
          this.gridProperties.metadata.validations = this.validations;
        },
        (error) => {
          this.validations = [];
        },
      );
  }

  private getManualValidations() {
    this.validationsService
      .getManualValidationsObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          this.manualItems = response;
          this.gridProperties.metadata = this.gridProperties.metadata || {};
          this.gridProperties.metadata.manualValidations = this.manualItems;
        },
        (error) => {
          this.manualItems = [];
        },
      );
  }

  reload() {}

  // bypass
  rowClick(row: any): void {
    this.onRowClick.emit(row);
  }

  /**
   * Change grid data applying the Filter.
   * Make a backend call to get the data.
   *
   * @param {FilterDB} filter Filter object to be applied.
   *
   * @memberof GridHandlerViewComponent
   */
  applyFilter(filter: FilterDB): void {
    const filterIndex = this.appliedFilters.findIndex((item) => {
      if (filter.name === '') {
        return item.name === filter.name;
      }
      return item.id === filter.id;
    });
    if (filterIndex === -1) {
      const applyFilterValue = clone(filter);
      if (applyFilterValue.query) {
        applyFilterValue.filter = JSON.parse(applyFilterValue.query);
      }
      this.appliedFilters = [...this.appliedFilters, applyFilterValue];
    } else {
      this.appliedFilters[filterIndex] = clone(filter);
      // Compare filters and remove if they are equal
      if (JSON.stringify(filter) === JSON.stringify(this.appliedFilters[filterIndex])) {
        this.appliedFilters.splice(filterIndex, 1);
        this.appliedFilters = [...this.appliedFilters];
      } else {
        this.appliedFilters[filterIndex] = filter;
        this.appliedFilters = [...this.appliedFilters];
      }
    }
    this.executeFilters(this.appliedFilters);
  }

  /* ######################################################
   *  Grid functions
   * ######################################################
   */

  changeSelectedRows(evt): void {
    this.selectedRows = evt.length > 0;
    this.rows = evt;
    this.selectedRowsChange.emit(evt);
    safeDetectChanges(this.cdr);
  }

  clickCellEvent(data) {
    this.clickCellActionMapping[data.column](data.event, data.data);
  }
  /**
   * Update the data grid and remove the current filter applied.
   *
   *
   * @memberof GridHandlerViewComponent
   */
  resetFilter(filter: FilterDB): void {
    this.investmentFilters.resetFilter(this.getCurrentOrigin(), filter);
    if (filter.meta.fastSearch) {
      let key = filter.filter.rules[0]['field'];
      delete this.fastSearchConditions[key];
      this.fastSearchConditions = { ...this.fastSearchConditions };
    }
    // force reference change
    this.appliedFilters = this.investmentFilters.getFilterByOrigin(this.getCurrentOrigin());
    this.executeFilters(this.appliedFilters);
  }

  /**
   * Upload infoMasterConfiguration value.
   *
   * @param {object} origin New value of infoMasterConfiguration.
   *
   * @memberof GridHandlerViewComponent
   */

  setInfoMasterLoad(origin: any): void {
    this.infoMasterConfiguration = origin;
  }

  /**
   * Upload resultsCount value.
   *
   * @param {number} count New value of resultsCount.
   *
   * @memberof GridHandlerViewComponent
   */

  setResultsCount(count: any): void {
    this.resultsCount = {
      total: {
        title: 'Total',
        count: count.total,
        amountTitle: 'Importe',
        amount: count.amount.total,
      },
    };

    if (this.gridProperties.origin === 'sabana_cuadre_inversiones_pco_iid') {
      this.resultsCount.filtered = {
        totalWorks: count.amount.totalWorks,
        totalInstallations: count.amount.totalInstallations,
        amountPco: count.amount.amountPco,
        amountIid: count.amount.amountIid,
        amountDifference: count.amount.amountDifference,
      };

      if (count.filtered && count.filtered != count.total) {
        this.resultsCount.filtered.count = count.filtered;
      }
    }

    if (
      [
        'sabana_inversiones_mtbt_agregada_vista',
        'sabana_inversiones_despachos_agregado_obra_vista',
      ].includes(this.gridProperties.origin)
    ) {
      this.resultsCount.filtered = {};
      if (count.filtered && count.filtered != count.total) {
        this.resultsCount.filtered.title = 'Expedientes filtrados';
        this.resultsCount.filtered.count = count.filtered;
        this.resultsCount.filtered.worksAmount = count.amount.filtered;
        this.resultsCount.filtered.worksTitle = 'Expediente original';
      }

      this.resultsCount.filtered.mtbtTitle = 'MTBT + Cesiones';
      this.resultsCount.filtered.dispatchersTitle = 'Despachos';

      this.resultsCount.filtered.mtbtAmount = count.amount.mtbt;
      this.resultsCount.filtered.dispatchersAmount = count.amount.dispatchers;
    } else if (
      ['sabana_informe_instalaciones_despachos_detalle_vista'].includes(this.gridProperties.origin)
    ) {
      this.resultsCount.filtered = {};

      if (count.filtered && count.filtered != count.total) {
        this.resultsCount.filtered.title = 'Expedientes filtrados';
        this.resultsCount.filtered.count = count.filtered;
        this.resultsCount.filtered.worksAmount = count.amount.filtered;
        this.resultsCount.filtered.worksTitle = 'Expediente original';
      }

      this.resultsCount.filtered.auditedTitle = 'Valor Auditado';
      this.resultsCount.filtered.auditedAmount = count.amount.audited;

      this.resultsCount.filtered.investmentTitle = 'Valor Inversión';
      this.resultsCount.filtered.investmentAmount = count.amount.investment;

      this.resultsCount.filtered.tpiTitle = 'Valor TPI';
      this.resultsCount.filtered.tpiAmount = count.amount.tpi;
    } else if (count.filtered && count.filtered != count.total) {
      this.resultsCount.filtered = {
        title: 'Con filtros',
        count: count.filtered,
      };
      this.resultsCount.filtered.amountTitle = 'Importe';
      this.resultsCount.filtered.amount = count.amount.filtered;
    }

    this.gridProperties.resultsShowingCount = count.showing;
    this.cdr.detectChanges()
  }

  toggleCreateDeleteBanner($event) {
    this.showCreateDeleteBanner = $event;
    safeDetectChanges(this.cdr);
  }

  /* ######################################################
   *  Grouping
   * ######################################################
   */

  changeGroupingColumns(columns: { field: string; title: string }[]): void {
    this.groupingColumns = columns;
    if (columns.length > 0) {
      this.grouping = true;
      // this.columnDropEnabled = false;
    } else {
      this.grouping = false;
      // this.columnDropEnabled = true;
    }
  }

  /* ######################################################
   *  Dialogs
   * ######################################################
   */

  addDialog(options: any): void {
    this.dialogService.createDialog(options);
  }

  closeDialog(id: string): void {
    this.dialogService.closeDialog(id);
  }

  /**
   * Open Filters Editor Panel. Optionally load on it a saved Filter.
   *
   * @param {FilterDB} filter Filter object to be loaded.
   *
   * @memberOf GridHandlerViewComponent
   */

  fastSearch(conditions: {
    [field: string]: { type: string; search: string; colName: string };
  }): void {
    this.fastSearchConditions = conditions;
    if (Object.keys(conditions).length === 0) {
      this.appliedFilters = this.appliedFilters.filter((f) => !f.meta.fastSearch);
    } else {
      Object.keys(conditions)
        .map((field) => ({ field, ...conditions[field] }))
        .forEach((obj) => {
          const id = `fs_${obj.field}`;
          const filterIndex = this.appliedFilters.findIndex((f) => f.id === id);
          if (obj.search !== null && obj.search.length > 0) {
            let name = `${ucfirst(obj.colName)} `.toUpperCase();
            let operator;
            switch (obj.type) {
              case 'string':
              case 'array':
                name += `contiene "${obj.search}"`;
                operator = 'contains';
                break;
              case 'date':
                name += `en "${obj.search}"`;
                operator = 'equal_date';
                break;
              default:
                name += `= ${obj.search}`;
                operator = 'equal';
            }

            const type = FILTER_TYPES[obj.field] || 'filter';
            if (type === 'manual-validations-filter') {
              const validations = this.manualItems.find((item) => item.id === obj.search) || {};
              if (validations && validations.name) {
                name = validations.name;
              }
            }
            if (type === 'validations-filter') {
              const validations = this.validations.find(
                (item) => item.idValidations === parseInt(obj.search),
              );
              if (validations && validations.name) {
                name = validations.name;
              }
            }
            const filter = new FilterDB({
              id,
              name,
              origin: this.getCurrentOrigin(),
              meta: { type, fastSearch: true },
              filter: {
                condition: 'AND',
                rules: [
                  {
                    operator,
                    id: obj.field,
                    field: obj.field,
                    type: obj.type,
                    value: obj.search,
                  },
                ],
              },
            });

            if (filterIndex === -1) {
              this.appliedFilters.push(filter);
            } else {
              this.appliedFilters[filterIndex] = filter;
            }
          } else {
            this.appliedFilters.splice(filterIndex, 1);
          }
        });
    }
    this.executeFilters(this.appliedFilters);
  }

  private getCurrentOrigin(): string {
    return this.gridProperties.origin;
  }

  handleChangeFastFiltersVisibility(filtersShown: boolean) {
    this.columnsSearchVisible = filtersShown;
  }

  findFilterConditions(fastFilter, item) {
    if (fastFilter.name === '') {
      return item.name === fastFilter.name && item.meta.type === fastFilter.meta.type;
    }
    return item.id === fastFilter.id;
  }

  applyFastFilter(fastFilter: FilterDB): void {
    const filterIndex = this.appliedFilters.findIndex((item) => {
      return this.findFilterConditions(fastFilter, item);
    });
    if (filterIndex === -1) {
      this.appliedFilters.push(clone(fastFilter));
    } else {
      this.appliedFilters[filterIndex] = clone(fastFilter);
    }
    this.executeFilters(this.appliedFilters);
  }

  haltFastFilter(fastFilter: FilterDB): void {
    const filterIndex = this.appliedFilters.findIndex((item) => {
      return this.findFilterConditions(fastFilter, item);
    });

    if (filterIndex !== -1) {
      this.appliedFilters.splice(filterIndex, 1);
    }

    this.executeFilters(this.appliedFilters);
  }

  private executeFilters(appliedFilters: FilterDB[]): void {
    const newFilter = this.filterService.formatFilter(appliedFilters);
    this.currentFilter = newFilter;
    this.investmentFilters.setFilter(newFilter);
    this.currentDataQuery = newFilter;

    this.investmentFilters.setAppliedFilters(this.getCurrentOrigin(), this.appliedFilters);
  }

  private applyNewFilters(appliedFilters: FilterDB[]): void {
    const newFilter = this.filterService.formatFilter(appliedFilters);
    this.currentFilter = newFilter;
    this.investmentFilters.setFilter(newFilter);
    this.currentDataQuery = newFilter;
  }

  sortChange(sort: any[]): void {
    this.currentDataQuery = this.currentDataQuery || {};
    this.currentDataQuery.sort = sort.length > 0 ? sort : undefined;
  }

  setColumnSearch(evt) {
    this.columnsSearchVisible = evt;
    safeDetectChanges(this.cdr);
  }

  toggleColumnSearch() {
    this.columnsSearchVisible = !this.columnsSearchVisible;
  }

  generateAction(evt): void {
    const dialogOptions: any = {
      component: DialogConfirmComponent,
      size: { width: 50, height: 50 },
      fixed: true,
      bindings: {
        inputs: {
          content:
            '<p>¿Desea volver a cargar los datos de la página actualizados?</p>' +
            '<p>Los datos anteriores se borrarán y se actualizarán por los nuevos.</p>',
        },
        outputs: {
          confirm: this.generateActionConfirm.bind(this, evt),
        },
      },
      notHeader: true,
    };
    this.addDialog(dialogOptions);
  }

  generateActionConfirm(id) {
    this.onGenerateAction.emit(id);
    this.dialogService.closeDialog(id);
  }

  dblClick($event): void {
    this.onDblClick.emit($event);
  }

  filterChange(filters) {
    this.cancelActions(null);
    this.filters = filters;
  }

  gridEditChange($event) {
    this.onGridEditChange.emit($event);
  }
  cancelActions($event) {
    this.onCancel.emit($event);
  }

  saveChanges($event) {
    this.onSaveChanges.emit($event);
  }

  createRows($event) {
    this.onCreateRows.emit($event);
  }

  deleteRows($event) {
    this.onDeleteRows.emit($event);
  }

  copyRows($event) {
    this.onCopyRows.emit($event);
  }

  showLogCtx(evt, row) {
    const options = {
      naked: true,
      branded: true,
      body: {
        origin: this.gridProperties.origin,
        item: row,
        showLogDialog: this.showLogDialog.bind(this),
      },
    };
    this.ctxmenuService.open(CtxLogInfoComponent, evt, <any>options);
  }

  showLogDialog(origin, item) {
    let title;
    if (origin.match(/instalaciones/gi)) {
      title = `Modificaciones  Instalacion ${item.codigo_instalacion} - Obra ${item.codigo_obra}`;
    } else {
      title = `Modificaciones  AC ${item.id} - Obra ${item.codigo_obra}`;
    }
    const dialogOptions: any = {
      title,
      size: { width: 80, height: 60 },
      fixed: true,
      component: DialogLogListComponent,
      bindings: { inputs: { item: item, origin: origin } },
    };
    this.addDialog(dialogOptions);
  }

  addFilterToFolder($event) {
    this.filterService.addFilterToFolder($event.filter, $event.folder);
  }

  showConfirmAction($event) {
    const dialogOptions: any = {
      component: DialogConfirmComponent,
      size: { width: 50, height: 50 },
      title: $event.title,
      bindings: {
        inputs: {
          id: $event.id,
          content: $event.content,
          buttonText: $event.buttonText,
          showCancel: $event.showCancel,
        },
        outputs: {
          confirm: () => {
            $event.confirm();
            this.closeDialog($event.id);
          },
          closeDialog: this.closeDialog.bind(this),
        },
      },
      notHeader: $event.hasOwnProperty('notHeader') ? $event.notHeader : true,
      fixed: $event.hasOwnProperty('fixed') ? $event.fixed : true,
    };
    this.dialogService.createDialog(dialogOptions);
  }

  addFilterToFolderDialog($event) {
    const dialogOptions: any = {
      component: DialogFilterToFolderComponent,
      size: { width: 80, height: 180 },
      title: 'Añadir a carpeta',
      fixed: true,
      headerComponent: false,
      bindings: {
        inputs: {
          filter: $event.filter,
          folders: $event.folders,
        },
        outputs: { save: this.addFilterToFolder.bind(this) },
      },
    };
    this.addDialog(dialogOptions);
  }

  createFilterDialog(filterObj: any): void {
    const dialogOptions: any = {
      component: DialogFilterComponent,
      size: { width: 80, height: 180 },
      title: !!filterObj.filter ? 'Editar filtro' : 'Crear un nuevo filtro',
      fixed: true,
      headerComponent: false,
      bindings: {
        inputs: {
          title: !!filterObj.filter ? filterObj.filter.name : '',
          item: !!filterObj.filter
            ? clone(filterObj.filter, false)
            : this.filterService.getNewFilter(this.getCurrentOrigin()),
          origin: this.getCurrentOrigin(),
          type: filterObj.type,
          schemaItems: this.filterSchemaItems,
          sideList: [
            {
              id: filterObj.type,
              icon: 'filters',
              type: 'filter',
              list: this.filterService,
            },
          ],
        },
        outputs: { applyItem: this.applyFilter.bind(this) },
      },
    };
    this.addDialog(dialogOptions);
  }

  manualValidationsClick($event) {
    if (!$event.id) {
      return;
    }
    const validations = this.manualItems.find((item) => item.id === $event.id);
    const validationsName = !!validations ? validations.name : '';
    const filter = new FilterDB({
      name: `${validationsName}`,
      origin: this.getCurrentOrigin(),
      meta: { type: 'manual-validations-filter' },
      filter: {
        condition: 'AND',
        rules: [
          {
            operator: 'equal',
            id: 'coherencias_manuales',
            field: 'coherencias_manuales',
            type: 'string',
            value: $event.id,
          },
        ],
      },
    });
    this.applyFilter(filter);
  }

  saveFilter(filter: FilterDB) {
    if (filter.saved) {
      return this.filterService.update(filter);
    }
    this.filterService.create(filter);
  }

  openColumnSelector() {
    if (!this.gridProperties.columnSelector) {
      return;
    }
    const title = 'Seleccione las columnas que desea visualizar';
    let dialogSchemaData = clone(this.defaultSchemaVisibility);
    Object.values(dialogSchemaData).forEach((item: any) => {
      item.visible = this.visibleColumns.includes(item.field);
    });
    let defaultSchemaData = clone(this.defaultSchemaVisibility);
    const dialogOptions: any = {
      title,
      size: { width: 20, height: 40 },
      component: DialogColumnSelectorComponent,
      fixed: true,
      notHeader: true,
      headerComponent: true,
      bindings: {
        inputs: {
          title,
          defaultSchemaData: defaultSchemaData,
          schemaData: dialogSchemaData,
          changedColumns: this.changedColumns,
        },
        outputs: {
          changeDisplayedColumns: (evt) => {
            this.visualizationDialogVisible = false;
            this.changeDisplayedColumns(evt);
          },
          closeDialog: (id) => {
            this.visualizationDialogVisible = false;
            this.closeDialog(id);
            safeDetectChanges(this.cdr);
          },
        },
      },
    };
    this.visualizationDialogVisible = true;
    this.addDialog(dialogOptions);
  }

  openMassEditorDialog() {
    if (!this.gridProperties.columnSelector) {
      return;
    }
    const title = 'Seleccione la columna que desea editar';
    let dialogSchemaData = clone(this.defaultSchemaVisibility);
    Object.values(dialogSchemaData).forEach((item: any) => {
      item.visible = this.visibleColumns.includes(item.field);
    });
    let defaultSchemaData = clone(this.defaultSchemaVisibility);
    const dialogOptions: any = {
      title,
      size: { width: 40, height: 60 },
      component: DialogMassEditComponent,
      fixed: true,
      notHeader: true,
      headerComponent: true,
      bindings: {
        inputs: {
          title,
          defaultSchemaData: defaultSchemaData,
          schemaData: dialogSchemaData,
        },
        outputs: {
          massEditionResponse: ($evt) => {
            this.massEditPanelVisible = false;
            this.handleMassEditionResponse($evt);
            safeDetectChanges(this.cdr);
          },
          closeDialog: (id) => {
            this.massEditPanelVisible = false;
            this.closeDialog(id);
            safeDetectChanges(this.cdr);
          },
        },
      },
    };
    this.massEditPanelVisible = true;
    this.addDialog(dialogOptions);
  }

  createRowConfirmation($event): void {
    const dialogOptions: any = {
      component: DialogConfirmComponent,
      size: { width: 50, height: 50 },
      fixed: true,
      notHeader: false,
      title: 'Crear nuevo registro',
      bindings: {
        inputs: {
          id: 'dialog-confirm-create-row',
          content:
            "<div class='a-c'>" +
            '<p>¿Estás seguro de que deseas insertar un nuevo registro?</p>' +
            '</div>',
          alternativeButtonText: 'Cancelar',
          showCancel: true,
          buttonText: 'Crear',
        },
        outputs: {
          confirm: () => {
            this.closeDialog($event.id);
            this.createNewRowObject = $event;
            safeDetectChanges(this.cdr);
          },
          confirmAlternative: () => {
            this.closeDialog($event.id);
            this.createNewRowObject = {
              showNewRow: false,
              confirmNewRow: false,
            };
            safeDetectChanges(this.cdr);
          },
          closeDialog: () => {
            this.closeDialog($event.id);
            this.createNewRowObject = {
              showNewRow: false,
              confirmNewRow: false,
            };
            safeDetectChanges(this.cdr);
          },
        },
      },
    };
    this.addDialog(dialogOptions);
  }

  deleteRowConfirmation($event): void {
    const dialogOptions: any = {
      component: DialogConfirmComponent,
      size: { width: 50, height: 50 },
      fixed: true,
      notHeader: false,
      title: 'Eliminar registros',
      bindings: {
        inputs: {
          id: 'dialog-confirm-delete-rows',
          content:
            "<div class='a-c'>" +
            '<p>¿Estás seguro de que deseas eliminar los registros seleccionados?</p>' +
            '</div>',
          alternativeButtonText: 'Cancelar',
          showCancel: true,
          buttonText: 'Eliminar',
        },
        outputs: {
          confirm: () => {
            this.closeDialog($event.id);
            this.deleteRowsObject = $event;
            safeDetectChanges(this.cdr);
          },
          confirmAlternative: () => {
            this.closeDialog($event.id);
            this.deleteRowsObject = {
              confirmDeleteRows: false,
              showDeleteRows: false,
            };
            safeDetectChanges(this.cdr);
          },
          closeDialog: () => {
            this.closeDialog($event.id);
            this.deleteRowsObject = {
              confirmDeleteRows: false,
              showDeleteRows: false,
            };
            safeDetectChanges(this.cdr);
          },
        },
      },
    };
    this.addDialog(dialogOptions);
  }

  copyRowConfirmation($event): void {
    const dialogOptions: any = {
      component: DialogConfirmComponent,
      size: { width: 50, height: 50 },
      fixed: true,
      notHeader: false,
      title: 'Copiar registros al año anterior',
      bindings: {
        inputs: {
          id: 'dialog-confirm-copy-rows',
          content:
            "<div class='a-c'>" +
            '<p>¿Estás seguro de que deseas copiar al año anterior los registros seleccionados?</p>' +
            '</div>',
          alternativeButtonText: 'Cancelar',
          showCancel: true,
          buttonText: 'Copiar',
        },
        outputs: {
          confirm: () => {
            this.closeDialog($event.id);
            this.copyRowsObject = $event;
            safeDetectChanges(this.cdr);
          },
          confirmAlternative: () => {
            this.closeDialog($event.id);
            this.copyRowsObject = {
              showCopyRows: false,
              confirmCopyRows: false,
            };
            safeDetectChanges(this.cdr);
          },
          closeDialog: () => {
            this.closeDialog($event.id);
            this.copyRowsObject = {
              showCopyRows: false,
              confirmCopyRows: false,
            };
            safeDetectChanges(this.cdr);
          },
        },
      },
    };
    this.addDialog(dialogOptions);
  }

  handleMassEditionResponse(result) {
    this.closeDialog(result.id);
    this.massEditionObject = result;
    safeDetectChanges(this.cdr);
  }

  changeDisplayedColumns(result) {
    this.closeDialog(result.id);
    this.changedColumns = result.changedColumns;
    this.visibleColumns = this.gridService.getVisibleColumns(result.schemaData);
    safeDetectChanges(this.cdr);
  }

  schemaChange(currentSchema) {
    this.disableFilters();
    if (!currentSchema) {
      this.panels.filters.disabled = true;
      this.panels.fastFilter.disabled = true;
      return;
    }

    if (!this.firstSchemaChange) {
      this.defaultSchemaVisibility = {};
      for (let i = 0, len = currentSchema.length; i < len; i++) {
        this.defaultSchemaVisibility[currentSchema[i].field] =
          this.gridProperties.defaultSchemaVisibility[currentSchema[i].field] || {};
        this.defaultSchemaVisibility[currentSchema[i].field].visible =
          this.gridProperties.additionalSchemaData &&
          this.gridProperties.additionalSchemaData[currentSchema[i].field] &&
          this.gridProperties.additionalSchemaData[currentSchema[i].field].hasOwnProperty('visible')
            ? this.gridProperties.additionalSchemaData[currentSchema[i].field].visible
            : currentSchema[i].visible;
        this.defaultSchemaVisibility[currentSchema[i].field].field = currentSchema[i].field;
        this.defaultSchemaVisibility[currentSchema[i].field].title = currentSchema[i].title;
        this.defaultSchemaVisibility[currentSchema[i].field].editable = currentSchema[i].editable;
      }
      this.visibleColumns = this.gridService.getVisibleColumns(this.defaultSchemaVisibility);
      this.allColumns = Object.keys(this.defaultSchemaVisibility);
      this.firstSchemaChange = true;
    }

    if (
      !!this.filterSchemaItems[this.getCurrentOrigin()] &&
      !!this.filterSchemaItems[this.getCurrentOrigin()][this.getCurrentOrigin()]
    ) {
      this.enableFilters();
    }
    safeDetectChanges(this.cdr);
  }

  disableFilters() {
    if (this.panels.filters) {
      this.panels.filters.disabled = true;
    }
    if (this.panels.fastFilter) {
      this.panels.fastFilter.disabled = true;
    }
    safeDetectChanges(this.cdr);
  }

  enableFilters() {
    if (this.panels.filters) {
      this.panels.filters.disabled = false;
    }
    if (this.panels.fastFilter) {
      this.panels.fastFilter.disabled = false;
    }
    this.panels = Object.assign({}, this.panels);
  }

  resetSchemaColumns() {
    if (!!this.getCurrentOrigin()) {
      this.gridProperties.additionalSchemaData = this.schemasService.getDefaultSchema(
        this.getCurrentOrigin(),
      );
      safeDetectChanges(this.cdr);
    }
  }

  objectKeys(item) {
    return Object.keys(item || {});
  }

  handleChangedTabs(evt) {
    this.isTabSelected = evt;
    safeDetectChanges(this.cdr);
  }

  newRow($event) {
    if ($event.confirmNewRow) {
      this.createRowConfirmation($event);
    } else {
      this.createNewRowObject = $event;
      safeDetectChanges(this.cdr);
    }
  }

  delRows($event) {
    if ($event.confirmDeleteRows) {
      this.deleteRowConfirmation($event);
    }
  }

  cpRows($event) {
    if ($event.confirmCopyRows) {
      this.copyRowConfirmation($event);
    }
  }
}
