import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';

@Component({
  selector: 'bdr-button-circle',
  templateUrl: './bdr-button-circle.component.html',
  styleUrls: ['./bdr-button-circle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BdrButtonCircleComponent implements OnInit {
  @Input() size: string;
  @Input() active: boolean;

  hover: boolean;
  constructor() { }

  ngOnInit() {
    this.hover = false;
    this.size = this.size || 'regular';
  }
}
