import {
  Directive,
  ElementRef,
  Renderer2,
  Input,
  Output,
  EventEmitter,
  HostListener
} from '@angular/core';

const PARENT_ITEM_SELECTOR = '.qb-drop';
const DRAG_OVER_CLASS = 'qb-dragging';

@Directive({
  selector: '[query-builder-drop]'
})
export class QueryBuilderDropDirective {
  // tslint:disable-next-line:no-input-rename
  @Input('query-builder-drop') item: any;

  @Output() dropRequest: EventEmitter<any> = new EventEmitter<any>();

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent): void {
    this.stopEventBubbling();
  }

  @HostListener('dragenter', ['$event'])
  onDragEnter(event: DragEvent): void {
    this.stopEventBubbling();
    this.addDragClass(event);
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent): void {
    this.stopEventBubbling();
    this.removeDragClass(event);
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent): void {
    this.stopEventBubbling();
    this.removeDragClass(event);

    this.dropRequest.emit(this.item);
  }

  private stopEventBubbling(): void {
    event.preventDefault();
    event.stopPropagation();
  }

  private getTarget(event: DragEvent): Element {
    return (<any>event).target.closest(PARENT_ITEM_SELECTOR);
  }

  private addDragClass(event: any): void {
    const element: Element = this.getTarget(event);
    this.renderer.addClass(element, DRAG_OVER_CLASS);
  }

  private removeDragClass(event: any): void {
    const element: Element = this.getTarget(event);
    this.renderer.addClass(element, DRAG_OVER_CLASS);
  }
}
