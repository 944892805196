import { Component, Input, Output, EventEmitter, OnChanges, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatMenuTrigger } from '@angular/material/menu';

interface Filter {
  name: string;
  label: string;
  selected?: true;
}

@Component({
  selector: 'bdr-visible-columns',
  templateUrl: './bdr-visible-columns.component.html',
  styleUrls: ['./bdr-visible-columns.component.scss']
})
export class BdrVisibleColumnsComponent implements OnChanges {
  @ViewChild('clickMenuTrigger', { static: false }) matMenu: MatMenuTrigger;
  @Input() filters: Filter[];
  @Output() onColumnsVisible: EventEmitter<{ filters: Filter[] }> = new EventEmitter();
  selection = new SelectionModel<Filter>(true, []);
  private filtersCopy: Filter[];

  ngOnChanges() {
    if (this.filters && this.filters.length > 0) {
      this.filters = this.filters.filter(field => field.label);
      this.filtersCopy = [...this.filters];
      this.markAllFiltersAsTouched();
    }
  }

  removeFilters(event: Event) {
    event.stopPropagation();
    this.selection.clear();
  }

  applyFilters(event: Event) {
    event.stopPropagation();

    this.matMenu.closeMenu();

    this.onColumnsVisible.emit({
      filters: this.selection.selected
    });
  }

  handleFilter(event) {
    event.preventDefault();

    const search = event.target.value.toLowerCase();
    const searchFiltered = filter => filter.label.toLowerCase().includes(search);

    this.filters = search ? this.filtersCopy.filter(searchFiltered) : this.filtersCopy;
  }

  addItem(item) {
    this.selection.toggle(item);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numFilters = this.filters.length;
    return numSelected === numFilters;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.markAllFiltersAsTouched();
  }

  markAllFiltersAsTouched() {
    return this.filters.forEach(row => this.selection.select(row));
  }
}
