import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from "@angular/core";
import * as clone from 'clone'


@Component({
  selector: "dialog-column-selector",
  templateUrl: "./dialog-column-selector.component.html",
  styleUrls: ["./dialog-column-selector.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogColumnSelectorComponent implements OnInit {
  @Input() title: string;
  @Input() schemaData: any = {};
  @Input() id: string;
  @Input() changedColumns: boolean;
  @Input() defaultSchemaData: any = {};
  @Output() changeDisplayedColumns: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeDialog: EventEmitter<string> = new EventEmitter<string>();

  displayedColumns = {};
  allSelected = false;
  allDeselected = false;
  queryString: string;
  textEmptySearch: boolean;
  iconSearch: string;

  constructor() {
    this.iconSearch = "search";
  }

  ngOnInit() {
    this.displayedColumns = {};
    let columns = Object.keys(this.schemaData).sort();
    if (this.schemaData) {
      columns.forEach(key => {
        this.displayedColumns[key] = this.schemaData[key];
      });
    }
    this.setMassiveSelectors();
  }

  objectKeys(paramObj) {
    return Object.keys(paramObj);
  }

  handleCloseDialog(): void {
    this.closeDialog.emit(this.id);
  }

  handleApplyItem() {
    this.changeDisplayedColumns.next({
      id: this.id,
      changedColumns: true,
      schemaData: this.schemaData
    });
  }

  filterColumns(value: string) {
    this.queryString = value;
    this.iconSearch = this.queryString.length > 0 ? "cancel-search" : "search";
    const keys = Object.keys(this.schemaData)
      .filter(
        item =>
          this.schemaData[item].title
            .toUpperCase()
            .indexOf(value.toUpperCase()) > -1
      )
      .sort();
    this.displayedColumns = {};
    keys.forEach(key => {
      this.displayedColumns[key] = this.schemaData[key];
    });
    this.textEmptySearch = Object.keys(this.displayedColumns).length === 0;
  }

  closeInputSearch() {
    this.queryString = "";
    this.filterColumns("");
  }

  massiveChange(status) {
    let displayed = Object.keys(this.displayedColumns);
    displayed.forEach(key => {
      this.displayedColumns[key].visible = status;
      this.schemaData[key].visible = status;
    });
    this.allSelected = status === true;
    this.allDeselected = status === false;
  }

  setMassiveSelectors() {
    let schemaDataArr = Object.values(this.schemaData);
    let visibleItems = schemaDataArr.filter(item => item["visible"] === true);
    this.allSelected = visibleItems.length === schemaDataArr.length;
    this.allDeselected = visibleItems.length === 0;
  }

  resetToDefault() {
    this.schemaData = Object.assign(this.schemaData, clone(this.defaultSchemaData))
    this.setMassiveSelectors()
  }
}