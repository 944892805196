import {
  Directive,
  ElementRef,
  Renderer2,
  AfterViewInit,
  HostListener,
  Input
} from '@angular/core';
import { roundedRect } from '../../util';

@Directive({
  selector: '[bdrDialogDraggable]'
})
export class DialogDraggableDirective implements AfterViewInit {
  @Input('bdrDialogDraggable') rulerCtx: CanvasRenderingContext2D;

  @Input() isContextualMenu?= false;
  @Input() draggable?= true;
  @Input() dialogSize: any = { width: 80, height: 60 };
  // dom de la ventana
  private parent: HTMLElement;
  // coordenadas de la ventana
  private x = 0;
  private y = 0;

  // ancho y alto de la ventana
  private w = 0;
  private h = 0;

  // coordenadas del placeholder de la ventana
  private cx = 0;
  private cy = 0;

  // diferencia entre la posición del puntero cuando empecé a mover y las coordenadas de la ventana
  private dx = 0;
  private dy = 0;

  // flag para saber si la intención es mover la ventana
  private readyToMove = false;

  // flag para saber si ya está oculto cuando lo empiezo a mover
  private alreadyHidden = false;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit(): void {
    this.dialogSize = this.dialogSize || { width: 80, height: 60 };
    if (!!this.draggable) {
      this.parent = this.isContextualMenu
        ? this.el.nativeElement
        : this.el.nativeElement.closest('bdr-dialog-wrapper');
      this.renderer.setStyle(this.parent, 'left', `-9999px`);
      setTimeout(
        function () {
          if (this.isContextualMenu) {
            this.x = this.parent.offsetLeft;
            this.y = this.parent.offsetTop;
          } else {
            this.renderer.setStyle(
              this.parent,
              'width',
              `${this.parent.closest('body').clientWidth *
              this.dialogSize.width /
              100}px`
            );
            this.renderer.setStyle(
              this.parent,
              'height',
              `${this.parent.closest('body').clientHeight *
              this.dialogSize.height /
              100}px`
            );
            this.x =
              (this.parent.closest('body').clientWidth -
                this.parent.clientWidth) /
              2;
            this.y =
              (this.parent.closest('body').clientHeight -
                this.parent.clientHeight) /
              2;
          }

          this.renderer.setStyle(this.parent, 'left', `${this.x}px`);
          this.renderer.setStyle(this.parent, 'top', `${this.y}px`);
          this.renderer.setStyle(
            this.parent,
            'width',
            `${this.parent.clientWidth}px`
          );
          this.renderer.setStyle(
            this.parent,
            'height',
            `${this.parent.clientHeight}px`
          );
        }.bind(this),
        0
      );
    }
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent): void {
    if (!!this.draggable) {
      this.readyToMove = true;

      const boundaries = this.parent.getBoundingClientRect();
      this.x = boundaries.left;
      this.y = boundaries.top;
      this.w = boundaries.width;
      this.h = boundaries.height;

      this.cx = event.clientX;
      this.cy = event.clientY;

      this.dx = this.x - this.cx;
      this.dy = this.y - this.cy;
    }
  }

  @HostListener('body:mousemove', ['$event'])
  onMouseMove(event: MouseEvent): boolean {
    if (!!this.draggable && this.readyToMove) {
      if (!this.alreadyHidden) {
        this.renderer.addClass(this.parent, 'hide');
        this.alreadyHidden = true;
      }

      this.cx = event.clientX;
      this.cy = event.clientY;

      this.drawRuler();
    }

    return !this.readyToMove;
  }

  @HostListener('body:mouseup', ['$event'])
  onMouseUp(event: MouseEvent): void {
    if (!!this.draggable && this.readyToMove) {
      this.clearRuler();
      if (this.isContextualMenu) {
        this.x = this.safeX(this.cx + this.dx) + this.w / 2;
      } else {
        this.x = this.safeX(this.cx + this.dx);
      }

      this.y = this.safeY(this.cy + this.dy);

      this.renderer.setStyle(this.parent, 'left', `${this.x}px`);
      this.renderer.setStyle(this.parent, 'top', `${this.y}px`);
      this.renderer.addClass(this.parent, 'hide');

      this.readyToMove = false;
      this.alreadyHidden = false;
    }
  }

  private clearRuler(): void {
    this.rulerCtx.clearRect(
      0,
      0,
      this.rulerCtx.canvas.width,
      this.rulerCtx.canvas.height
    );
  }

  private drawRuler(): void {
    this.clearRuler();

    const x = this.safeX(this.cx + this.dx) - 3;
    const y = this.safeY(this.cy + this.dy) - 3;
    const w = this.w + 6;
    const h = this.h + 6;

    this.rulerCtx.strokeStyle = '#000';
    this.rulerCtx.fillStyle = '#F5F5F5';
    this.rulerCtx.lineWidth = 3;
    roundedRect(this.rulerCtx, x, y, w, h, 5);
    this.rulerCtx.fill();
    this.rulerCtx.strokeStyle = '#fff';
    this.rulerCtx.lineWidth = 1;
    this.rulerCtx.rect(x + 2, y + 2, this.w - 4, this.h - 4);
    this.rulerCtx.stroke();

    let iconSize = 68;
    if (this.w <= iconSize) {
      iconSize = Math.floor(this.w * 0.75);
    }
    if (this.h <= iconSize) {
      iconSize = Math.floor(this.h * 0.75);
    }
    const icon = new Image(iconSize, iconSize);
    icon.src = '/assets/images/drag.svg';
    const iconX = Math.ceil(x + this.w / 2 - iconSize / 2);
    const iconY = Math.ceil(y + this.h / 2 - iconSize / 2);
    this.rulerCtx.drawImage(icon, iconX, iconY, iconSize, iconSize);
  }

  private safeX(x: number): number {
    return Math.round(
      Math.min(
        Math.max(x, 20),
        this.parent.closest('body').clientWidth - this.w - 20
      )
    );
  }

  private safeY(y: number): number {
    return Math.round(
      Math.min(
        Math.max(y, 20),
        this.parent.closest('body').clientHeight - this.h - 20
      )
    );
  }
}
