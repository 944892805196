import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from "@angular/core";
import {
  Zone,
  Delegation,
} from "../../shared/interfaces/validations.interface";

@Component({
  selector: "bdr-validations-zones",
  templateUrl: "./validations-zones.component.html",
  styleUrls: ["./validations-zones.component.scss"],
})
export class ValidationsZonesComponent implements OnChanges {
  @Input() zones: Zone[];
  @Output() selectedZones: EventEmitter<Zone[]> = new EventEmitter<Zone[]>();

  ngOnChanges() {
    if (this.zones) {
      this.zones = this.setCheckedZones(this.zones);
    }
  }

  private setCheckedZones(zones: Zone[]): Zone[] {
    return zones.map((zone) => ({
      ...zone,
      delegations: zone.delegations.map((delegations) => ({
        ...delegations,
        checked: false,
        ...{
          bases: delegations.bases.map((bases) => ({
            ...bases,
            checked: false,
          })),
        },
      })),
    }));
  }

  private toggleBases(delegation: Delegation) {
    const checkedDelegation = delegation.checked;
    delegation.bases.forEach((base) => (base.checked = checkedDelegation));
  }

  private toggleDelegation(delegation: Delegation) {
    const checkedSome = delegation.bases.some((base) => base.checked);
    if (checkedSome) {
      delegation.checked = true;
    } else {
      delegation.checked = false;
    }
  }

  private getDelegation(zoneName: string, delegationName: string): Delegation {
    const zone = this.zones.find((zone) => zone.name === zoneName);
    const delegation = zone.delegations.find(
      (delegation) => delegation.name === delegationName
    );

    return delegation;
  }

  private emitSelectedZones(): void {
    const zones = this.filteredCheckedZones();
    this.selectedZones.emit(zones);
  }

  private filteredCheckedZones(): Zone[] {
    const filter = this.zones
      .map((zone: Zone) => ({
        ...zone,
        delegations: zone.delegations
          .filter((delegation) => delegation.checked)
          .map((delegation) => ({
            ...delegation,
            bases: delegation.bases.filter((bases) => bases.checked),
          })),
      }))
      .filter((zone) => zone.delegations.length);

    return filter;
  }

  toggle(zoneName: string, delegationName: string, isDelegation = true): void {
    const delegation = this.getDelegation(zoneName, delegationName);

    if (isDelegation) {
      this.toggleBases(delegation);
    } else {
      this.toggleDelegation(delegation);
    }

    this.emitSelectedZones();
  }
}
