import {
  Component,
  ChangeDetectionStrategy,
  OnChanges,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { MASTER_AMENDMENT_TYPES } from '../../../../shared/interfaces/master-data.interface';
import { MastersService } from '../../../../services/masters/masters.service';
import { WorkClosureService } from '../../service/work-closure.service';
import { DataApiService } from '../../../../services/data-api/data-api.service';
import {
  NotificationsService,
  Notification,
} from '../../../../services/notifications/notifications.service';

const INVESTMENTS_SHEET_MTBT_AGGREGATE_MANUAL = 'sabana_inversiones_mtbt_agregada_manual';

@Component({
  selector: 'closure-distribution',
  templateUrl: './closure-distribution.component.html',
  styleUrls: ['./closure-distribution.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClosureDistributionComponent implements OnInit {
  @Input() distributions;
  @Input() canEdit: boolean;
  @Input() nonEditableMessage: string;
  @Input() workcode: string;
  @Output() onEditDistribution: EventEmitter<any> = new EventEmitter<any>();

  search: { search: string; filters: string[] };

  retributiveYear: string;

  isLoading: boolean = false;

  constructor(
    private mastersService: MastersService
  ) { }

  ngOnInit() {
    this.setRetributiveYear();
  }

  private setRetributiveYear() {
    this.mastersService.getRetributiveYear().subscribe((year) => (this.retributiveYear = year));
  }

  handleEditDistributions(row: any): void {
    this.onEditDistribution.emit({
      table: INVESTMENTS_SHEET_MTBT_AGGREGATE_MANUAL,
      amendment_type: MASTER_AMENDMENT_TYPES.UPSERT,
      primary_keys: {
        anio_contable: this.retributiveYear,
        codigo_obra: this.workcode,
      },
      new_values: { ...row },
    });
  }

  trackByFn(index) {
    return index;
  }
}
