import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";

@Component({
  selector: "dialog-retributive-year-form",
  templateUrl: "./dialog-retributive-year-form.component.html",
  styleUrls: ["./dialog-retributive-year-form.component.scss"]
})
export class DialogRetributiveYearFormComponent implements OnInit {
  formError = false;
  staticCurrentYear: string;
  @Input() selectedYear: string;
  @Input() yearList: string[];
  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  @Output() onSubmit: EventEmitter<any> = new EventEmitter();
  constructor() {

  }

  ngOnInit() {
    this.staticCurrentYear = this.selectedYear;
  }

  submit() {
    this.onSubmit.emit(this.selectedYear);
  }
  cancel() {
    this.onCancel.emit();
  }
}
