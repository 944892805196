import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MastersService } from "../services/masters/masters.service";
import { environment } from './../../environments/environment';


@Injectable()
export class YearParameterInterceptor implements HttpInterceptor {
  currentYear: string = "";
  constructor(private mastersService: MastersService) {
    this.mastersService.getRetributiveYear().subscribe(year => {
      this.currentYear = year;
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let newRequest = req.clone();
    if (req.url.match(environment.apiDomain)) {
      if (req.method === "POST" || req.method === "PUT") {
        req.body.year = this.currentYear;
        return next.handle(newRequest);
      }


      if (!req.url.match(/system\//gi) && !req.url.includes('year=')) { 
        let reqYear = parseInt(this.currentYear)
        if (req.url.includes('unload/masterdata/maestros_adelanto_inversiones')) { 
          reqYear = reqYear - 1
        } 
        newRequest = req.clone({
          setParams: {
            year: reqYear.toString()
          }
        });
      }
      return next.handle(newRequest);
    }
    return next.handle(req);
  }
}
