import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// Modules
import { ComponentsModule } from '../components/components.module';

// Components
import { CustomHeaderComponent } from './components/aggrid-table/custom-header/custom-header.component';
import { ConfirmDialogComponent } from './components/dialog/confirm-dialog/confirm-dialog.component';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';

// Pipes
import { FilterPipe } from './pipes/filter.pipe';

// Directives
import { ContextMenuDirective } from './directives/ag-grid/context-menu.directive';
import { ValidationsCellRenderer } from './components/aggrid-table/validations-cell-renderer/validations-cell-renderer.component';


const components = [
  FilterPipe,
  CustomHeaderComponent,
  ContextMenuDirective,
  ConfirmDialogComponent,
  ToggleButtonComponent,
  ValidationsCellRenderer
];

@NgModule({
  imports: [CommonModule, ComponentsModule],
  declarations: components,
  exports: [CommonModule, ...components],
})
export class SharedModule { }
