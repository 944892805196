import { LoadsReportComponent } from './pages/loads-report/loads-report.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { InvestmentsSheetComponent } from './pages/investments-sheet/investments-sheet.component';
import { InvestmentsAdjustmentSheetComponent } from './pages/investments-adjustment-sheet/investments-adjustment-sheet.component';
import { InventorySheetComponent } from './pages/inventory-sheet/inventory-sheet.component';
import { ContributionsSheetComponent } from './pages/contributions-sheet/contributions-sheet.component';
import { InvestmentsPlanComponent } from './pages/investments-plan/investments-plan.component';
import { LoggedGuard } from './guards/logged-guard';
import { ElaDetailComponent } from './pages/ela-detail/ela-detail.component';
import { LoginComponent } from './pages/login/login.component';
import { WorkClosureComponent } from './containers/work-closure/work-closure.component';
import { FormsComponent } from './pages/forms/forms.component';
import { InstallationsSheetComponent } from './pages/installations-sheet/installations-sheet.component';



export const routes = [
  {
    pathMatch: 'full',
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '',
    component: HomePageComponent,
  },
  {
    path: 'loads',
    component: LoadsReportComponent,
    canActivate: [LoggedGuard],
  },
  {
    path: 'investments-plan',
    component: InvestmentsPlanComponent,
    canActivate: [LoggedGuard],
  },
  {
    path: 'investments-adjustment-sheet',
    component: InvestmentsAdjustmentSheetComponent,
    canActivate: [LoggedGuard],
  },
  {
    path: 'investments-adjustment-sheet/:sheet',
    component: InvestmentsAdjustmentSheetComponent,
    canActivate: [LoggedGuard],
  },
  {
    path: 'investments-sheet',
    component: InvestmentsSheetComponent,
    canActivate: [LoggedGuard],
  },
  {
    path: 'investments-sheet/:sheet',
    component: InvestmentsSheetComponent,
    canActivate: [LoggedGuard],
  },
  {
    path: 'investments-sheet/at/ela-detail/:id',
    component: ElaDetailComponent,
    canActivate: [LoggedGuard],
  },
  {
    path: 'investments-sheet/reclassification/work-closure/:id',
    canActivate: [LoggedGuard],
    component: WorkClosureComponent,
  },
  {
    path: 'investments-sheet/mtbt/work-closure/:id',
    canActivate: [LoggedGuard],
    component: WorkClosureComponent,
  },
  {
    path: 'investments-sheet/dispatchers/work-closure/:id',
    canActivate: [LoggedGuard],
    component: WorkClosureComponent,
  },
  {
    path: 'investments-sheet/:sheet/:view/:date',
    component: InvestmentsSheetComponent,
    canActivate: [LoggedGuard]
  },
  {
    path: 'investments-sheet/:sheet/:view',
    component: InvestmentsSheetComponent,
    canActivate: [LoggedGuard]
  },
  {
    path: 'inventory-sheet',
    component: InventorySheetComponent,
    canActivate: [LoggedGuard],
  },
  {
    path: 'installations-sheet',
    component: InstallationsSheetComponent,
    canActivate: [LoggedGuard],
  },
  {
    path: 'forms',
    disabled: false,
    component: FormsComponent,
    canActivate: [LoggedGuard],
    meta: { caption: 'Formularios', icon: 'ico-formularios' },
  },
  {
    path: 'contributions-sheet',
    component: ContributionsSheetComponent,
    canActivate: [LoggedGuard],
  },

  {
    path: 'settings/master-data',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./master-data/master-data.module').then(m => m.MasterDataModule),
  },
  { path: '**', redirectTo: '/' },
];