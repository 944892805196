import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';

@Component({
  selector: 'bdr-checkbox',
  templateUrl: './bdr-checkbox.component.html',
  styleUrls: ['./bdr-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BdrCheckboxComponent {
  @Input() model: boolean;
  @Input() name: string;
  @Input() caption: string;
  @Input() size: string;
  @Input() disabled: boolean;
  @Input() error: boolean;
  @Input() errorMsg: string;

  @Output() modelChange = new EventEmitter<boolean>();

  constructor() {
    this.model = false;
    this.name = '';
    this.caption = '';
    this.size = 'small';
    this.disabled = false;
    this.error = false;
    this.errorMsg = '';
  }

  emitChange(value: boolean): void {
    if (!this.disabled) {
      this.modelChange.emit(value);
    }
  }
}
