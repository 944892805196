import {
  Component,
  OnChanges,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { GridExportData } from '../../../../services/grid/grid.service';
import { DownloadsService } from '../../../../services/downloads/downloads.service';
import { FilterDB } from '../../../../services/filter/filter.service';
import { MastersService } from '../../../../services/masters/masters.service';
import { DialogService } from '../../../dialog/dialog.service';
import { DialogSplitAppointmentComponent } from '../../../dialog/dialog-split-appointment/dialog-split-appointment.component';
import { WorkClosureService } from '../../service/work-closure.service';
import { DialogActionsComponent } from '../../../dialog/dialog-actions/dialog-actions.component';
import { DataApiService } from '../../../../services/data-api/data-api.service';
import {
  MASTER_AMENDMENT_TYPES,
  MasterPayloadItem,
} from '../../../../shared/interfaces/master-data.interface';
import { cloneDeep } from 'lodash';
import { NotificationsService } from '../../../../services/notifications/notifications.service';
import { BdrTableComponent } from '../../../../components/bdr-table/bdr-table.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogRevertReclassificationsComponent } from '../../../dialog/dialog-revert-reclassifications.component.ts/dialog-revert-reclassifications.component';

const INVESTMENT_CLASSIFICATION = 'clasificacion_inversion_recl';
const AMENDMENT_TABLE = 'sap_incurridos_manual_registro';

@Component({
  selector: 'closure-sap',
  templateUrl: './closure-sap.component.html',
  styleUrls: ['./closure-sap.component.scss'],
})
export class ClosureSapComponent implements OnChanges, OnInit {
  @ViewChild('bdrTable', { static: false }) bdrTable: BdrTableComponent;
  @Input() items;
  @Input() workcode: string;
  @Input() isEditable: boolean;

  private retributiveYear;
  private amendmentsInvestmentClassification: Map<string, MasterPayloadItem> = new Map();
  sap = { rows: [], headers: [] };
  search: { search: string; filters: string[] }; // Binding en vista del componente bdr-input-filter
  selectedRows = [];


  isLoading: boolean = false;
  splashMessage: string;

  constructor(
    private downloadsService: DownloadsService,
    private mastersService: MastersService,
    private dialogService: DialogService,
    private workClosureService: WorkClosureService,
    private dataApiService: DataApiService,
    private notificationService: NotificationsService,
    public matDialog: MatDialog
  ) { }

  ngOnChanges() {
    if (this.items) {
      this.initSapTable();

      this.mastersService.getRetributiveYear().subscribe((year) => {
        this.retributiveYear = year;
      });
    }
  }

  ngOnInit() {
  }

  private editableFields(header): { type: 'select' | 'input'; options?: string[] } {
    let editable = { type: header.type };

    if (header.type === 'select') {
      editable['options'] = header.options;
    }

    return editable;
  }

  initSapTable() {
    // Headers
    this.sap.headers = this.items.headers.map((header) => ({
      name: header.field,
      label: header.title,
      ...this.editableFields(header),
    }));

    // Rows
    this.sap.rows = cloneDeep(this.items.rows);
  }

  download() {
    const exportItem: GridExportData = {
      origin: 'sabana_inversiones_cierre_obra_sap_vista',
      filterOrigin: 'sabana_inversiones_cierre_obra_sap_vista',
      filterable: true,
      filename: 'Sábana inversiones Cierre obra SAP',
      title: 'Sábana inversiones Cierre obra SAP',
    };

    const headerNames = this.sap.headers.map((item) => item.name);

    this.downloadsService.asyncDownload(
      exportItem,
      true,
      [this.getFilters()],
      headerNames,
      headerNames
    );
  }

  getFilters() {
    let mainFilter = new FilterDB();
    mainFilter.meta = { type: 'filter' };
    mainFilter.table = 'sabana_inversiones_mtbt_detalle_vista';
    mainFilter.filter = {
      condition: 'AND',
      rules: [
        {
          operator: 'equal',
          id: 'codigo-obra',
          field: 'codigo_obra',
          type: 'filter',
          value: this.workcode,
        },
        {
          operator: 'equal',
          id: 'anio-retributivo',
          field: 'anio_retributivo',
          type: 'filter',
          value: this.retributiveYear,
        },
      ],
    };
    return mainFilter;
  }

  handleSelectedRows(rows: any): void {
    this.selectedRows = rows;
  }


  showSplitAppointmentDialog() {
    this.matDialog.open(DialogSplitAppointmentComponent, {
      data: {
        table: '',
        selectedRows: this.selectedRows
      },
    });
    this.dialogService.closeDialog('actions');
  }


  showRevertReclassificationsDialog() {
    this.matDialog.open(DialogRevertReclassificationsComponent, {
      data: {
        table: '',
        selectedRows: this.selectedRows
      },
    });
    this.dialogService.closeDialog('actions');
  }

  showSelectedActions(id: string) {
    const actions = [];

    actions.push(
      {
        title: 'Editar apuntes',
        icon: 'ico-copy',
        action: this.showSplitAppointmentDialog.bind(this),
      }
    );

    if (this.selectedRows.some(row => row.tipo_reclasificacion)) {
      actions.push(
        {
          title: 'Revertir reclasificaciones',
          icon: 'ico-copy',
          action: this.showRevertReclassificationsDialog.bind(this),
        }
      );
    }

    const dialogOptions: any = {
      component: DialogActionsComponent,
      title: 'Acciones',
      fixed: true,
      size: {
        width: 40,
        height: 40,
      },
      bindings: {
        inputs: {
          actions,
          id: 'actions',
          type: 'actions',
        },
      },
    };

    if (!id || typeof id === 'object') {
      return this.dialogService.createDialog(dialogOptions);
    }
    this.dialogService.updateDialog(id, dialogOptions);
  }


  changeSelectedRows(event) {
    const valueChanged = event[0][INVESTMENT_CLASSIFICATION];
    for (let item of this.selectedRows) {
      this.setAmendment(item, valueChanged);
    }
  }

  saveChanges(isDialog: boolean = false) {
    this.dialogService.clearDialogs();

    const amendments = Array.from(this.amendmentsInvestmentClassification.values());
    if (amendments.length > 0) {
      this.isLoading = true;
      this.splashMessage = 'Guardando cambios realizados';
      this.dataApiService.putAmendments(amendments).subscribe(
        (res) => {
          this.isLoading = false;
          if (isDialog) {
            this.applyAmendmentsInTable();
          }
          this.notificationService.add({
            type: 'confirmation',
            message: 'Modificaciones guardadas con éxito.',
            fixed: false,
            popup: true,
          });
          this.amendmentsInvestmentClassification = new Map();
          this.bdrTable.clearSelection();
        },
        (err) => {
          this.notificationService.add({
            type: 'error',
            message: 'Ha ocurrido un error guardando las modificaciones.',
            fixed: false,
            popup: true,
          });
          throw new Error(`Ha ocurrido un error guardando las modificaciones: ${err.message}.`);
        }
      );
    }
  }

  private applyAmendmentsInTable() {
    const amendments = Array.from(this.amendmentsInvestmentClassification.values());
    for (let amendment of amendments) {
      const row = this.sap.rows.find((row) => row.id === amendment.primary_keys.id);
      row.clasificacion_inversion_recl = amendment.new_values.clasificacion_inversion_recl;
    }
  }

  private setAmendment(item, selectedValueRows?): void {
    const id = item.id;
    const anio_contable = item.anio_retributivo;
    const value = selectedValueRows ? selectedValueRows : item[INVESTMENT_CLASSIFICATION];

    const amendment = {
      table: AMENDMENT_TABLE,
      amendment_type: MASTER_AMENDMENT_TYPES.UPSERT,
      primary_keys: {
        id,
        anio_contable,
      },
      new_values: {
        [INVESTMENT_CLASSIFICATION]: value,
      },
    };

    this.amendmentsInvestmentClassification.set(id, amendment);
  }

  get canSave() {
    return this.isEditable && this.amendmentsInvestmentClassification.size > 0;
  }
}
