import {
  Component,
  OnChanges,
  OnDestroy,
  ViewChild,
  Input,
  ViewContainerRef,
  ComponentFactoryResolver,
  ComponentRef,
  ChangeDetectionStrategy
} from '@angular/core';
import { ComponentBindings } from './../../models/common';

@Component({
  selector: 'bdr-reflect-component',
  template: '<ng-container #placeholder></ng-container>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BdrReflectComponent implements OnChanges, OnDestroy {
  @ViewChild('placeholder', { read: ViewContainerRef, static: true })
  placeholder: ViewContainerRef;
  subscriptions: any[] = [];
  @Input() component: Component;
  @Input() bindings: ComponentBindings;

  private reflection: ComponentRef<any>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
    this.bindings = { inputs: {}, outputs: {} };
  }

  ngOnChanges() {
    if (this.component) {
      this.placeholder.clear();
      this.reflection = this.createComponent(this.placeholder, this.component);

      if (this.bindings.hasOwnProperty('inputs')) {
        Object.keys(this.bindings.inputs).forEach(
          prop => (this.reflection.instance[prop] = this.bindings.inputs[prop])
        );
      }
      if (this.bindings.hasOwnProperty('outputs')) {
        Object.keys(this.bindings.outputs).forEach(prop => {
          if (this.reflection.instance[prop]) {
            this.subscriptions.push(
              this.reflection.instance[prop].subscribe(
                this.bindings.outputs[prop]
              )
            );
          }
        });
      }
      this.placeholder.insert(this.reflection.hostView);
      if (this.reflection.instance.hasOwnProperty('__ngOnChangesBypass')) {
        this.reflection.instance.__ngOnChangesBypass();
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subs => {
      if (subs.unsubscribe) {
        subs.unsubscribe();
      }
    });
  }

  callFunction(fn: string, ...args): any {
    if (
      this.reflection.instance[fn] &&
      typeof this.reflection.instance[fn] === 'function'
    ) {
      return this.reflection.instance[fn](...args);
    }
  }

  private createComponent(
    vessel: ViewContainerRef,
    component: any
  ): ComponentRef<any> {
    const factory = this.componentFactoryResolver.resolveComponentFactory(
      component
    );
    return vessel.createComponent(factory);
  }
}
