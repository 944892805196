import { Component } from '@angular/core';
import { GridProperties, GridService } from '../../services/grid/grid.service';
import { OriginService } from '../../services/origin/origin.service';
import { SchemaService } from '../../services/schema/schema.service';
import { Subscription } from 'rxjs';
import { TasksService } from '../../services/tasks/tasks.service';

@Component({
  selector: 'installations-sheet',
  templateUrl: './installations-sheet.component.html',
  styleUrls: ['./installations-sheet.component.scss']
})
export class InstallationsSheetComponent {
  currentOrigin: any = {};
  gridProperties: GridProperties;
  panels: any;
  editData: any[] = [];
  savingFlag: any;
  editingRows: Set<number> = new Set();
  editMode = false;
  installationsOrigins: any;
  subheaderSelector = [];
  saveGridSubscription: Subscription;


  constructor(
    private gridService: GridService,
    private originService: OriginService,
    private schemaService: SchemaService,
    private tasksService: TasksService
  ) {
    this.installationsOrigins = this.originService.getByKey('installations');
    this.subheaderSelector = [
      {
        title: 'Instalaciones',
        key: 'instalaciones',
        changeEvent: this.changeOrigin.bind(this),
        selectData: this.installationsOrigins.instalaciones.map(item => {
          return { name: item.title, value: item.origin };
        }),
      },
      {
        title: 'Desagrupado',
        key: 'desagrupado',
        changeEvent: this.changeOrigin.bind(this),
        selectData: this.installationsOrigins.desagrupado.map(item => {
          return { name: item.title, value: item.origin };
        })
      },
      {
        title: 'Inspección',
        key: 'inspeccion',
        changeEvent: this.changeOrigin.bind(this),
        selectData: this.installationsOrigins.inspeccion.map(item => {
          return { name: item.title, value: item.origin };
        })
      },
      {
        title: 'Comunicaciones',
        key: 'comunicaciones',
        changeEvent: this.changeOrigin.bind(this),
        selectData: this.installationsOrigins.comunicaciones.map(item => {
          return { name: item.title, value: item.origin };
        })
      },
      {
        title: 'Cuadro Resumen',
        key: 'cuadro_resumen',
        changeEvent: this.changeOrigin.bind(this),
        selectData: this.installationsOrigins.cuadro_resumen.map(item => {
          return { name: item.title, value: item.origin };
        })
      },
      {
        title: 'Despachos',
        key: 'despachos',
        changeEvent: this.changeOrigin.bind(this),
        selectData: this.installationsOrigins.despachos.map(item => {
          return { name: item.title, value: item.origin };
        }),
        hideSelector: true
      },
      {
        title: 'Adelanto de inversiones',
        key: 'maestros_adelanto_inversiones',
        changeEvent: this.changeOrigin.bind(this),
        selectData: this.installationsOrigins.expedientes_auditados.map(item => {
          return { name: item.title, value: item.origin };
        }),
        hideSelector: true
      },
      {
        title: 'Listado SREs',
        key: 'listado_sres',
        changeEvent: this.changeOrigin.bind(this),
        selectData: this.installationsOrigins.listado_sres.map(item => {
          return { name: item.title, value: item.origin };
        }),
        hideSelector: true
      },
    ];

    this.gridProperties = this.gridService.getDefaultGridProperties();
    this.gridProperties.globalEditMode = false;
    this.gridProperties.bordered = true;
    this.gridProperties.edit = false;
    this.gridProperties.reload = false;
    this.gridProperties.headerActions = [];
    this.gridProperties.columnSelector = true;
    this.gridProperties.editDblClick = false;
    this.gridProperties.generate = false;
    this.gridProperties.showSelectBox = true;
    this.gridProperties.selectable = true;
    this.gridProperties.columnSearch = true;
    this.gridProperties.editingRows = [];
    this.gridProperties.currentRequestParams = {};
    this.gridProperties.schemaScope = 'inventory';

    this.panels = {
      downloads: { disabled: false },
      fastFilter: { disabled: true },
      filters: { disabled: true },
      visualization: { disabled: false },
      validations: false,
      manualValidations: false,
      refreshSheet: { disabled: false },
    };
  }

  changeOrigin(evt) {
    if (['despachos', 'listado_sres', 'expedientes_auditados'].includes(evt.key)) {
      this.currentOrigin = this.installationsOrigins[evt.key][0]
      evt.value = this.currentOrigin.origin
    } else {
      this.currentOrigin = this.installationsOrigins[evt.key].find(item => item.origin === evt.value) || {};
    }
    
    // Force empty grid
    let newGridProperties = Object.assign({}, this.gridProperties);
    newGridProperties.filterOrigin = '';
    newGridProperties.origin = '';
    newGridProperties.reload = false;

    let origin = evt.value
    newGridProperties.defaultSchemaVisibility = {}
    newGridProperties.additionalSchemaData = this.gridService.resetSchema(
      this.gridProperties.schemaScope,
      evt.value,
      null,
      this.schemaService.getDefaultSchema(origin)
    );
    newGridProperties.filterOrigin = origin;
    newGridProperties.origin = origin;
    newGridProperties.exportData = [
      this.gridService.createComponentExportData(evt, this.currentOrigin.title, true)
    ];
    setTimeout(() => {
      newGridProperties.filterOrigin = evt.value;
      newGridProperties.origin = evt.value;
      this.gridProperties = Object.assign({}, newGridProperties);
    }, 200);
  }

  generateSheet(action) {
    this.tasksService.reloadSheet(action);
  }

}
