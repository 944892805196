import {
  Component,  OnInit,
  EventEmitter,
  Output,
  Input,
  OnChanges,
  ChangeDetectionStrategy
} from '@angular/core';

import { GridExportData } from '../../services/grid/grid.service';
import { DownloadsService } from '../../services/downloads/downloads.service';
import { TasksService } from '../../services/tasks/tasks.service';

@Component({
  selector: 'bdr-logs-cnmc-options',
  templateUrl: './bdr-logs-cnmc-options.component.html',
  styleUrls: ['./bdr-logs-cnmc-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BdrLogsCNMCOptionsComponent implements OnChanges {
  @Input() appliedFilters: any = [];
  @Input() visibleColumns = [];
  @Input() allColumns = [];
  @Input() exportData: GridExportData[];
  @Output() close: EventEmitter<Function> = new EventEmitter<Function>();

  isFilter: boolean = false;

  constructor(
    private downloadsService: DownloadsService,    
    private tasksService: TasksService,
  ) { }

  ngOnChanges() { 
    this.isFilter = this.appliedFilters.length > 0; 
  }

  closePanel() {
    this.close.emit();
  }

  exportLogsCNMC() {
    this.downloadsService.downloadCNMCLogs();
  }

  exportCNMCFormLogs() {
    this.downloadsService.downloadCNMCFormLogs(this.exportData[0].origin);
  }

  exportCNMCFormGroupedLogs() {
    this.downloadsService.downloadCNMCFormGroupedLogs(this.exportData[0].origin);
  }

  reloadCNMCValidations() {
    this.tasksService.reloadSheet('cnmc-validations');
  }

  reloadCNMCErrorLogs() {
    this.tasksService.reloadSheet('cnmc-logs');
  }

  groupedForms() {
    return [
      'fb1at_vista',
      'fb1bt_vista',
      'fb1mt_vista',

      'fb1_1at_vista',
      'fb1_1bt_vista',
      'fb1_1mt_vista',

      'fb7atmt_vista',
      'fb7bt_vista'
    ].includes(this.exportData[0].origin);
  }
}
