import { Injectable, EventEmitter } from '@angular/core';

import { FilterDB, FilterService } from './../filter/filter.service';
import { Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class InvestmentFilterService {
  public filter: any;
  public appliedFilters: any = {};
  public appliedFiltersSubject: Subject<any> = new Subject();

  constructor(
    private filterService: FilterService,
    private router: Router
  ) { }

  getAppliedFilters() {
    return this.appliedFiltersSubject.asObservable().pipe(startWith(this.appliedFilters));
  }

  getQueryFormat() {
    if (this.filter) {
      return this.filter.filter;
    }
    return null;
  }

  getFilterByOrigin(origin) {
    return this.appliedFilters[origin] || [];
  }

  setAppliedFilters(origin, appliedFilters) {
    this.appliedFilters[origin] = appliedFilters;
    this.appliedFiltersSubject.next(this.appliedFilters);
  }

  setFilter(filter) {
    this.filter = filter;
  }

  isFiltering() {
    if (this.filter !== null && this.filter !== undefined) {
      return Object.keys(this.filter).length > 0;
    }
    return false;
  }

  dropFilter(key) {
    if (this.filter['filter']['rules'] !== undefined && this.filter['filter']['rules'] !== null) {
      for (const filterObj of this.filter['filter']['rules']) {
        if (filterObj['rules'][0]['field'] === key) {
          const idx = this.filter['filter']['rules'].findIndex(item => item === filterObj);
          this.filter['filter']['rules'].splice(idx, 1);
        }
      }
    }
  }

  getFilterReadable() {
    const readable = [];
    this.filter['filter']['rules'].forEach(element => {
      let str, key
      if (element['rules']) {
        str = this.fieldReadable(element['rules'][0]['field']) + ' = ' + element['rules'][0]['value']
        key = element['rules'][0]['field']
      } else {
        str = this.fieldReadable(element['field']) + ' = ' + element['value'];
        key = element['field']
      }
      readable.push({ title: str, key });
    });
    return readable;
  }

  fieldReadable(field) {
    const str = field.replace('_', ' ');
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  reset() {
    this.filter = {};
  }

  resetFilter(origin: string, filter: FilterDB) {
    if (!this.appliedFilters[origin]) {
      this.appliedFilters[origin] = [];
    }

    const filterIndex = this.appliedFilters[origin].findIndex(item => {
      return item.id === filter.id;
    });

    if (filterIndex !== -1) {
      this.appliedFilters[origin].splice(filterIndex, 1);
    }

    this.filter = this.filterService.formatFilter(this.appliedFilters[origin]);
    this.appliedFiltersSubject.next(this.appliedFilters);
  }


  filterAtDetailWithGroupedFilters() {
    this.filterDetailWithFilters(
      'sabana_inversiones_at_traspaso_agregado_vista',
      'sabana_inversiones_at_detalle_traspaso_vista',
      'at'
    );
  }

  filterAtDetailWithELAGroupedFilters() {
    this.filterDetailWithFilters(
      'sabana_inversiones_at_traspaso_agregado_ela_vista',
      'sabana_inversiones_at_detalle_traspaso_vista',
      'at'
    );
  }


  filterMtbtDetailWithWorksFilters() {
    this.filterDetailWithFilters(
      'sabana_inversiones_mtbt_agregada_vista',
      'sabana_inversiones_mtbt_detalle_vista',
      'mtbt'
    );
  }

  filterDispatchersDetailWithWorksFilters() {
    this.filterDetailWithFilters(
      'sabana_inversiones_despachos_agregado_obra_vista',
      'sabana_inversiones_despachos_detalle_vista',
      'dispatchers'
    );
  }

  private filterDetailWithFilters(origin, destiny, sheet) {
    let filters = this.getFilterByOrigin(origin);

    filters.forEach(filter => {
      filter.filter.rules.forEach(rule => this.setRuleOrigin(rule, origin));
    });
    this.setAppliedFilters(destiny, filters);
    this.router.navigate([
      `./investments-sheet/${sheet}/${destiny}/${new Date()
        .getTime()
        .toString()}/`,
    ]);
  }

  private setRuleOrigin(rule, origin) {
    if (rule.rules) {
      rule.rules.forEach(r => this.setRuleOrigin(r, origin));
    } else {
      rule.origin = origin;
    }
  }


  filterGroupedAtDetail(selectedRows) {
    let filterMessages = [];
    let rules = selectedRows.map((selectedRow) => {
      let filter = {
        condition: 'AND',
        rules: [
          {
            operator: 'equal',
            field: 'anio_contable',
            value: selectedRow.anio_contable,
          },
          {
            operator: 'equal',
            field: 'anio_retributivo',
            value: selectedRow.anio_retributivo,
          },
          {
            operator: selectedRow.ela ? 'equal' : 'is_null',
            field: 'ela',
            value: selectedRow.ela,
          },
          {
            operator: selectedRow.ela_final ? 'equal' : 'is_null',
            field: 'ela_final',
            value: selectedRow.ela_final,
          },
          {
            operator: selectedRow.mei ? 'equal' : 'is_null',
            field: 'mei',
            value: selectedRow.mei,
          },
          {
            operator: selectedRow.mei_final ? 'equal' : 'is_null',
            field: 'mei_final',
            value: selectedRow.mei_final,
          },
          {
            operator: selectedRow.clasificacion ? 'equal' : 'is_null',
            field: 'clasificacion',
            value: selectedRow.clasificacion,
          },
          {
            operator: selectedRow.clasificacion_final ? 'equal' : 'is_null',
            field: 'clasificacion_final',
            value: selectedRow.clasificacion_final,
          },
          {
            operator: selectedRow.cod_actuacion ? 'equal' : 'is_null',
            field: 'cod_actuacion',
            value: selectedRow.cod_actuacion,
          },
          {
            operator: selectedRow.cod_actuacion_final ? 'equal' : 'is_null',
            field: 'cod_actuacion_final',
            value: selectedRow.cod_actuacion_final,
          },
          {
            operator: 'equal',
            field: 'ot',
            value: selectedRow.ot,
          },
          {
            operator: 'equal',
            field: 'clase_coste',
            value: selectedRow.clase_coste,
          },
          {
            operator: 'equal',
            field: 'tipo_apunte',
            value: selectedRow.tipo_apunte,
          }
        ],
      };
      filterMessages.push(
        `(${filter['rules'].map((rule) => `${rule.field}=${rule.value}`).join(' AND ')})`
      );
      return filter;
    });
    let msg = filterMessages.join(' OR ');

    this.filterAtDetail(rules, msg);
  }

  filterGroupedByELAAtFinal(selectedRows) {
    let elas = [];
    let rules = selectedRows.map((selectedRow) => {
      let finalEla = selectedRow.ela_final;
      elas.push(finalEla);
      return {
        operator: 'equal',
        field: 'ela_final',
        value: finalEla,
      };
    });
    this.filterAtAggregateTransfer(rules, `ela_final=${elas.join(' OR ')}`);
  }

  // 311558 232709
  private filterAtAggregateTransfer(rules: any[], msg: string) {
    let filters = [
      new FilterDB({
        filter: {
          condition: 'OR',
          rules: rules,
        },
        meta: { type: 'filter' },
        name: `Agregado ELA: ${msg}`,
        id: 'grouped_at_aggregate_transfer_filter',
      }),
    ];

    this.setAppliedFilters(
      'sabana_inversiones_at_traspaso_agregado_vista',
      filters
    );

    this.router.navigate([
      `./investments-sheet/at/sabana_inversiones_at_traspaso_agregado_vista/${new Date()
        .getTime()
        .toString()}/`,
    ]);
  }

  filterGroupedByELAAtDetail(selectedRows) {
    let elas = [];
    let rules = selectedRows.map((selectedRow) => {
      let finalEla = selectedRow.ela_final;
      elas.push(finalEla);
      return {
        operator: 'equal',
        field: 'ela_final',
        value: finalEla,
      };
    });
    this.filterAtDetail(rules, `ela_final=${elas.join(' OR ')}`);
  }

  private filterAtDetail(rules: any[], msg: string) {
    let filters = [
      new FilterDB({
        filter: {
          condition: 'OR',
          rules: rules,
        },
        meta: { type: 'filter' },
        name: `Detalle Incurridos: ${msg}`,
        id: 'grouped_at_detail_filter',
      }),
    ];

    this.setAppliedFilters(
      'sabana_inversiones_at_detalle_traspaso_vista',
      filters
    );

    this.router.navigate([
      `./investments-sheet/at/sabana_inversiones_at_detalle_traspaso_vista/${new Date()
        .getTime()
        .toString()}/`,
    ]);
  }


  filterForms(form) {
    let filters = [
      new FilterDB({
        filter: {
          condition: 'AND',
          rules: [
            {
              operator: 'equal',
              field: 'formulario',
              value: form,
            }
          ],
        },
        meta: { type: 'filter' },
        name: form,
        id: 'form_alert',
      }),
    ];

    this.setAppliedFilters(
      'sabana_formularios_auditoria_importes_ajustados_vista',
      filters
    );
  }

}
