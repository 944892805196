import { Injectable } from '@angular/core';
import { Subject, Observable, throwError } from 'rxjs';

import { AuthService } from './../auth/auth.service';
import { columnTitleFormatter, ORIGINS } from './../../util';
import { DataApiService } from '../data-api/data-api.service';
import { startWith, catchError } from 'rxjs/operators';
import { OriginService } from '../origin/origin.service';
import { DEFAULT_VIEWS } from '../../../environments/common/default-views';

export const NUMERIC_TYPES = ['bigint', 'smallint', 'integer', 'numeric', 'number', 'decimal'];

@Injectable()
export class SchemaService {
  private filterSchemas: {
    [origin: string]: { [origin: string]: { caption: string; items: Schema[] } };
  } = {};
  private filterSchemasSubject: Subject<{
    [origin: string]: { [origin: string]: { caption: string; items: Schema[] } };
  }> = new Subject();
  private schemas: { [origin: string]: Schema[] };
  private schemasSubject: { [origin: string]: Subject<Schema[]> };
  private callBackend: { [origin: string]: boolean };
  private permissionsMap: { [origin: string]: string };

  constructor(
    private api: DataApiService,
    private auth: AuthService,
    private originService: OriginService
  ) {
    this.reset();
  }

  public get(origin: string): Observable<Schema[]> {
    // const originStr = origin.replace(/_vista/gi, '');
    const originStr = origin;
    if (!this.schemasSubject[originStr]) {
      this.schemasSubject[originStr] = new Subject<Schema[]>();
      this.permissionsMap[originStr] = 'grid';
    }
    if (
      (!this.callBackend[originStr] &&
        !this.schemas[originStr]) ||
      !!this.schemas[originStr] && this.schemas[originStr].length === 0
    ) {
      this.callBackend[originStr] = true;
      this.api
        .schema(originStr)
        .pipe(
          catchError(error => {
            this.callBackend[originStr] = false;
            this.schemasSubject[originStr].error(error);
            return throwError(error);
          })
        )
        .subscribe((response: any) => {
          const result =
            response && response.result && Array.isArray(response.result)
              ? response.result || []
              : response || [];

          this.schemas[origin] = this.processRawSchema(result);
          this.saveLocalSchemas();
          this.schemasSubject[origin].next(this.schemas[origin]);
          if (!this.filterSchemas[origin]) {
            this.filterSchemas[origin] = {};
          }
          this.updateFilterSchemas();
        });
    }
    return this.schemasSubject[originStr].asObservable().pipe(startWith(this.schemas[originStr]));
  }

  updateFilterSchemas() {
    let filterOriginsObj = this.originService.getFilterOrigins();
    let filterOrigins = Object.keys(filterOriginsObj);
    filterOrigins.forEach(origin => {
      filterOriginsObj[origin].forEach(filterOrigin => {
        if (!this.filterSchemas[origin]) {
          this.filterSchemas[origin] = {};
        }
        if (
          !this.filterSchemas[origin][filterOrigin] ||
          !this.filterSchemas[origin][filterOrigin].items ||
          this.filterSchemas[origin][filterOrigin].items.length === 0
        ) {
          let originObj = this.originService.getByOrigin(filterOrigin);
          this.filterSchemas[origin][filterOrigin] = {
            caption: originObj.title,
            items: this.schemas[filterOrigin]
          };
        }
      });
    });
    let originKeys = Object.keys(this.schemas).filter(
      item => filterOrigins.includes(item) === false
    );
    originKeys.forEach(origin => {
      let originObj = this.originService.getByOrigin(origin);
      if (originObj) {
        if (!this.filterSchemas[origin]) {
          this.filterSchemas[origin] = {};
        }
        this.filterSchemas[origin][origin] = {
          caption: originObj.title,
          items: this.schemas[origin]
        };
      }
    });
    this.filterSchemasSubject.next(this.filterSchemas);
  }

  processRawSchema(items) {
    return items.map(item => {
      return this.processRawSchemaItem(item);
    });
  }

  processRawSchemaItem(item): Schema {
    let type = this.getItemType(item.type);

    return {
      ...item,
      field: item.column,
      group: item.headerGroup,
      title: item.title || columnTitleFormatter(item.column),
      type,
      numericFormat: this.isNumeric(type) && !this.isNumericExclude(item),
      minFractionDigits: item.maxFractionDigits || 0,
      maxFractionDigits: item.maxFractionDigits || 2
    };
  }

  isNumeric(type): boolean {
    return type.match(/numeric/gi) !== null || type.match(/number/gi) !== null;
  }

  isNumericExclude(item) {
    return item.column.match(/cod/gi) !== null || item.column.match(/fecha/gi) !== null
      || item.column.match(/anio_pta/gi) !== null
      || item.column.match(/situa/gi) !== null
      || item.column.match(/posicion/gi) !== null;
  }

  getItemType(type): string {
    if (NUMERIC_TYPES.includes(type) || type.match(/numeric/gi) !== null) {
      return 'number';
    }
    if (
      type.match(/character var/gi) !== null ||
      type.match(/varchar/gi) !== null ||
      type.match(/char/gi) !== null ||
      type.match(/text/gi) !== null ||
      type.match(/string/gi) !== null
    ) {
      return 'string';
    }
    return type;
  }

  getFilterSchemas() {
    return this.filterSchemasSubject.asObservable().pipe(startWith(this.filterSchemas));
  }
  getLocalSchemas() {
    let schemas = JSON.parse(localStorage.getItem('BDR_SCHEMAS')) || {};
    let schemaSaveDate = localStorage.getItem('BDR_SCHEMAS_SAVE_DATE');
    if (!!schemaSaveDate) {
      let saveDate = new Date(schemaSaveDate).valueOf();
      if (new Date().valueOf() - saveDate > this.getCacheExpiration()) {
        //reset schemas after 5 days
        schemas = {};
      }
    }
    return schemas;
  }

  getCacheExpiration(): number {
    return 60 * 60 * 1000;
    // return 5 * 24 * 60 * 60 * 1000
  }

  saveLocalSchemas() {
    let saveDate = new Date().toISOString();
    localStorage.setItem('BDR_SCHEMAS', JSON.stringify(this.schemas));
    localStorage.setItem('BDR_SCHEMAS_SAVE_DATE', saveDate);
  }
  fetchSchemas() {
    this.schemas = this.getLocalSchemas();
    this.updateFilterSchemas();
    let filterOriginsObj = this.originService.getFilterOrigins();
    Object.keys(this.schemas).forEach(schema => {
      this.callBackend[schema] = true;
    });

    this.originService.getAllOrigins().forEach(item => {
      this.get(item.origin);
    });
    Object.keys(filterOriginsObj).forEach(item => {
      filterOriginsObj[item].forEach(filterOrigin => {
        this.get(filterOrigin);
      });
    });
  }
  reset(): void {
    this.schemas = {};
    this.schemasSubject = {};
    this.callBackend = {};
    this.permissionsMap = {};
    this.filterSchemas = {};
    this.schemas = {};
  }
  //reset schema

  getDefaultSchema(origin: string) {
    return DEFAULT_VIEWS[origin];
  }
}

export class Schema {
  adjusted?: boolean;
  field: string;
  title: string;
  headerGroup?: string;
  type: string;
  calculated?: boolean;
  pk?: boolean;
  fastSearch?: boolean;
  fixed?: boolean;
  link?: boolean;
  linkUrlBase?: string;
  order?: number;
  numericFormat?: boolean;
  minFractionDigits?: number;
  maxFractionDigits?: number;
  groupDecimals?: boolean;
  maxLength?: number;
  maxValue?: number;
  minValue?: number;
  visible?: boolean;
  editable?: boolean;
  groupedKey?: boolean;
  fieldType?: string;
  separator?: string;
  dependentOptionValues?: any;
  optionValues?: any;
  value?: any;
}
