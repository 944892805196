import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';

@Component({
  selector: 'dialog-actions',
  templateUrl: './dialog-actions.component.html',
  styleUrls: ['./dialog-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogActionsComponent {
  @Input() id;
  @Input() actions;
  @Output() closeDialog: EventEmitter<string> = new EventEmitter<string>();
  constructor() { }
}
