import { Component, OnInit, Input } from '@angular/core';
import { SheetState } from '../../../models/common';

import { GridProperties } from '../../../services/grid/grid.service';
import { Schema } from '../../../services/schema/schema.service';

@Component({
  selector: 'dialog-state-error',
  templateUrl: './dialog-state-error.component.html',
  styleUrls: ['./dialog-state-error.component.scss']
})
export class DialogStateErrorComponent implements OnInit {
  @Input() errorState: SheetState;
  data: GridProperties;
  constructor() { }

  ngOnInit() {
    this.data = {
      title: '',
      origin: '',
      filterType: 'filter',
      globalEditMode: false,
      showSelectBox: true,
      selectionMode: 'Row',
      adjustColumns: true,
      export: false,
      edit: false,
      currentFilter: null,
      gridHeight: null
    };
    if (!!this.errorState && !!this.errorState.more_info && this.errorState.more_info.length > 0) {
      this.data.externalData = this.errorState.more_info;
      this.data.staticSchema = this.createStaticSchema(this.errorState.more_info);
    }
  }

  createStaticSchema(data): Schema[] {
    let dataItem = data[0];
    let staticSchema = [];
    Object.keys(dataItem).forEach(key => {
      let schemaItem = {
        field: key,
        title: key,
        visible: true,
        type: 'string',
        adjusted: false
      };
      staticSchema.push(schemaItem);
    });
    return staticSchema;
  }
}
