import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Installation } from '../../../models/common';

@Component({
  selector: 'dialog-installation-form',
  templateUrl: './dialog-installation-form.component.html',
  styleUrls: ['./dialog-installation-form.component.scss']
})
export class DialogInstallationFormComponent {
  @Input() id;
  @Input() performanceTypologies = [];
  @Input() installationTypes = [];
  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Output() onSubmit: EventEmitter<any> = new EventEmitter();
  formError: boolean;
  formErrorMsg: string;
  installation: Installation;
  showInstallationCode = true;
  showPerformanceType = true;

  constructor() {
    this.installation = new Installation();
    this.installation.installationType = null;
    this.installation.installationCode = '';
    this.installation.performanceType = null;
    this.installation.performanceType2 = null;
    this.installation.performanceType3 = null;
  }

  cancel() {
    this.onCancel.emit(this.id);
  }

  validateForm() {
    if (!this.installationTypes[this.installation.installationType]) {
      this.formError = true;
      this.formErrorMsg = 'Debe seleccionar un tipo de instalación';
      return;
    }
    if (
      this.installation.installationType < 6 &&
      !this.installation.installationCode
    ) {
      this.formError = true;
      this.formErrorMsg = 'Debe introducir un código de instalación';
      return;
    }
    if (
      this.installation.installationType < 6 &&
      !(
        this.performanceTypologies[this.installation.performanceType] &&
        this.performanceTypologies[this.installation.performanceType].name
      )
    ) {
      this.formError = true;
      this.formErrorMsg = 'Debe seleccionar un tipo de actuación';
      return;
    }
    if (
      this.installation.installationType < 6 &&
      this.installation.performanceType2 === null &&
      this.performanceTypologies[this.installation.performanceType].data
    ) {
      this.formError = true;
      this.formErrorMsg = 'Debe seleccionar un tipo de actuación 2';
      return;
    }
    if (
      this.installation.installationType < 6 &&
      this.installation.performanceType2 !== null &&
      (this.installation.performanceType3 === null &&
        this.performanceTypologies[this.installation.performanceType].data[
          this.installation.performanceType2
        ].data)
    ) {
      this.formError = true;
      this.formErrorMsg = 'Debe seleccionar un tipo de actuación 3';
      return;
    }

    this.formError = false;
    this.formErrorMsg = '';
    return true;
  }

  onConfirm() {
    if (this.validateForm()) {
      const valueToSave = this.getSaveObject();
      this.onSubmit.emit({ valueToSave, dialogId: this.id });
    }
  }

  getSaveObject(): any {
    if (this.installation.installationType >= 6) {
      const tipo_instalacion = this.installationTypes[
        this.installation.installationType
      ]
        ? this.installationTypes[this.installation.installationType].name
        : null;
      return {
        tipo_instalacion,
        codigo_instalacion: tipo_instalacion
      };
    }
    const tipologia0 = this.performanceTypologies[
      this.installation.performanceType
    ]
      ? this.performanceTypologies[this.installation.performanceType].name
      : null;
    const tipologia1 =
      this.performanceTypologies[this.installation.performanceType] &&
        this.performanceTypologies[this.installation.performanceType].data[
        this.installation.performanceType2
        ]
        ? this.performanceTypologies[this.installation.performanceType].data[
          this.installation.performanceType2
        ].name
        : null;
    const tipologia2 =
      this.performanceTypologies[this.installation.performanceType] &&
        this.performanceTypologies[this.installation.performanceType].data &&
        this.performanceTypologies[this.installation.performanceType].data[
        this.installation.performanceType2
        ] &&
        this.performanceTypologies[this.installation.performanceType].data[
          this.installation.performanceType2
        ].data &&
        this.performanceTypologies[this.installation.performanceType].data[
          this.installation.performanceType2
        ].data[this.installation.performanceType3]
        ? this.performanceTypologies[this.installation.performanceType].data[
          this.installation.performanceType2
        ].data[this.installation.performanceType3].name
        : null;
    return {
      tipologia0,
      tipologia1,
      tipologia2,
      tipo_instalacion: this.installationTypes[
        this.installation.installationType
      ]
        ? this.installationTypes[this.installation.installationType].name
        : null,
      codigo_instalacion: this.installation.installationCode
    };
  }
  setInstallationType(installationType) {
    this.installation.installationType = installationType;
    // validate installationType
    if (this.installation.installationType >= 6) {
      this.showInstallationCode = false;
      this.showPerformanceType = false;
      return;
    }
    this.showInstallationCode = true;
    this.showPerformanceType = true;
  }
}
