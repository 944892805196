import { Component, Input, OnChanges, Output, EventEmitter, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WorkAssociatesComponent } from '../../components/work-associates/work-associates.component';
import { EditedInstallation } from '../../../../shared/interfaces/edited-installations.interface';
import { ManualStartupAct as EditedStartupAct } from '../../../../shared/interfaces/manual-startup-act.interface';
import { GridExportData } from '../../../../services/grid/grid.service';
import { FilterDB } from '../../../../services/filter/filter.service';
import { DownloadsService } from '../../../../services/downloads/downloads.service';
import { MastersService } from '../../../../services/masters/masters.service';
import { MASTER_AMENDMENT_TYPES } from '../../../../shared/interfaces/master-data.interface';
import { InstallationEditionsDialogComponent } from '../../components/installation-editions-dialog/installation-editions-dialog.component';
import { DataApiService } from '../../../../services/data-api/data-api.service';
import { NotificationsService } from '../../../../services/notifications/notifications.service';

@Component({
  selector: 'closure-installations',
  templateUrl: './closure-installations.component.html',
  styleUrls: ['./closure-installations.component.scss'],
})
export class ClosureInstallationsComponent implements OnChanges {
  @Input() items;
  @Input() workCode;
  @Input() canEdit: boolean;
  @Input() nonEditableMessage: string;
  @Input() user: string;
  @Input() automaticAmounts: string = 'NO';

  @Output() onAssociateInstallation: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEditInstallation: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEditedStartupAct: EventEmitter<any> = new EventEmitter<any>();
  @Output() onVisibleColumnsChange: EventEmitter<any> = new EventEmitter<any>();


  summary = { rows: [], headers: [] };
  searchSummary: { search: string; filters: string[] };
  searchInstallations: { search: string; filters: string[] };

  isLoading: boolean;
  installations = { rows: [], headers: [], options: [], filteredColumns: [] };
  startupAct = { rows: [], headers: [] };

  private retributiveYear: String;

  constructor(
    public dialog: MatDialog,
    private downloadsService: DownloadsService,
    private mastersService: MastersService,
    private dataApiService: DataApiService,
    private notificationsService: NotificationsService,
    private zone: NgZone
  ) { }

  ngOnChanges() {
    if (this.items) {
      this.initSummaries(this.items.installations_summary);
      this.initInstallations(this.items.installations_detail);
      this.initStartupAct(this.items.installations_startup_act);

      this.mastersService.getRetributiveYear().subscribe((year) => {
        this.retributiveYear = year;
      });
    }
  }

  initSummaries(summary) {
    // Headers
    this.summary.headers = Object.keys(summary).map((summary) => {
      return {
        name: summary,
        label: summary,
      };
    });
    this.summary.headers.unshift({ name: 'name', label: '' });

    // Rows
    let theorical_installations = { name: 'Instalaciones Teóricas' };
    let real_existing_installations = { name: 'Instalaciones Reales Existentes' };
    let real_new_installations = { name: 'Instalaciones Reales Nuevas' };
    let diff = { name: 'Diferencia' };

    for (let key in summary) {
      theorical_installations[key] = summary[key].theorical_installations;
      real_existing_installations[key] = summary[key].real_existing_installations;
      real_new_installations[key] = summary[key].real_new_installations;
      diff[key] = summary[key].diff.value;
    }

    this.summary.rows = [
      theorical_installations,
      real_existing_installations,
      real_new_installations,
      diff,
    ];
  }

  initInstallations(installationsDetail) {
    this.installations.headers = installationsDetail.headers.map((header) => ({
      name: header.field,
      label: header.title,
      visible: header.visible,
    }));
    this.installations.rows = installationsDetail.data;
    this.installations.options = installationsDetail.installations_options;
  }

  initStartupAct(startupAct) {
    this.startupAct.headers = startupAct.headers.map((header) => ({
      name: header.field,
      label: header.title,
      visible: header.visible,
      editable: header.editable,
      type: header.type,
    }));
    this.startupAct.rows = startupAct.data;
  }

  download() {
    const exportItem: GridExportData = {
      origin: 'sabana_inversiones_cierre_obra_instalaciones_vista',
      filterOrigin: 'sabana_inversiones_cierre_obra_instalaciones_vista',
      filterable: true,
      filename: `Instalaciones ${this.workCode}`,
      title: `Instalaciones ${this.workCode}`,
    };

    const headerNames = this.installations.headers.map((item) => item.name);

    this.downloadsService.asyncDownload(
      exportItem,
      true,
      [this.getFilters()],
      headerNames,
      headerNames
    );
  }

  downloadSummary() {
    const exportItem: GridExportData = {
      origin: 'sabana_inversiones_cierre_obra_resumen_instalaciones',
      filterOrigin: 'sabana_inversiones_cierre_obra_resumen_instalaciones',
      filterable: false,
      filename: 'Cierre de obra - Resumen',
      title: 'Cierre de obra - Resumen',
    };

    this.downloadsService.asyncDownload(
      exportItem,
      true,
      [],
      [],
      []
    );
  }

  downloadAll() {
    const exportItem: GridExportData = {
      origin: 'sabana_inversiones_cierre_obra_instalaciones_vista',
      filterOrigin: 'sabana_inversiones_cierre_obra_instalaciones_vista',
      filterable: true,
      filename: 'Cierre de obra - Instalaciones',
      title: 'Cierre de obra - Instalaciones',
    };

    const headerNames = this.installations.headers.map((item) => item.name);

    this.downloadsService.asyncDownload(
      exportItem,
      true,
      [],
      headerNames,
      headerNames
    );
  }

  downloadInstallationsEditions() {
    const exportItem: GridExportData = {
      origin: 'sabana_inversiones_cierre_obra_instalaciones_manual',
      filterOrigin: 'sabana_inversiones_cierre_obra_instalaciones_manual',
      filterable: true,
      filename: `Cierre de Obra - Ediciones de instalaciones`,
      title: `Cierre de Obra - Ediciones de instalaciones`
    };

    this.downloadsService.asyncDownload(
      exportItem,
      true,
      [],
      [],
      []
    );
  }

  downloadAssociateDisassociateInstallations() {
    const exportItem: GridExportData = {
      origin: 'sabana_inversiones_cierre_obra_asociar_desasociar_instalaciones_manual',
      filterOrigin: 'sabana_inversiones_cierre_obra_asociar_desasociar_instalaciones_manual',
      filterable: true,
      filename: `Cierre de Obra - Asociar/desasociar instalaciones`,
      title: `Cierre de Obra - Asociar/desasociar instalaciones`
    };

    this.downloadsService.asyncDownload(
      exportItem,
      true,
      [],
      [],
      []
    );
  }


  getFilters() {
    let mainFilter = new FilterDB();
    mainFilter.meta = { type: 'filter' };
    mainFilter.table = 'sabana_inversiones_mtbt_instalaciones_detalle_vista';
    mainFilter.filter = {
      condition: 'AND',
      rules: [
        {
          operator: 'equal',
          id: 'codigo-obra',
          field: 'codigo_obra',
          type: 'filter',
          value: this.workCode,
        },
        {
          operator: 'equal',
          id: 'anio-contable',
          field: 'anio_contable',
          type: 'filter',
          value: this.retributiveYear,
        },
      ],
    };
    return mainFilter;
  }

  handleColumnVisible(columns: { filters: any[] }) {
    this.setFilteredColumns(columns);
    this.onVisibleColumnsChange.emit({
      table: 'usuarios_columnas_visibles',
      amendment_type: 'upsert',
      primary_keys: {
        usuario: this.user,
        tabla: 'sabana_inversiones_cierre_obra_detalle_instalaciones_vista',
      },
      new_values: { columnas_visibles: columns.filters.map((column) => column.name).join('|') },
    });
  }

  private setFilteredColumns(columns: { filters: any[] }) {
    const filters = columns.filters;
    this.installations.filteredColumns = this.installations.headers.filter((header) =>
      filters.some((column) => column.name === header.name)
    );
  }

  /*
   * MANUAL
   */

  openManualInstallationsDialog(): void {
    this.zone.run(() => {
      const dialogRef = this.dialog.open(WorkAssociatesComponent, {
        width: '800px',
        height: '800px',
        data: { retributiveYear: this.retributiveYear, installations: this.installations.rows, workCode: this.workCode },
      });
      dialogRef.componentInstance.onAssociateInstallation.subscribe((evt) => {
        this.onAssociateInstallation.emit(evt);
      });
      dialogRef.afterClosed().subscribe();
    });
  }

  openInstallationEditionsDialog(): void {
    this.zone.run(() => {
      const dialogRef = this.dialog.open(InstallationEditionsDialogComponent, {
        width: '800px',
        height: '800px',
        data: { retributiveYear: this.retributiveYear, workCode: this.workCode },
      });
      dialogRef.afterClosed().subscribe(res=>{
        this.dataApiService.unblockWorkClosure().subscribe(res => {
          this.notificationsService.add({
            type: 'confirmation',
            message: 'Se ha desbloqueado la edición de la Pantalla de Cierre de Obra',
            fixed: false,
            popup: true
          });
        });
      });
    });
  }


  handleStartupActEdition(startupAct: EditedStartupAct) {
    this.onEditedStartupAct.emit(
      {
        amendment_type: MASTER_AMENDMENT_TYPES.UPSERT,
        new_values: startupAct['newValues'],
        primary_keys: {
          anio_informacion: startupAct['anio_informacion'],
          expediente: startupAct['expediente']
        },
        table: "maestros_acta_puesta_en_marcha"
      }
    );
  }

  handleInstallationEdition(installations: EditedInstallation[]) {
    this.onEditInstallation.emit(installations);
  }

}
