import { Pipe, PipeTransform } from '@angular/core';

import { formatNumber } from '../util';

@Pipe({
  name: 'localeNumber'
})
export class LocaleNumberPipe implements PipeTransform {
  transform(value: number): string {
    return formatNumber(value);
  }
}
