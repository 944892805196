import { SelectionModel } from '@angular/cdk/collections';
import {
  Component, EventEmitter, Input, OnChanges,
  OnInit, Output, ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { debounceTime } from 'rxjs/operators';

interface Filter {
  name: string;
  label: string;
  selected?: true;
}

@Component({
  selector: 'bdr-input-filter',
  templateUrl: './bdr-input-filter.component.html',
  styleUrls: ['./bdr-input-filter.component.scss']
})
export class BdrInputFilterComponent implements OnChanges, OnInit {
  @ViewChild('clickMenuTrigger', { static: false }) matMenu: MatMenuTrigger;
  @Input() filters: Filter[];
  @Input() placeholder: string;
  @Output() onSearch: EventEmitter<{ search: string; filters: Filter[] }> = new EventEmitter();
  searchInput: FormControl = new FormControl('');
  selection = new SelectionModel<Filter>(true, []);

  ngOnInit() {
    this.valueChanges();
  }

  ngOnChanges() {
    if (this.filters && this.filters.length > 0) {
      this.filters = this.filters.filter(field => field.label);
      this.markAllFiltersAsTouched();
    }
  }

  valueChanges() {
    this.searchInput.valueChanges.pipe(debounceTime(500)).subscribe(search => {
      const value: string = search.trim().toLowerCase();
      const filterSearch = {
        search: value,
        filters: this.selection.selected
      };
      this.onSearch.emit(filterSearch);
    });
  }

  removeFilters(event: Event) {
    event.stopPropagation();
    this.selection.clear();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numFilters = this.filters.length;
    return numSelected === numFilters;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.markAllFiltersAsTouched();
  }

  markAllFiltersAsTouched() {
    return this.filters.forEach(row => this.selection.select(row));
  }

  onClickFilter(event) {
    event.stopPropagation();

    this.matMenu.closeMenu();

    const value: string = this.searchInput.value.trim().toLowerCase();
    const filterSearch = {
      search: value,
      filters: this.selection.selected
    };
    this.onSearch.emit(filterSearch);
  }
}
