import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  OnChanges,
  ChangeDetectionStrategy,
} from '@angular/core';

// TODO: Remove environments when all downloads be async
import { GridExportData } from '../../services/grid/grid.service';
import { DownloadsService } from '../../services/downloads/downloads.service';
import { OriginService } from '../../services/origin/origin.service';

@Component({
  selector: 'bdr-download-options',
  templateUrl: './bdr-download-options.component.html',
  styleUrls: ['./bdr-download-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BdrDownloadOptionsComponent implements OnChanges {
  @Input() appliedFilters: any = [];
  @Input() visibleColumns = [];
  @Input() allColumns = [];
  @Input() exportData: GridExportData[];
  @Output() close: EventEmitter<Function> = new EventEmitter<Function>();
  isFilter = false;
  isInventory = false;
  isInvestment = false;
  isForms = false;
  isInstallations = false;

  constructor(
    private downloadsService: DownloadsService,
    private originService: OriginService,
  ) {}

  ngOnChanges(changes) {
    if (changes.appliedFilters) {
      this.appliedFilters = this.appliedFilters || [];
    }
    if (changes.allColumns) {
      this.allColumns = this.allColumns || [];
    }
    if (changes.visibleColumns) {
      this.visibleColumns = this.visibleColumns || [];
    }
    this.isFilter =
      this.appliedFilters.length > 0 || this.visibleColumns.length !== this.allColumns.length;
  }

  closePanel() {
    this.close.emit();
  }

  export(exportItem: GridExportData, filtered = false, customView = undefined) {
    let inventoryOrigins = this.originService.getByKey('inventory');
    Object.values(inventoryOrigins).forEach((element: any) => {
        if (element.find((e) => e.origin === exportItem.origin)) {
            this.isInventory = true;
        }
    });

    let investmentOrigins = this.originService.getByKey('investments');
    Object.values(investmentOrigins).forEach((element: any) => {
        if (element.find((e) => e.origin === exportItem.origin)) {
            this.isInvestment = true;
        }
    });

    let formsOrigins = this.originService.getByKey('forms');
    Object.values(formsOrigins).forEach((element: any) => {
        if (element.find((e) => e.origin === exportItem.origin)) {
            this.isForms = true;
        }
    });

    let installationsOrigins = this.originService.getByKey('installations');
    Object.values(installationsOrigins).forEach((element: any) => {
        if (element.find((e) => e.origin === exportItem.origin)) {
            this.isInstallations = true;
        }
    });

    origin = filtered ? exportItem.filterOrigin || exportItem.origin : exportItem.origin;

    let downloadTitle = exportItem.title;
    let fileName = exportItem.filename;
    let appliedFilters = this.appliedFilters;
    let visibleColumns = this.visibleColumns;
    let allColumns = this.allColumns;
    let modifiedFilters  = appliedFilters;

    if (customView) {
        let originalOrigin = origin;
        let originSplit = origin.split('_vista');
        origin = `${originSplit[0]}_${customView}_vista`;
        downloadTitle = origin;
        fileName = fileName.replace(originalOrigin, origin);

        let columnToRemove = 'identificador_regulatorio';

        visibleColumns = visibleColumns.filter((element) => element !== columnToRemove);
        allColumns = allColumns.filter((element) => element !== columnToRemove);
        let replacementField = this.getReplacementField(exportItem.origin);

        if (replacementField) {
            modifiedFilters = this.appliedFilters.map(filter => {
                if (filter.filter && filter.filter.rules) {
                    let modifiedRules = filter.filter.rules.map(rule => {
                        if (rule.field === 'identificador_regulatorio') {
                            return {
                                ...rule,
                                field: replacementField
                            };
                        }
                        return rule;
                    });
                    return {
                        ...filter,
                        filter: {
                            ...filter.filter,
                            rules: modifiedRules
                        }
                    };
                }
                return filter;
            });
        }
    }

    downloadTitle += filtered ? ' (Selección)' : ' (Total)';

    let newExportItem: GridExportData = {
        ...exportItem,
        filename: fileName,
        origin: origin,
        filterOrigin: exportItem.filterOrigin,
        title: downloadTitle,
    };

    this.downloadsService.asyncDownload(
        newExportItem,
        filtered,
        modifiedFilters,
        visibleColumns,
        allColumns,
    );
  }


  exportLanding(origin) {
    this.downloadsService.exportLanding(origin, this.visibleColumns, this.allColumns);
  }

  downloadSREForms(origin) {
    let sreTitle = origin.replace('_vista', '_sres_vista');
    let exportItem: GridExportData = {
      origin: sreTitle,
      filterOrigin: sreTitle,
      filterable: true,
      filename: `${origin} con SREs`,
      title: `${origin} con SREs`,
    };
    this.downloadsService.asyncDownload(exportItem, false, [], [], []);
  }

  getReplacementField(customView: string): string | undefined {
    switch (customView) {
      case 'fb1bt_vista':
      case 'fb1mt_vista':
      case 'fb1at_vista':
        return 'identificador_tramo';
      case 'fb1_1bt_vista':
      case 'fb1_1at_vista':
      case 'fb1_1mt_vista':
        return 'cod_segmento';
      case 'fb2_vista':
      case 'fb2_1_vista':
      case 'fb2_2_vista':
        return 'identificador_ct';
      case 'fb3_2_vista':
      case 'fb3_3_vista':
        return 'identificador_subestacion';
      case 'fb4_vista':
        return 'identificador_posicion';
      case 'fb5_vista':
        return 'identificador_maquina';
      case 'fb6_vista':
        return 'identificador_em';
      default:
        return undefined;
    }
  }
  getRegulatoryParameter(origin: string): string {
    if ([
      'fb1_1bt_vista',
      'fb1_1at_vista',
      'fb1_1mt_vista'
    ].includes(origin)) {
      return 'pk_id_regulatorio_cod_segmento';
    } else {
      return 'pk_id_regulatorio';
    }
  }

  hasRegulatoryId(origin) {
    return [
      'fb1bt_vista',
      'fb1mt_vista',
      'fb1at_vista',

      'fb1_1bt_vista',
      'fb1_1at_vista',
      'fb1_1mt_vista',

      'fb2_vista',

      'fb2_1_vista',
      'fb2_2_vista',

      'fb3_2_vista',
      'fb3_3_vista',

      'fb4_vista',
      'fb5_vista',
      'fb6_vista',
    ].includes(origin);
  }

  hasSres(origin) {
    return [
      'f1_inventario_vista',
      'f2_inventario_vista',
      'f4_inventario_vista',
      'f5_inventario_vista',
      'f7_inventario_vista',
      'f8_inventario_vista',
    ].includes(origin);
  }
}
