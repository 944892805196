import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';

@Component({
  selector: 'bdr-pagination',
  templateUrl: './bdr-pagination.component.html',
  styleUrls: ['./bdr-pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BdrPaginationComponent {
  @Input() subject: string;
  @Input() totalRows: number;
  @Input() dataLength: number;
  @Input() loadingMore: boolean;

  @Output() paginate: EventEmitter<number>;
  @Output() scrollTop: EventEmitter<void>;

  constructor() {
    this.subject = 'registros';
    this.totalRows = 0;
    this.dataLength = 0;
    this.loadingMore = false;

    this.paginate = new EventEmitter<number>();
    this.scrollTop = new EventEmitter<void>();
  }

  triggerPaginate(rows: number): void {
    this.paginate.emit(rows);
  }

  triggerScrollTop(): void {
    this.scrollTop.emit();
  }
}
