import { Component} from '@angular/core';
import { OriginService } from '../../services/origin/origin.service';
import { SchemaService } from '../../services/schema/schema.service';
import { TasksService } from '../../services/tasks/tasks.service';
import { GridExportData, GridProperties, GridService } from './../../services/grid/grid.service';
import { DownloadsService } from '../../services/downloads/downloads.service';
@Component({
  selector: 'investments-adjustment-sheet',
  templateUrl: './investments-adjustment-sheet.component.html',
  styleUrls: ['./investments-adjustment-sheet.component.scss'],
})
export class InvestmentsAdjustmentSheetComponent {
  currentOrigin: any = {};
  gridProperties: GridProperties;
  error: boolean;
  panels: any;
  savingFlag: any;
  editMode = false;
  appliedFilters: any[] = [];
  origin: any;
  filterOrigin: any;
  subheaderSelector = [];
  
  investmentOrigin: any;
  currentView: string;

  selectedRows = [];

  saveDisabled: boolean = false;
  saveDisabledReason: string;

  constructor(
    private gridService: GridService,
    private tasksService: TasksService,
    private originService: OriginService,
    private schemaService: SchemaService,
    private downloadsService: DownloadsService
  ) {
    this.origin = this.originService.getByKey('investmentsAdjustment').incurridos_origen[0].origin;
    this.investmentOrigin = this.originService.getByKey('investmentsAdjustment');

    this.filterOrigin = this.originService.getByKey('investmentsFilter');
    this.currentOrigin = this.subheaderSelector = this.getSubheaderSelector();

    this.gridProperties = this.gridService.getDefaultGridProperties();
    this.gridProperties.globalEditMode = false;
    this.gridProperties.bordered = true;
    this.gridProperties.edit = false;
    this.gridProperties.reload = false;
    this.gridProperties.headerActions = [];
    this.gridProperties.columnSelector = true;
    this.gridProperties.editDblClick = false;
    this.gridProperties.generate = false;
    this.gridProperties.showSelectBox = true;
    this.gridProperties.selectable = true;
    this.gridProperties.columnSearch = true;
    this.gridProperties.editingRows = [];
    this.gridProperties.currentRequestParams = {};
    this.gridProperties.schemaScope = 'investments_adjustment';

    this.panels = {
      downloads: { disabled: false },
      fastFilter: { disabled: true },
      filters: { disabled: true },
      visualization: { disabled: false },
      validations: { disabled: false },
      manualValidations: { disabled: false },
      refreshSheet: { disabled: false },
    };
  }

  getSubheaderSelector() {
    return Object.values(this.investmentOrigin).map((value: any[]) => {
      return value.reduce((acc, element) => {
        let key = element.namespace.slice(element.namespace.indexOf('.') + 1);
        return {
          title: element.specificReloadNicename,
          key,
          changeEvent: this.changeOrigin.bind(this),
          selectData: this.investmentOrigin[key].map((item) => {
            return { name: item.title, value: item.origin };
          }),
        };
      }, {});
    });
  }

  selectedRowsChange(evt): void {
    this.selectedRows = evt;
  }

  generateSheet(type) {
    this.tasksService.reloadSheet(type);
  }

  downloadValidations(): void {

    const exportValidation1: GridExportData = {
      origin: 'sabana_cuadre_inversiones_validacion_1',
      filterOrigin: 'sabana_cuadre_inversiones_validacion_1',
      filterable: true,
      filename: 'Cuadre Inversiones - Validaciones 1',
      title: 'Cuadre Inversiones - Validaciones 1'
    };

    const exportValidation2: GridExportData = {
      origin: 'sabana_cuadre_inversiones_validacion_2',
      filterOrigin: 'sabana_cuadre_inversiones_validacion_2',
      filterable: true,
      filename: 'Cuadre Inversiones - Validaciones 2',
      title: 'Cuadre Inversiones - Validaciones 2'
    };

    this.downloadsService.asyncDownload(
      exportValidation1,
      true,
      [],
      ['anio_informacion', 'id'],
      []
    );

    this.downloadsService.asyncDownload(
      exportValidation2,
      true,
      [],
      ['anio_informacion', 'id'],
      []
    );
  }

  changeOrigin(evt) {
    if (!evt.value && evt.key && this.investmentOrigin[evt.key].length == 1) {
      evt.value = this.investmentOrigin[evt.key][0].origin;
    }
    
    // Force empty grid
    let newGridProperties = Object.assign({}, this.gridProperties);
    newGridProperties.filterOrigin = '';
    newGridProperties.origin = '';
    newGridProperties.reload = false;


    if (evt.value) {

      let origin = evt.value;
      this.currentView = evt.value;

      newGridProperties.defaultSchemaVisibility = {};
      newGridProperties.additionalSchemaData = this.gridService.resetSchema(
        this.gridProperties.schemaScope,
        evt.value,
        null,
        this.schemaService.getDefaultSchema(origin),
      );
      newGridProperties.filterOrigin = origin;
      newGridProperties.origin = origin;

      newGridProperties.exportData = [
        this.gridService.createComponentExportData(evt, this.originService.getByOrigin(evt.value).title, true),
      ];
    }
    setTimeout(() => {
      newGridProperties.filterOrigin = evt.value;
      newGridProperties.origin = evt.value;
      this.gridProperties = Object.assign({}, newGridProperties);
    }, 200);

    this.addHeadersActions(newGridProperties)
  }

  private addHeadersActions(gridProperties) {
    //header actions
    gridProperties.headerActions = [];

    // Añade la acción de descargar las validacones 1 y 2
    if (this.currentView === 'sabana_cuadre_inversiones_validaciones') {
      gridProperties.headerActions.push({
        type: 'investments_adjustment.validations',
        requireSelected: false,
        requireFilter: false,
        fire: this.downloadValidations.bind(this),
      });
    }
  }
}
