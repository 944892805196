import { Component, OnInit, Input, Output, EventEmitter, Injectable, ChangeDetectorRef } from '@angular/core';
import { DataApiService } from '../../../services/data-api/data-api.service';
import { ManualInstallation } from '../../../models/common';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NotificationsService } from '../../../services/notifications/notifications.service';

@Component({
  selector: 'dialog-ela-detail-installations',
  templateUrl: './dialog-ela-detail-installations.component.html',
  styleUrls: ['./dialog-ela-detail-installations.component.scss']
})
@Injectable()
export class DialogElaDetailInstallationsComponent implements OnInit {
  @Input() id: string;
  @Input() type: string;

  @Input() installations: any[];
  @Input() incurreds: any[] = [];
  @Input() installationType: string;

  @Input() origin: string;

  @Input() ela: string;

  @Output() onAssociateDesassociateATInstallations: EventEmitter<any> = new EventEmitter();
  @Output() onAssociateDesassociateDispatchersInstallations: EventEmitter<any> = new EventEmitter();

  @Output() backAction: EventEmitter<any> = new EventEmitter();
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Output() closeDialog: EventEmitter<any> = new EventEmitter();

  error: boolean = false;
  errorMsg: string;

  loading: boolean = false;

  constructor(private dataApi: DataApiService, private notificationsService: NotificationsService, private cdr: ChangeDetectorRef) { }

  destroy$: Subject<any> = new Subject();

  ngOnInit() {
    let incurredInstallations = (this.incurreds[0].validas_declarar || '').split(';').map((i: string) => {
      let installationParts = i.split('_');
      return { instalacion: installationParts[0], secuencial: installationParts[1] }
    });

    this.installations.forEach(i => {
      let fromIncurred = incurredInstallations.some((inst: { instalacion: any; secuencial: string; }) => inst.instalacion === i.instalacion && inst.secuencial === "" + i.secuencial);
      i.original = i.fromDB && fromIncurred;
      i.selected = fromIncurred;
    });
  }


  back(): void {
    this.backAction.emit(this.id);
  }

  validateInstallation($event: string) {
    this.error = false;
    this.loading = true;

    let origin = 'sabana_inversiones_at_informacion_inventario';

    let rules = [
      {
        operator: "equal",
        id: "inventario",
        field: "inventario",
        type: "string",
        value: $event
      }
    ];

    if (this.origin === 'AT') {
      rules.push({
        operator: "equal",
        id: "tipo_instalacion",
        field: "tipo_instalacion",
        type: "string",
        value: this.installationType
      });
    }

    let params = {
      query: {
        filters: [
          {
            rules: [
              {
                condition: "AND",
                rules: rules
              }
            ],
            condition: "AND"
          }
        ],
        logic: "and"
      },
    };

    this.dataApi.data(origin, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        this.loading = true;
        let installation = result.result[0];
        this.error = !this.loading;
        if (installation) {
          installation.instalacion = installation.inventario;
          installation.clasificacion = installation.tipo_instalacion;
          installation.secuencial = Math.max(...this.installations.filter(i => i.instalacion === installation.instalacion).map(i => i.secuencial), 0) + 1;
          installation.selected = true;
          installation.origen_instalacion = this.origin;
          installation.ela_final = this.ela;
          this.associateInstallation(installation);

          if (installation.anio_contable !== installation.anio_retributivo) {
            this.notificationsService.add({
              type: "warning",
              message: "La instalación que se ha asociado está diferida en el inventario del año actual",
              fixed: false,
              popup: true
            });
          }
        } else {
          this.errorMsg = "La instalación no es válida."
        }
        this.loading = false;
        this.cdr.detectChanges();
      });
  }

  associateInstallation(installation: any) {
    this.installations.push(installation);
  }

  save(): void {
    let installationsToSave = {};
    let newInstallations = [];
    this.installations.forEach(i => {
      if (i.selected) {
        newInstallations.push(i);
        if (!i.original) {
          this.incurreds.forEach(incurred => {
            let incurredKey = `${i.ela_final}_${incurred.ot}_${incurred.clase_coste}`;
            if (!installationsToSave[incurredKey]) {
              installationsToSave[incurredKey] = [];
            }
            installationsToSave[incurredKey].push(
              this.manualInstallation(
                i.anio_contable,
                i.ela_final,
                incurred.ot,
                incurred.clase_coste,
                i.instalacion,
                1
              )
            );
          });
        }
      }

      if (i.original && !i.selected) {
        this.incurreds.forEach(incurred => {
          let incurredKey = `${i.ela_final}_${incurred.ot}_${incurred.clase_coste}`;
          if (!installationsToSave[incurredKey]) {
            installationsToSave[incurredKey] = [];
          }
          installationsToSave[incurredKey].push(this.manualInstallation(
            i.anio_contable,
            i.ela_final,
            incurred.ot,
            incurred.clase_coste,
            i.instalacion,
            0
          ));
        });
      }
    });

    this.incurreds.forEach(incurred => {
      incurred.validas_declarar = newInstallations.map(i => i.instalacion + '_' + i.secuencial).join(';');
    });

    let emitter: EventEmitter<any>;
    switch (this.origin) {
      case 'AT':
        emitter = this.onAssociateDesassociateATInstallations;
        break;
      case 'DESPACHOS':
        emitter = this.onAssociateDesassociateDispatchersInstallations;
        break;
    }

    emitter.emit({
      manualInstallations: installationsToSave,
      installations: this.installations,
      incurreds: this.incurreds
    });

    this.back();
  }

  private manualInstallation(year: any, ela: any, ot: any, costClass: any, installationId: any, action: number) {
    return <ManualInstallation>{
      anio_contable: year,
      ela_final: ela,
      ot: ot,
      clase_coste: costClass,
      instalacion: installationId,
      accion: action,
      origen_instalacion: this.origin
    }
  }
}
