import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BdrRequestService } from './../bdr-request/bdr-request.service';
import { environment } from './../../../environments/environment';
import { QueryPoller, AsyncQuery } from '../../models/query-poller';
import { OriginService } from '../origin/origin.service';

@Injectable()
export class DataApiService {
  private host: string;
  private sheetStateOrigin: any;
  private tasksOrigin: any;
  private singleTaskOrigin: any;

  constructor(
    private request: BdrRequestService,
    private originService: OriginService,
  ) {
    this.tasksOrigin = this.originService.getByKey('tasks');
    this.singleTaskOrigin = this.originService.getByKey('task');
    this.sheetStateOrigin = this.originService.getByKey('sheetState');
    this.host = environment.host;
  }

  getApi(origin: string, params: any) {
    return this.request.get(`${this.host}/${origin}`, params);
  }

  schema(origin: string) {
    return this.request.get(`${this.host}/schema/data/${origin}`);
  }

  data(origin: string, params: any) {
    return this.request.get(`${this.host}/table/data/${origin}`, params);
  }

  dataTableState(payload: any) {
    return this.request.post(`${this.host}/table/data/state`, payload);
  }

  dataTableTask(origin: string, params: any, profileEmail: string) {
    return this.request.post(`${this.host}/table/data/task`, {
      tableName: origin,
      params: params,
      //profileEmail: profileEmail
    });
  }

  getState() {
    return this.request.get(`${this.host}/${this.sheetStateOrigin}`, {});
  }

  getNotifications(year) {
    return this.request.get(`${this.host}/notifications/year/${year}`, {});
  }

  saveElaDetail(params) {
    return this.request.put(`${this.host}/ela-detail/save`, {
      year: 2019,
      changes: params,
    });
  }

  getManualWeights(queryStringParams: any) {
    return this.request.get(`${this.host}/ela-detail/weights`, queryStringParams);
  }

  dataExport(origin: string, params: any) {
    return this.request.getFile(`${this.host}/excel/${origin}`, params);
  }

  getTasks() {
    return this.request.get(`${this.host}/${this.tasksOrigin}/`);
  }

  reloadSheet(sheet, body = {}) {
    return this.request.post(`${this.host}/sheet-reload/${sheet}`, body);
  }

  reloadFormCnmcErrorLogs(form) {
    return this.request.post(`${this.host}/cnmc/reload/error-logs`, {});
  }

  reloadFormCnmcValidations(form) {
    return this.request.post(`${this.host}/cnmc/reload/validations`, {});
  }

  getLandingZones() {
    return this.request.get(`${this.host}/landing_files`);
  }

  getExportGeneratedFormsCircular(origin: string): Observable<any> {
    return this.request.get(`${this.host}/generated-forms/circular/${origin}`, {});
  }

  checkAllsCNMCForms(): Observable<any> {
    return this.request.get(`${this.host}/generated-forms/cnmc/status`, {});
  }

  checkCNMCForm(origin: string): Observable<any> {
    return this.request.get(`${this.host}/generated-forms/cnmc/status/${origin}`, {});
  }

  getExportCNMCForms(): Observable<any> {
    return this.request.get(`${this.host}/generated-forms/cnmc`, {});
  }

  getExportGroupedCNMCForms(origin: string): Observable<any> {
    return this.request.get(`${this.host}/generated-forms/cnmc/grouped/${origin}`, {});
  }

  getCNMCLogs(): Observable<any> {
    return this.request.get(`${this.host}/generated-logs/cnmc`, {});
  }

  getCNMCFormLogs(origin: string): Observable<any> {
    return this.request.get(`${this.host}/generated-logs/cnmc/${origin}`, {});
  }

  getCNMCFormGroupedLogs(origin: string): Observable<any> {
    return this.request.get(`${this.host}/generated-logs/cnmc/grouped/${origin}`, {});
  }

  clearLoads() {
    return this.request.put(`${this.host}/load-errors/show-date`, {});
  }

  getExportAsync(params) {
    return this.request.get(
      `${this.host}/async/unload/${params.operation || 'download'}/${params.name}`,
      params,
    );
  }

  jobQueryPoller(jobId) {
    let query: AsyncQuery = {
      url: `${this.host}/async/unload/status/${jobId}`,
      interval: environment.async.query.interval || environment.async.defaultInterval,
      completeStatus: environment.async.query.completeStatus,
      errorStatus: environment.async.query.errorStatus,
    };
    return new QueryPoller(this.request, query);
  }

  /*
   * WORK CLOSURE
   */

  getWorkClosure(workId: string, userID: string) {
    return this.request.get(`${this.host}/work-closure/${workId}`, {
      user: userID,
    });
  }

  panicButton(workcode, panicButton) {
    return this.request.put(`${this.host}/work-closure/panic-button`, {
      year: 2019,
      workcode: workcode,
      panic_button: panicButton,
    });
  }

  workClosureCheckIn(body) {
    return this.request.put(`${this.host}/work-closure/checkin`, body);
  }

  workClosureCheckOut(body) {
    return this.request.put(`${this.host}/work-closure/checkout`, body);
  }

  worksClosureCheckOut(body) {
    return this.request.post(`${this.host}/works-closure/checkout`, body);
  }

  validateInstallation(body) {
    return this.request.put(`${this.host}/work-closure/installations/validate`, body);
  }

  saveWorkClosure(workcode, params: any) {
    return this.request.put(`${this.host}/work-closure/save`, {
      year: 2019,
      workcode: workcode,
      work_closure_changes: params,
    });
  }

  asyncSaveWorkClosure(workcode, params: any) {
    return this.request.put(`${this.host}/async/work-closure/save`, {
      year: 2019,
      workcode: workcode,
      work_closure_changes: params,
    });  
  }

  blockWorkClosure() {
    return this.request.post(`${this.host}/notifications/work-closure/block`, {
      year: 2019,
    });
  }

  unblockWorkClosure() {
    return this.request.delete(`${this.host}/notifications/work-closure/unblock`);
  }

  deleteAllWorkClosureManualChanges(workcode) {
    return this.request.delete(`${this.host}/work-closure/amendments/${workcode}`);
  }

  /*
   * AMENDMENTS
   */
  unloadAmendment(year: number, table: string): Observable<any> {
    return this.request.get(`${this.host}/async/unload/masterdata/${table}/`);
  }

  validateCopyAmendments(amendments) {
    return this.request.put(`${this.host}/amendments/validate_copy`, {
      year: 2019,
      amendments: amendments,
    });
  }

  putAmendments(payload) {
    payload.year = 2019;

    return this.request.put(`${this.host}/amendments`, payload);
  }

  putAsyncAmendments(payload): Observable<any> {
    payload.year = 2019;
    return this.request.put(`${this.host}/async/amendments`, payload);
  }


  /*
   * RECLASSIFICATION MASS EDITION
   */

  reclassificationMassEdition(reclassification) {
    return this.request.put(`${this.host}/async/reclassification_mass_edition`, {
      year: 2019,
      reclassification: reclassification,
    });
  }

  /*
   * RECLASSIFICATION
   */

  reclassification(reclassification) {
    return this.request.put(`${this.host}/async/reclassification`, {
      year: 2019,
      reclassification: reclassification,
    });
  }

  revertReclassifications(reversions) {
    return this.request.put(`${this.host}/async/reclassification/revert`, {
      year: 2019,
      reversions: reversions,
    });
  }

  revertSplittedAppointments(reversions) {
    return this.request.put(`${this.host}/async/reclassification/remove-splitted`, {
      year: 2019,
      appointments_to_remove: reversions,
    });
  }

  loginLog(userProfile) {
    return this.request.post(`${this.host}/log/login`, {
      year: 2019,
      user: userProfile,
    });
  }

  getUserPermissions(userProfile) {
    return this.request.post(`${this.host}/user/permissions`, {
      user: userProfile,
    });
  }

  getReclassificationManualRegisters(ids) {
    return this.request.get(`${this.host}/reclassification/manual-registers`, {
      year: 2019,
      ids: ids,
    });
  }

  getVirtualAppointments(ids) {
    return this.request.get(`${this.host}/reclassification/splitted-appointments`, {
      year: 2019,
      appointments: ids,
    });
  }

  openAuditory(id) {
    return this.request.delete(`${this.host}/notifications/auditory/${id}`);
  }

  closeAuditory() {
    return this.request.post(`${this.host}/notifications/auditory/close`, {
      year: 2019,
    });
  }

  deleteAllNotifications() {
    return this.request.delete(`${this.host}/notifications`);
  }

  deleteNotification(notification_id) {
    return this.request.delete(`${this.host}/notifications/${notification_id}`);
  }

  getMasterdataS3List(origin: string): Observable<any> {
    return this.request.get(`${this.host}/masterdata/s3/list/${origin}`, {});
  }

  deleteMasterdataFile(path: string): Observable<any> {
    return this.request.post(`${this.host}/masterdata/s3/csv-gz/delete`, { path });
  }

  getMasterdataCsvGz(path: string): Observable<any> {
    return this.request.post(`${this.host}/masterdata/s3/csv-gz/download`, { path });
  }

  sheetsReload(sheets) {
    return this.request.post(`${this.host}/sheets-reload`, sheets);
  }
}
