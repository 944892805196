import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { AngularSlickgridModule } from 'angular-slickgrid';

import { BdrInputComponent } from './bdr-input/bdr-input.component';
import { BdrCheckboxComponent } from './bdr-checkbox/bdr-checkbox.component';
import { BdrIconComponent } from './bdr-icon/bdr-icon.component';
import { BdrButtonComponent } from './bdr-button/bdr-button.component';
import { BdrUserBoxComponent } from './bdr-user-box/bdr-user-box.component';
import { BdrReflectComponent } from './bdr-reflect/bdr-reflect.component';
import { BdrGridHeaderComponent } from './bdr-grid-header/bdr-grid-header.component';
import { BdrGridComponent } from './bdr-grid/bdr-grid.component';
import { BdrCreateDeleteBannerComponent } from './bdr-create-delete-banner/bdr-create-delete-banner.component';

import { FocusDirective } from './../directives/focus.directive';

import { BdrPaginationComponent } from './bdr-pagination/bdr-pagination.component';

import { FilterArrayPipe } from './../pipes/filter-array.pipe';
import { LocaleNumberPipe } from './../pipes/locale-number.pipe';
import { LocaleCurrencyPipe } from './../pipes/locale-currency.pipe';
import { BdrRowDragComponent } from './bdr-row-drag/bdr-row-drag.component';
import { BdrFilterListComponent } from './bdr-filter-list/bdr-filter-list.component';
import { BdrInlineNotificationComponent } from './bdr-inline-notification/bdr-inline-notification.component';
import { BdrInputSearchComponent } from './bdr-input-search/bdr-input-search.component';
import { InputMultipleComponent } from './bdr-input-multiple/bdr-input-multiple.component';
import { BdrSelectPillsComponent } from './bdr-select-pills/bdr-select-pills.component';
import { BdrGridColumnSearchComponent } from './bdr-grid-column-search/bdr-grid-column-search.component';
import { BdrSelectComponent } from './bdr-select/bdr-select.component';
import { BdrInputPredictiveComponent } from './bdr-input-predictive/bdr-input-predictive.component';
import { BdrTaskLoadListComponent } from './bdr-task-load-list/bdr-task-load-list.component';
import { PopupNotificationsComponent } from './popup-notifications/popup-notifications.component';
import { BdrGridFieldComponent } from './bdr-grid-field/bdr-grid-field.component';
import { BdrSidePanelComponent } from './bdr-side-panel/bdr-side-panel.component';
import { SimpleGridHandlerComponent } from './simple-grid-handler/simple-grid-handler.component';
import { BdrSelectMultipleCheckComponent } from './bdr-select-multiple-check/bdr-select-multiple-check.component';
import { LogItemComponent } from './log-item/log-item.component';
import { BdrButtonCircleComponent } from './bdr-button-circle/bdr-button-circle.component';
import { BdrSplashComponent } from './bdr-splash/bdr-splash.component';
import { BdrRetributiveYearSelectorComponent } from './bdr-retributive-year-selector/bdr-retributive-year-selector.component';
import { BdrButtonSelectboxSelectorComponent } from './bdr-button-selectbox-selector/bdr-button-selectbox-selector.component';
import { BdrFieldWrapperComponent } from './bdr-field-wrapper/bdr-field-wrapper.component';
import { CtxDownloadList } from './bdr-contextual-menu/ctx-download-list/ctx-download-list.component';
import { BdrAppliedFilters } from './bdr-applied-filters/bdr-applied-filters.component';
import { BdrDownloadOptionsComponent } from './bdr-download-options/bdr-download-options.component';
import { BdrHeaderNotificationsComponent } from './bdr-header-notifications/bdr-header-notifications.component';
import { BdrVolumetriesComponent } from './bdr-volumetries/bdr-volumetries.component';
import { BdrHeroImageComponent } from './bdr-hero-image/bdr-hero-image.component';
import { MaterialModule } from '../shared/modules/material.module';
import { BdrTableComponent } from './bdr-table/bdr-table.component';
import { BdrInputFilterComponent } from './bdr-input-filter/bdr-input-filter.component';
import { BdrVisibleColumnsComponent } from './bdr-visible-columns/bdr-visible-columns.component';
import { ValidationsComponent } from './validations/validations.component';
import { CollapsePanelComponent } from './collapse-panel/collapse-panel.component';
import { ValidationsZonesComponent } from './validations-zones/validations-zones.component';
import { ValidationsListComponent } from './validations-list/validations-list.component';
import { BdrReloadOptionsComponent } from './bdr-reload-options/bdr-reload-options.component';
import { BdrValidationsSummaryComponent } from './bdr-validations-summary/bdr-validations-summary.component';
import { BdrExportCNMCOptionsComponent } from './bdr-export-cnmc-options/bdr-export-cnmc-options.component';
import { BdrLogsCNMCOptionsComponent } from './bdr-logs-cnmc-option/bdr-logs-cnmc-options.component';
import { BdrReloadLogsCNMCOptionsComponent } from './bdr-reload-logs-cnmc-options/bdr-reload-logs-cnmc-options.component';
import { BdrInfoMasterConfigurationComponent } from './bdr-info-master-configuration/bdr-info-master-configuration.component';

const components = [
  BdrButtonComponent,
  BdrInputComponent,
  BdrCheckboxComponent,
  BdrSelectComponent,
  BdrIconComponent,
  BdrUserBoxComponent,
  BdrReflectComponent,
  BdrGridHeaderComponent,
  BdrGridComponent,
  BdrCreateDeleteBannerComponent,
  BdrInlineNotificationComponent,
  BdrInputSearchComponent,
  BdrSelectPillsComponent,
  FilterArrayPipe,
  LocaleNumberPipe,
  LocaleCurrencyPipe,
  BdrRowDragComponent,
  BdrFilterListComponent,
  BdrGridColumnSearchComponent,
  InputMultipleComponent,
  BdrTaskLoadListComponent,
  PopupNotificationsComponent,
  BdrGridFieldComponent,
  BdrSidePanelComponent,
  SimpleGridHandlerComponent,
  LogItemComponent,
  BdrButtonCircleComponent,
  BdrRetributiveYearSelectorComponent,
  BdrButtonSelectboxSelectorComponent,
  BdrAppliedFilters,
  BdrHeaderNotificationsComponent,
  BdrVolumetriesComponent,
  BdrFieldWrapperComponent,
  CtxDownloadList,
  BdrHeroImageComponent,
  BdrInputPredictiveComponent,
  BdrSelectMultipleCheckComponent,
  BdrDownloadOptionsComponent,
  BdrExportCNMCOptionsComponent,
  BdrLogsCNMCOptionsComponent,
  BdrReloadLogsCNMCOptionsComponent,
  BdrReloadOptionsComponent,
  BdrSplashComponent,
  BdrPaginationComponent,
  FocusDirective,
  BdrTableComponent,
  BdrInputFilterComponent,
  BdrVisibleColumnsComponent,
  ValidationsComponent,
  CollapsePanelComponent,
  ValidationsZonesComponent,
  ValidationsListComponent,
  BdrValidationsSummaryComponent,
  BdrInfoMasterConfigurationComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    AngularSlickgridModule.forRoot()
  ],
  exports: components,
  declarations: components,
})
export class ComponentsModule { }
