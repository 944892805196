import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { safeSet } from '../../util';

@Component({
  selector: 'bdr-grid-column-search',
  templateUrl: './bdr-grid-column-search.component.html',
  styleUrls: ['./bdr-grid-column-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BdrGridColumnSearchComponent {
  @Input()
  conditions: {
    [field: string]: { type?: string; search: string; colName: string };
  };
  @Input() type: string;
  @Input() field: string;

  @Output()
  conditionsChange: EventEmitter<{
    [field: string]: { type?: string; search: string; colName: string };
  }>;
  @Output() search: EventEmitter<void>;

  constructor() {
    this.conditionsChange = new EventEmitter<any>();
    this.search = new EventEmitter<void>();
  }

  submit() {
    this.search.emit();
  }

  changeConditions() {
    this.conditionsChange.emit(this.conditions);
  }

  safeSet(obj, path, value) {
    return safeSet(obj, path, value);
  }
}
