import {
  Component,
  Injectable,
  Inject,
  OnInit,
  Input
} from '@angular/core';
import { DataApiService } from '../../../services/data-api/data-api.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DownloadsService } from '../../../services/downloads/downloads.service';
import { Subject } from 'rxjs';
import { AsyncQueryResponse } from '../../../models/query-poller';

import * as _ from 'lodash';


interface DialogData {
  table: string;
  selectedRows: any[];
}

interface Appointment {
  id: string;
  selected: boolean;
  visible: boolean;
  fecha: string;
}

@Component({
  selector: 'dialog-revert-splitted-appointments',
  templateUrl: './dialog-revert-splitted-appointments.component.html',
  styleUrls: ['./dialog-revert-splitted-appointments.component.scss']
})
@Injectable()
export class DialogRevertSplittedAppointmentsComponent implements OnInit {
  selectedRows: any[];

  loading: boolean = false;
  hasValues: boolean = false;
  saving: boolean = false;

  rowsNumber = 0;
  currentPage = 0;
  pages = [];

  splittedAppointments = [];

  retributiveYear: string;

  appointmentsFlatMap: { [id: string]: Appointment } = {};

  constructor(
    private dataApi: DataApiService,
    private downloadsService: DownloadsService,
    public dialogRef: MatDialogRef<DialogRevertSplittedAppointmentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  }

  ngOnInit() {
    this.selectedRows = this.data.selectedRows;

    this.loading = true;

    this.dataApi.getVirtualAppointments(this.selectedRows.map(row => row.id_original)).subscribe((response) => {
      this.splittedAppointments = response;
      this.setAppointmentsFlatMap(this.splittedAppointments);
      this.rowsNumber = this.splittedAppointments.length;
      this.hasValues = this.splittedAppointments.some(originalIds => originalIds.splitted_appointments.length > 0);
      this.loading = false;
    });
  }

  setAppointmentsFlatMap(appointments) {
    appointments.forEach(appointment => {
      this.appointmentsFlatMap[appointment.id] = {
        visible: true,
        selected: false,
        id: appointment.id,
        fecha: appointment.fecha || appointment.splitted_appointments.map(a => a.fecha).sort()[0]
      }
      this.setAppointmentsFlatMap(appointment.splitted_appointments)
    });
  }

  setPage(page) {
    this.currentPage = page;
  }

  save() {
    this.saving = true;
    this.dataApi.revertSplittedAppointments(this.appointmentsToRevert()).subscribe(response => {
      let asyncId = response.async_job_id;
      this.downloadsService.addDownload({
        id: asyncId,
        fileName: "Revirtiendo particiones",
        url: '#',
        status: 'RUNNING'
      });
      let jobSubject = new Subject<AsyncQueryResponse>();
      let poller = this.dataApi.jobQueryPoller(asyncId);
      poller.getObservable().subscribe((pollerResponse: AsyncQueryResponse) => {
        poller.complete();
        this.downloadsService.assignData(pollerResponse, asyncId);
        jobSubject.next(pollerResponse);
        this.downloadsService.downloadReady = true;
        return;
      });
      this.saving = false;
      this.close();
    });
  }

  close() {
    this.dialogRef.close();
  }

  private appointmentsToRevert() {
    return Object.values(this.appointmentsFlatMap).filter(appointment => appointment.selected);
  }

  switchVisible(id) {
    this.appointmentsFlatMap[id].visible = !this.appointmentsFlatMap[id].visible;
  }

  visible(id) {
    return this.appointmentsFlatMap[id].visible;
  }

  changeSelectedPartitions(row) {
    this.select(row.splitted_appointments, this.appointmentsFlatMap[row.id].selected);
  }

  private select(list, selectedValue) {
    list.forEach(appointment => {
      if (appointment.splitted_appointments.length > 0) {
        this.appointmentsFlatMap[appointment.id].selected = selectedValue;
        this.select(appointment.splitted_appointments, selectedValue);
      }
    });
  }
}
