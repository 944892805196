import { Component, OnInit, Input, NgZone, ChangeDetectionStrategy } from '@angular/core';

import {
  Notification,
  NotificationsService,
} from './../../services/notifications/notifications.service';

@Component({
  selector: 'bdr-popup-notifications',
  templateUrl: './popup-notifications.component.html',
  styleUrls: ['./popup-notifications.component.scss']
})
export class PopupNotificationsComponent implements OnInit {
  @Input() notification: Notification;

  private sec: number;
  private timeoutId: any;

  constructor(private notificationsService: NotificationsService, private ngZone: NgZone) {
    this.sec = 5000;
  }

  ngOnInit() {
    if (!this.notification.fixed) {
      this.ngZone.runOutsideAngular(() => {
        this.timeoutId = setTimeout(() => {
          this.ngZone.run(() => {
            this.notificationsService.close(this.notification);
          });
        }, this.sec);
      });
    }
  }

  public close() {
    this.notificationsService.markAsRead(this.notification);
    clearTimeout(this.timeoutId);
  }

  public undo() {
    this.notificationsService.close(this.notification);
    clearTimeout(this.timeoutId);
  }

  public action() {
    this.notificationsService.action(this.notification);
  }

  public showDetail() {
    this.notificationsService.showDetail(this.notification);
  }
}
