import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { BdrButtonComponent } from "../bdr-button/bdr-button.component";
import { FilterDB } from '../../services/filter/filter.service';
import { CtxAppliedFiltersComponent } from '../bdr-contextual-menu/ctx-applied-filters/ctx-applied-filters.component';
import { BdrContextualMenuService } from '../../services/bdr-contextual-menu/bdr-contextual-menu.service';
@Component({
  selector: "bdr-applied-filters",
  templateUrl: "./bdr-applied-filters.component.html",
  styleUrls: ["./bdr-applied-filters.component.scss"]
})
export class BdrAppliedFilters /* implements OnInit */ {
  // @Input() config;
  // @Input() changedColumns: boolean;
  @Input() appliedFilters: FilterDB[];
  @Output() resetFilterRequest: EventEmitter<FilterDB> = new EventEmitter<FilterDB>();

  constructor(private ctxmenuService: BdrContextualMenuService) { }


  getItemName(item: FilterDB): string {
    const filterDefaultName = "Filtro aplicado";
    const ruleDefaultName = "Regla aplicada";
    const itemDefaultName = "Elemento aplicado";

    const currentDefaultName = this.isFilter(item)
      ? filterDefaultName
      : this.isRule(item) ? ruleDefaultName : itemDefaultName;

    return item.name !== "" ? item.name : currentDefaultName;
  }

  private isFilter(item: FilterDB): boolean {
    const filterTypes = [
      "filter",
      "manual-validations-filter",
      "validations-filter",
      "issueFilter"
    ];
    return filterTypes.indexOf(item.meta.type) !== -1;
  }

  private isRule(item: FilterDB): boolean {
    const ruleTypes = ["rule", "workflowRule", "assignationRule"];
    return ruleTypes.indexOf(item.meta.type) !== -1;
  }

  resetFilter(filter: FilterDB): void {
    this.resetFilterRequest.emit(filter);
  }

  openAppliedFiltersTooltip(evt: MouseEvent) {
    const options = {
      body: {
        filters: this.appliedFilters,
        removeAppliedFilter: this.removeAppliedFilterAction.bind(this)
      }
    };
    this.ctxmenuService.open(CtxAppliedFiltersComponent, evt, options);
  }

  private removeAppliedFilterAction(filter: FilterDB, filtersLength: number) {
    this.resetFilter(filter);
    if (filtersLength === 2) {
      this.ctxmenuService.close();
    }
  }

}
