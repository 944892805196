import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Validation } from '../../shared/interfaces/validations.interface';

@Component({
  selector: 'bdr-validations-list',
  templateUrl: './validations-list.component.html',
  styleUrls: ['./validations-list.component.scss'],
})
export class ValidationsListComponent {
  _validations: Validation[];
  @Input() set validations(value: Validation[]) {
    const checkedValidations = value.map((val) => ({ ...val, checked: false }));
    this._validations = checkedValidations;
  }
  get validations() {
    return this._validations.filter((validations) => validations.checked);
  }

  @Output() changeValidations: EventEmitter<Validation[]> = new EventEmitter<Validation[]>();

  onChangeValidations(event: Event) {
    event.stopPropagation();
    this.changeValidations.emit(this.validations);
  }
}
