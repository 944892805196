import {
  Directive,
  EventEmitter,
  Input,
  ElementRef,
  Inject,
  OnInit
} from '@angular/core';

@Directive({
  selector: '[focusDirective]'
})
export class FocusDirective implements OnInit {
  @Input('focusDirective') focusEvent: EventEmitter<boolean>;

  constructor(
    @Inject(ElementRef) private element: ElementRef
  ) { }

  ngOnInit() {
    this.element.nativeElement.focus()
  }
}
