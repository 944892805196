import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { GridExportData } from '../../services/grid/grid.service';
import { DataApiService } from '../../services/data-api/data-api.service';
import { ActivatedRoute, Router } from '@angular/router';

import { Subject, throwError, Observable, concat } from 'rxjs';
import { DialogService } from '../../containers/dialog/dialog.service';
import { GridHandlerViewComponent } from '../../containers/grid-handler-view/grid-handler-view.component';
import { DialogElaDetailInstallationsComponent } from '../../containers/dialog/dialog-ela-detail-installations/dialog-ela-detail-installations.component';
import { DialogWeightsComponent } from '../../containers/dialog/dialog-facilities-weights/dialog-facility-weights.component';
import { slideInOutAnimation } from '../../utils/slide-in-out.animation';
import { cloneDeep } from 'lodash';
import 'rxjs/add/observable/forkJoin';
import { DownloadsService } from '../../services/downloads/downloads.service';
import {
  NotificationsService,
  Notification
} from '../../services/notifications/notifications.service';
import { COHERENCE_PROCESSES_AT } from '../../services/bdr-validations/bdr-validations.service';
import { InvestmentFilterService } from '../../services/investment-filter/investment-filter.service';
import {
  CellValueChangedEvent,
  ColDef,
  GridApi,
  GridOptions,
} from 'ag-grid-community';
import { AgGridCustomColumn } from '../../../app/shared/interfaces/master-data.interface';
import { AgGridColumn } from 'ag-grid-angular';
import { CustomHeaderComponent } from '../../../app/shared/components/aggrid-table/custom-header/custom-header.component';
import { DataOrigin, DataParams, DataResponse, DataTable, QueryFilters } from '../../../app/shared/interfaces/data.interface';
import { catchError, finalize, map, takeUntil, tap } from 'rxjs/operators';
import { FilterDB } from '../../../app/services/filter/filter.service';
import { MastersService } from '../../../app/services/masters/masters.service';

const CLOSED_AUDITORY = "La Auditoría está cerrada.";

@Component({
  selector: 'ela-detail',
  templateUrl: './ela-detail.component.html',
  styleUrls: ['./ela-detail.component.scss'],
  animations: [slideInOutAnimation]
})
export class ElaDetailComponent implements OnInit, OnDestroy {
  @ViewChild(GridHandlerViewComponent, { static: false }) gridHandler: GridHandlerViewComponent;

  elaId: string;
  retributiveYear: string;
  elaRecord: any;
  dispatchersElaRecord: any;


  atMoney: number = 0;
  dispatchersMoney: number = 0;

  filteredAtMoney: number = 0;
  filteredDispatchersMoney: number = 0;


  atInstallations: any;
  dispatchersInstallations: any;

  inventoryRows: any[] = [];
  isFloatingFilter: boolean = false;
  atGridOptions;
  dispatchersGridOptions;
  splashMessage = 'Detalle de ELA';
  atSelectedRows = [];
  dispatchersSelectedRows = [];
  canEdit: boolean = true;
  noRowsTemplate: string = "No hemos encontrado ningún registro";
  isFiltering: boolean = false;
  isEditable = true;
  nonEditableMessage = '';

  saving: boolean = false;
  atManualInstallationsEditionEnable = {
    enable: false
  };
  dispatchersManualInstallationsEditionEnable = {
    enable: false
  };

  weights = {
    'AT': {

    },
    'DESPACHOS': {

    }
  };

  manualInstallations = {
    'AT': {

    },
    'DESPACHOS': {

    }
  };

  manualWeights = {
    'AT': {

    },
    'DESPACHOS': {

    }
  }

  weightsToDelete = {
    'AT': {

    },
    'DESPACHOS': {

    }
  }

  editedInstallations = [];

  destroy$: Subject<any> = new Subject();

  gridOptions: GridOptions = {
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
      suppressMenu: true,
      filterParams: {
        textFormatter(value: string) {
          return '';
        },
        filterOptions: ['contains', 'notContains'],
        suppressMenu: true,
        debounceMs: 0,
        newRowsAction: 'keep',
        suppressSyncValuesAfterDataChange: true,
      },
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    rowClassRules: {
      'new-row-added': (params) => {
        return params.context.newRows.has(params.data.timestamp);
      },
      'new-row-error': (params) => {
        return params.context.errorRows.has(params.data.timestamp);
      },
      'removed-row': function (params) {
        return params.data.deletedRow;
      },
    },
    suppressPropertyNamesCheck: true,
    context: {
      newRows: new Set(),
      errorRows: new Set(),
    },
  };

  vtrCodigosActuacion = {};

  constructor(
    private dialogService: DialogService,
    private dataApiService: DataApiService,
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataApiService,
    private downloadsService: DownloadsService,
    private notificationsService: NotificationsService,
    private investmentsFiltersService: InvestmentFilterService,
    private mastersService: MastersService,
    private cdr: ChangeDetectorRef
  ) {
    this.elaId = this.route.snapshot.paramMap.get('id');

    this.atGridOptions = cloneDeep(this.gridOptions);
    this.atGridOptions.onRowSelected = () => {
      this.onAtRowSelected();
    };

    this.dispatchersGridOptions = cloneDeep(this.gridOptions);
    this.dispatchersGridOptions.onRowSelected = () => {
      this.onDispatchersRowSelected();
    };
    this.setRetributiveYear();
  }

  private setRetributiveYear() {
    this.mastersService.getRetributiveYear().subscribe((year) => (this.retributiveYear = year));
  }

  ngOnInit() {
    let f = <DataParams>{
      query: <QueryFilters>{
        filters: [
          {
            rules: [
              {
                field: 'ela_final',
                operator: 'equal',
                value: this.elaId

              }
            ],
            condition: 'AND'
          }
        ]
      },
      fixed_total_count_filters: <QueryFilters>{
        filters: [
          {
            rules: [
              {
                field: 'ela_final',
                operator: 'equal',
                value: this.elaId
              }
            ],
            condition: 'AND'
          }
        ]
      },
    };

    let promise = this.dataApiService.data("maestros_vtr_codigo_actuacion", { limit: 1000 }).toPromise();
    promise.then((results) => {
      for (let res of results.result) {
        if (!this.vtrCodigosActuacion[res.clasificacion]) {
          this.vtrCodigosActuacion[res.clasificacion] = [];
        }
        this.vtrCodigosActuacion[res.clasificacion].push(res.codigo_descripcion_limitado);
      }
    });

    this.isLoadingWeights = true;
    this.dataService.getManualWeights({
      anio_contable: this.retributiveYear,
      ela_final: this.elaId
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        if (response && response.length > 0) {
          for (let result in response) {
            let row = response[result];
            let originWeights = this.weights[row["origen_instalacion"]];
            let incurredKey = `${row["ela_final"]}_${row["ot"]}_${row["clase_coste"]}`;
            if (!originWeights[incurredKey]) {
              originWeights[incurredKey] = {};
            }
            let installationKey = `${row["instalacion"]}_${row["secuencial"]}`;
            originWeights[incurredKey][installationKey] = row["reparto"];
          }
        }
        this.isLoadingWeights = false;
      });

    this.data$ = this.data(<DataOrigin>{ origin: 'sabana_inversiones_at_traspaso_agregado_vista', title: '' }, f);
    this.data2$ = this.data(<DataOrigin>{ origin: 'sabana_inversiones_at_detalle_ela_instalaciones_vista', title: '' }, f);
    this.data3$ = this.data(<DataOrigin>{ origin: 'sabana_inversiones_despachos_detalle_ela_vista', title: '' }, f);
    this.data4$ = this.data(<DataOrigin>{ origin: 'sabana_inversiones_despachos_detalle_ela_instalaciones_vista', title: '' }, f);

    this.notificationsService.getNotificationObservable().subscribe(notifications => {
      let closedAuditory = notifications.some(n => n.class === 'closed_auditory');
      this.isEditable = !closedAuditory;
      this.nonEditableMessage = this.isEditable ? null : CLOSED_AUDITORY;
      this.cdr.detectChanges();
    });
  }


  ngOnDestroy() {
    this.dialogService.clearDialogs();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  handleSelectedRows(rows, enableEdition): void {
    let differentSelectedInstallations = new Set(
      rows.map(row => {
        return row['validas_declarar']
          ? row['validas_declarar']
            .split(';')
            .sort()
            .join(';')
          : '';
      })
    );
    let differenteSelectedTypes = new Set(rows.map(row => row.clasificacion));
    enableEdition.enable = differentSelectedInstallations.size <= 1 && differenteSelectedTypes.size == 1;
  }

  addNotification(type: 'confirmation' | 'error' | 'info', message: string) {
    const saveNotification: Notification = {
      fixed: false,
      popup: true,
      type,
      message
    };
    setTimeout(() => {
      this.notificationsService.add(saveNotification);
    }, 0);
  }

  filterAtDetail() {
    this.investmentsFiltersService.filterGroupedAtDetail(this.atSelectedRows);
  }

  data$: Observable<DataTable>;
  data2$: Observable<DataTable>;
  data3$: Observable<DataTable>;
  data4$: Observable<DataTable>;

  isLoadingWeights = false;
  isLoadingAT = false;
  isLoadingATInstallations = false;
  isLoadingDispatchers = false;
  isLoadingDispatchersInstallations = false;

  atIncurredsGridApi: GridApi;
  atInstallationsGridApi: GridApi;

  dispatchersIncurredsGridApi: GridApi;
  dispatchersInstallationsGridApi: GridApi;

  dataTables = {};

  private data(origin: DataOrigin, params?: DataParams): Observable<DataTable> {
    let o = origin.origin;

    switch (o) {
      case 'sabana_inversiones_at_traspaso_agregado_vista':
        this.isLoadingAT = true;
        break;
      case 'sabana_inversiones_despachos_detalle_ela_vista':
        this.isLoadingDispatchers = true;
        break;
      case 'sabana_inversiones_at_detalle_ela_instalaciones_vista':
        this.isLoadingATInstallations = true;
        break;
      case 'sabana_inversiones_despachos_detalle_ela_instalaciones_vista':
        this.isLoadingDispatchersInstallations = true;
        break;
      default:
        break;
    }

    return this.query(origin, params).pipe(
      tap((res) => {
        this.dataTables[o] = cloneDeep(res)
        if (o === 'sabana_inversiones_at_traspaso_agregado_vista') {
          this.elaRecord = res.rows[0];
          this.atMoney = res.amount.total;
          this.filteredAtMoney = res.amount.filtered;

        } else if (o === 'sabana_inversiones_despachos_detalle_ela_vista') {
          this.dispatchersElaRecord = res.rows[0];
          this.dispatchersMoney = res.amount.total ?? 0;
          this.filteredDispatchersMoney = res.amount.filtered;

        } else if (o === 'sabana_inversiones_at_detalle_ela_instalaciones_vista') {
          this.atInstallations = res.rows;
          this.atInstallations.forEach(i => {
            i.fromDB = true;
          })
        } else if (o === 'sabana_inversiones_despachos_detalle_ela_instalaciones_vista') {
          this.dispatchersInstallations = res.rows;
          this.dispatchersInstallations.forEach(i => {
            i.fromDB = true;
          })
        }
      }),
      finalize(() => {
        if (o === 'sabana_inversiones_at_traspaso_agregado_vista') {
          this.isLoadingAT = false;
        } else if (o === 'sabana_inversiones_despachos_detalle_ela_vista') {
          this.isLoadingDispatchers = false;
        } else if (o === 'sabana_inversiones_at_detalle_ela_instalaciones_vista') {
          this.isLoadingATInstallations = false;
        } else if (o === 'sabana_inversiones_despachos_detalle_ela_instalaciones_vista') {
          this.isLoadingDispatchersInstallations = false;
        }
        this.cdr.detectChanges();
      }),
      catchError((err) => throwError(err))
    );
  }

  private query(origin: DataOrigin, params: DataParams = { limit: 50 }): Observable<DataTable> {
    const { origin: originName } = origin;

    params = {
      ...params,
      ...{ offset: 0, limit: 1000 },
    };

    return this.dataService.data(originName, params).pipe(
      map((response: DataResponse) => ({
        headers: this.setHeaders(response.headers, response.result),
        rows: response.result,
        filtered: response.filtered,
        count: response.count,
        manualTable: response.manual_table,
        canUnloadS3: response.unload_to_s3_after_amendment,
        amount: response.amount,
      })),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  private setHeaders(headers: AgGridCustomColumn[], rows) {
    this.setSelectableColumn(headers);
    return headers.map<Partial<AgGridColumn>>((header: AgGridCustomColumn) => ({
      ...header,
      ...this.getCustomHeaders(header),
      ...this.setEditableColumns(header),
      ...this.addSelectCellEditor(header, rows),
    }));
  }

  private getCustomHeaders(header: AgGridCustomColumn) {
    return {
      headerComponentFramework: CustomHeaderComponent,
      headerComponentParams: { isEditable: header.isEditable },
    };
  }

  private setSelectableColumn(headers) {
    const selectableColumn: Partial<AgGridColumn> = {
      headerName: '',
      field: 'select',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      resizable: false,
      filter: false,
      pinned: 'left',
      width: 40,
    };
    headers.unshift(selectableColumn);
  }

  private setEditableColumns(header: AgGridCustomColumn) {
    const canEdit = (params) => header.primaryKey ?
      params.context.newRows.has(params.data.timestamp) : header.isEditable;

    return {
      editable: canEdit,
    };
  }

  private getUFDValidationNicename(id) {
    return COHERENCE_PROCESSES_AT[id].name;
  }

  private addSelectCellEditor(header: AgGridCustomColumn, rows) {
    if (header.field === 'tipologia_actuacion_final') {
      return {
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: params => {
          return { values: this.vtrCodigosActuacion[params.data.clasificacion] };
        }
      };
    }

    if (header.field === 'validaciones_ufd') {
      return {
        cellRenderer: params => {
          let validations = params.data.validaciones_ufd ? params.data.validaciones_ufd.split('|') : [];
          return validations.map(validacion => {
            return `
                <span class="validations validations--ela-detail fake-icon" title="${this.getUFDValidationNicename(validacion)}">
                  ${validacion} 
                </span>
              `
          }).join(' ');
        }
      };
    }

    return {
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: header.options,
      },
    };
  }


  onAtInstallationsGridReady(params): void {
    this.atInstallationsGridApi = params.api;
  }

  onDispatchersInstallationsGridReady(params): void {
    this.dispatchersInstallationsGridApi = params.api;
  }

  onAtIncurredsGridReady(params): void {
    this.atIncurredsGridApi = params.api;
    this.atSelectedRows = this.atIncurredsGridApi.getSelectedNodes();
  }

  onDispatchersIncurredsGridReady(params): void {
    this.dispatchersIncurredsGridApi = params.api;
    this.dispatchersSelectedRows = this.dispatchersIncurredsGridApi.getSelectedNodes();
  }

  onAtRowSelected() {
    this.atSelectedRows = this.atIncurredsGridApi.getSelectedRows();
    this.onRowSelected(this.atIncurredsGridApi, this.atSelectedRows, this.atManualInstallationsEditionEnable);
  }

  onDispatchersRowSelected() {
    this.dispatchersSelectedRows = this.dispatchersIncurredsGridApi.getSelectedRows();
    this.onRowSelected(this.dispatchersIncurredsGridApi, this.dispatchersSelectedRows, this.dispatchersManualInstallationsEditionEnable);
  }

  onRowSelected(gridApi, rows, enableEdition) {
    rows = gridApi.getSelectedRows();
    this.handleSelectedRows(rows, enableEdition);
  }

  handleSortColumns(event) {
    this.isLoadingAT = true;
  }

  onAtIncurredsFilter(event: Event) {
    this.onFilter(event,
      this.atIncurredsGridApi,
      this.dataTables['sabana_inversiones_at_traspaso_agregado_vista'],
      <DataOrigin>{ origin: 'sabana_inversiones_at_traspaso_agregado_vista', title: '' },
      'at-incurreds-table'
    );
  }

  onAtInstallationsFilter(event: Event) {
    this.onFilter(event,
      this.atInstallationsGridApi,
      this.dataTables['sabana_inversiones_at_detalle_ela_instalaciones_vista'],
      <DataOrigin>{ origin: 'sabana_inversiones_at_detalle_ela_instalaciones_vista', title: '' },
      'at-installations-table'
    );
  }

  onDispatchersIncurredsFilter(event: Event) {
    this.onFilter(event,
      this.dispatchersIncurredsGridApi,
      this.dataTables['sabana_inversiones_at_detalle_ela_instalaciones_vista'],
      <DataOrigin>{ origin: 'sabana_inversiones_at_detalle_ela_instalaciones_vista', title: '' },
      'dispatchers-incurreds-table'
    );
  }

  onDispatchersInstallationsFilter(event: Event) {
    this.onFilter(event,
      this.dispatchersInstallationsGridApi,
      this.dataTables['sabana_inversiones_despachos_detalle_ela_instalaciones_vista'],
      <DataOrigin>{ origin: 'sabana_inversiones_despachos_detalle_ela_instalaciones_vista', title: '' },
      'dispatchers-installations-table'
    );
  }

  onFilter(event: Event, gridApi: GridApi, masterTable, origin, tableId) {
    event.stopPropagation();

    this.isFloatingFilter = !this.isFloatingFilter;

    setTimeout(() => {
      gridApi.refreshHeader();
      let colDefs = gridApi.getColumnDefs();
      colDefs.forEach((c: ColDef) => c.floatingFilter = true);

      try {
        gridApi.setColumnDefs(colDefs);
      } catch (error) { }

      const filters = document.querySelector(
        "#" + tableId + " .ag-header-container .ag-header-row[aria-rowindex='2']"
      );

      filters.addEventListener('keypress', (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          this.handleFilters(masterTable, gridApi, origin);
        }
      });
    }, 0);
  }

  handleFilters(masterTable, gridApi, origin) {
    this.getFilter(masterTable, gridApi, origin);
  }

  getFilter(masterTable, gridApi, origin) {
    this.isLoadingAT = true;
    this.isFiltering = true;
    this.cdr.detectChanges();
    this.data(origin, this.getParams(gridApi))
      .pipe(catchError((err) => this.handleErrors(err)))
      .subscribe((res) => {
        masterTable.rows = res.rows;
        masterTable.filtered = res.filtered;
        gridApi.setRowData(res.rows);
        this.isLoadingAT = false;
        this.isFiltering = false;
        this.cdr.detectChanges();
      });
  }

  getParams(gridApi): DataParams {
    return this.queryFilters(gridApi);
  }

  private handleErrors(error) {
    this.isLoadingAT = false;
    this.cdr.detectChanges();
    return throwError(error);
  }


  queryFilters(gridApi): DataParams {
    let filters = gridApi.getFilterModel();

    let filterParams = <DataParams>{
      query: <QueryFilters>{
        filters: [
          {
            rules: [
              {
                field: 'ela_final',
                operator: 'equal',
                value: this.elaId
              }
            ],
            condition: 'AND'
          }
        ]
      },
      fixed_total_count_filters: <QueryFilters>{
        filters: [
          {
            rules: [
              {
                field: 'ela_final',
                operator: 'equal',
                value: this.elaId
              }
            ],
            condition: 'AND'
          }
        ]
      },
    };

    if (Object.keys(filters).length > 0) {
      const addRule = (columnName: string, value: string) => ({
        operator: 'contains',
        id: columnName,
        field: columnName,
        type: 'string',
        value,
      });

      let arr = [];
      for (let item in filters) {
        arr.push(addRule(item, filters[item].filter));
      }

      filterParams.query.filters[0]['rules'] = [...filterParams.query.filters[0]['rules'], ...arr];
    }

    return filterParams;
  }


  /*
   *
   * DIALOGS & DOWNLOADS
   * 
   */

  showAssociateAtInstallations(id) {
    this.showAssociateInstallations(id, this.atSelectedRows, this.atInstallations, 'AT');
  }

  showAssociateDispatchersInstallations(id) {
    this.showAssociateInstallations(id, this.dispatchersSelectedRows, this.dispatchersInstallations, 'DESPACHOS');
  }

  private showAssociateInstallations(id, selectedIncurreds, installations, origin) {
    const dialog = {
      id,
      component: DialogElaDetailInstallationsComponent,
      title: 'Asociar / Desasociar Instalaciones',
      size: { width: 60, height: 40 },
      fixed: true,
      bindings: {
        inputs: {
          id: 'installations-associate',
          type: 'associate-installations-rows',
          incurreds: selectedIncurreds,
          installations: installations,
          installationType: selectedIncurreds[0].clasificacion,
          origin: origin,
          ela: this.elaId
        },
        outputs: {
          closeDialog: this.dialogService.closeDialog.bind(this.dialogService),
          backAction: this.dialogService.closeDialog.bind(this.dialogService),
          onAssociateDesassociateATInstallations: this.onAssociateDesassociateATInstallations.bind(this),
          onAssociateDesassociateDispatchersInstallations: this.onAssociateDesassociateDispatchersInstallations.bind(this)
        }
      }
    };

    this.dialogService.createDialog(dialog);
  }

  showAtModifyWeights(id) {
    this.showModifyWeights(id, this.atSelectedRows, this.atInstallations, this.atMoney, 'AT');
  }

  showDispatchersModifyWeights(id) {
    this.showModifyWeights(id, this.dispatchersSelectedRows, this.dispatchersInstallations, this.dispatchersMoney, 'DESPACHOS');
  }

  private showModifyWeights(id, incurreds, installations, elaAmount, origin) {
    let incurred = incurreds[0];
    let incurredInstallationIds = ((incurred.validas_declarar || '').split(';'));
    let incurredInstallations = installations.filter(i => incurredInstallationIds.includes(`${i.instalacion}_${i.secuencial}`))
    const dialog = {
      id,
      component: DialogWeightsComponent,
      title: 'Modificar criterios de reparto',
      size: { width: 60, height: 40 },
      fixed: true,
      bindings: {
        inputs: {
          id: 'percentages-edit',
          type: 'edit-percentages-rows',
          elaDescription: this.elaRecord.ela_final + ' - ' + this.elaRecord.ela_final_desc,
          elaAmount: elaAmount,
          incurred: incurred,
          installations: incurredInstallations,
          weights: this.weights[origin][`${incurred.ela_final}_${incurred.ot}_${incurred.clase_coste}`] || {},
          origin: origin
        },
        outputs: {
          onDeleteWeights: this.onDeleteWeights.bind(this),
          onModifyWeights: this.onModifyWeights.bind(this),
          closeDialog: this.dialogService.closeDialog.bind(this.dialogService),
          backAction: this.dialogService.closeDialog.bind(this.dialogService)
        }
      }
    };
    this.dialogService.createDialog(dialog);
  }

  back() {
    this.router.navigate([
      './investments-sheet/at/sabana_inversiones_at_traspaso_agregado_vista'
    ]);
  }

  onDownloadAtIncurreds(event: Event) {
    event.stopPropagation();
    let exportItem: GridExportData = {
      origin: 'sabana_inversiones_at_traspaso_agregado_vista',
      filterOrigin: 'sabana_inversiones_at_traspaso_agregado_vista',
      filterable: true,
      filename: 'Detalle ELA - Incurridos AT',
      title: 'Detalle ELA - Incurridos AT'
    };
    this.download(exportItem, this.atInstallationsGridApi);
  }

  onDownloadDispatchersIncurreds(event: Event) {
    event.stopPropagation();
    let exportItem: GridExportData = {
      origin: 'sabana_inversiones_despachos_detalle_ela_vista',
      filterOrigin: 'sabana_inversiones_despachos_detalle_ela_vista',
      filterable: true,
      filename: 'Detalle ELA - Incurridos despachos',
      title: 'Detalle ELA - Incurridos despachos'
    };
    this.download(exportItem, this.atInstallationsGridApi);
  }

  onDownloadAtInstallations(event: Event) {
    event.stopPropagation();
    let exportItem: GridExportData = {
      origin: 'sabana_inversiones_at_detalle_ela_instalaciones_vista',
      filterOrigin: 'sabana_inversiones_at_detalle_ela_instalaciones_vista',
      filterable: true,
      filename: 'Detalle ELA - Instalaciones AT',
      title: 'Detalle ELA - Instalaciones AT'
    };
    this.download(exportItem, this.atInstallationsGridApi);
  }

  onDownloadDispatchersInstallations(event: Event) {
    event.stopPropagation();
    let exportItem: GridExportData = {
      origin: 'sabana_inversiones_despachos_detalle_ela_instalaciones_vista',
      filterOrigin: 'sabana_inversiones_despachos_detalle_ela_instalaciones_vista',
      filterable: true,
      filename: 'Detalle ELA - Instalaciones Despachos',
      title: 'Detalle ELA - Instalaciones Despachos'
    };
    this.download(exportItem, this.atInstallationsGridApi);
  }

  private download(exportItem, gridApi) {
    let filter = <FilterDB>{
      meta: { type: 'filter' },
      table: exportItem.origin,
      filter: this.getParams(gridApi).query.filters[0]
    };
    this.downloadsService.asyncDownload(exportItem, true, [filter], [], []);
  }

  /*
   * MANUAL
   */

  undo() {
    this.ngOnInit()
  }

  resetManual() {
    this.manualInstallations = {
      'AT': {

      },
      'DESPACHOS': {

      }
    };

    this.manualWeights = {
      'AT': {

      },
      'DESPACHOS': {

      }
    }

    this.weightsToDelete = {
      'AT': {

      },
      'DESPACHOS': {

      }
    }
    this.editedInstallations = [];
  }

  canSave() {
    return Object.keys(this.manualInstallations['AT']).length > 0 ||
      Object.keys(this.manualInstallations['DESPACHOS']).length > 0 ||
      Object.keys(this.weightsToDelete['AT']).length > 0 ||
      Object.keys(this.weightsToDelete['DESPACHOS']).length > 0 ||
      Object.keys(this.manualWeights['AT']).length > 0 ||
      Object.keys(this.manualWeights['DESPACHOS']).length > 0 ||
      this.editedInstallations.length > 0;
  }

  private flatObject(obj) {
    return Array.prototype.concat.apply([], Object.values(obj || {}));
  }
  save() {
    this.saving = true;
    let params = {
      edited_installations: this.editedInstallations,
      manual_installations: this.flatObject(this.manualInstallations['AT']).concat(this.flatObject(this.manualInstallations['DESPACHOS'])),
      weights_to_delete: this.flatObject(this.weightsToDelete['AT']).concat(this.flatObject(this.weightsToDelete['DESPACHOS'])),
      manual_weights: this.flatObject(this.manualWeights['AT']).concat(this.flatObject(this.manualWeights['DESPACHOS']))
    };

    this.dataApiService.saveElaDetail(params).subscribe(
      () => {
        this.saving = false;
        this.addNotification('confirmation', 'Modificaciones guardadas correctamente');
        this.resetManual();
      },
      () => {
        this.saving = false;
        this.addNotification('error', 'Se ha producido un error');
        this.resetManual();
      }
    );
  }

  onAssociateDesassociateATInstallations(evt) {
    evt.incurreds.forEach(x => {
      let incurred = this.atIncurredsGridApi.getSelectedRows().find(i =>
        i.ela_final === x.ela_final && i.clase_coste === x.clase_coste && i.ot === x.ot
      )
      incurred.validas_declarar = x.validas_declarar;
    });

    this.atIncurredsGridApi.refreshView();

    let currentInstallations = [];
    this.atIncurredsGridApi.getModel().forEachNode(n => {
      (n.data.validas_declarar || '').split(';').forEach(element => {
        currentInstallations = currentInstallations.concat(element);
      });;
    });

    this.atInstallationsGridApi.setRowData(evt.installations.filter(i => currentInstallations.includes(i.instalacion + '_' + i.secuencial)));
    this.manualInstallations['AT'] = { ... this.manualInstallations['AT'], ...evt.manualInstallations };
  }

  onAssociateDesassociateDispatchersInstallations(evt) {
    evt.incurreds.forEach(changedIncurred => {
      let incurred = this.dispatchersIncurredsGridApi.getSelectedRows().find(i =>
        i.ela_final === changedIncurred.ela_final
        && i.clase_coste === changedIncurred.clase_coste
        && i.ot === changedIncurred.ot
      )
      incurred.validas_declarar = changedIncurred.validas_declarar;
    });

    this.dispatchersIncurredsGridApi.refreshView();

    let currentInstallations = [];
    this.dispatchersIncurredsGridApi.getModel().forEachNode(n => {
      (n.data.validas_declarar || '').split(';').forEach(element => {
        currentInstallations = currentInstallations.concat(element);
      });;
    });

    this.dispatchersInstallationsGridApi.setRowData(evt.installations.filter(i => currentInstallations.includes(i.instalacion + '_' + i.secuencial)));
    this.manualInstallations['DESPACHOS'] = { ... this.manualInstallations['DESPACHOS'], ...evt.manualInstallations };
  }


  onDeleteWeights(evt) {
    let origin = evt.origin;
    this.weightsToDelete[origin][evt.incurredKey] = evt.weightToDelete;
    this.weights[origin][evt.incurredKey] = evt.weights;
  }

  onModifyWeights(evt) {
    let origin = evt.origin;
    this.manualWeights[origin][evt.incurredKey] = evt.manualWeight;
    this.weights[origin][evt.incurredKey] = evt.weights;
  }

  onAtCellValueChanged(event: CellValueChangedEvent): void {
    this.onCellValueChange(event, 'AT', this.atInstallations);
  }

  onDispatchersCellValueChanged(event: CellValueChangedEvent): void {
    this.onCellValueChange(event, 'DESPACHOS', this.dispatchersInstallations);
  }

  private onCellValueChange(event: CellValueChangedEvent, installationOrigin, installations) {
    if (event.colDef.field === 'tipologia_actuacion_final' && this.checkTipologiesError(installations)) {
      this.addNotification('error', 'No puede asignar la misma tipología dos veces a la misma instalación');
      return;
    }
    const { newValue, oldValue } = event;
    const hasChange = newValue !== oldValue;
    if (hasChange) {
      let row = event.data;
      let amendment = this.editedInstallations.find(amendment =>
        amendment.instalacion === row.instalacion
        && amendment.secuencial === row.secuencial
        && amendment.origen_instalacion === installationOrigin
      );
      if (!amendment) {
        amendment = {
          anio_contable: row.anio_contable,
          ela_final: this.elaId,
          instalacion: row.instalacion,
          secuencial: row.secuencial,
          origen_instalacion: installationOrigin
        }
        this.editedInstallations.push(amendment);
      }
      amendment[event.colDef.field] = newValue;
    }
  }

  private checkTipologiesError(installations) {
    let installationTipologies = {};
    return installations.some(installation => {
      let tipologies = installationTipologies[installation.instalacion] || [];
      if (tipologies.includes(installation.tipologia_actuacion_final)) {
        return true;
      }
      tipologies.push(installation.tipologia_actuacion_final);
      installationTipologies[installation.instalacion] = tipologies;
      return false;
    });
  }

}



