import {
  Component,
  Injectable,
  Inject,
  OnInit,
  NgModule
} from '@angular/core';
import { DataApiService } from '../../../services/data-api/data-api.service';
import { MastersService } from '../../../services/masters/masters.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DownloadsService } from '../../../services/downloads/downloads.service';
import { Subject } from 'rxjs';
import { AsyncQueryResponse } from '../../../models/query-poller';
import { DialogObservationsComponent } from '../dialog-observations/dialog-observations.component';
import { FilterDB } from '../../../services/filter/filter.service';
import { getExpandedItem } from '../../../util';

import * as _ from 'lodash';
import { DialogService } from '../dialog.service';


interface DialogData {
  table: string;
  // selectedRows: any[];
  filters: FilterDB[];
}

@Component({
  selector: 'dialog-split-reclassification-mass-edition',
  templateUrl: './dialog-split-reclassification-mass-edition.component.html',
  styleUrls: ['./dialog-split-reclassification-mass-edition.component.scss']
})
@Injectable()
export class DialogSplitReclassificationMassEditionComponent implements OnInit {
  selectedRows = [];
  filters: FilterDB[];

  isDiffering = true;
  isEditing = true;
  isReclassificating = true;
  massiveEdition = true;

  globalMeiOptions = [];
  globalClassificationOptions = [];
  globalInvestmentsClassificationOptions = [];
  globalActingCodesOptions = [];

  newValues: any = {};

  newAppointments: any = {};
  newSingleAppointmentsAmount: number = 0;

  saving: boolean = false;

  canMassiveSplit: boolean = true;
  canSingleSplit: boolean = true;

  preclasificacionReclOptions = [
    { value: 'MT/BT', name: 'MT/BT' },
    { value: 'AT', name: 'AT' },
    { value: 'EDIFICIOS/VARIOS', name: 'EDIFICIOS/VARIOS' },
    { value: 'DESPACHOS', name: 'DESPACHOS' },
    { value: 'EQUIPOS DE MEDIDA', name: 'EQUIPOS DE MEDIDA' }
  ];

  invesmentClassificationOptionValues = {
    "MT/BT": [
      { value: 'DIRECTO', name: 'DIRECTO' },
      { value: 'EXCLUIR', name: 'EXCLUIR' },
      { value: 'CESIONES', name: 'CESIONES' },
      { value: 'MOI', name: 'MOI' },
      { value: 'BOLSA TRAMITACIONES', name: 'BOLSA TRAMITACIONES' },
      { value: 'BOLSA SUPERVISIÓN', name: 'BOLSA SUPERVISIÓN' },
      { value: 'BOLSA INGENIERÍA', name: 'BOLSA INGENIERÍA' },
      { value: 'BOLSA SEGURIDAD', name: 'BOLSA SEGURIDAD' },
      { value: 'BOLSA GENERAL', name: 'BOLSA GENERAL' },
      { value: 'BOLSA CENTRO', name: 'BOLSA CENTRO' },
      { value: 'BOLSA GALICIA', name: 'BOLSA GALICIA' }
    ],

    "AT": [
      { value: 'DIRECTO', name: 'DIRECTO' },
      { value: 'EXCLUIR', name: 'EXCLUIR' },
      { value: 'BOLSA AT', name: 'BOLSA AT' },
    ],

    "DESPACHOS": [
      { value: 'DIRECTO', name: 'DIRECTO' },
      { value: 'EXCLUIR', name: 'EXCLUIR' },
    ],

    "EDIFICIOS/VARIOS": [
      { value: 'DIRECTO', name: 'DIRECTO' },
      { value: 'EXCLUIR', name: 'EXCLUIR' },
    ],

    "EQUIPOS DE MEDIDA": [
      { value: 'DIRECTO', name: 'DIRECTO' },
      { value: 'EXCLUIR', name: 'EXCLUIR' },
    ]
  };

  yearOptions = [];

  amountErrors: { [key: string]: boolean } = {};
  preclassificationErrors: { [key: string]: boolean } = {};

  rowsNumber = 0;
  currentPage = 0;
  pages = [];

  retributiveYear: string;

  differingMessage: string = "";

  mtbt: boolean = false;
  at: boolean = false;
  despachos: boolean = false;
  equipos: boolean = false;
  edificios: boolean = false;
  cierre_obra: boolean = false;

  massiveEditionSplittedRows: any[] = [];

  constructor(
    private dataApi: DataApiService,
    private mastersService: MastersService,
    private downloadsService: DownloadsService,
    public dialogRef: MatDialogRef<DialogSplitReclassificationMassEditionComponent>,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  }

  ngOnInit() {
    this.filters = this.data.filters

    this.selectedRows = [{
      "anio_contable": null,
      "id": "id",
      "id_original": "id_original",
      "pi": null,
      "pi_desc": null,
      "tipo_inversion": null,
      "ceco_pi": null,
      "ceco_pi_desc": null,
      "ca_pi": null,
      "ot": null,
      "ot_desc": null,
      "ceco_ot": null,
      "ceco_ot_desc":null,
      "clase_ot": null,
      "clase_ot_desc": null,
      "inmovilizado": null,
      "sn": null,
      "inmov_desc": null,
      "clase_inmov": null,
      "clase_inmov_desc": null,
      "preclasificacion": "MT/BT",
      "preclasificacion_recl": null,
      "preclasificacion_final": "MT/BT",
      "ela": null,
      "ela_recl": null,
      "ela_final": null,
      "ela_desc": null,
      "ela_final_desc":null,
      "mei": null,
      "mei_recl": null,
      "mei_final": null,
      "mei_desc": null,
      "mei_final_desc": null,
      "provincia": null,
      "provincia_desc": null,
      "pep": null,
      "pep_desc": null,
      "clase_coste": null,
      "clase_coste_desc": null,
      "clase_coste_tipo": null,
      "centro": "",
      "documento_compra": null,
      "n_factura": "",
      "n_doc": "",
      "clase_doc": null,
      "n_doc_referencia": "",
      "asignacion": "",
      "referencia": "",
      "cta_contrapartida": "",
      "cta_contrapartida_desc": "",
      "prov_n": null,
      "prov_desc": "",
      "txt_documento_compra": "",
      "denominacion_documento_compra": "",
      "texto_cabecera_doc": null,
      "cantidad": 100,
      "ud_cantidad": null,
      "material": null,
      "material_desc": "",
      "num_cuenta": null,
      "fecha_doc": "2021-01-25",
      "mes": 1,
      "ano": 2021,
      "fecha_cont": "2021-01-25",
      "importe": 100,
      "cargo_abono": "S",
      "anulados": "",
      "anulacion": "",
      "ref_anulacion": null,
      "anul": "",
      "anio_retributivo": "2021",
      "clasificacion": null,
      "clasificacion_recl": null,
      "clasificacion_final": null,
      "clasificacion_inversion": null,
      "clasificacion_inversion_recl": null,
      "clasificacion_inversion_final": null,
      "clase_de_coste_concepto": null,
      "cesion": null,
      "origen": null,
      "codigo_obra_trabajo": null,
      "codigo_obra_original": null,
      "codigo_obra": null,
      "descripcion": null,
      "obra_revisada": null,
      "cod_zona": null,
      "tipologia_actuacion": null,
      "responsable": null,
      "obra_cerrada": null,
      "fecha_rte": null,
      "comentarios": null,
      "validaciones_ufd": null,
      "tipo_reclasificacion": null,
      "tipo_apunte": "ORIGINAL",
      "importe_ufd": 0,
      "importe_cedido": 0,
      "instalaciones_inventario_bdi": null,
      "instalaciones_transaccionales_sgt_sgm": null,
      "validas_inventario": null,
      "validas_declarar": null,
      "cod_trabajo": "",
      "observaciones": null
    }];

    this.rowsNumber = this.selectedRows.length;
    this.pages = Array.from(Array(Math.ceil(this.rowsNumber / 10)).keys());

    this.selectedRows = this.selectedRows.map(row => {
      let copiedRow = Object.assign({}, row);
      copiedRow.originalRow = row;
      return copiedRow;
    });

    this.mastersService.getRetributiveYear().subscribe(year => {
      this.retributiveYear = year;
      this.yearOptions = [
        { name: `${+year - 1}`, value: `${+year - 1}` },
        { name: `${+year}`, value: `${+year}` },
        { name: `${+year + 1}`, value: `${+year + 1}` },
      ]
    });

    let table = this.data.table;
    if (this.at = table === 'sabana_inversiones_at_detalle_traspaso_vista') {
      this.preclasificacionReclOptions = this.preclasificacionReclOptions.filter(option => option.name !== 'AT');
      this.globalInvestmentsClassificationOptions = this.invesmentClassificationOptionValues['AT'];
      this.globalMeiOptions = this.meisFromMastersService('AT');
    } else if (this.mtbt = table === 'sabana_inversiones_mtbt_detalle_vista') {
      this.differingMessage = "Recuerde: si cambia el año retributivo de un apunte de MT/BT, se cambiará a su vez para todos los apuntes que tengan su mismo código de obra (en MT/BT y Despachos).";
      this.globalInvestmentsClassificationOptions = this.invesmentClassificationOptionValues['MT/BT'];
      this.globalMeiOptions = this.meisFromMastersService('MT/BT');
    } else if (this.despachos = ['sabana_inversiones_despachos_detalle_vista', 'sabana_informe_instalaciones_despachos_detalle_vista'].includes(table)) {
      this.preclasificacionReclOptions = this.preclasificacionReclOptions.filter(option => option.name !== 'DESPACHOS');
      this.globalInvestmentsClassificationOptions = this.invesmentClassificationOptionValues['DESPACHOS'];
      this.globalMeiOptions = this.meisFromMastersService('DESPACHOS');
    } else if (this.equipos = table === 'sabana_inversiones_equipos_medida_vista') {
      this.preclasificacionReclOptions = this.preclasificacionReclOptions.filter(option => option.name !== 'EQUIPOS DE MEDIDA');
      this.globalInvestmentsClassificationOptions = this.invesmentClassificationOptionValues['EQUIPOS DE MEDIDA'];
      this.globalMeiOptions = this.meisFromMastersService('EQUIPOS DE MEDIDA');
    } else if (this.edificios = table === 'sabana_inversiones_edificios_varios_vista') {
      this.preclasificacionReclOptions = this.preclasificacionReclOptions.filter(option => option.name !== 'EDIFICIOS/VARIOS');
      this.globalInvestmentsClassificationOptions = this.invesmentClassificationOptionValues['EDIFICIOS/VARIOS'];
      this.globalMeiOptions = this.meisFromMastersService('EDIFICIOS/VARIOS');
    } else {
      this.cierre_obra = true;
      this.differingMessage = "Recuerde: si cambia el año retributivo de un apunte de MT/BT, se cambiará a su vez para todos los apuntes que tengan su mismo código de obra (en MT/BT y Despachos).";

      let mtbt_preclasificacion = this.selectedRows
        .findIndex(row => row.preclasificacion_final.toUpperCase() === 'MT/BT') != -1;
      let despachos_preclasificacion = this.selectedRows
        .findIndex(row => row.preclasificacion_final.toUpperCase() === 'DESPACHOS') != -1;

      if (mtbt_preclasificacion && !despachos_preclasificacion) {
        this.globalInvestmentsClassificationOptions = this.invesmentClassificationOptionValues['MT/BT'];
        this.globalMeiOptions = this.meisFromMastersService('MT/BT');
        this.data.table = 'sabana_inversiones_mtbt_detalle_vista';
      } else if (!mtbt_preclasificacion && despachos_preclasificacion) {
        this.preclasificacionReclOptions = this.preclasificacionReclOptions.filter(option => option.name !== 'DESPACHOS');
        this.globalInvestmentsClassificationOptions = this.invesmentClassificationOptionValues['DESPACHOS'];
        this.globalMeiOptions = this.meisFromMastersService('DESPACHOS');
        this.data.table = 'sabana_inversiones_despachos_detalle_vista';
      }
    }

    this.globalClassificationOptions = [];
    this.globalMeiOptions.forEach(mei => {
      let e = { name: mei.classification, value: mei.classification };
      if (this.globalClassificationOptions.every(x => x.name !== e.name && x.value !== e.value)) {
        this.globalClassificationOptions.push(e);
      }
    });
  }

  addMassiveEditionSplittedRow() {
    let new_id = this.massiveEditionSplittedRows[this.massiveEditionSplittedRows.length - 1]
      ? this.massiveEditionSplittedRows[this.massiveEditionSplittedRows.length - 1].id + 1 : 1;

    this.massiveEditionSplittedRows.push({
      id: new_id,
      mei_options: this.globalMeiOptions,
      classification_options: this.globalClassificationOptions,
      acting_code_options: this.globalActingCodesOptions,
      investments_classification_options: this.globalInvestmentsClassificationOptions,
      anio_retributivo: this.retributiveYear,
      importe: 0,
      importe_round: 0,
      clasificacion_inversion_recl: null,
      cini: null,
      num_cuenta: null,
      preclasificacion_recl: null,
      mei_recl: null,
      clasificacion_recl: null,
      ela_recl: null,
      cod_actuacion_recl: null,
      observaciones: null,
      codigo_obra_trabajo: null,
      codigo_obra_original: null,
      codigo_obra: null
    });

    this.selectedRows.forEach(row => this.splitAppointment(row, true));
  }

  removeMassiveEditionSplittedRow(id) {
    this.massiveEditionSplittedRows = this.massiveEditionSplittedRows.filter(x => x.id !== id);

    this.massiveEditionSplittedRows.forEach(function (row, i) {
      row.id = i + 1;
    });

    this.selectedRows.forEach(row => {
      this.removeVirtualAppointment(row.id, this.newAppointments[row.id][id].new_appointment, true);
      (this.newAppointments[row.id] || []).forEach(function (row, i) {
        if (i != 0) {
          row.new_appointment.id = row.new_appointment.id_padre + '_' + i;
        }
      });
    });
  }

  setAllSplitted(field, row, value, idx) {
    this.selectedRows.forEach(row => {
      if (field === 'importe') {
        var new_appointment = this.newAppointments[row.id][idx].new_appointment;

        this.newAppointments[row.id][idx].new_appointment.importe = ((this.newAppointments[row.id][idx - 1].original_appointment.importe) * (value / 100));
        this.newAppointments[row.id][idx].new_appointment.importe_round = (this.newAppointments[row.id][idx].new_appointment.importe).toFixed(2);

        this.amountError(row.id);

        if (new_appointment.new) {
          let oldId = new_appointment.id;
          let originalAppointment = this.newAppointments[row.id][idx].original_appointment;
          let newId = this.appointmentId(originalAppointment, new_appointment);
          this.newAppointments[row.id][idx].new_appointment.id = newId;

          let rowNewValues = this.newValues[oldId];
          if (rowNewValues) {
            this.newValues[newId] = rowNewValues;
            delete this.newValues[oldId];
          }
        }
      } else if (field === 'anio_retributivo') {
        var new_appointment = this.newAppointments[row.id][idx].new_appointment;
        let extraRowsToDiffer = [];

        if (new_appointment) {
          if (this.mtbt || this.despachos || this.cierre_obra) {
            extraRowsToDiffer = this.rows().filter(extra =>
              extra.codigo_obra === row.codigo_obra
              && extra.id !== row.id
              && extra.anio_retributivo !== value
            );
            extraRowsToDiffer.forEach(extraRowToDiffer => this.set('anio_retributivo', extraRowToDiffer, value));
            this.massiveEditionSplittedRows.forEach(massiveRow => massiveRow.anio_retributivo = value);
            this.retributiveYear = value;
          } else {
            this.set('anio_retributivo', new_appointment, value);
          }
        }
      } else if (field === 'clasificacion_inversion_recl') {
        var new_appointment = this.newAppointments[row.id][idx].new_appointment;
        if (new_appointment) {
          this.set('clasificacion_inversion_recl', new_appointment, value);
        }
      } else if (field === 'cini') {
        var new_appointment = this.newAppointments[row.id][idx].new_appointment;
        if (new_appointment) {
          this.set('cini', new_appointment, value);
        }
      } else if (field === 'num_cuenta') {
        var new_appointment = this.newAppointments[row.id][idx].new_appointment;
        if (new_appointment) {
          this.set('num_cuenta', new_appointment, value);
        }
      } else if (field === 'codigo_obra') {
        var new_appointment = this.newAppointments[row.id][idx].new_appointment;
        if (new_appointment) {
          this.set('codigo_obra', new_appointment, value);
        }
      } else if (field === 'preclasificacion_recl') {
        var new_appointment = this.newAppointments[row.id][idx].new_appointment;
        if (new_appointment) {
          this.set('preclasificacion_recl', new_appointment, value);
          new_appointment.mei_options = this.meisFromMastersService(value);

          let selectedRowClasifificacionOptions = [];
          new_appointment.mei_options.forEach(mei => {
            let e = { name: mei.classification, value: mei.classification };
            if (selectedRowClasifificacionOptions.every(x => x.name !== e.name && x.value !== e.value)) {
              selectedRowClasifificacionOptions.push(e);
            }
          });

          this.massiveEditionSplittedRows[idx - 1].mei_options = this.meisFromMastersService(value);
          this.massiveEditionSplittedRows[idx - 1].mei_recl = null;

          this.massiveEditionSplittedRows[idx - 1].clasificacion_recl = null;
          this.massiveEditionSplittedRows[idx - 1].classification_options = selectedRowClasifificacionOptions;
          this.massiveEditionSplittedRows[idx - 1].clasificacion_inversion_recl = row.clasificacion_inversion_original;
          this.massiveEditionSplittedRows[idx - 1].clasificacion_inversion_options = this.investmentClassification(value);
          this.massiveEditionSplittedRows[idx - 1].investments_classification_options = this.invesmentClassificationOptionValues[value];
        }
      } else if (field === 'ela_recl') {
        var new_appointment = this.newAppointments[row.id][idx].new_appointment;
        if (new_appointment) {
          this.set('ela_recl', new_appointment, value);
        }
      } else if (field === 'mei_recl') {
        var new_appointment = this.newAppointments[row.id][idx].new_appointment;
        if (new_appointment) {
          this.set('mei_recl', new_appointment, value);
        }
      } else if (field === 'clasificacion_recl') {
        this.massiveEditionSplittedRows[idx - 1].cod_actuacion_recl = null;
        this.massiveEditionSplittedRows[idx - 1].acting_code_options = this.vtrActingCodesFromMastersService(value);
        var new_appointment = this.newAppointments[row.id][idx].new_appointment;
        if (new_appointment) {
          this.set('clasificacion_recl', new_appointment, value);
        }
      } else if (field === 'cod_actuacion_recl') {
        this.massiveEditionSplittedRows[idx - 1].cod_actuacion_recl = value;
        var new_appointment = this.newAppointments[row.id][idx].new_appointment;
        if (new_appointment) {
          this.set('cod_actuacion_recl', new_appointment, value);
        }
      } else if (field === 'observaciones') {
        this.massiveEditionSplittedRows[idx - 1].observaciones = value;
        var new_appointment = this.newAppointments[row.id][idx].new_appointment;
        if (new_appointment) {
          this.set('observaciones', new_appointment, value);
        }
      }
    });
  }

  reload() {
    this.selectedRows = this.selectedRows.map(row => Object.assign({}, row));
    this.newAppointments = {};
    this.newValues = {};
    if (!this.cierre_obra) {
      this.globalMeiOptions = [];
      this.globalInvestmentsClassificationOptions = [];
    }
    this.globalClassificationOptions = [];
    this.globalActingCodesOptions = [];
    this.amountErrors = {};
    this.preclassificationErrors = {};
    this.massiveEditionSplittedRows = [];
    this.canSingleSplit = true;
    this.canMassiveSplit = true;
  }

  paginatedRows() {
    if (this.massiveEditionSplittedRows.length == 0) {
      return this.rowsToDraw(this.selectedRows.filter(row => row.new === true));
    } else {
      return this.rowsToDraw(this.selectedRows);
    }
    
  }

  rows() {
    return this.rowsToDraw(this.selectedRows);
  }

  splitAppointment(selectedRow, isMassive = false): void {
    if (!isMassive) {
      this.canMassiveSplit = !(this.massiveEditionSplittedRows.length === 0);
      this.newSingleAppointmentsAmount++;
    } else {
      this.canSingleSplit = false;
    }
    selectedRow.splitted = true;

    this.set('clasificacion_inversion_recl', selectedRow, 'PARTIDO-EXCLUIR');

    if (!this.newAppointments[selectedRow.id]) {
      let annulationAppointment = this.virtualAppointment(selectedRow, -selectedRow.importe, null, true);

      annulationAppointment.id = this.appointmentId(selectedRow, annulationAppointment);
      annulationAppointment['clasificacion_inversion'] = 'PARTIDO-EXCLUIR';
      annulationAppointment['tipo_apunte'] = `${this.appointmentTypeSuffix(selectedRow)}-NETEADO`;

      this.newAppointments[selectedRow.id] = [
        {
          original_appointment: selectedRow,
          new_appointment: annulationAppointment
        }
      ];
    }

    let virtualAppointment = this.virtualAppointment(selectedRow, 0, this.newAppointments[selectedRow.id].length, false);

    virtualAppointment.id = this.appointmentId(selectedRow, virtualAppointment);
    virtualAppointment['clasificacion_inversion'] = selectedRow.tipo_apunte.includes('CESION') ? 'CESIONES' : 'DIRECTO';

    virtualAppointment['tipo_apunte'] = `VIRTUAL-${this.appointmentTypeSuffix(selectedRow)}`;
    
    this.newAppointments[selectedRow.id].push({
      original_appointment: selectedRow,
      new_appointment: virtualAppointment
    });

    this.amountError(selectedRow.id);
  }

  setAll(field: string, newValue: any) {
    if (field === 'preclasificacion_recl') {
      this.globalMeiOptions = this.meisFromMastersService(newValue);
      this.globalClassificationOptions = [];
      this.globalMeiOptions.forEach(mei => {
        let e = { name: mei.classification, value: mei.classification };
        if (this.globalClassificationOptions.every(x => x.name !== e.name && x.value !== e.value)) {
          this.globalClassificationOptions.push(e);
        }
      });
      this.globalInvestmentsClassificationOptions = this.investmentClassification(newValue);

      this.massiveEditionSplittedRows.forEach(massiveRow => {
        massiveRow.preclasificacion_recl = newValue;

        massiveRow.mei_recl = null;
        massiveRow.clasificacion_recl = null;

        massiveRow.mei_options = this.globalMeiOptions;
        massiveRow.classification_options = this.globalClassificationOptions;
        massiveRow.clasificacion_inversion_recl = null;
        massiveRow.clasificacion_inversion_options = this.globalInvestmentsClassificationOptions;
      });
    } else if (field === 'clasificacion_recl') {
      this.globalActingCodesOptions = this.vtrActingCodesFromMastersService(newValue);

      this.massiveEditionSplittedRows.forEach(massiveRow => {
        massiveRow.clasificacion_recl = newValue
        massiveRow.acting_code_options = this.globalActingCodesOptions;
      });
    } else if (field === 'cod_actuacion_recl') {
      this.massiveEditionSplittedRows.forEach(massiveRow => {
        massiveRow.cod_actuacion_recl = newValue;
      });
    } else if (field === 'anio_retributivo') {
      let extraRowsToDiffer = this.rows().filter(extra => extra.anio_retributivo !== newValue);

      extraRowsToDiffer.forEach(extraRowToDiffer => this.set('anio_retributivo', extraRowToDiffer, newValue));
      this.massiveEditionSplittedRows.forEach(massiveRow => massiveRow.anio_retributivo = newValue);
      this.retributiveYear = newValue;
    } else if (field === 'clasificacion_inversion_recl') {
      this.massiveEditionSplittedRows.forEach(massiveRow => massiveRow.clasificacion_inversion_recl = newValue);
    } else if (field === 'cini') {
      this.massiveEditionSplittedRows.forEach(massiveRow => massiveRow.cini = newValue);
    } else if (field === 'num_cuenta') {
      this.massiveEditionSplittedRows.forEach(massiveRow => massiveRow.num_cuenta = newValue);
    } else if (field === 'codigo_obra') {
      this.massiveEditionSplittedRows.forEach(massiveRow => massiveRow.codigo_obra = newValue);
    } else if (field === 'mei_recl') {
      this.massiveEditionSplittedRows.forEach(massiveRow => massiveRow.mei_recl = newValue);
    } else if (field === 'ela_recl') {
      this.massiveEditionSplittedRows.forEach(massiveRow => massiveRow.ela_recl = newValue);
    } else if (field === 'observaciones') {
      this.massiveEditionSplittedRows.forEach(massiveRow => massiveRow.observaciones = newValue);
    }

    if (field === 'observaciones') {
      this.rows().forEach(row => this.set(field, row, newValue));
    } else {
      this.rows().filter(row => row.clasificacion_inversion_recl !== 'PARTIDO-EXCLUIR').forEach(row => this.set(field, row, newValue));
    }

  }

  set(field: string, row, newValue: any) {
    if (newValue) {
      let originalValue = row.originalRow[field];
      if (originalValue === newValue && !(this.massiveEdition)) {
        if (this.newValues[row.id]) {
          delete this.newValues[row.id][field];
        }
        if (field === 'preclasificacion_recl') {
          if (this.newValues[row.id]) {
            delete this.newValues[row.id]['mei_recl'];
            delete this.newValues[row.id]['clasificacion_recl'];
            delete this.newValues[row.id]['clasificacion_inversion_recl'];
          }
          row.mei_recl = null;
          row.mei_options = this.meisFromMastersService(newValue);
          row.clasificacion_recl = null;
          let selectedRowClasifificacionOptions = [];
          row.mei_options.forEach(mei => {
            let e = { name: mei.classification, value: mei.classification };
            if (selectedRowClasifificacionOptions.every(x => x.name !== e.name && x.value !== e.value)) {
              selectedRowClasifificacionOptions.push(e);
            }
          })
          row.clasificacion_options = selectedRowClasifificacionOptions;

          row.clasificacion_inversion_recl = row.clasificacion_inversion_original;
          row.clasificacion_inversion_options = this.investmentClassification(newValue);

          delete this.preclassificationErrors[row.id];
        }
        return;
      }

      row[field] = newValue;

      if (field === 'importe') {
        this.amountError(row.id_padre);
        if (row.new) {
          let oldId = row.id;

          // recalculate ID using new amount
          let originalAppointment = this.newAppointments[row.id_padre]
            .find(newAppointment => newAppointment.new_appointment.id === row.id).original_appointment;
          let newId = this.appointmentId(originalAppointment, row);
          row.id = newId;

          // reassign amendments if needed
          let rowNewValues = this.newValues[oldId]
          if (rowNewValues) {
            this.newValues[newId] = rowNewValues;
            delete this.newValues[oldId];
          }
        }
      } else {
        if (!this.isPreclassificationAppointment(row, 'AT') && field === 'ela_recl') {
          return;
        }

        if (!this.isPreclassificationAppointment(row, 'DESPACHOS')
          && !this.isPreclassificationAppointment(row, 'EDIFICIOS/VARIOS')
          && (field === 'cini' || field === 'num_cuenta' || field === 'codigo_obra')) {
          return;
        }
        if (!this.newValues[row.id]) {
          this.newValues[row.id] = {};
        }
        
        this.newValues[row.id][field] = newValue;

        if (field === 'preclasificacion_recl') {
          row.mei_recl = null;
          row.mei_options = this.meisFromMastersService(newValue);

          row.clasificacion_recl = null;

          let selectedRowClasifificacionOptions = [];
          row.mei_options.forEach(mei => {
            let e = { name: mei.classification, value: mei.classification };
            if (selectedRowClasifificacionOptions.every(x => x.name !== e.name && x.value !== e.value)) {
              selectedRowClasifificacionOptions.push(e);
            }
          })
          row.clasificacion_options = selectedRowClasifificacionOptions;

          row.clasificacion_inversion_recl = row.clasificacion_inversion_original;
          row.clasificacion_inversion_options = this.investmentClassification(newValue);

          if (this.massiveEdition) {
            row.preclasificacion_recl = newValue;
            row.preclasificacion = newValue;
          }
          this.preclassificationError(row.id);

        } else if (field === 'ela_recl') {
          row.ela_final = newValue;
          row.ela_recl = newValue;
        } else if (field === 'anio_retributivo') {
          let extraRowsToDiffer = [];
          if (this.mtbt || this.cierre_obra || this.despachos) {
            extraRowsToDiffer = this.rows().filter(extra =>
              extra.codigo_obra === row.codigo_obra
              && extra.id !== row.id
              && extra.anio_retributivo !== newValue
            );
            this.retributiveYear = newValue;
          }
          extraRowsToDiffer.forEach(extraRowToDiffer => this.set('anio_retributivo', extraRowToDiffer, newValue));
        } else if (field === 'mei_recl') {
          if (this.newValues[row.id]) {
            delete this.newValues[row.id]['cod_actuacion_recl'];
            delete this.newValues[row.id]['clasificacion_recl'];
          }
          let clasificacion_recl = row.mei_options.find(mei_option => mei_option.value === newValue).classification;
          this.set('clasificacion_recl', row, clasificacion_recl);
          row.cod_actuacion_recl = null;
          row.cod_actuacion_recl_options = this.vtrActingCodesFromMastersService(clasificacion_recl);
          this.preclassificationError(row.id);
        } else if (field === 'clasificacion_recl') {
          if (this.newValues[row.id]) {
            delete this.newValues[row.id]['cod_actuacion_recl'];
          }
          row.cod_actuacion_recl = null;
          row.cod_actuacion_recl_options = this.vtrActingCodesFromMastersService(newValue);
          this.preclassificationError(row.id);
        } else if (field === 'clasificacion_inversion_recl') {
          this.preclassificationError(row.id);
        }
      }
    } else {
      row[field] = '';
      if (this.newValues[row.id]) {
        delete this.newValues[row.id][field];
      }
    }
  }

  isPreclassificationAppointment(row, preclassification) {
    return (row.preclasificacion === preclassification && !row.preclasificacion_recl) || row.preclasificacion_recl === preclassification;
  }

  removeVirtualAppointment(id_padre, appointmentToRemove, isMassive = false): void {
    this.newAppointments[id_padre] = this.newAppointments[id_padre]
      .filter(newAppointment => newAppointment.new_appointment != appointmentToRemove);

    if (isMassive) {
      this.canSingleSplit = this.massiveEditionSplittedRows.length === 0;
    } else {
      this.newSingleAppointmentsAmount--;

      if (this.newSingleAppointmentsAmount == 0)
        this.canMassiveSplit = true;
    }

    if (this.newAppointments[id_padre].length === 1) {
      delete this.newAppointments[id_padre];
      let originalAppointment = this.selectedRows.find(selectedRow => selectedRow.id === id_padre);
      originalAppointment.splitted = false;
      this.set('clasificacion_inversion_recl', originalAppointment, originalAppointment.clasificacion_inversion_original);
      delete this.preclassificationErrors[id_padre];
      return;
    } else {
      this.amountError(id_padre);
    }
  }

  meisFromMastersService(preclasificacionRecl: string): string[] {
    return this.mastersService
      .getMeisByPreclasification((preclasificacionRecl || '').toUpperCase())
      .map(row => {
        return { name: row.mei + '::' + row.mei_desc, value: row.mei, classification: row.clasificacion };
      });
  }

  vtrActingCodesFromMastersService(classification: string): string[] {
    return this.mastersService
      .getActingCodesByClassification(classification)
      .map(code => {
        return { name: code.codigo_actuacion + " - " + code.descripcion_actuacion, value: code.codigo_actuacion };
      });
  }

  investmentClassification(preclassificationRecl: string) {
    return this.invesmentClassificationOptionValues[preclassificationRecl];
  }

  amountError(id) {
    let originalAmount = this.selectedRows.find(row => row.id == id).importe;

    console.log(`[AmountError] id = ${id} originalAmount = ${originalAmount}`)

    let newAppointmentsAmounts = this.newAppointments[id]
      .map(newAppointment => newAppointment.new_appointment)
      .filter(virtualAppointment => !virtualAppointment.annulation)
      .map(virtualAppointment => Number(virtualAppointment.importe));

      console.log(`[AmountError] newAppointmentsAmounts ${newAppointmentsAmounts}`)

    let sum = newAppointmentsAmounts.reduce((a: number, b: number) => a + b);
    this.amountErrors[id] = newAppointmentsAmounts.some(newAppointmentAmount => newAppointmentAmount <= 0)
      || Math.round(sum * 100) / 100 != originalAmount;

      console.log(`[AmountError] this.amountErrors[id] ${JSON.stringify(this.amountErrors)}`)
  }

  preclassificationError(id) {
    let newValue = this.newValues[id];
    this.preclassificationErrors[id] = newValue['preclasificacion_recl']
      && newValue['clasificacion_inversion_recl'] !== 'PARTIDO-EXCLUIR'
      && !newValue['mei_recl'];
  }

  checkErrors() {
    return Object.values(this.amountErrors).some(v => v) || Object.values(this.preclassificationErrors).some(v => v);
  }

  getPreclasificacion(row) {
    return row.preclasificacion_recl != null ? row.preclasificacion_recl : row.preclasificacion;
  }

  save() {
    if (!this.checkErrors()) {
      Object.keys(this.newAppointments).forEach(id => {
        this.newAppointments[id].forEach(appointment => {
          appointment.new_appointment.originalRow = null;
        });
      });

      const filters = this.filters.map(currentFilter => {
        currentFilter.filter.rules.forEach(rule => delete rule['origin'])
        return getExpandedItem(currentFilter.filter, [])
      })
      
      const queryValue = JSON.stringify(
        {
          filters: [{ rules: filters, condition: 'AND' }],
          logic: 'and',
        }
      )
      
      const sheetValue = this.data.table ? this.data.table.replace('_vista', '') : 'sabana_inversiones_mtbt_detalle'

      let payload = {
        query: queryValue,
        sheet: sheetValue,
        amendments: this.amendments(),
        virtual_appointments: this.newAppointments
      };

      this.dataApi.reclassificationMassEdition(payload).subscribe(response => {
        let asyncId = response.async_job_id;
        this.downloadsService.addDownload({
          id: response.async_job_id,
          fileName: "Ejecutando edición masiva de reclasificación/partición ",
          url: '#',
          status: 'RUNNING'
        });
        let jobSubject = new Subject<AsyncQueryResponse>();
        let poller = this.dataApi.jobQueryPoller(asyncId);
        poller.getObservable().subscribe((pollerResponse: AsyncQueryResponse) => {
          poller.complete();
          this.downloadsService.assignData(pollerResponse, asyncId);
          jobSubject.next(pollerResponse);
          this.downloadsService.downloadReady = true;
          return;
        });
        this.dialogRef.close();
      });
    }
  }

  virtualAppointment(selectedRow, amount, sequence, annulation) {
    let virtualAppointment = {
      id: '',
      id_padre: selectedRow.id,
      id_original: selectedRow.id_original,
      importe: amount,
      anio_retributivo: selectedRow.anio_retributivo,
      preclasificacion: selectedRow.preclasificacion,
      mei: selectedRow.mei,
      ela: selectedRow.ela,
      preclasificacion_recl: selectedRow.preclasificacion_recl,
      ela_recl: selectedRow.ela_recl,
      mei_recl: selectedRow.mei_recl,
      mei_options: selectedRow.mei_options,
      clasificacion_inversion_recl: null,
      clasificacion_inversion: null,
      clasificacion_inversion_original: selectedRow.clasificacion_inversion_original,
      clasificacion_inversion_options: selectedRow.clasificacion_inversion_options,
      clasificacion_recl: selectedRow.clasificacion_recl,
      clasificacion_options: selectedRow.clasificacion_options,
      cod_actuacion_recl: selectedRow.cod_actuacion_recl,
      cod_actuacion_recl_options: selectedRow.cod_actuacion_recl_options,
      new: true,
      annulation: annulation,
      sequence: sequence,
      cod_trabajo: selectedRow.cod_trabajo,
      ela_final: selectedRow.ela_recl || selectedRow.ela_final,
      clase_coste: selectedRow.clase_coste,
      ot: selectedRow.ot,
      cini: selectedRow.cini,
      num_cuenta: selectedRow.num_cuenta,
      observaciones: selectedRow.observaciones,
      codigo_obra_trabajo: selectedRow.codigo_obra_trabajo,
      codigo_obra_original: selectedRow.codigo_obra_original,
      codigo_obra: selectedRow.codigo_obra
    };
    virtualAppointment['originalRow'] = virtualAppointment;
    return virtualAppointment;
  }

  setPage(page) {
    this.currentPage = page;
  }

  rowsToDraw(rowsToDraw: any[]) {
    let rows = [];
    rowsToDraw.forEach(selectedRow => {
      let preclassication = selectedRow.preclasificacion_recl || selectedRow.preclasificacion;

      // MEI
      let meiOptions = selectedRow.mei_options || this.meisFromMastersService(preclassication);
      selectedRow.mei_options = meiOptions;

      // CLASIFICACION INVERSION
      let clasificacionInversionOptions = selectedRow.clasificacion_inversion_options || this.investmentClassification(preclassication);
      selectedRow.clasificacion_inversion_options = clasificacionInversionOptions;
      selectedRow.clasificacion_inversion_original = selectedRow.clasificacion_inversion_original || selectedRow.clasificacion_inversion_final;

      // CLASIFICACION
      let selectedRowClasifificacion = selectedRow.clasificacion_recl || selectedRow.clasificacion;

      let selectedRowClasifificacionOptions = [];
      meiOptions.forEach(mei => {
        let e = { name: mei.classification, value: mei.classification };
        if (selectedRowClasifificacionOptions.every(x => x.name !== e.name && x.value !== e.value)) {
          selectedRowClasifificacionOptions.push(e);
        }
      });

      selectedRow.clasificacion_options = selectedRow.clasificacion_options || selectedRowClasifificacionOptions;

      // COD ACTUACION
      let codActuacionReclOptions = selectedRow.cod_actuacion_recl_options || this.vtrActingCodesFromMastersService(selectedRowClasifificacion);
      selectedRow.cod_actuacion_recl_options = codActuacionReclOptions;

      rows.push(selectedRow);

      let selectedRowNewAppointments = this.newAppointments[selectedRow.id];
      if (selectedRowNewAppointments) {
        selectedRow.editableAmount = true;
        selectedRowNewAppointments.forEach(newAppointment => rows.push(newAppointment.new_appointment));
      }
    });
    return rows;
  }

  getItemName(item: FilterDB): string {
    const filterDefaultName = "Filtro aplicado";
    const ruleDefaultName = "Regla aplicada";
    const itemDefaultName = "Elemento aplicado";

    const currentDefaultName = this.isFilter(item)
      ? filterDefaultName
      : this.isRule(item) ? ruleDefaultName : itemDefaultName;

    return item.name !== "" ? item.name : currentDefaultName;
  }

  private isFilter(item: FilterDB): boolean {
    const filterTypes = [
      "filter",
      "manual-validations-filter",
      "validations-filter",
      "issueFilter"
    ];
    return filterTypes.indexOf(item.meta.type) !== -1;
  }

  private isRule(item: FilterDB): boolean {
    const ruleTypes = ["rule", "workflowRule", "assignationRule"];
    return ruleTypes.indexOf(item.meta.type) !== -1;
  }

  appointmentId(originalAppointment, virtualAppointment) {
    let newId;
    if (originalAppointment.clasificacion_inversion_original === 'CESIONES') {
      let parentId = originalAppointment.id;
      let importe = +virtualAppointment.importe;
      let sequence = virtualAppointment.sequence
        ? `_${virtualAppointment.sequence}` : "";

      newId = `${parentId}_${importe}${sequence}`;
    } else {
      let nDoc = originalAppointment.n_doc ?
        originalAppointment.n_doc : "";
      let ot = originalAppointment.ot ?
        originalAppointment.ot : "";
      let claseCoste = originalAppointment.clase_coste ?
        originalAppointment.clase_coste : "";
      let importe = +virtualAppointment.importe;
      let denominacionDocumentoCompra = originalAppointment.denominacion_documento_compra ?
        originalAppointment.denominacion_documento_compra : "";
      let textoCabeceraDoc = originalAppointment.texto_cabecera_doc ?
        originalAppointment.texto_cabecera_doc : "";
      let sequence = virtualAppointment.sequence ?
        `_${virtualAppointment.sequence}` : "";

      newId = `${nDoc}_${ot}_${claseCoste}_${importe}_${denominacionDocumentoCompra}_${textoCabeceraDoc}${sequence}`;
    }
    return newId;

  }

  amendments() {
    return Object.keys(this.newValues).map(id => {
      let newValues = this.newValues[id]
      return { ... { id: id }, ...newValues };
    });
  }

  showObservationsDialog(row, mode) {
    const dialogRef = this.matDialog.open(DialogObservationsComponent, {
      data: {
        observations: row ? row.observaciones : ''
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        const observaciones = (result === "") ? null : result.trim();
        if (mode.includes('singleEdition')) {
          this.set('observaciones', row, observaciones);
        } else if (mode.includes('massiveEdition')) {
          this.setAll('observaciones', observaciones);
        } else if (mode.includes('massiveSplittedEdition')) {
          this.setAllSplitted('observaciones', row, observaciones, row.id);
        }
      }
    });
  }

  private appointmentTypeSuffix(row) {
    if (row.tipo_apunte.includes('RECLASIFICADO') || this.isReclassification(this.newValues[row.id])) {
      return 'RECLASIFICADO';
    } else if (row.tipo_apunte.includes('CESION')) {
      return 'CESIONES';
    }
    return 'ORIGINAL';
  }

  private isReclassification(values) {
    return values.anio_retributivo ||
      values.preclasificacion_recl ||
      values.mei_recl ||
      values.ela_recl ||
      values.clasificacion_recl ||
      values.cod_actuacion_recl;
  }
}