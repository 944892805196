import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ContextualmenuContent } from '../contextual-menu-content';
import { BdrContextualMenuService } from '../../../services/bdr-contextual-menu/bdr-contextual-menu.service';

@Component({
  selector: 'ctx-export',
  templateUrl: './ctx-export.component.html',
  styleUrls: ['./ctx-export.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CtxExportComponent extends ContextualmenuContent {
  formRadiosModel: string;
  formRadios: any;
  formData: any;

  constructor(private _ctxmenuService: BdrContextualMenuService) {
    super(_ctxmenuService);

    this.formRadiosModel = 'normal';
    this.formRadios = [
      {
        id: 'normal',
        name: 'Normal'
      },
      {
        id: 'buscon',
        name: 'Agrupaciones de activos por jerarquías'
      }
    ];
    this.formData = {
      entity: 'meter',
      period: 'today',
      dateFrom: null,
      dateTo: null,
      report: 's05'
    };
  }

  export(): void {
    this.content.export();
    this.close();
  }

  sendForm(): void {
    this.content.buscon(this.formData);
    this.close();
  }
}
