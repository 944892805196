import { Component, ChangeDetectionStrategy } from '@angular/core';

import { ContextualmenuContent } from './../contextual-menu-content';
import { BdrContextualMenuService } from './../../../services/bdr-contextual-menu/bdr-contextual-menu.service';

@Component({
  selector: 'bdr-ctx-text-info',
  templateUrl: './ctx-text-info.component.html',
  styleUrls: ['./ctx-text-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CtxTextInfoComponent extends ContextualmenuContent {
  constructor(private _ctxmenuService: BdrContextualMenuService) {
    super(_ctxmenuService);
  }
}
