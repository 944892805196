import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { ContextualmenuContent } from './../contextual-menu-content';
import { BdrContextualMenuService } from '../../../services/bdr-contextual-menu/bdr-contextual-menu.service';

@Component({
  selector: 'bdr-ctx-choose-tag',
  templateUrl: './ctx-choose-tag.component.html',
  styleUrls: ['./ctx-choose-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CtxChooseTagComponent extends ContextualmenuContent
  implements OnInit {
  model: string;
  newTag: boolean;
  isItem: boolean;
  itemName: string;

  private tag: string;

  constructor(private _ctxmenuService: BdrContextualMenuService) {
    super(_ctxmenuService);
  }

  ngOnInit() {
    this.model = this.content.item.tag || '';
    this.itemName = this.content.item.name;
    this.newTag = this.model === '';
    this.isItem = this.content.isItem;
  }

  modelChange(model: string): void {
    this.newTag = true;
    this.tag = model;
  }

  selectOption(option: string): void {
    this.newTag = false;
    this.tag = option;
  }

  setTag(): void {
    this.content.setTag(this.tag.trim() || undefined);
  }
}
