import {
  Directive,
  ElementRef,
  Renderer2,
  AfterViewInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  HostListener
} from '@angular/core';

const PARENT_ITEM_SELECTOR = '.qb-item-row';
const DRAG_HANDLER_SELECTOR = '.drag-handler';
const DRAG_OVER_CLASS = 'qb-dragging';

@Directive({
  selector: '[queryBuilderDrag]'
})
export class QueryBuilderDragDirective implements AfterViewInit, OnDestroy {
  // tslint:disable-next-line:no-input-rename
  @Input('queryBuilderDrag') item: any;

  @Output() dragStartRequest: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  isVisibleButtonTrashRequest: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();

  private handler: any;
  private parent: any;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit(): void {
    this.handler = this.el.nativeElement.querySelector(DRAG_HANDLER_SELECTOR);
    this.parent = this.el.nativeElement.closest(PARENT_ITEM_SELECTOR);
    this.renderer.setAttribute(this.handler, 'draggable', 'true');
  }

  ngOnDestroy(): void {
    this.renderer.setAttribute(this.handler, 'draggable', 'false');
  }

  @HostListener('dragstart', ['$event'])
  onDragStart(event: DragEvent): void {
    event.stopPropagation();
    this.isVisibleButtonTrashRequest.emit(false);
    // Setting the data is required by firefox
    event.dataTransfer.setData('text/plain', (<any>event).target.id);

    (<any>event.dataTransfer).setDragImage(this.el.nativeElement, 20, 10);

    setTimeout(() => {
      this.renderer.addClass(this.parent, 'hide');
    }, 0);
    this.dragStartRequest.emit(this.item);
  }

  @HostListener('dragend', ['$event'])
  onDragEnd(event: DragEvent): void {
    event.stopPropagation();
    this.isVisibleButtonTrashRequest.emit(true);
    const target = (<any>event).target.closest(PARENT_ITEM_SELECTOR);
    this.renderer.addClass(target, DRAG_OVER_CLASS);

    setTimeout(() => {
      this.renderer.addClass(this.parent, 'hide');
    }, 0);
  }
}
