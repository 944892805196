import { Subject, timer } from 'rxjs';
import { Injectable, NgZone } from '@angular/core';
import { startWith, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DataApiService } from '../data-api/data-api.service';
import { NotificationsService, Notification } from '../notifications/notifications.service';
import { OriginService } from '../origin/origin.service';
import { SheetState } from '../../models/common';
import { DialogService } from '../../containers/dialog/dialog.service';
import { DialogStateErrorComponent } from '../../containers/dialog/dialog-state-error/dialog-state-error.component';

export const INSTALLATION_TYPES = [
  { value: 0, name: 'CT' },
  { value: 1, name: 'EMF' },
  { value: 2, name: 'LMTA' },
  { value: 3, name: 'LMTS' },
  { value: 4, name: 'RBTA' },
  { value: 5, name: 'RBTS' },
  { value: 6, name: 'Telecontrol' },
  { value: 7, name: 'Telegestion' },
  { value: 8, name: 'Despachos_Otros' }
];

@Injectable()
export class MastersService {
  fetchedPerformance = false;
  fetchedRetributiveYear = false;
  canUpdateSheet: boolean;
  sheetState: SheetState;
  canUpdateSheetNotificator = new Subject<any>();
  perfTypologies: any[] = [];
  installationTypes: Subject<any[]> = new Subject();
  retributiveYearSubject: Subject<any> = new Subject();
  retributiveYearsSubject: Subject<any[]> = new Subject();
  performanceTypologies: Subject<any[]> = new Subject();
  retributiveYears: any[] = [
    new Date(new Date().getFullYear() - 1, 1, 1).getFullYear(),
    new Date().getFullYear()
  ];
  retributiveYear: string = this.loadRetributiveYear();
  private retributiveYearsOrigin: any;
  private typologiesMasterOrigin: any;
  elas: any[] = [];
  preclasificacionMeis = {
    '': []
  };

  vtrCodigosActuacion = {
    '': []
  };

  cnmcValidations = {
    '1_M_001': 'La suma de importes es diferente al valor auditado',
    '2_M_001': 'La suma de importes es diferente al valor auditado',
    '4_M_001': 'La suma de importes es diferente al valor auditado',
    '5_M_001': 'La suma de importes es diferente al valor auditado',
    '6_M_001': 'La suma de importes es diferente al valor auditado',

    '1_M_002': 'Declaración incorrecta de bajas',
    '2_M_002': 'Declaración incorrecta de bajas',
    '4_M_002': 'Declaración incorrecta de bajas',
    '5_M_002': 'Declaración incorrecta de bajas',
    '6_M_002': 'Declaración incorrecta de bajas',

    '1_M_003': 'Bajas no declaradas',
    '2_M_003': 'Bajas no declaradas',
    '4_M_003': 'Bajas no declaradas',
    '5_M_003': 'Bajas no declaradas',
    '6_M_003': 'Bajas no declaradas',

    '1_M_004': 'Fecha APS mal informada',
    '2_M_004': 'Fecha APS mal informada',
    '4_M_004': 'Fecha APS mal informada',
    '5_M_004': 'Fecha APS mal informada',
    '6_M_004': 'Fecha APS mal informada',

    '1_M_005': 'Alta declarada en el año anterior',
    '2_M_005': 'Alta declarada en el año anterior',
    '4_M_005': 'Alta declarada en el año anterior',
    '5_M_005': 'Alta declarada en el año anterior',
    '6_M_005': 'Alta declarada en el año anterior',

    '1_M_006': 'Instalación sin cambios incoherente',
    '2_M_006': 'Instalación sin cambios incoherente',
    '3_M_006': 'Instalación sin cambios incoherente',
    '4_M_006': 'Instalación sin cambios incoherente',
    '5_M_006': 'Instalación sin cambios incoherente',
    '6_M_006': 'Instalación sin cambios incoherente',
    '8_M_006': 'Instalación sin cambios incoherente',

  }

  constructor(
    private dataApiService: DataApiService,
    private notificationsService: NotificationsService,
    private ngzone: NgZone,
    private originService: OriginService,
    private dialogService: DialogService
  ) {
    this.typologiesMasterOrigin = this.originService.getByKey('typologiesMaster');
    this.retributiveYearsOrigin = this.originService.getByKey('retributiveYears');
    if (!this.retributiveYear) {
      this.setRetributiveYear(new Date().getFullYear().toString());
    }
  }

  fetchMasters() {
    this.fetchTypologies();
    this.fetchNotifications();
    this.fetchRetributiveYears();
    this.fetchMeisMaster();
    this.fetchVtrCodigoActuacionMaster();
    this.fetchCnmcValidations();
  }

  fetchCnmcValidations() {
    this.dataApiService.data("maestros_validaciones_cnmc_informacion", { limit: 100000 })
      .subscribe(response => {
        if (response) {
          for (let res of response.result) {
            this.cnmcValidations[res.id_validacion] = res.descripcion_validacion;
          }
        }
      });

    this.dataApiService.data("maestros_validaciones_cnmc_formato", {limit: 100000})
    .subscribe(response => {
      if (response) {
        for (let res of response.result) {
          this.cnmcValidations[res.id_validacion] = res.descripcion_validacion;
        }
      }
    });
  }

  fetchMeisMaster() {
    this.dataApiService.data("maestros_clasificacion_meis", { limit: 0 })
      .subscribe(response => {
        if (response && response.count > 0) {
          this.dataApiService.data("maestros_clasificacion_meis", { limit: response.count }).subscribe(results => {
            if (results) {
              for (let res of results.result) {
                if (!this.preclasificacionMeis[res.preclasificacion]) {
                  this.preclasificacionMeis[res.preclasificacion] = [];
                }
                this.preclasificacionMeis[res.preclasificacion].push(
                  {
                    'mei': res.mei,
                    'clasificacion': res.clasificacion,
                    'mei_desc': res.descripcion
                  }
                );
              }
            }
          })
        }
      });
  }

  vtrCodigoActuacionMasterObservable(limit) {
    return this.dataApiService.data("maestros_vtr_codigo_actuacion", { limit: limit });
  }

  fetchVtrCodigoActuacionMaster() {
    this.vtrCodigoActuacionMasterObservable(0)
      .subscribe(response => {
        if (response && response.count > 0) {
          this.vtrCodigoActuacionMasterObservable(response.count).subscribe(results => {
            if (results) {
              for (let res of results.result) {
                if (!this.vtrCodigosActuacion[res.clasificacion]) {
                  this.vtrCodigosActuacion[res.clasificacion] = [];
                }
                this.vtrCodigosActuacion[res.clasificacion].push({
                  codigo_actuacion: res.codigo_actuacion,
                  descripcion_actuacion: res.descripcion_actuacion,
                });
              }
            }
          })
        }
      });
  }

  getCanUpdateSheetNotificator() {
    return this.canUpdateSheetNotificator.asObservable().pipe(startWith(this.canUpdateSheet));
  }

  fetchTypologies() {
    this.dataApiService.data(this.typologiesMasterOrigin, { limit: 0 }).subscribe(result => {
      this.fetchedPerformance = true;
      this.perfTypologies = result.result;
      this.performanceTypologies.next(this.perfTypologies);
    });
  }

  getMeisByPreclasification(preclasificacion: string) {
    return this.preclasificacionMeis[preclasificacion];
  }

  getActingCodesByClassification(classification: string) {
    return this.vtrCodigosActuacion[classification] || [];
  }

  getCnmcValidationsDescription(validationCode: string) {
    return this.cnmcValidations[validationCode] || [];
  }

  getPerformanceTypologies() {
    if (!this.fetchedPerformance) {
      this.fetchTypologies();
    }
    return this.performanceTypologies.asObservable().pipe(startWith(this.perfTypologies));
  }

  getRetributiveYear() {
    return this.retributiveYearSubject.asObservable().pipe(startWith(this.retributiveYear));
  }

  setRetributiveYear(year) {
    this.retributiveYear = year;
    this.notificationsService.reset();
    this.retributiveYearSubject.next(this.retributiveYear);
    localStorage.setItem('BDR_RETRIBUTIVE_YEAR', year);
  }

  getRetributiveYears() {
    // TODO: REMOVE RETRIBUTIVE YEAR MOCKS
    return this.retributiveYearsSubject.asObservable().pipe(startWith(this.retributiveYears));
  }

  fetchRetributiveYears() {
    this.dataApiService
      .getApi(this.retributiveYearsOrigin, { limit: 0 })
      .subscribe(({ result }) => {
        this.fetchedRetributiveYear = true;
        this.retributiveYears = result;
        this.retributiveYearsSubject.next(this.retributiveYears);
      });
  }

  private loadRetributiveYear() {
    return localStorage.getItem('BDR_RETRIBUTIVE_YEAR');
  }


  fetchNotifications() {
    this.ngzone.runOutsideAngular(() => {
      timer(0, environment.state.interval)
        .pipe(
          switchMap(() => {
            this.getRetributiveYear().subscribe(year => {
              this.retributiveYear = year;
            });
            return this.dataApiService.getNotifications(this.retributiveYear);
          })
        )
        .subscribe(notifications => this.notificationsService.mergeNotifications(notifications))
    });
  }

}
