import { Component, ChangeDetectorRef } from '@angular/core';
import { GridProperties, GridService } from '../../services/grid/grid.service';
import { OriginService } from '../../services/origin/origin.service';
import { SchemaService } from '../../services/schema/schema.service';
import { safeDetectChanges } from '../../util';
import { Subject, Subscription } from 'rxjs';
import { TasksService } from '../../services/tasks/tasks.service';
import { DataApiService } from '../../services/data-api/data-api.service';
import { DEFAULT_VIEWS } from '../../../environments/common/default-views';
import { MastersService } from '../../services/masters/masters.service';
import { DownloadsService } from '../../services/downloads/downloads.service';
import { AsyncQueryResponse } from '../../models/query-poller';
import { AuthService } from '../../services/auth/auth.service';
import { DialogInvestmentsAdvance } from '../../containers/dialog/dialog-investments-advance/dialog-investments-advance.component';
import { DialogService } from '../../containers/dialog/dialog.service';
import { MasterDataService } from '../../master-data/services/master-data.service';

@Component({
  selector: 'inventory-sheet',
  templateUrl: './inventory-sheet.component.html',
  styleUrls: ['./inventory-sheet.component.scss'],
})
export class InventorySheetComponent {
  currentOrigin: any = {};
  gridProperties: GridProperties;
  panels: any;
  editData: any[] = [];
  savingFlag: any;
  savingType: string;
  showModalSaving: any;
  editingRows: Set<number> = new Set();
  editMode = false;
  editType: string;
  inventoryOrigins: any;
  subheaderSelector = [];
  saveGridSubscription: Subscription;

  constructor(
    private gridService: GridService,
    private originService: OriginService,
    private schemaService: SchemaService,
    private tasksService: TasksService,
    private apiService: DataApiService,
    private mastersService: MastersService,
    private downloadsService: DownloadsService,
    private cdr: ChangeDetectorRef,
    public authService: AuthService,
    private dialogService: DialogService,
    private masterDataService: MasterDataService
  ) {
    this.inventoryOrigins = this.originService.getByKey('inventory');
    this.subheaderSelector = [
      {
        title: 'Componente',
        key: 'componente',
        changeEvent: this.changeOrigin.bind(this),
        selectData: this.inventoryOrigins.componente.map((item) => {
          return { name: item.title, value: item.origin };
        }),
      },
      {
        title: 'Regulatoria',
        key: 'regulatoria',
        changeEvent: this.changeOrigin.bind(this),
        selectData: this.inventoryOrigins.regulatoria.map((item) => {
          return { name: item.title, value: item.origin };
        }),
      },
      {
        title: 'Otras',
        key: 'otras',
        changeEvent: this.changeOrigin.bind(this),
        selectData: this.inventoryOrigins.otras.map((item) => {
          return { name: item.title, value: item.origin };
        }),
      },
      {
        title: 'Conectividad',
        key: 'conectividad',
        changeEvent: this.changeOrigin.bind(this),
        selectData: this.inventoryOrigins.conectividad.map((item) => {
          return { name: item.title, value: item.origin };
        }),
      },
      {
        title: 'Mercados atendidos',
        key: 'mercadosAtendidos',
        changeEvent: this.changeOrigin.bind(this),
        selectData: this.inventoryOrigins.mercadosAtendidos.map((item) => {
          return { name: item.title, value: item.origin };
        }),
      },
    ];
    this.gridProperties = this.gridService.getDefaultGridProperties();
    this.gridProperties.globalEditMode = false;
    this.gridProperties.bordered = true;
    this.gridProperties.edit = false;
    this.gridProperties.reload = false;

    this.gridProperties.headerActions = [{
      type: 'investments_advance',
      requireSelected: false,
      requireFilter: false,
      fire: this.showInvestmentsAdvance.bind(this)
    }];

    this.gridProperties.columnSelector = true;
    this.gridProperties.editDblClick = false;
    this.gridProperties.generate = false;
    this.gridProperties.showSelectBox = true;
    this.gridProperties.selectable = true;
    this.gridProperties.columnSearch = true;
    this.gridProperties.editingRows = [];
    this.gridProperties.currentRequestParams = {};
    this.gridProperties.schemaScope = 'inventory';

    this.panels = {
      downloads: { disabled: false },
      fastFilter: { disabled: true },
      filters: { disabled: true },
      visualization: { disabled: false },
      validations: false,
      manualValidations: false,
      refreshSheet: { disabled: false },
    };

    this.saveGridSubscription = this.gridService
      .getSaveGridSubject()
      .subscribe(this.processSaveGridResponse.bind(this));
  }

  changeOrigin(evt) {
    // Trick to change object reference and reflect it on ngOnChanges
    this.currentOrigin =
      this.inventoryOrigins[evt.key].find((item) => item.origin === evt.value) || {};
    // Force empty grid
    let newGridProperties = Object.assign({}, this.gridProperties);
    newGridProperties.filterOrigin = '';
    newGridProperties.origin = '';
    newGridProperties.reload = false;

    let origin = evt.value;
    newGridProperties.defaultSchemaVisibility = {};
    newGridProperties.additionalSchemaData = this.gridService.resetSchema(
      this.gridProperties.schemaScope,
      evt.value,
      null,
      this.schemaService.getDefaultSchema(origin),
    );
    newGridProperties.filterOrigin = origin;
    newGridProperties.origin = origin;
    newGridProperties.exportData = [
      this.gridService.createComponentExportData(evt, this.currentOrigin.title, true),
    ];
    setTimeout(() => {
      newGridProperties.filterOrigin = evt.value;
      newGridProperties.origin = evt.value;
      this.gridProperties = Object.assign({}, newGridProperties);
    }, 200);
  }

  generateSheet(action) {
    this.tasksService.reloadSheet(action);
  }

  dblClick(item): void {
    this.editingRows.add(item);
    this.setEditMode();
  }

  cancelEdit(): void {
    this.editingRows.clear();
    this.gridProperties.editingRows = Array.from(this.editingRows);
    this.editData = [];
    this.setEditMode();
  }

  setEditMode() {
    if (
      this.gridProperties.editingRows &&
      this.editingRows.size !== this.gridProperties.editingRows.length
    ) {
      this.gridProperties.editingRows = Array.from(this.editingRows);
    }
    this.editMode = this.gridProperties.editingRows && this.gridProperties.editingRows.length > 0;
    this.gridProperties.globalEditMode = this.editMode;
    this.gridProperties = Object.assign({}, this.gridProperties);
  }

  gridEditChange(eventObject) {
    if (!!this.gridProperties.reload) {
      this.gridProperties.reload = false;
    }

    if (eventObject.type === 'massiveChange') {
      this.editingRows = new Set(eventObject.object.map((item) => item));
      this.setEditMode();
    }

    if (this.editMode) {
      this.editData = eventObject.object;
      this.editType = eventObject.type;
      this.savingFlag = false;
      safeDetectChanges(this.cdr);
    }
  }

  private processSaveGridResponse(response) {
    if (response.type === 'ERROR') {
      this.savingFlag = true;
    } else {
      this.savingFlag = false;
      this.gridProperties.reload = true;
      this.cancelEdit();
    }
    this.showModalSaving = false;
    safeDetectChanges(this.cdr);
  }

  //Applied on Select tags
  saveChanges($event) {
    if (this.editData && this.editData.length > 0) {
      this.savingFlag = true;
      this.showModalSaving = true;

      let table = this.gridProperties.origin.replace('_circular', '').replace('_vista', '');

      let tableConfig = DEFAULT_VIEWS[this.gridProperties.origin];

      let pks = Object.keys(tableConfig).filter((f) => tableConfig[f].pk);

      let componentIdField = pks.find((f) => !f.includes('anio_'));

      this.mastersService.getRetributiveYear().subscribe((retributiveYear) => {
        this.authService.getProfile().subscribe((profile) => {
          let amendments = [];

          let isMassive = this.editType === 'massiveChange';
          let payload = { amendments: [] };
          let recurrentEdition = $event['recurrentEdition'] !== undefined ? $event['recurrentEdition'] : false;

          this.editData.forEach((editedData) => {
            editedData['currentEditedFields'].forEach((editedField) => {
              payload.amendments.push({
                table: 'maestros_ediciones_manuales',
                amendment_type: 'upsert',
                primary_keys: {
                  anio_informacion: retributiveYear,
                  identificador: editedData[componentIdField],
                  tabla: table,
                  campo_edicion: editedField,
                },
                new_values: {
                  valor_edicion: editedData[editedField],
                  observaciones: isMassive ? 'Edición masiva vía web' : 'Edición puntual vía web',
                  usuario: profile.email,
                  edicion_recurrente: recurrentEdition,
                },
              });
            });
          });

          this.savingType = 'massiveSaving';
          this.showModalSaving = false;

          this.apiService.putAsyncAmendments(payload).subscribe((response) => {
            let asyncId = response.async_job_id;

            this.downloadsService.addDownload({
              id: response.async_job_id,
              fileName: 'Ejecutando modificaciones',
              url: '#',
              status: 'RUNNING',
            });

            let poller = this.apiService.jobQueryPoller(asyncId);
            poller.getObservable().subscribe((pollerResponse: AsyncQueryResponse) => {
              poller.complete();
              this.downloadsService.assignData(pollerResponse, asyncId);
              new Subject<AsyncQueryResponse>().next(pollerResponse);
              this.downloadsService.downloadReady = true;
              this.masterDataService.unloadAmendment(retributiveYear, 'maestros_ediciones_manuales').subscribe();
              return;
            });
          });
        });
      });
    }
  }

  private addYearToEditDataIfInvisible(tableConfig) {
    let invisibleYearField = Object.keys(tableConfig).find(
      (field) => field.includes('anio_') && tableConfig[field].pk && !tableConfig[field].visible,
    );

    if (invisibleYearField) {
      this.mastersService.getRetributiveYear().subscribe((retributiveYear) => {
        this.editData.forEach((data) => (data[invisibleYearField] = +retributiveYear));
      });
    }
  }

  showInvestmentsAdvance(selectedRows) {
    const dialogOptions: any = {
      component: DialogInvestmentsAdvance,
      title: 'Adelanto de inversiones',
      fixed: true,
      size: {
        width: 40,
        height: 40,
      },
      bindings: {
        inputs: {
          id: 'actions',
          selectedRows: selectedRows,
          origin: this.currentOrigin.origin.replace("_circular_vista", "")
        },
        outputs: {
          onCloseAction: this.dialogService.closeDialog.bind(this.dialogService),
        }
      },
    };

    return this.dialogService.createDialog(dialogOptions);

  }
}
