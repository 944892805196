import { throwError as observableThrowError, Subscription, Subject } from 'rxjs';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import * as clone from 'clone';

import { BdrValidationsService } from '../../services/bdr-validations/bdr-validations.service';
import { InvestmentFilterService } from '../../services/investment-filter/investment-filter.service';
import { catchError, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'bdr-validations-summary',
  templateUrl: './bdr-validations-summary.component.html',
  styleUrls: ['./bdr-validations-summary.component.scss'],
})
export class BdrValidationsSummaryComponent implements OnInit, OnChanges, OnDestroy {
  @Input() origin: String;
  @Input() filters: any;
  @Input() showManualData: boolean;
  @Input() validationsScope;

  @Output() close: EventEmitter<Function> = new EventEmitter<Function>();
  @Output() validationsDetail: EventEmitter<any> = new EventEmitter<any>();
  @Output() manualValidationsClick: EventEmitter<any> = new EventEmitter<any>();

  private destroy$: Subject<any> = new Subject<any>();
  manualItems = [];
  automaticItems: any;
  automaticError = false;
  selectedTab = 'automatic';
  items = [];
  loading = false;

  constructor(
    private validationsService: BdrValidationsService,
    private investmentFilterService: InvestmentFilterService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.loading = true;
    this.selectedTab = 'automatic';
    // if (this.showManualData) {
    //   this.getManualData();
    // }
  }

  // getManualData() {
  //   this.validationsService
  //     .getManualValidationsObservable()
  //     .pipe(
  //       catchError(err => {
  //         this.manualError = true;
  //         return observableThrowError(err);
  //       })
  //     )
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe(response => {
  //       this.manualError = false;
  //       this.manualItems = clone(response);
  //       this.manualItems.forEach(item => {
  //         // TODO: Delete when fixed in api
  //         item.id = item.id["N"] ? item.id["N"] : item.id;
  //         item.name = item.name["S"] ? item.name["S"] : item.name;
  //         item.description = item.description["S"]
  //           ? item.description["S"]
  //           : item.description;
  //         item.editables = {
  //           name: false,
  //           description: false
  //         };
  //         item.saved = true;
  //       });
  //       this.cdRef.detectChanges()
  //     });
  // }

  getAutomaticData() {
    this.loading = true;
    if (
      this.filters &&
      this.investmentFilterService.isFiltering() &&
      this.validationsScope === 'mtbt'
    ) {
      const query = { filters: this.filters };
      this.validationsService
        .getFilteredSummary({
          query,
        })
        .pipe(catchError(this.requestErrorFn.bind(this)))
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          this.automaticError = false;
          this.automaticItems = this.validationsService.formatSummary(response.result);
          this.loading = false;
        });
    } else {
      this.validationsService
        .getSummary()
        .pipe(catchError(this.requestErrorFn.bind(this)))
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          this.automaticItems = {
            mtbt: this.validationsService.formatSummary(response[0].result, 'mtbt'),
            at: this.validationsService.formatSummary(response[1].result, 'at'),
          };
          this.automaticError = false;
          this.loading = false;
        });
    }
  }

  requestErrorFn(err) {
    this.automaticError = true;
    this.loading = false;
    return observableThrowError(err);
  }

  ngOnChanges(changes = <any>{}) {
    if (!!changes.filters) {
      this.getAutomaticData();
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  // saveValidations(validationsItem) {
  //   if (!validationsItem.name || !validationsItem.description) {
  //     validationsItem.error = true;
  //     validationsItem.errorMsg =
  //       "Debe introducir nombre y descripción de la coherencia";
  //     return;
  //   }
  //   if (!validationsItem.saved) {
  //     this.validationsService.saveValidations(validationsItem);
  //   } else {
  //     this.validationsService.updateValidations(validationsItem);
  //   }
  // }

  closePanel() {
    this.close.emit();
  }

  toValidationsDetail(item: Object) {
    this.validationsDetail.emit(item);
  }

  // createValidations() {
  //   const maxId =
  //     this.manualItems.length > 0
  //       ? Math.max(...this.manualItems.map(item => parseInt(item.id))) + 1
  //       : 1;
  //   const newValidations = {
  //     id: maxId,
  //     name: "",
  //     description: "",
  //     saved: false,
  //     editables: {
  //       name: true,
  //       description: true
  //     }
  //   };
  //   this.manualItems.push(newValidations);
  // }

  // getMenu(item) {
  //   const validationsOptions = {
  //     options: [
  //       {
  //         caption: "Editar",
  //         icon: "ico-edit",
  //         class: "menu-item-edit",
  //         action: () => this.editItem.bind(this)(item)
  //       },
  //       {
  //         caption: "Eliminar",
  //         icon: "ico-delete",
  //         class: "menu-item-remove",
  //         action: () => this.deleteItem.bind(this)(item)
  //       }
  //     ]
  //   };
  //   return { body: [validationsOptions] };
  // }

  // editItem(item) {
  //   const index = this.manualItems.findIndex(
  //     manualItem => manualItem.id.toString() === item.id.toString()
  //   );
  //   if (this.manualItems[index]) {
  //     this.manualItems[index].editables.name = true;
  //     this.manualItems[index].editables.description = true;
  //   }
  // }

  // deleteItem(item) {
  //   if (!item.saved) {
  //     return this.removeManualItem(item);
  //   }
  //   this.validationsService.deleteValidations(item);
  // }

  // removeManualItem(item) {
  //   const index = this.manualItems.findIndex(
  //     manualItem => manualItem.id.toString() === item.id.toString()
  //   );
  //   this.manualItems.splice(index, 1);
  // }

  // manualValidationsClickAction(item) {
  //   if (item.editables.name || item.editables.description) {
  //     return;
  //   }
  //   this.manualValidationsClick.emit(item);
  // }
}
