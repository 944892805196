import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from "@angular/core";
import {
  ConditionGroup,
  ConditionRow
} from "./query-builder-condition/query-builder-condition.service";
import { OriginService } from '../../services/origin/origin.service';
import { Schema } from "./../../services/schema/schema.service";

export interface Operator {
  type: string[];
  name: string;
  caption: string;
}


export const SQL_OPERATORS = {
  equal: <Operator>{ type: ['string', 'number'], name: 'equal', caption: 'Igual' },
  not_equal: <Operator>{ type: ['string', 'number'], name: 'not_equal', caption: 'No es igual' },
  contains: <Operator>{ type: ['string'], name: 'contains', caption: 'Contiene' },
  not_contains: <Operator>{ type: ['string'], name: 'not_contains', caption: 'No contiene' },
  begins_with: <Operator>{ type: ['string'], name: 'begins_with', caption: 'Comienza' },
  not_begins_with: <Operator>{ type: ['string'], name: 'not_begins_with', caption: 'No comienza' },
  ends_with: <Operator>{ type: ['string'], name: 'ends_with', caption: 'Acaba' },
  not_ends_with: <Operator>{ type: ['string'], name: 'not_ends_with', caption: 'No acaba' },
  is_true: <Operator>{ type: ['boolean'], name: 'is_true', caption: 'Es verdadero' },
  is_false: <Operator>{ type: ['boolean'], name: 'is_false', caption: 'Es falso' },
  greater: <Operator>{ type: ['number'], name: 'greater', caption: 'Es mayor que' },
  greater_or_equal: <Operator>{
    type: ['number'],
    name: 'greater_or_equal',
    caption: 'Es mayor o igual que'
  },
  less: <Operator>{ type: ['number'], name: 'less', caption: 'Es menor que' },
  less_or_equal: <Operator>{
    type: ['number'],
    name: 'less_or_equal',
    caption: 'Es menor o igual que'
  },
  equal_date: <Operator>{ type: ['date'], name: 'equal_date', caption: 'Igual' },
  not_equal_date: <Operator>{ type: ['date'], name: 'not_equal_date', caption: 'No es igual' },
  less_date: <Operator>{ type: ['date'], name: 'less_date', caption: 'Anterior a' },
  less_or_equal_date: <Operator>{
    type: ['date'],
    name: 'less_or_equal_date',
    caption: 'Anterior o igual a'
  },
  greater_date: <Operator>{ type: ['date'], name: 'greater_date', caption: 'Posterior a' },
  greater_or_equal_date: <Operator>{
    type: ['date'],
    name: 'greater_or_equal_date',
    caption: 'Posterior o igual a'
  },
  in: <Operator>{ type: ['string', 'number'], name: 'in', caption: 'Está en' },
  not_in: <Operator>{ type: ['string', 'number'], name: 'not_in', caption: 'No está en' },
  is_empty: <Operator>{
    type: ['string', 'number', 'date', 'boolean'],
    name: 'is_empty',
    caption: 'Está vacío'
  },
  is_null: <Operator>{
    type: ['string', 'number', 'date', 'boolean'],
    name: 'is_null',
    caption: 'Es nulo'
  },
  is_not_empty: <Operator>{
    type: ['string', 'number', 'date', 'boolean'],
    name: 'is_not_empty',
    caption: 'No está vacío'
  },
  is_not_null: <Operator>{
    type: ['string', 'number', 'date', 'boolean'],
    name: 'is_not_null',
    caption: 'No es nulo'
  }
};


@Component({
  selector: "bdr-query-builder-new",
  templateUrl: "./query-builder.component.html",
  styleUrls: ["./query-builder.component.scss"]
})
export class QueryBuilderComponent implements OnInit {
  @Input() query: ConditionGroup;
  @Input() dropZones: string[];
  @Input() index: number;
  @Input() type: string;
  @Input() multipleOrigin: boolean;
  @Input() origin: string;
  @Input() itemType: string;
  @Input() dragInfo: any;
  @Input() filters: any[];
  @Input() isVisibleButtonTrash: boolean;
  @Input() admin: boolean;
  @Input() schemaItems: { [origin: string]: { [filterOrigin: string]: { caption: string, items: Schema[] } } }

  @Output() validationError: EventEmitter<{ error: boolean; message: string }>;
  @Output() expandItemMainRequest: EventEmitter<any>;
  @Output() openMainListRequest: EventEmitter<void>;

  operators: { type: string[]; name: string; caption: string }[] = Object.values(SQL_OPERATORS);
  origins: any;

  constructor(
    private originService: OriginService
  ) {
    this.validationError = new EventEmitter<{
      error: boolean;
      message: string;
    }>();
    this.expandItemMainRequest = new EventEmitter<any>();
    this.openMainListRequest = new EventEmitter<void>();
    this.origins = this.originService.getAll();
  }

  ngOnInit() {
    this.query = {
      condition:
        this.query && this.query.condition ? this.query.condition : "AND",
      rules: this.query && this.query.rules ? this.query.rules : []
    };
    this.isVisibleButtonTrash = true;
  }

  public getQuery(): any {
    return this.query;
  }

  openMainList(): void {
    this.openMainListRequest.emit();
  }

  dragStart($event: any): void {
    this.dragInfo = $event;
    this.isVisibleButtonTrash = false;
  }

  drop($event: any): void {
    const drag = this.dragInfo;
    const drop = $event;

    if (!!drag) {
      const newItem = this.getDroppedItem(drag);
      const dropIndex =
        1 + this.getItemIndex(drop.parent.rules, drop.element, drop.group); // Se añade 1 para insertarlo después del item
      this.insertNewItem(drop.parent.rules, dropIndex, newItem);

      if (!!drag.parent) {
        const dragIndex = this.getItemIndex(drag.parent.rules, drag.element);
        this.deleteOldItem(drag.parent.rules, dragIndex);
      }
    }
    this.isVisibleButtonTrash = true;
  }

  /**
   *
   *
   * @param {Array<any>} list
   * @param {*} item
   * @param {boolean} [isGroup]
   * @returns {number}
   *
   * @memberOf QueryBuilderComponent
   */
  getItemIndex(list: any[], item: any, isGroup?: boolean): number {
    return !isGroup ? list.findIndex(findItem => findItem === item) : -1;
  }

  /**
   *
   *
   * @param {Array<any>} list
   * @param {number} index
   * @param {*} item
   *
   * @memberOf QueryBuilderComponent
   */
  insertNewItem(list: any[], index: number, item: any): void {
    list.splice(index, 0, Object.assign({}, item));
  }

  /**
   *
   *
   * @param {Array<any>} list
   * @param {number} index
   *
   * @memberOf QueryBuilderComponent
   */
  deleteOldItem(list: any[], index: number): void {
    list.splice(index, 1);
  }

  /**
   *
   *
   * @private
   * @param {*} item
   * @returns {void}
   *
   * @memberOf QueryBuilderComponent
   */
  private getDroppedItem(itemWrapper: { parent: any; element: any }) {
    if (this.itemBelongsToQueryBuilder(itemWrapper)) {
      return itemWrapper.element;
    }
    const item = itemWrapper.element;
    // MVG: Controlar si hay que enlazar el item o descomponerlo
    if (this.droppedItemEqualToQueryBuilder(item, this.type)) {
      return this.createLinkedItem(item);
    }
    return this.getExpandedItem(item.filter);
  }

  /**
   *
   *
   * @private
   * @param {*} item
   * @param {string} querybuilderType
   * @returns {boolean}
   *
   * @memberOf QueryBuilderComponent
   */
  private droppedItemEqualToQueryBuilder(
    item: any,
    querybuilderType: string
  ): boolean {
    const itemTypes: { filter: string[]; rule: string[] } = {
      filter: ["filter", "issueFilter"],
      rule: ["rule", "workflowRule", "assignationRule"]
    };
    const itemType = !item.actions ? "filter" : "rule";

    return itemTypes[itemType].indexOf(querybuilderType) !== -1;
  }

  /**
   *
   *
   * @private
   * @param {{ parent: any, element: any}} itemWrapper
   * @returns {boolean} Return if dragged item belongs to Query Builder
   * object (is already inside QB structure) or not (is a new item to be added)
   *
   * @memberOf QueryBuilderComponent
   */
  private itemBelongsToQueryBuilder(itemWrapper: {
    parent: any;
    element: any;
  }): boolean {
    return !!itemWrapper.parent;
  }

  /**
   * Returns a new object that refers to a saved item.
   *
   * @private
   * @param {*} item Object referring to saved item in DB.
   * @returns {*} Returns an object that refers to a saved item with
   *  the structure needed by QueryBuilder element.
   *
   * @memberOf QueryBuilderComponent
   */
  private createLinkedItem(item: any): any {
    return {
      id: "-2",
      type: this.getLinkedItemType(this.origin, this.type),
      reference: item._id,
      value: item.name
    };
  }

  private getLinkedItemType(origin: string, type: string): string {
    let investmentOrigin = this.originService.getByKey('investments')
    const itemTypes = {
      [investmentOrigin]: {
        filter: "filter",
        rule: "filterrule"
      }
    };
    return itemTypes[origin][type] || "";
  }

  /**
   * Returns a new object that refers to a saved item.
   *
   * @private
   * @param {*} item Object referring to saved item in DB.
   * @returns {*} Returns an object that refers to a saved item with
   *  the structure needed by QueryBuilder element.
   *
   * @memberOf QueryBuilderComponent
   */
  private getExpandedItem(item: any): any {
    const newItemRules = item.rules.map((rule: any) => {
      if (!!rule.condition) {
        return this.getExpandedItem(rule);
      }
      if (!!rule.reference) {
        const it = this.filters.find(
          (item2: any) => item2["_id"] === rule.reference
        );
        return this.getExpandedItem(it.filter);
      }
      return rule;
    });

    return {
      condition: item.condition,
      rules: newItemRules
    };
  }

  /**
   *
   *
   * @param {*} $event
   *
   * @memberOf QueryBuilderComponent
   */
  expandItemMain($event: any): void {
    this.expandItemMainRequest.emit($event);
  }

  setVisibleButtonTrash(visible: boolean) {
    this.isVisibleButtonTrash = visible;
  }




  handleValidationError(error: { error: boolean; message: string }): void {
    this.validationError.emit(error);
  }

  public validateQuery(query: ConditionGroup): boolean {
    let isValidForm = query.rules.length > 0;
    query.rules.forEach((condition: any) => {
      if (!!condition.rules) {
        isValidForm = isValidForm && this.validateQuery(condition);
      } else {
        isValidForm = isValidForm && this.validateQueryCondition(condition);
      }
    });
    return isValidForm;
  }

  public changeSchema(origin: string): void {
    this.origin = origin;
    this.cleanQuery();
  }

  private cleanQuery() {
    this.query = {
      condition: "",
      rules: [
        {
          id: "",
          origin: "",
          field: "",
          fieldError: "",
          type: "",
          operator: "",
          operatorError: "",
          value: "",
          valueError: ""
        }
      ]
    };
  }

  private validateQueryCondition(condition: ConditionRow): boolean {
    const nullableOperators = [
      "is_empty",
      "is_null",
      "is_not_empty",
      "is_not_null",
      "is_true",
      "is_false"
    ];
    const isOperatorNullable =
      nullableOperators.indexOf(condition.operator) !== -1;
    const isLinkedItem = !!condition.reference;
    condition.fieldError = !condition.id ? "Campo obligatorio" : "";
    condition.operatorError = !condition.operator ? "Campo obligatorio" : "";
    condition.valueError =
      !isOperatorNullable && !condition.value ? "Campo obligatorio" : "";
    return isOperatorNullable
      ? isLinkedItem || (!!condition.id && !!condition.operator)
      : isLinkedItem ||
      (!!condition.id && !!condition.operator && !!condition.value);
  }
}
