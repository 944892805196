import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from "@angular/core";

import { BdrContextualMenuService } from "../../services/bdr-contextual-menu/bdr-contextual-menu.service";
import { ItemDB } from "../../models/common";
import { CtxListComponent } from "../bdr-contextual-menu/ctx-list/ctx-list.component";
import { OriginService } from '../../services/origin/origin.service';

@Component({
  selector: "bdr-row-drag",
  templateUrl: "./bdr-row-drag.component.html",
  styleUrls: ["./bdr-row-drag.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BdrRowDragComponent implements OnInit {
  @Input() isSelected: boolean;
  @Input() itemId: string;
  @Input() origin: string;
  @Input() type: string;
  @Input() editable: boolean;
  @Input() linkedFilter: boolean;
  @Input() menu: any;
  @Input() iconText: string;
  @Input() iconClass: string;
  @Input() newEdit: boolean;

  @Output() expandItemRequest: EventEmitter<void> = new EventEmitter<void>();
  @Output() applyItemRequest: EventEmitter<ItemDB> = new EventEmitter<ItemDB>();

  originKey: string;
  hover: boolean;

  constructor(private ctxmenuService: BdrContextualMenuService, private originService: OriginService) {
    this.itemId = "";
    this.origin = "";
    this.type = "";
    this.linkedFilter = false;
    this.menu = null;
    this.newEdit = false;
    this.hover = false;
  }

  ngOnInit() {
    let allOrigins = this.originService.getAll()
    const originKeys = Object.keys(allOrigins).reduce((a, b) => {
      a[allOrigins[b]] = b;
      return a;
    }, {});

    this.originKey = originKeys[this.origin];
  }

  openMenu(evt: MouseEvent) {
    evt.stopPropagation();
    this.ctxmenuService.open(CtxListComponent, evt, this.menu);
  }

  linkFilter() {
    this.expandItemRequest.emit();
  }


  applyItem(item: ItemDB) {
    this.applyItemRequest.emit(item);
  }
}
