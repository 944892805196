export class ItemDB { }

export class Coordinates {
  x: number;
  y: number;
}

export class ComponentBindings {
  inputs: any;
  outputs: any;
}

export class SelectOption {
  name: string;
  value: string;
}

export class Installation {
  installationType: any;
  installationCode: string;
  performanceType: any;
  performanceType2: any;
  performanceType3: any;
}


export interface SidePanelUI {
  [panel: string]: {
    disabled: boolean
  }
}

export interface SheetState {
  is_modified: boolean;
  status: string;
  compensation_year?: string,
  message?: string;
  more_info?: any[];
}


export interface ManualWeights {
  anio_contable: number;
  ela_final: string;
  ot: string;
  clase_coste: string;
  origen_instalacion: string;
  repartos;
}

export interface ManualInstallation {
  anio_contable: number;
  ela_final: string;
  ot: string;
  clase_coste: string;
  instalacion: string;
  accion: number;
  origen_instalacion: string;
}

