import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  Renderer2,
  ViewChild,
  OnDestroy,
  ChangeDetectionStrategy
} from "@angular/core";
import { Subscription } from "rxjs";

import {
  FilterService,
  FilterDB
} from "../../../services/filter/filter.service";
import { DialogI18nService } from "../dialog-i18n.service";
import { QueryBuilderComponent } from "../../query-builder/query-builder.component";
import { DialogSideList } from "../dialog.service";
import { Notification } from "../../../services/notifications/notifications.service";
import { SelectOption } from "../../../models/common";
import { getGridsSelectOption } from "../../../util";
import { Schema } from './../../../services/schema/schema.service';

export const SIDEBAR_WIDTH = "26.5rem";

@Component({
  selector: "bdr-dialog-filter",
  templateUrl: "./dialog-filter.component.html",
  styleUrls: ["./dialog-filter.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogFilterComponent implements OnInit, OnDestroy {
  @ViewChild(QueryBuilderComponent, { static: false }) queryBuilderElement: QueryBuilderComponent;

  @Input() id: string;
  @Input() item?: FilterDB;
  @Input() origins: string[];
  @Input() origin: string;
  @Input() schemaItems: { [origin: string]: { [filterOrigin: string]: { caption: string, items: Schema[] } } };
  @Input() dialogSize: any;
  @Input() rulerCtx: any;
  @Input() type: string;
  @Input() sideList: DialogSideList[];
  @Input() admin: boolean;

  @Output() onCloseAction: EventEmitter<string> = new EventEmitter<string>();
  @Output() applyItem: EventEmitter<FilterDB> = new EventEmitter<FilterDB>();
  @Output()
  onMinimizeAction: EventEmitter<FilterDB> = new EventEmitter<FilterDB>();

  itemNameTitle: string;
  dragInfo: any;
  editItem: boolean;
  sideListTitle: string;
  selectedSideList: string;
  typeI18n: string;
  tag: string;
  tags: any[];
  error: boolean;
  errorNotification: Notification;
  errorMessage: string;
  grids: SelectOption[];
  titleError: boolean;
  private filters: FilterDB[] = [];
  private filtersSubscription: Subscription;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private dialogI18n: DialogI18nService,
    private filterService: FilterService
  ) {
    this.tags = [];
    this.tag = "";
    this.admin = false;
  }

  ngOnInit() {
    this.subscribeItems();
    this.editItem = !!this.item && !!this.item.id;
    this.item = this.item || new FilterDB({ name: "" });
    this.itemNameTitle = this.dialogI18n.getItemNameTitle(this.type);

    this.typeI18n = this.dialogI18n.getType(this.type);
    if (this.item.tag) {
      this.tag = this.item.tag;
    }
    this.grids = getGridsSelectOption();
  }

  ngOnDestroy() {
    this.unsubscribeItems();
  }

  setDragElementInfo($event: any): void {
    this.dragInfo = {
      element: $event.dragData
    };
  }

  /**
   * Replace item into filters object to its rules.
   *
   * @param {string} reference Identifier of the item.
   *
   * @memberOf EsirDraggableDialogComponent
   */
  expandItem($event: any): void {
    const element = $event.element;
    const parent = $event.parent;
    const index = $event.index;

    const reference = element.reference;
    const expandedItem = this.filters.find(item => item.id === reference);
    parent.rules.splice(index, 1);
    parent.rules.splice(
      index,
      0,
      JSON.parse(JSON.stringify(expandedItem.filter))
    );
  }

  handleCloseDialog(): void {
    this.onCloseAction.emit(this.id);
  }

  minimizeDialog(): void {
    this.onMinimizeAction.emit();
  }

  /**
   * Send to parent component the filter to be applied into grid.
   *
   *
   * @memberOf EsirDraggableDialogComponent
   */
  handleApplyItem(): void {
    const qbFilter = this.queryBuilderElement.getQuery();

    if (this.queryBuilderElement.validateQuery(qbFilter)) {
      const filter: FilterDB = new FilterDB({
        ...this.item,
        id: this.item.id,
        filter: qbFilter,
        meta: { type: this.type },
        name: this.item.name,
        table: ""
      });
      this.applyItem.emit(filter);
    } else {
      this.handleValidationError({
        error: true,
        titleError: false,
        message: "Condición incompleta"
      });
    }
  }

  /**
   * Send to parent component the filter to be saved into DB.
   *
   *
   * @memberOf EsirDraggableDialogComponent
   */
  handleSaveItem(apply: boolean): void {
    const qbFilter = this.queryBuilderElement.getQuery();
    if (this.isValidForm(this.item.name, qbFilter)) {
      const item: FilterDB = new FilterDB({
        ...this.item,
        filter: qbFilter,
        folder_name: null,
        folder_desc: null,
        cross_origin: this.getCrossOrigin(qbFilter),
        quick_filter: this.item.quick_filter || false,
        tag: this.tag || null,
        admin: this.admin
      });

      if (this.item.saved) {
        this.filterService.update(item);
      } else {
        this.filterService.create(item);
      }
      this.handleValidationError({
        error: false,
        titleError: false,
        message: ""
      });
      if (apply) {
        this.handleApplyItem();
      }
      this.handleCloseDialog();
    } else {
      if (this.item.name === undefined || this.item.name === "") {
        this.handleValidationError({
          error: true,
          titleError: true,
          message: "Debe introducir el nombre del filtro"
        });
      } else {
        this.handleValidationError({
          error: true,
          titleError: false,
          message: "Condición incompleta"
        });
      }
    }
  }

  private getCrossOrigin(filter: any): string {
    const uniqueOrigins = [];
    this.extractGroupOrigins(filter.rules, uniqueOrigins);
    const crossOrigin: string[] = uniqueOrigins.filter(
      (origin: string) => origin !== this.origin
    );
    return crossOrigin.length > 0 ? crossOrigin[0] : undefined;
  }

  private extractGroupOrigins(
    filterGroup: any[],
    uniqueOrigins: string[]
  ): void {
    filterGroup.forEach(group => {
      if (group.hasOwnProperty("rules")) {
        this.extractGroupOrigins(group.rules, uniqueOrigins);
      } else {
        let origin;
        if (!!group.reference) {
          const linkedFilter = this.filters.find(
            (item: FilterDB) => item.id === group.reference
          );

          origin = linkedFilter ? linkedFilter.cross_origin ? linkedFilter.cross_origin : linkedFilter.table : group.origin;
        } else {
          origin = group.origin;
        }
        if (uniqueOrigins.indexOf(origin) === -1) {
          uniqueOrigins.push(origin);
        }
      }
    });
  }

  openMainList($event): void {
    if (!this.selectedSideList) {
      const action = this.sideList[0].id;
      this.moveDraggableDialog(this.selectedSideList, action);
      this.selectedSideList = action;
      this.sideListTitle = this.dialogI18n.getAvailableItemList(action);
    }
  }

  private isValidForm(name: string, qbFilter: any): boolean {
    const nameValid =
      name !== undefined &&
      name !== "" &&
      name.toLowerCase() !== "nuevo filtro";
    const qbFilterValid: boolean = this.queryBuilderElement.validateQuery(
      qbFilter
    );
    return nameValid && qbFilterValid;
  }

  private moveDraggableDialog(
    previousAction: string,
    currentAction: string
  ): void {
    const sidebarWidth = SIDEBAR_WIDTH;
    const parent = this.el.nativeElement.closest(".dialog");
    if (!!previousAction) {
      /** Hide sidebar **/
      if (previousAction === currentAction) {
        const width = parent.offsetWidth;
        this.renderer.setStyle(
          parent,
          "width",
          `calc(${width}px - ${sidebarWidth})`
        );

        const left = parent.offsetLeft;
        this.renderer.setStyle(
          parent,
          "left",
          `calc(${left}px + ${sidebarWidth})`
        );
      }
    } else {
      /** Show sidebar **/

      const width = parent.offsetWidth;
      this.renderer.setStyle(
        parent,
        "width",
        `calc(${width}px + ${sidebarWidth})`
      );

      const left = parent.offsetLeft;
      this.renderer.setStyle(
        parent,
        "left",
        `calc(${left}px - ${sidebarWidth})`
      );
    }
  }

  private subscribeItems(): void {
    const getFunction = this.admin ? "getAdmin" : "getFilterSubject";
    this.filtersSubscription = this.filterService[getFunction](
      this.origin
    ).subscribe(filters => {
      this.filters = filters[this.origin]
      const rawTags = Array.from(
        new Set(
          this.filters
            .map(item => item.tag)
            .filter(tag => !!tag)
            .sort((a, b) => (!!a && !!b ? a.localeCompare(b) : -1))
        )
      );
      this.tags = rawTags.map(tag => ({ caption: tag, name: tag, value: tag }));
    });
  }

  private unsubscribeItems(): void {
    if (!!this.filtersSubscription) {
      this.filtersSubscription.unsubscribe();
    }
  }

  handleValidationError(error: {
    error: boolean;
    titleError: boolean;
    message: string;
  }): void {
    this.error = false;
    this.error = error.error;
    this.titleError = error.titleError;
    this.errorMessage = error.message;
  }
}
