import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modules
import { MaterialModule } from '../../shared/modules/material.module';
import { ComponentsModule } from '../../components/components.module';
import { ClosingWorkRoutingModule } from './closing-work-routing.module';
import { GridHandlerViewModule } from '../grid-handler-view/grid-handler-view.module';
import { SharedModule } from '../../shared/shared.module';
import { DialogModule } from '../dialog/dialog.module';

// Container
import { WorkClosureComponent } from './work-closure.component';

// Pages
import { ClosureInstallationsComponent } from './pages/closure-installations/closure-installations.component';
import { ClosureSapComponent } from './pages/closure-sap/closure-sap.component';
import { ClosureUuccComponent } from './pages/closure-uucc/closure-uucc.component';
import { ClosureDistributionComponent } from './pages/closure-distribution/closure-distribution.component';

// Components
import { TableInstallationsComponent } from './components/table-installations/bdr-table-installations.component';
import { WorkAssociatesComponent } from './components/work-associates/work-associates.component';
import { TableInstallationsStartupActComponent } from './components/bdr-table-installations-startup-act/bdr-table-installations-startup-act.component';

// Services
import { DataApiService } from '../../services/data-api/data-api.service';
import { InstallationEditionsDialogComponent } from './components/installation-editions-dialog/installation-editions-dialog.component';

@NgModule({
    declarations: [
        WorkClosureComponent,
        ClosureInstallationsComponent,
        ClosureSapComponent,
        ClosureUuccComponent,
        WorkAssociatesComponent,
        InstallationEditionsDialogComponent,
        TableInstallationsComponent,
        TableInstallationsStartupActComponent,
        ClosureDistributionComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        ClosingWorkRoutingModule,
        ComponentsModule,
        ReactiveFormsModule,
        FormsModule,
        GridHandlerViewModule,
        SharedModule,
        DialogModule,
    ],
    providers: [DataApiService]
})
export class WorkClosureModule { }
