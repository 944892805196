import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { sortBy, uniq } from 'lodash';

import { BdrRequestService } from '../bdr-request/bdr-request.service';
import { environment } from '../../../environments/environment';
import { FIELDS } from '../../util';

moment.locale('es');

const ORIGINS = {
  sabana_inversiones_mtbt_detalle_vista: 'Incurred',
  sabana_inversiones_mtbt_instalaciones_vista: 'Installation'
};
const LOGFIELDS = {
  sabana_inversiones_mtbt_detalle_vista: 'id',
  sabana_inversiones_mtbt_instalaciones_vista: ['codigo_instalacion', 'codigo_obra']
};

@Injectable()
export class LogService {
  fields = FIELDS;

  constructor(private request: BdrRequestService) { }

  getUrlPath(origin, item) {
    const logOrigin = ORIGINS[origin];
    const logField = LOGFIELDS[origin];
    if (logOrigin === 'Incurred') {
      return `${logOrigin}-${item[logField]}`;
    }
    return `${logOrigin}-${item[logField[0]]}-${item[logField[1]]}`;
  }

  getLatestLog(origin: string, item: any): Observable<any> {
    const path = this.getUrlPath(origin, item);
    return this.request.get(`${environment.host}/log/${encodeURI(path)}/last`);
  }

  getLogs(origin: string, item: any): Observable<any[]> {
    const path = this.getUrlPath(origin, item);
    return this.request.get(`${environment.host}/log/${encodeURI(path)}`);
  }

  mergeChanges(log) {
    let newValues, oldValues;
    try {
      newValues = JSON.parse(log.new_value) || {};
    } catch (err) {
      newValues = {};
    }
    try {
      oldValues = JSON.parse(log.old_value) || {};
    } catch (err) {
      oldValues = {};
    }

    log.displayDate = moment(log.date).format('DD/MM/YYYY');
    log.timelineDate = moment(log.date).format('dddd D HH:mm');
    log.timestamp = moment(log.date).valueOf();
    log.changes = [];

    const keys = uniq(Object.keys(oldValues).concat(Object.keys(newValues)));
    keys.forEach(key => {
      log.changes.push({
        key,
        title: this.fields[key] || key.toUpperCase(),
        oldValue: oldValues[key] || 'vacío',
        newValue: newValues && newValues[key] ? newValues[key] : 'Eliminado'
      });
    });
    return log;
  }
}
