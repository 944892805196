import {
  Component,
  Input,
  Output,
  OnInit,
  OnChanges,
  EventEmitter,
  ChangeDetectionStrategy
} from "@angular/core";
import * as clone from "clone";
import { Schema } from "../../services/schema/schema.service";

@Component({
  selector: "bdr-field-wrapper",
  templateUrl: "./bdr-field-wrapper.component.html",
  styleUrls: ["./bdr-field-wrapper.component.scss"]
})
export class BdrFieldWrapperComponent implements OnInit, OnChanges {
  constructor() { }

  @Input()
  field: {
    field: Schema;
    initialized?: boolean;
    maxValue?: number;
    minValue?: number;
  } = {
      field: <any>{}
    };
  @Input() fieldValue: any;
  @Input() originalValue: any;
  @Input() dependentOptionValues: any[];
  @Output() onFieldChange: EventEmitter<any> = new EventEmitter();
  @Output() onInitialized: EventEmitter<any> = new EventEmitter();

  optionValues: any;
  error: boolean;
  ngOnInit() {
    if (this.dependentOptionValues) {
      this.optionValues = this.dependentOptionValues;
      return;
    }
    this.optionValues = this.field.field.optionValues;
  }

  initialized() {
    this.onInitialized.emit();
  }

  ngOnChanges(props) {
    if (this.dependentOptionValues) {
      this.optionValues = this.dependentOptionValues;
    }
  }

  changeValue(evt) {
    const value = this.validateField(evt);
    if (value === this.fieldValue) {
      this.onFieldChange.emit("");
    }
    setTimeout(() => {
      this.onFieldChange.emit(value);
    }, 10);
  }

  validateField(value) {
    const numericValue = parseFloat(value);
    if (
      !isNaN(numericValue) &&
      this.field.maxValue !== undefined &&
      numericValue > this.field.maxValue
    ) {
      return this.field.maxValue;
    }
    if (
      !isNaN(numericValue) &&
      this.field.minValue !== undefined &&
      numericValue < this.field.minValue
    ) {
      return this.field.minValue;
    }
    return value;
  }
}
