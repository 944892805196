import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterArray',
  pure: false
})
export class FilterArrayPipe implements PipeTransform {
  transform(items: Array<any>, args?: any): any {
    if (!items) {
      return [];
    }

    let filter = item => item;
    if (args) {
      if (args.value instanceof RegExp) {
        filter = item => args.value.test(item[args.key]);
      } else {
        filter = item => item[args.key] === args.value;
      }
    }

    return items.filter(filter);
  }
}
