import { Injectable } from '@angular/core';
import { AgGridColumn } from 'ag-grid-angular';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { BdrRequestService } from '../../services/bdr-request/bdr-request.service';
import {
  Notification,
  NotificationsService,
} from '../../services/notifications/notifications.service';
import { CustomHeaderComponent } from '../../shared/components/aggrid-table/custom-header/custom-header.component';
import {
  AgGridCustomColumn,
  MasterParams,
  MasterResponse,
  MasterTable,
} from '../../shared/interfaces/master-data.interface';
import { Origin } from '../../shared/interfaces/origin.interface';

@Injectable()
export class MasterDataService {
  private host = environment.host;
  private isEditable: boolean = false;
  private masterName = ''

  constructor(
    private request: BdrRequestService,
    private notificationService: NotificationsService
  ) { }

  getMtbtStockExchange() {
    return this.request.get(
      `${this.host}/table/data/sabana_inversiones_mtbt_asignacion_bolsas_vista`,
      {}
    );
  }

  getAtStockExchange() {
    return this.request.get(
      `${this.host}/table/data/sabana_inversiones_at_asignacion_bolsas_vista`,
      {}
    );
  }

  getMaster(origin: Origin, params: MasterParams = { limit: 50 }): Observable<MasterTable> {
    const { origin: originName } = origin;

    params = {
      ...params,
      ...{ offset: 0 },
    };

    return this.request.get(`${this.host}/table/data/${originName}`, params).pipe(
      map((response: MasterResponse) => ({
        headers: this.setHeaders(response.headers),
        rows: response.result,
        filtered: response.filtered,
        count: response.count,
        manualTable: response.manual_table,
        canUnloadS3: response.unload_to_s3_after_amendment,
      })),
      catchError((err) => {
        this.addNotification(
          'error',
          `Ha ocurrido un error al recuperar el maestro ${origin.title}.`
        );
        return throwError(err);
      })
    );
  }

  private setHeaders(headers: AgGridCustomColumn[]) {
    this.setSelectableColumn(headers);

    return headers.map<Partial<AgGridColumn>>((header: AgGridCustomColumn) => ({
      ...header,
      ...this.getCustomHeaders(header),
      ...this.setEditableColumns(header),
      ...this.addSelectCellEditor(header),
    }));
  }

  private addSelectCellEditor(header: AgGridCustomColumn) {
    const isSelect = header.type === 'select';
    return (
      isSelect && {
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: header.options,
        },
      }
    );
  }

  private getCustomHeaders(header: AgGridCustomColumn) {
    return {
      headerComponentFramework: CustomHeaderComponent,
      headerComponentParams: { isEditable: header.isEditable },
    };
  }

  private setSelectableColumn(headers) {
    const selectableColumn: Partial<AgGridColumn> = {
      headerName: '',
      field: 'select',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      resizable: false,
      filter: false,
      pinned: 'left',
      width: 40,
    };

    headers.unshift(selectableColumn);
  }

  private setEditableColumns(header: AgGridCustomColumn) {
    const canEdit = (params) =>
      header.primaryKey
        ? params.context.newRows.has(params.data.timestamp)
        : header.isEditable && this.isEditable;

    return {
      editable: canEdit,
    };
  }

  setEditable(isEditable: boolean) {
    this.isEditable = isEditable;
  }

  getEditable(): boolean {
    return this.isEditable;
  }

  setMasterName(name: string) {
    this.masterName = name;
  }

  getMasterName(): string {
    return this.masterName;
  }

  unloadAmendment(year: number, table: string): Observable<any> {
    return this.request.get(`${this.host}/async/unload/masterdata/${table}/`).pipe(
      catchError((err) => {
        this.addNotification('error', 'Ha ocurrido un error al exportar el maestro a S3.');
        return throwError(err);
      })
    );
  }

  editAmendments(payload: any): Observable<any> {
    const url = `${this.host}/amendments`;
    return this.request.put(url, payload).pipe(
      catchError((err) => {
        const hasTooLongError = err.error.Message.includes('too long');
        if (hasTooLongError) {
          this.addNotification(
            'error',
            'Se ha producido un error al guardar los cambios: alguno de los valores introducidos excede la longitud máxima para su campo.'
          );
        } else {
          this.addNotification('error', 'Ha ocurrido un error al editar maestros.');
        }
        return throwError(err);
      })
    );
  }

  validateAmendmenments(payload: any): Observable<any> {
    const url = `${this.host}/amendments/validate`;
    return this.request.put(url, payload).pipe(
      catchError((err) => {
        this.addNotification('error', 'Ha ocurrido un error validando datos.');
        return throwError(err);
      })
    );
  }

  closeNotification(text) {
    const notification = this.notificationService
      .getNotifications()
      .find((notification) => text === notification.message);
    this.notificationService.close(notification);
  }

  clearErrorNotifications() {
    this.notificationService.clearErrors();
  }

  addNotification(
    type: 'confirmation' | 'error' | 'info' | 'warning',
    message: string,
    fixed: boolean = false
  ) {
    const saveNotification: Notification = {
      fixed,
      type,
      message,
      popup: true,
    };
    setTimeout(() => {
      const notifications = this.notificationService.getNotifications();
      const notification = notifications.find(
        (notification) => saveNotification.message === notification.message
      );

      if (!notification) {
        this.notificationService.add(saveNotification);
      }
    }, 0);
  }

}
