import { BdrContextualMenuService } from "./../../services/bdr-contextual-menu/bdr-contextual-menu.service";

import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { MastersService } from "../../services/masters/masters.service";
import { CtxListComponent } from "../bdr-contextual-menu/ctx-list/ctx-list.component";
import { DialogService } from "../../containers/dialog/dialog.service";
import { CtxRetributiveYearSelectorComponent } from "../bdr-contextual-menu/ctx-retributive-year-selector/ctx-retributive-year-selector.component";
import { DialogConfirmComponent } from "../../containers/dialog/dialog-confirm/dialog-confirm.component";
import { DialogRetributiveYearFormComponent } from "../../containers/dialog/dialog-retributive-year-form/dialog-retributive-year-form.component";

@Component({
  selector: "bdr-retributive-year-selector",
  templateUrl: "./bdr-retributive-year-selector.component.html",
  styleUrls: ["./bdr-retributive-year-selector.component.scss"]
})
export class BdrRetributiveYearSelectorComponent implements OnInit {
  retributiveYear: string;
  retributiveYears: Array<any>;
  selectedYear = "";
  displayYears = 3;
  showMenu = false;
  @Output() changeYear: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private mastersService: MastersService,
    private ctxmenuService: BdrContextualMenuService,
    private dialogService: DialogService
  ) { }

  ngOnInit() {
    this.mastersService.getRetributiveYear().subscribe(year => {
      this.retributiveYear = year;
    });

    this.mastersService.getRetributiveYears().subscribe(years => {
      this.retributiveYears = years;
    });
  }

  openOptions(event) {
    const options = this.getOptionsRetributiveYearSelector();
    const elements = {
      complex: false,
      alignment: "right",
      onClose: this.closeMenu.bind(this),
      body: [
        {
          title: "Trabajar en un año retributivo:",
          options: options
        }
      ]
    };
    this.showMenu = true;
    this.ctxmenuService.open(
      CtxRetributiveYearSelectorComponent,
      event,
      elements
    );
  }

  getOptionsRetributiveYearSelector() {
    let years = this.getYearsForDisplay();
    let options = years.map(year => ({
      caption: year === this.retributiveYear ? `${year} (seleccionado)` : year,
      icon: "calendar",
      class: "calendar retributive",
      action: () => this.selectYearRetributive(year)
    }));
    if (this.retributiveYears.length > this.displayYears) {
      options.push({
        caption: "Ver más años",
        icon: "add",
        class: "view-more retributive",
        action: () => this.openMoreYears()
      });
    }

    return options;
  }

  private closeMenu() {
    this.showMenu = false;
  }

  private getYearsForDisplay(): Array<string> {
    const total = this.retributiveYears.length;

    if (total > this.displayYears) {
      if (this.retributiveYear) {
        const index = this.retributiveYears.findIndex(
          year => year === this.retributiveYear
        );
        if (index >= 0) {
          if (index === total - 1) {
            return this.retributiveYears.slice(
              total - this.displayYears,
              total - this.displayYears + this.displayYears
            );
          }
          if (index === 0) {
            return this.retributiveYears.slice(0, this.displayYears);
          }

          return this.retributiveYears.slice(
            index - 1,
            index - 1 + this.displayYears
          );
        }
        return this.retributiveYears.slice(
          total - this.displayYears,
          total - this.displayYears + this.displayYears
        );
      }

      return this.retributiveYears.slice(
        total - this.displayYears,
        total - this.displayYears + this.displayYears
      );
    }
    return this.retributiveYears;
  }

  selectYearRetributive(year: string) {
    this.selectedYear = year;
    const dialogOptions: any = {
      component: DialogConfirmComponent,
      // size: { width: 50, height: 50 },
      bindings: {
        inputs: {
          id: "confirm-change-year",
          content:
            "<div class='a-c'>" +
            "<p class='title'>¿Desea cambiar a otro año retributivo?</h1>" +
            "<p>Va a empezar a trabajar en el año retributivo:</p>" +
            "<p class='highlight center'>" +
            year +
            "</p>" +
            "<p>A partir de ahora, cada carga de archivos <br/>o modificación se realizará sobre el año seleccionado.</p>" +
            "</div>",
          buttonText: "Cambiar"
        },
        outputs: {
          confirm: this.selectYear.bind(this),
          closeDialog: this.closeDialog.bind(this)
        }
      },
      notHeader: true,
      fixed: true
    };
    this.dialogService.createDialog(dialogOptions);
  }

  openMoreYears() {
    const dialogOptions = {
      component: DialogRetributiveYearFormComponent,
      size: { width: 680, height: 350 },
      title: "Año retributivo",

      bindings: {
        inputs: {
          id: "change-year-form",
          selectedYear: this.retributiveYear,
          yearList: this.retributiveYears
        },
        outputs: {
          onSubmit: year => {
            this.closeDialog("change-year-form");
            this.selectYearRetributive(year);
          },
          onCancel: this.closeDialog.bind(this)
        }
      },
      fixed: true
    };

    this.dialogService.createDialog(dialogOptions);
  }

  selectYear() {
    this.closeDialog("confirm-change-year");
    this.mastersService.setRetributiveYear(this.selectedYear);
  }

  private closeDialog(id) {
    this.dialogService.closeDialog(id);
  }
}
