import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
@Component({
  selector: 'bdr-task-load-list',
  templateUrl: './bdr-task-load-list.component.html',
  styleUrls: ['./bdr-task-load-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BdrTaskLoadListComponent {
  @Input() tasks;
  constructor() { }


}
