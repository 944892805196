import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from "@angular/core";
import * as clone from 'clone'


@Component({
  selector: "dialog-mass-edit",
  templateUrl: "./dialog-mass-edit.component.html",
  styleUrls: ["./dialog-mass-edit.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogMassEditComponent implements OnInit {
  @Input() title: string;
  @Input() schemaData: any = {};
  @Input() id: string;
  @Input() defaultSchemaData: any = {};
  @Output() massEditionResponse: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeDialog: EventEmitter<string> = new EventEmitter<string>();

  displayedColumns = [];
  queryString: string;
  stringToModify: string;
  iconSearch: string;
  selectedColumn: any;

  constructor() {
    this.iconSearch = 'search';
  }

  ngOnInit() {
    this.selectedColumn = {};
    this.displayedColumns = [];
    this.stringToModify = '';


    if (this.schemaData) {
      var arrayColumns = Object.values(this.schemaData)
      this.displayedColumns = arrayColumns.filter(field => field['editable'] && field['field'] != 'ediciones').sort();
    }
  }

  handleCloseDialog(): void {
    this.closeDialog.emit(this.id);
  }

  handleSelectedColumn(column) {
    this.selectedColumn = column;
  }

  handleSaveModification() {

    this.massEditionResponse.emit({
      id: this.id,
      column: this.selectedColumn.field,
      name: this.selectedColumn.title,
      value: this.stringToModify
    });
    this.closeInputSearch();
    this.stringToModify = '';
    this.selectedColumn = {};
  }

  filterColumns(value: string) {
    this.queryString = value;
    this.iconSearch = this.queryString.length > 0 ? "cancel-search" : "search";
    this.displayedColumns = Object.values(this.schemaData)
      .filter(
        item =>
          item['editable']
          && item['field'] != 'ediciones'
          && item['title']
            .toUpperCase()
            .indexOf(value.toUpperCase()) > -1
      )
      .sort();
  }

  closeInputSearch() {
    this.queryString = '';
    this.filterColumns('');
  }

}