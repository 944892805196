import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ContextualmenuContent } from '../contextual-menu-content';
import { BdrContextualMenuService } from '../../../services/bdr-contextual-menu/bdr-contextual-menu.service';

@Component({
  selector: 'ctx-grid-cell-menu',
  templateUrl: './ctx-grid-cell-menu.component.html',
  styleUrls: ['./ctx-grid-cell-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CtxGridCellMenuComponent extends ContextualmenuContent {
  constructor(private _ctxmenuService: BdrContextualMenuService) {
    super(_ctxmenuService);
  }

  optionClick(option: any): void {
    option.action(value => this.volumetriesCallback(value, option));
    option.volumetries.loading = true;
  }

  deferredClose(): void {
    setTimeout(() => this.onClose.emit(), 0);
  }

  private volumetriesCallback(value: any, option: any): void {
    option.volumetries.value = value;
    option.volumetries.loading = false;
  }
}
