import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'bdr-icon',
  templateUrl: './bdr-icon.component.html',
  styleUrls: ['./bdr-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BdrIconComponent {
  @Input() esir: boolean;
  @Input() icon: string;
  @Input() size = '20px';
  constructor() { }
}
