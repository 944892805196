import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
// Modules
import { CommonModule } from '@angular/common';
import { ComponentsModule } from './../../components/components.module';
import { QueryBuilderModule } from './../query-builder/query-builder.module';

// Components
import { DialogListComponent } from './dialog-list/dialog-list.component';
import { DialogWrapperComponent } from './dialog-wrapper/dialog-wrapper.component';

// Directives
import { DialogDraggableDirective } from './dialog-draggable.directive';
import { DialogResizeDirective } from './dialog-resize.directive';

// Services
import { DialogI18nService } from './dialog-i18n.service';
import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';
import { DialogInstallationFormComponent } from './dialog-installation-form/dialog-installation-form.component';
import { DialogFilterComponent } from './dialog-filter/dialog-filter.component';
import { DialogActionsComponent } from './dialog-actions/dialog-actions.component';
import { DialogInvestmentsAdvance } from './dialog-investments-advance/dialog-investments-advance.component';

import { DialogLogListComponent } from './dialog-log-list/dialog-log-list.component';
import { DialogEditMultipleComponent } from './dialog-edit-multiple/dialog-edit-multiple.component';
import { DialogMessageComponent } from './dialog-message/dialog-message.component';
import { DialogRetributiveYearFormComponent } from './dialog-retributive-year-form/dialog-retributive-year-form.component';
import { DialogColumnSelectorComponent } from './dialog-column-selector/dialog-column-selector.component';
import { DialogEtls } from './dialog-etls/dialog-etls.component';
import { DialogFileUploadComponent } from './dialog-file-upload/dialog-file-upload.component';
import { DialogFilterToFolderComponent } from './dialog-filter-to-folder/dialog-filter-to-folder.component';
import { DialogStateErrorComponent } from './dialog-state-error/dialog-state-error.component';
import { DialogObservationsComponent } from './dialog-observations/dialog-observations.component';
import { DialogElaDetailInstallationsComponent } from './dialog-ela-detail-installations/dialog-ela-detail-installations.component';
import { DialogWeightsComponent } from './dialog-facilities-weights/dialog-facility-weights.component';
import { DialogSplitAppointmentComponent } from './dialog-split-appointment/dialog-split-appointment.component';
import { DialogSplitReclassificationMassEditionComponent } from './dialog-split-reclassification-mass-edition/dialog-split-reclassification-mass-edition.component';
import { DialogNotificationDetail } from './dialog-notifications-detail/dialog-notification-detail.component';
import { DialogRevertReclassificationsComponent } from './dialog-revert-reclassifications.component.ts/dialog-revert-reclassifications.component';
import { DialogRevertSplittedAppointmentsComponent } from './dialog-revert-splitted-appointments.component.ts/dialog-revert-splitted-appointments.component';
import { DialogMassEditComponent } from './dialog-mass-edit/dialog-mass-edit.component';
import { MasterDataService } from '../../master-data/services/master-data.service';
import { DialogUnlockWorks } from './dialog-unlock-works/dialog-unlock-works.component';

@NgModule({
  imports: [CommonModule, ComponentsModule, QueryBuilderModule, FormsModule],
  exports: [
    DialogListComponent,
    DialogWrapperComponent,
    DialogActionsComponent,
    DialogInstallationFormComponent,
    DialogDraggableDirective,
    DialogMessageComponent,
    DialogFilterToFolderComponent,
    DialogElaDetailInstallationsComponent,
    DialogWeightsComponent,
    DialogSplitAppointmentComponent,
    DialogSplitReclassificationMassEditionComponent,
    DialogRevertReclassificationsComponent,
    DialogRevertSplittedAppointmentsComponent,
    DialogNotificationDetail,
    DialogObservationsComponent,
    DialogInvestmentsAdvance
  ],
  declarations: [
    DialogListComponent,
    DialogWrapperComponent,
    DialogDraggableDirective,
    DialogResizeDirective,
    DialogConfirmComponent,
    DialogInstallationFormComponent,
    DialogFilterComponent,
    DialogActionsComponent,
    DialogLogListComponent,
    DialogEditMultipleComponent,
    DialogMessageComponent,
    DialogRetributiveYearFormComponent,
    DialogColumnSelectorComponent,
    DialogEtls,
    DialogFileUploadComponent,
    DialogFilterToFolderComponent,
    DialogStateErrorComponent,
    DialogElaDetailInstallationsComponent,
    DialogWeightsComponent,
    DialogSplitAppointmentComponent,
    DialogSplitReclassificationMassEditionComponent,
    DialogRevertReclassificationsComponent,
    DialogRevertSplittedAppointmentsComponent,
    DialogNotificationDetail,
    DialogObservationsComponent,
    DialogMassEditComponent,
    DialogUnlockWorks,
    DialogInvestmentsAdvance
  ],
  providers: [DialogI18nService, MasterDataService],
})
export class DialogModule {}
