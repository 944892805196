import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';
import { DataApiService } from '../../../services/data-api/data-api.service';
import { NotificationsService } from '../../../services/notifications/notifications.service';
import { UserPermissionsService } from '../../../services/user-permissions/user-permissions.service';

@Component({
  selector: 'dialog-unlock-works',
  templateUrl: './dialog-unlock-works.component.html',
  styleUrls: ['./dialog-unlock-works.component.scss'],
})
export class DialogUnlockWorks implements OnInit {
  @Input() title: string;
  @Input() id: string;
  @Input() year: string;

  @Output() onCloseAction: EventEmitter<string> = new EventEmitter<string>();

  blockSaveButton: boolean = false;

  payload = {
    workCodes: '',
  };

  constructor(
    private dataApiService: DataApiService,
    private notificationsService: NotificationsService,
    public userPermissionsService: UserPermissionsService,
  ) {}

  ngOnInit() {}

  handleCloseDialog(): void {
    this.onCloseAction.emit(this.id);
  }

  changeWorks(): void {
    // Eliminates spaces
    this.payload.workCodes = this.payload.workCodes.replace(/\s/g, '');
  }

  handleSave(): void {
    this.blockSaveButton = true;

    let payload = {
      workCodes: this.payload.workCodes.split(','),
    };

    this.dataApiService
      .worksClosureCheckOut(payload)
      .pipe(
        catchError((err) => {
          let message = `Ha ocurrido un error (${err.statusText}) al intentar desbloquear las obras`;
          this.notificationsService.add({
            type: 'error',
            message: message,
            fixed: false,
            popup: true,
          });
          return observableThrowError(err);
        }),
      )
      .subscribe((response) => {
        let message = 'Se han desbloqueado las obras';
        this.notificationsService.add({
          type: 'confirmation',
          message: message,
          fixed: false,
          popup: true,
        });

        this.onCloseAction.emit(this.id);
      });
  }
}
