import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { BdrContextualMenuService } from '../../services/bdr-contextual-menu/bdr-contextual-menu.service';

import { FilterDB } from '../../services/filter/filter.service';
import { CtxActionsMenuComponent } from '../../components/bdr-contextual-menu/ctx-actions-menu/ctx-actions-menu.component';
import { slideInOutAnimation } from '../../utils/slide-in-out.animation';
import { DownloadsService } from '../../services/downloads/downloads.service';
import { namespaceNicenames } from "../../../environments/common/origins";


@Component({
  selector: 'bdr-grid-header',
  templateUrl: './bdr-grid-header.component.html',
  styleUrls: ['./bdr-grid-header.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideInOutAnimation]
})
export class BdrGridHeaderComponent implements OnChanges {
  @Input() scope: string;
  @Input() origin: string;
  @Input() currentDataQuery: any;
  @Input() currentFilter: FilterDB;
  @Input() resultsCount: any;
  @Input() simulationDetails: { id: string; plan: any; status: string };
  @Input() title: string;
  @Input() canGenerate: boolean;
  @Input() selectedRows: boolean;
  @Input() selectedRowsNumber: number;
  @Input() actionsMenuOptions: any[];
  @Input() classicView: boolean;

  @Input() canManageFastFilters: boolean;
  @Input() canSelectColumns: boolean;
  @Input() canColumnSearch: boolean;
  @Input() appliedFilters: FilterDB[] = [];
  @Input() columnsSearchVisible: boolean;
  @Input() currentShowingRows: number;
  @Input() headerActions: any[];
  @Input() fakeIcon: any;
  @Input() editMode: boolean;
  @Input() saving: boolean;
  @Input() changedColumns: boolean;
  @Input() disabledVisualization: boolean;

  @Output() resetFilterRequest: EventEmitter<FilterDB> = new EventEmitter<FilterDB>();

  @Output() createViewRequest: EventEmitter<void> = new EventEmitter<void>();
  @Output() changeFastFiltersVisibility: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() outputColumnSearch: EventEmitter<void> = new EventEmitter<void>();
  @Output() generateAction: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSaveChanges: EventEmitter<void> = new EventEmitter<void>();
  @Output() onCancelSave: EventEmitter<void> = new EventEmitter<void>();

  downloading: boolean;
  exportLink: string;
  fastFiltersShown: boolean;
  animationStateActions = 'show';
  animationStateRowActions = 'hide';
  constructor(
    private ctxmenuService: BdrContextualMenuService,
    public downloadsService: DownloadsService
  ) {
    this.downloading = false;
    this.exportLink = '';
    this.actionsMenuOptions = null;
    this.fastFiltersShown = false;
  }

  ngOnChanges(changes) {
    if (changes.headerActions) {
      if (this.animationStateActions === 'show' || this.animationStateRowActions === 'show') {
        this.animationStateActions = 'hide';
      }
    }
  }

  showActionsMenu(evt: MouseEvent): void {
    if (this.actionsMenuOptions) {
      const options = {
        complex: true,
        body: {
          actions: this.actionsMenuOptions
        }
      };

      this.ctxmenuService.open(CtxActionsMenuComponent, evt, options);
    }
  }

  resetFilter(filter: FilterDB): void {
    this.resetFilterRequest.emit(filter);
  }

  notificationTextByOrigin() {
    return "Está modificando datos que afectan a " + namespaceNicenames[this.origin];
  }

  // c: routeColClickEvt
  toggleColumnSearch() {
    this.outputColumnSearch.emit();
  }

  onGenerateAction(evt) {
    this.generateAction.emit(evt);
  }

  saveChanges() {
    this.onSaveChanges.emit();
  }
  cancelActions() {
    this.onCancelSave.emit();
  }
}
