import {
  Component,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  ChangeDetectionStrategy
} from "@angular/core";

@Component({
  selector: "bdr-input",
  templateUrl: "./bdr-input.component.html",
  styleUrls: ["./bdr-input.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BdrInputComponent implements OnChanges {
  @Input() model: string;
  @Input() caption: string;
  @Input() type: string;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() size: string;
  @Input() disabled: boolean;
  @Input() error: boolean;
  @Input() maxLength: boolean;
  @Input() minValue: number;
  @Input() maxValue: number;
  @Input() errorMsg: string;
  @Input() showError: boolean;
  @Input() validationInfo: string;
  @Input() horizontal: boolean;
  @Input() bottomError: string;
  @Input() button: boolean;
  @Input() loading: boolean;

  @Output() modelChange: EventEmitter<string>;
  @Output() change: EventEmitter<string>;
  @Output() focusChange: EventEmitter<boolean>;
  @Output() errorChange: EventEmitter<string>;

  valid: boolean;
  hasFocus: boolean;

  constructor() {
    this.model = "";
    this.caption = "";
    this.type = "text";
    this.placeholder = "";
    this.size = "regular";
    this.disabled = false;
    this.error = false;
    this.errorMsg = "";
    this.showError = true;
    this.loading = false;

    this.modelChange = new EventEmitter<string>();
    this.change = new EventEmitter<string>();
    this.focusChange = new EventEmitter<boolean>();
    this.errorChange = new EventEmitter<string>();

    this.valid = true;
    this.hasFocus = false;
  }

  ngOnChanges(changes) {
    if (changes.hasOwnProperty("error")) {
      const error = this.error ? this.errorMsg : "";
      this.errorChange.emit(error);
    }
  }

  emitChange(value: string): void {
    if (!this.disabled) {
      this.modelChange.emit(value);
    }
  }

  emitChangeValidate(value: string): void {
    if (!this.disabled) {
      this.change.emit(value);
    }
  }

  focus() {
    this.hasFocus = true;
    this.focusChange.emit(true);
  }

  blur() {
    this.hasFocus = false;
    this.focusChange.emit(false);
  }
}
