import {
  Directive,
  ElementRef,
  Renderer2,
  AfterViewInit,
  HostListener,
  Input
} from '@angular/core';
import { roundedRect } from '../../util';

@Directive({
  selector: '[bdrDialogResize]'
})
export class DialogResizeDirective implements AfterViewInit {
  @Input('bdrDialogResize') rulerCtx: CanvasRenderingContext2D;

  private WINDOW_MIN_WIDTH = 630;
  private WINDOW_MIN_HEIGHT = 500;

  // dom de la ventana
  private parent: any;

  // coordenadas de la ventana
  private x = 0;
  private y = 0;

  // ancho y alto de la ventana
  private w = 0;
  private h = 0;

  // posición del puntero mientras redimensiona
  private cx = 0;
  private cy = 0;

  // diferencia entre la posición del puntero cuando empecé a mover y las coordenadas de la esquina inferior derecha de la ventana
  private dw = 0;
  private dh = 0;

  // flag para saber si la intención es redimensionar la ventana
  private readyToMove = false;

  // flag para saber si ya está oculto cuando lo empiezo a redimensionar
  private alreadyHidden = false;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit(): void {
    this.parent = this.el.nativeElement.closest('.dialog');
  }

  @HostListener('mousedown', ['$event'])
  mouseDown(event: MouseEvent): boolean {
    this.readyToMove = true;

    const boundaries = this.parent.getBoundingClientRect();
    this.x = boundaries.left;
    this.y = boundaries.top;
    this.w = boundaries.width;
    this.h = boundaries.height;

    this.cx = event.clientX;
    this.cy = event.clientY;

    this.dw = this.x + this.w - this.cx;
    this.dh = this.y + this.h - this.cy;

    return false;
  }

  @HostListener('body:mousemove', ['$event'])
  mosueMove(event: MouseEvent): boolean {
    if (this.readyToMove) {
      if (!this.alreadyHidden) {
        this.renderer.addClass(this.parent, 'hide');
        this.alreadyHidden = true;
      }

      this.cx = event.clientX;
      this.cy = event.clientY;

      this.drawRuler();
    }
    return !this.readyToMove;
  }

  @HostListener('body:mouseup', ['$event'])
  mouseUp(event: MouseEvent): boolean {
    if (this.readyToMove) {
      this.clearRuler();

      this.w = this.safeW(this.cx + this.dw - this.x);
      this.h = this.safeH(this.cy + this.dh - this.y);

      this.renderer.setStyle(this.parent, 'width', `${this.w}px`);
      this.renderer.setStyle(this.parent, 'height', `${this.h}px`);
      this.renderer.addClass(this.parent, 'hide');
      this.readyToMove = false;
      this.alreadyHidden = false;
    }

    return !this.readyToMove;
  }

  private clearRuler(): void {
    this.rulerCtx.clearRect(
      0,
      0,
      this.rulerCtx.canvas.width,
      this.rulerCtx.canvas.height
    );
  }

  private drawRuler(): void {
    this.clearRuler();

    const x = this.x - 3;
    const y = this.y - 3;
    const w = this.safeW(this.cx + this.dw - this.x) + 6;
    const h = this.safeH(this.cy + this.dh - this.y) + 6;

    this.rulerCtx.strokeStyle = '#000';
    this.rulerCtx.fillStyle = '#F5F5F5';
    this.rulerCtx.lineWidth = 3;
    roundedRect(this.rulerCtx, x, y, w, h, 5);
    this.rulerCtx.fill();
    this.rulerCtx.strokeStyle = '#fff';
    this.rulerCtx.lineWidth = 1;
    this.rulerCtx.rect(x + 2, y + 2, w - 4, h - 4);
    this.rulerCtx.stroke();

    let iconSize = 68;
    if (w <= iconSize) {
      iconSize = Math.floor(w * 0.75);
    }
    if (h <= iconSize) {
      iconSize = Math.floor(h * 0.75);
    }
    const icon = new Image(iconSize, iconSize);
    icon.src = '/assets/images/resize.svg';
    const iconX = Math.ceil(x + w / 2 - iconSize / 2);
    const iconY = Math.ceil(y + h / 2 - iconSize / 2);
    this.rulerCtx.drawImage(icon, iconX, iconY, iconSize, iconSize);
  }

  private safeW(w: number): number {
    return Math.round(
      Math.min(
        Math.max(w, this.WINDOW_MIN_WIDTH),
        this.parent.closest('body').clientWidth - this.x - 50
      )
    );
  }

  private safeH(h: number): number {
    return Math.round(
      Math.min(
        Math.max(h, this.WINDOW_MIN_HEIGHT),
        this.parent.closest('body').clientHeight - this.y - 50
      )
    );
  }
}
