import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

// Modules
import { CommonModule } from '@angular/common';
import { ComponentsModule } from './../../components/components.module';
import { DialogModule } from './../dialog/dialog.module';
import { FormsModule } from '@angular/forms';

//Services
import { BdrValidationsService } from '../../services/bdr-validations/bdr-validations.service';
// Components
import { GridHandlerViewComponent } from './grid-handler-view.component';

@NgModule({
  imports: [CommonModule, ComponentsModule, DialogModule, FormsModule],
  exports: [GridHandlerViewComponent],
  declarations: [GridHandlerViewComponent],
  providers: [BdrValidationsService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class GridHandlerViewModule { }
