import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';

// Modules
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from './../../components/components.module';

// Components
import { NotificationsWrapperComponent } from './notifications-wrapper.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ComponentsModule,
    FormsModule
  ],
  exports: [
    NotificationsWrapperComponent
  ],
  declarations: [
    NotificationsWrapperComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class NotificationsWrapperModule { }
