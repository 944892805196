import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { DataApiService } from '../data-api/data-api.service';
import { startWith } from 'rxjs/operators';
import { OriginService } from '../origin/origin.service';
@Injectable()
export class LoadsService {
  lastLoad: any = {};
  lastAutomaticLoad: any = {};
  lastLoadSubject: Subject<any> = new Subject<any>();
  lastAutomaticLoadSubject: Subject<any> = new Subject<any>();

  constructor(
    private dataApi: DataApiService, 
    private originService: OriginService) {
  }

  fetchEtlLastExecution(retributiveYear) {
    let params = {
      query: {
        filters: [
          {
            rules: [
              {
                condition: 'AND',
                rules: [
                  {
                    operator: 'equal',
                    id: 'etl_year',
                    field: 'etl_year',
                    type: 'string',
                    value: `${retributiveYear}`
                  }
                ]
              }
            ],
            condition: 'AND'
          }
        ],
        logic: 'and'
      }
    };
    return this.dataApi.data('bdr_etl_last_execution', params);
  }

  currentYearAutomaticLoad(year) {
    return { date: `01/01/${year + 1}`, time: '00:00' };
  }

  fetchLastAutomaticLoad(retributive_year) {
    let origin = this.originService.getByKey('loadsLogAudit');
    let params = {
      sort: [
        {
          dir: 'desc',
          field: 'endtime'
        }
      ],
      query: {
        filters: [
          {
            rules: [
              {
                condition: 'AND',
                rules: [
                  {
                    operator: 'not_equal',
                    id: 'loadsquery-not-equal-maestros',
                    field: 'step',
                    type: 'string',
                    value: 'FormulariosLoad'
                  },
                  {
                    operator: 'not_equal',
                    id: 'loadsquery-not-equal-maestros',
                    field: 'step',
                    type: 'string',
                    value: 'MaestrosLoad'
                  },
                  {
                    operator: 'equal',
                    id: 'loadsquery-equal-restributive-year',
                    field: 'year',
                    type: 'string',
                    value: retributive_year.toString()
                  }
                ]
              }
            ],
            condition: 'AND'
          }
        ],
        logic: 'and'
      },
      limit: 1
    };
    return this.dataApi.data(origin, params);
  }

  getLastLoad() {
    return this.lastLoadSubject.asObservable().pipe(startWith(this.lastLoad));
  }

  clearLoads() {
    return this.dataApi.clearLoads();
  }
}
