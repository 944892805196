import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { SelectOption } from '../../models/common';

@Component({
  selector: 'bdr-select-pills',
  templateUrl: './bdr-select-pills.component.html',
  styleUrls: ['./bdr-select-pills.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BdrSelectPillsComponent {
  @Input() vertical = false;
  @Input() model: string;
  @Input() tabs: boolean;
  @Input() style: string = 'compact-tabs';
  @Input() options: SelectOption[];

  @Output() modelChange: EventEmitter<string>;

  isTabSelected: boolean = false;

  constructor() {
    this.options = [];
    this.modelChange = new EventEmitter<string>();
  }

  clickHandler(option) {
    if (!!option.disabled) {
      return;
    }
    this.isTabSelected = true;
    this.modelChange.emit(option.value);
  }
}
