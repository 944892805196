import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { TasksService } from '../../../services/tasks/tasks.service';
import { MastersService } from '../../../services/masters/masters.service';

@Component({
  selector: 'dialog-etls',
  templateUrl: './dialog-etls.component.html',
  styleUrls: ['./dialog-etls.component.scss'],
})
export class DialogEtls implements OnInit {
  @Input() title: string;
  @Input() id: string;

  @Output() uploadFileEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCloseAction: EventEmitter<string> = new EventEmitter<string>();

  displayed = {};
  formErrors = {};
  year;

  reloads = {
    masterdata: false,
    'masterdata-without-transactionals': false,
    inventory: false,
    investments: false,
    'forms': false,
    'investments-adjustment': false,
    cnmc: false,
    installations: false,
  };

  file: File;
  allDeselected = false;
  queryString: string;
  textEmptySearch: boolean;
  iconSearch: string;
  fileUpload: File;
  filePath: string;
  constructor(
    private tasksService: TasksService,
    private mastersService: MastersService,
  ) {
    this.iconSearch = 'search';
  }

  ngOnInit() {
    this.mastersService.getRetributiveYear().subscribe((year) => (this.year = year));
  }

  objectKeys(paramObj) {
    return Object.keys(paramObj);
  }

  fileUpdate(event) {
    this.fileUpload = event.target.files[0];
  }

  resetFileUpload(event) {
    event.preventDefault();
    this.fileUpload = null;
  }

  handleCloseDialog(): void {
    this.onCloseAction.emit(this.id);
  }

  handleReloadSheets(): void {
    this.tasksService.sheetsReload({
      sheets: Object.keys(this.reloads)
        .filter((r) => this.reloads[r])
        .map((r) => {
          const sheet = { name: r, year: this.year, extra: {} };
          if (r.includes('masterdata')) {
            sheet.extra['skip_transactional'] = r === 'masterdata-without-transactionals';
            sheet.name = sheet.name.replace('-without-transactionals', '');
          }
          return sheet;
        }),
    });
  }
}
