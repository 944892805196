import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { ClipboardModule } from "ngx-clipboard";

import { ComponentsModule } from "./../components.module";
import { DialogModule } from "./../../containers/dialog/dialog.module";

import { BdrContextualMenuComponent } from "./bdr-contextual-menu.component";
import { CtxListComponent } from "./ctx-list/ctx-list.component";
import { CtxTextInfoComponent } from "./ctx-text-info/ctx-text-info.component";

import { CtxGridCellMenuComponent } from "./ctx-grid-cell-menu/ctx-grid-cell-menu.component";
import { CtxChooseTagComponent } from "./ctx-choose-tag/ctx-choose-tag.component";
import { CtxInputMultipleComponent } from "./ctx-input-multiple/ctx-input-multiple.component";
import { CtxExportComponent } from "./ctx-export/ctx-export.component";
import { CtxLogInfoComponent } from "./ctx-log-info/ctx-log-info.component";
import { CtxAppliedFiltersComponent } from "./ctx-applied-filters/ctx-applied-filters.component";
import { CtxRetributiveYearSelectorComponent } from "./ctx-retributive-year-selector/ctx-retributive-year-selector.component";
import { CtxGridSortMenuComponent } from "./ctx-grid-sort-menu/ctx-grid-sort-menu.component";
import { CtxGenerateFileInventoryComponent } from "./ctx-generate-file-inventory/ctx-generate-file-inventory.component";
import { CtxActionsMenuComponent } from "./ctx-actions-menu/ctx-actions-menu.component";

@NgModule({
    imports: [
        ClipboardModule,
        CommonModule,
        ComponentsModule,
        FormsModule,
        DialogModule
    ],
    exports: [BdrContextualMenuComponent],
    declarations: [
        BdrContextualMenuComponent,
        CtxAppliedFiltersComponent,
        CtxListComponent,
        CtxTextInfoComponent,
        CtxGridCellMenuComponent,
        CtxChooseTagComponent,
        CtxInputMultipleComponent,
        CtxGenerateFileInventoryComponent,
        CtxExportComponent,
        CtxLogInfoComponent,
        CtxRetributiveYearSelectorComponent,
        CtxGridSortMenuComponent,
        CtxActionsMenuComponent
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BdrContextualMenuModule { }
