import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Subject } from 'rxjs';

import { ContextualmenuContent } from '../contextual-menu-content';
import { BdrContextualMenuService } from '../../../services/bdr-contextual-menu/bdr-contextual-menu.service';
import { DownloadsService } from '../../../services/downloads/downloads.service';
import { Download } from '../../../services/downloads/downloads-list';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'bdr-ctx-download-list',
  templateUrl: './ctx-download-list.component.html',
  styleUrls: ['./ctx-download-list.component.scss']
})
export class CtxDownloadList extends ContextualmenuContent implements OnInit, OnDestroy {
  expanded: boolean;
  downloads: Array<Download> = [];
  private destroy$: Subject<any> = new Subject()
  constructor(
    private _ctxmenuService: BdrContextualMenuService,
    private downloadsService: DownloadsService
  ) {
    super(_ctxmenuService);
  }

  ngOnInit() {
    this.downloadsService
      .getDownloadsObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(downloads => {
        this.downloads = downloads;
        this.expanded = true;
      });
  }

  cancelAll() {
    this.downloadsService.cancelAll();
    this.downloadsService.downloadReady = false;
  }
  cancelOne(index) {
    this.downloadsService.cancelOne(index);
    this.downloadsService.downloadReady = false;
  }
  ngOnDestroy() {
    this.destroy$.next(true)
    this.destroy$.complete()
  }
}
