// Core
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
// Modules
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
// Components
import { AppComponent } from './app.component';
import { BdrContextualMenuModule } from './components/bdr-contextual-menu/bdr-contextual-menu.module';
import { ComponentsModule } from './components/components.module';
import { ContainersModule } from './containers/containers.module';
import { NotificationsWrapperModule } from './containers/notifications-wrapper/notifications-wrapper.module';
import { WorkClosureModule } from './containers/work-closure/work-closure.module';
// Guards
import { LoggedGuard } from './guards/logged-guard';
import { httpInterceptorProviders } from './http-interceptors/index';
import { MenuComponent } from './menu/menu.component';
import { PagesModule } from './pages/pages.module';
import { AuthService } from './services/auth/auth.service';
import { CognitoUtil } from './services/auth/cognito-util';
import { BdrContextualMenuService } from './services/bdr-contextual-menu/bdr-contextual-menu.service';
import { BdrRequestService } from './services/bdr-request/bdr-request.service';
import { DataApiService } from './services/data-api/data-api.service';
import { FilterService } from './services/filter/filter.service';
import { GridService } from './services/grid/grid.service';
// Services
import { InvestmentFilterService } from './services/investment-filter/investment-filter.service';
import { LoadsService } from './services/loads/loads.service';
import { LogService } from './services/log/log.service';
import { MastersService } from './services/masters/masters.service';
import { NotificationsService } from './services/notifications/notifications.service';
import { SchemaService } from './services/schema/schema.service';
import { TasksService } from './services/tasks/tasks.service';
import { WindowService } from './services/window/window.service';
import { UserPermissionsService } from './services/user-permissions/user-permissions.service';

@NgModule({
  declarations: [AppComponent, MenuComponent],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ComponentsModule,
    ContainersModule,
    BdrContextualMenuModule,
    PagesModule,
    NotificationsWrapperModule,
    WorkClosureModule,
  ],
  providers: [
    CognitoUtil,
    AuthService,
    BdrRequestService,
    NotificationsService,
    GridService,
    FilterService,
    DataApiService,
    SchemaService,
    BdrContextualMenuService,
    InvestmentFilterService,
    WindowService,
    TasksService,
    LogService,
    MastersService,
    httpInterceptorProviders,
    LoadsService,
    LoggedGuard,
    UserPermissionsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
