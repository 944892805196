import { Injectable } from '@angular/core';

function getWindow(): any {
  return window;
}

@Injectable()
export class WindowService {
  pathMatch(path) {
    return this.getLocationHref().includes(path);
  }
  get window(): any {
    return getWindow();
  }
  getLocationHref(): string {
    return getWindow().location.href;
  }
}
