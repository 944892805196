import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ElementRef,
  OnInit,
  Renderer2
} from "@angular/core";

import { DialogService, Dialog } from "./../dialog.service";
import { BdrContextualMenuComponent } from "../../../components/bdr-contextual-menu/bdr-contextual-menu.component";
import {
  BdrContextualMenuService,
  CtxContext
} from "../../../services/bdr-contextual-menu/bdr-contextual-menu.service";

@Component({
  selector: "bdr-dialog-wrapper",
  templateUrl: "./dialog-wrapper.component.html",
  styleUrls: ["./dialog-wrapper.component.scss"]
})
export class DialogWrapperComponent implements OnInit {
  @Input() id: string;
  @Input() dialog: Dialog;
  @Input() rulerCtx: CanvasRenderingContext2D;

  @Output()
  minimizeDialogRequest: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  restoreDialogRequest: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  focusDialogRequest: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  onDialogTitleChange: EventEmitter<string> = new EventEmitter<string>();

  showEditTitleIcon = false;
  ctxMenuComponent = BdrContextualMenuComponent;
  ctxDialogContext = CtxContext.dialog;
  editTitle = false;

  private host_top: string;
  private host_left: string;
  private host_width: string;
  private host_height: string;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private dialogService: DialogService,
    public ctxmenuService: BdrContextualMenuService
  ) { }

  @HostListener("click", ["$event"])
  focus(event: MouseEvent): void {
    this.focusDialogRequest.emit(this.id);
  }

  ngOnInit() {
    if (this.dialog && this.dialog.bindings && this.dialog.bindings.outputs) {
      this.dialog.bindings.outputs["onCloseAction"] = this.closeDialog.bind(
        this
      );
      this.dialog.bindings.outputs[
        "onMinimizeAction"
      ] = this.minimizeDialog.bind(this);
    }
  }

  closeDialog(): void {
    this.dialogService.closeDialog(this.id);
  }

  minimizeDialog(): void {
    this.host_top = this.el.nativeElement.style.top;
    this.host_left = this.el.nativeElement.style.left;
    this.host_width = this.el.nativeElement.offsetWidth;
    this.host_height = this.el.nativeElement.offsetHeight;

    this.moveDialog(this.host_top, this.host_left, `auto`, `auto`);
    this.dialogService.minimizeDialog(this.id);
    this.minimizeDialogRequest.emit(this.id);
  }

  restoreDialog(): void {
    this.moveDialog(
      this.host_top,
      this.host_left,
      `${this.host_width}px`,
      `${this.host_height}px`
    );
    this.restoreDialogRequest.emit(this.id);
  }

  toggleDialogMinimisation(): void {
    if (this.dialog.minimized) {
      this.restoreDialog();
    } else {
      this.minimizeDialog();
      this.editTitle = false;
    }
  }

  public moveDialog(
    top: string,
    left: string,
    width: string,
    height: string
  ): void {
    this.renderer.setStyle(this.el.nativeElement, "top", top);
    this.renderer.setStyle(this.el.nativeElement, "left", left);
    this.renderer.setStyle(this.el.nativeElement, "width", width);
    this.renderer.setStyle(this.el.nativeElement, "height", height);
  }

  changeTitle(event) {
    const value = event.target.value;
    this.onDialogTitleChange.emit(value);
  }

  saveTitle(title) {
    if (title.length > 0) {
      this.editTitle = false;
    }
  }
}
